import React from 'react';
import PT from 'prop-types';
import FacebookAuth from 'react-facebook-auth';
import { IconButton } from 'components/shared';
import { FaFacebook } from "../icons";

const { FACEBOOK_APP_ID } = process.env;

function FacebookAuthButton({ authType, title, onSuccess, onFailure, ...rest }) {
  const isSingIn = authType === 'signIn';
  const buttonTitle = isSingIn ? 'Sign In With Facebook' : 'Sign Up With Facebook';

  const handleSuccess = (data) => {
    const { accessToken, email } = data;
    onSuccess({ fbToken: accessToken, email });
  };

  const handleFailure = () => {
    console.log('FB auth failure');
    if (onFailure) onFailure();
  };

  return (
    <FacebookAuth
      appId={FACEBOOK_APP_ID}
      fields="name,email"
      callback={handleSuccess}
      onFailure={handleFailure}
      // eslint-disable-next-line react/no-unstable-nested-components
      component={({ isSdkLoaded, isDisabled, isProcessing, onClick }) => (
        <IconButton
          variant="filled-secondary"
          testID={`sign-${isSingIn ? 'in' : 'up'}-fb-button`}
          onClick={onClick}
          {...rest}
          style={{
            height: 43,
            background: 'white',
            color: '#3c4044',
            borderRadius: 4,
            fontSize: 12
          }}
        >
          <FaFacebook style={{ color: "#4267B2", marginRight: 10 }}/>{title || buttonTitle}
        </IconButton>
      )}
    />
  )
}

FacebookAuthButton.propTypes = {
  authType: PT.oneOf(['signIn', 'signUp']).isRequired,
  title: PT.string,
  onSuccess: PT.func.isRequired,
  onFailure: PT.func
};

FacebookAuthButton.defaultProps = {
  title: '',
  onFailure: () => {}
};

export default FacebookAuthButton;
