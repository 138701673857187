import React, { useRef, useState, memo } from 'react';
import PT from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import clsx from 'clsx';
import map from 'lodash/map';
import { getRoutes } from 'utils';
import { styled } from 'components';
import { IconButton } from 'components/shared';
import { MdChevronRight } from 'components/icons';
import JobFitCard from './JobFitCard';

const GAP = 20;
const CARD_WIDTH = 206;

const StyledRoot = styled('div')(({ theme }) => ({
  '&.carousel__wrapper': {
    position: 'relative',
    height: 102,
    display: 'flex',
    alignItems: 'center'
  },
  '& .carousel__controller': {
    position: 'absolute',
    right: 0,
    paddingLeft: 20
  },
  '& .carousel__controller-btn': {
    width: 32,
    height: 32,
    color: '#49469D',
    boxShadow: '0px 1px 5px #00000033'
  },
  '& .carousel__container': {
    position: 'absolute',
    right: 60,
    left: 0,
    overflowX: 'hidden',
    // maxWidth: CARD_WIDTH * 3 + 2 * GAP,
    padding: 5
  },
  '& .carousel__track': {
    touchAction: 'pan-x',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    gap: GAP
  },
  '& .carousel__item': {
    minWidth: CARD_WIDTH,
    display: 'flex'
  },
  '& .carousel__item-card': {
    '&.jobFitCard': {
      width: '100%',
      padding: 10,
      border: 'none',
      userSelect: 'none', // prevent link/image drag
      touchAction: 'none' // prevent link/image drag
    },
    '& .jobFitCard__title': {
      fontSize: 12,
      color: 'initial',
      wordBreak: 'break-word'
    },
    '& .jobFitCard__image': {
      minWidth: 72,
      height: 72
    }
  }
}));

function JobFitsCarousel({ jobs }) {
  const navigate = useNavigate();
  const [{ x }, springApi] = useSpring(() => ({ x: 0, config: { tension: 60, friction: 60 } }));
  const trackRef = useRef();
  const prevPos = useRef(0);

  const setNewPosition = (pos) => {
    const trackScrollWidth = trackRef.current.scrollWidth;
    const trackVisibleWidth = trackRef.current.clientWidth;
    const max = trackScrollWidth - trackVisibleWidth;
    const overMax = (pos < 0 ? pos * -1 : pos) > max;

    let position = overMax ? -max : pos;
    if (pos >= 0) position = 0;
    springApi.start({ x: position });
    prevPos.current = position;
    return position;
  };

  const bind = useDrag(
    ({ event, memo: lastValue, movement: [mx] }) => {
      event.preventDefault();
      let newPos = (lastValue || prevPos.current) + mx;
      newPos = Number(Number(newPos).toFixed(2));
      return setNewPosition(newPos);
    },
    {
      axis: 'x',
      // delay: 1500,
      filterTaps: true
    }
  );

  const scrollRight = () => {
    const newPos = prevPos.current - 200;
    setNewPosition(newPos);
  };

  if (!jobs.length) return null;

  return (
    <StyledRoot className="carousel__wrapper">
      <div className="carousel__container">
        <animated.div
          ref={trackRef}
          {...bind()}
          className="carousel__track"
          style={{ transform: x.to((v) => `translate3d(${v}px, 0 ,0)`) }}
        >
          {map(jobs, (job) => (
            <div key={`jobFit__${job.id}`} className="carousel__item">
              <JobFitCard
                job={job}
                className="carousel__item-card elevatedCard"
                isRouterLink={false}
                to={undefined}
                onClick={() => navigate(getRoutes({ id: job.id }).employee.job)}
              />
            </div>
          ))}
        </animated.div>
      </div>
      {jobs.length > 2 && (
        <div className="carousel__controller">
          <IconButton color="primary" className="carousel__controller-btn" onClick={scrollRight}>
            <MdChevronRight />
          </IconButton>
        </div>
      )}
    </StyledRoot>
  );
}

JobFitsCarousel.propTypes = {
  jobs: PT.arrayOf(
    PT.shape({
      id: PT.number,
      imageUrl: PT.string,
      title: PT.string
    })
  ).isRequired
};

export default memo(JobFitsCarousel);
