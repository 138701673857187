import React, { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, useMatch } from 'react-router-dom';
import parseDate from 'date-fns/parse';
import format from 'date-fns/format';
import { qaAttr, getRoutes } from 'utils';
import { Box, styled } from 'components';
import { IconButton, Spinner, Button } from 'components/shared';
import { CloseJobDialog } from 'components/dialogs';
import { MdEdit, MdChevronRight } from 'components/icons';
import { ToggleJobActiveState } from 'components/Job';
import { useEmployerProfileQuery, useMediaQueryMatches } from 'hooks';
import { CLOSE_JOB, GET_JOB } from 'api';
import styles from 'styles/Dashboard/JobDetails';
import defaultImg from 'assets/img/job_default.png';
import ApplicantsDashboard from './ApplicantsDashboard';

const StyledRoot = styled('div')(styles);

const ROUTES = getRoutes();

function EmployerJobDetails(props) {
  const profileId = localStorage.getItem('profileId');
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { id: idParam } = useParams();

  const matchApplicants = useMatch(ROUTES.employer.job);
  const isApplicantsPage = !!matchApplicants;

  const { loading: profileLoading, canPostJob } = useEmployerProfileQuery();

  const { data: jobData, loading: jobLoading } = useQuery(GET_JOB, {
    variables: { jobId: Number(idParam) },
    cachePolicy: 'cache-first',
    errorPolicy: 'all'
  });

  const {
    active = true,
    description = '',
    draft = false,
    employerProfileProfileId,
    hireDate = '',
    id,
    imageUrl = '',
    location = '',
    payRange = '',
    requirements = '',
    title = '',
    webUrl = ''
  } = jobData?.job || {};
  const formattedHireDate = hireDate
    ? format(parseDate(hireDate, 'yyyy-MM-dd', new Date()), 'MM-dd-yyyy')
    : '';
  const isEmployerOwnJob = parseInt(profileId) === employerProfileProfileId;

  function renderJobDetails() {
    return (
      <div className="detailsCardWrapper">
        <div className="detailsCard">
          <img src={imageUrl || defaultImg} alt="" className="detailsCard__photo" />
          <Box p="24px 18px 16px">
            <Box mb="16px" display="flex" alignItems="flex-start" justifyContent="space-between">
              <h2 className="detailsCard__title" {...qaAttr('job-dashboard-job-title')}>
                {title}
              </h2>
              {isEmployerOwnJob && (
                <IconButton
                  isRouterLink
                  to={getRoutes({ id: idParam }).employer.job_edit}
                  color="primary"
                  aria-label="edit"
                  sx={{
                    width: 25,
                    height: 25,
                    padding: 0,
                    marginLeft: 'auto',
                    marginRight: 0
                  }}
                  testID="job-dashboard-edit-button"
                >
                  <MdEdit />
                </IconButton>
              )}
            </Box>
            <Box mb="20px">
              <Box mb="6px" className="textSecondary">
                Interview Address
              </Box>
              <div color="textMain">{location}</div>
            </Box>
            <Box mb="20px" display="flex" justifyContent="space-between">
              <div>
                <Box mb="6px" className="textSecondary">
                  Pay Range
                </Box>
                <div color="textMain">{`${payRange}`}</div>
              </div>
              <Box ml="25px">
                <Box mb="6px" className="textSecondary">
                  Hire Date
                </Box>
                <div color="textMain">{formattedHireDate}</div>
              </Box>
            </Box>
            <Box mb="20px">
              <Box mb="6px" className="textSecondary">
                Job Description
              </Box>
              <div color="textMain">{description}</div>
            </Box>
            <Box mb="20px">
              <Box mb="6px" className="textSecondary">
                Requirements
              </Box>
              <div color="textMain">{requirements}</div>
            </Box>
            {isEmployerOwnJob && !draft && (
              <ToggleJobActiveState jobId={id} jobTitle={title}>
                {({ openJob, closeJob, loading: activationLoading }) => (
                  <Button
                    variant="filled-primary"
                    endIcon={activationLoading && <Spinner size={24} />}
                    disabled={activationLoading || profileLoading || (!active && !canPostJob)}
                    sx={{ height: 50, width: '100%' }}
                    onClick={() => (!active ? openJob() : closeJob())}
                    testID={`job-dashboard-${!active ? 'open' : 'close'}-job-button`}
                  >
                    {!active ? 'Re-open Job' : 'Close Job Posting'}
                  </Button>
                )}
              </ToggleJobActiveState>
            )}
          </Box>
        </div>
      </div>
    );
  }

  function renderMobileContent() {
    return isApplicantsPage ? (
      <Box px="16px">
        <Box width="100%" maxWidth={382} mx="auto">
          <Box mb="15px" display="flex" alignItems="flex-start">
            <Box mr="10px" fontWeight="bold">
              {title}
            </Box>
            <Button
              isRouterLink
              to={getRoutes({ id: idParam }).employer.job_edit}
              variant="filled-primary"
              className="jobEditBtn"
            >
              Edit
            </Button>
          </Box>
          <Button
            isRouterLink
            to={getRoutes({ id: idParam }).employer.job_approved}
            variant="text"
            endIcon={<MdChevronRight />}
            className="hiredLink"
          >
            Hired someone for this position
          </Button>
        </Box>
      </Box>
    ) : null;
  }

  return (
    <StyledRoot className="jobDashboardContainer">
      {isDesktop ? renderJobDetails() : renderMobileContent()}
      {isEmployerOwnJob && <ApplicantsDashboard jobId={Number(id || idParam)} job={jobData?.job} />}
    </StyledRoot>
  );
}

EmployerJobDetails.propTypes = {};

EmployerJobDetails.defaultProps = {};

export default EmployerJobDetails;
