import React, { useState, useEffect, useRef } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { useSelect } from '@mui/base';
import { MdExpandMore, MdExpandLess } from 'components/icons';
import { qaAttr } from 'utils';

const renderSelectedValue = (value, options) => {
  const selectedOption = options.find(
    (option) => !option.delimiter && isEqual(option.value, value)
  );
  return selectedOption ? selectedOption.label : null;
};

function RootSelect(props) {
  const {
    buttonComponent,
    buttonProps,
    classes,
    className,
    closeIcon,
    containerComponent,
    disabled,
    error,
    getKey,
    getOptionTestID = (i, optionProps) => '',
    helperText,
    helperTextComponent,
    helperTextProps,
    iconProps,
    id = '',
    label,
    labelComponent,
    labelProps,
    listComponent,
    onChange,
    onFocus,
    openIcon,
    optionComponent,
    options = [],
    placeholder,
    renderIcon,
    renderList,
    required,
    showIcon,
    testID,
    value = ''
  } = props;
  const {
    container: containerClassName,
    root: rootClassName,
    button: buttonClassName = '',
    label: labelClassName = '',
    helperText: helperTextClassName = '',
    icon: iconClassName = '',
    list: listClassName = ''
  } = classes || {};
  const isError = !!error;

  const ContainerComponent = containerComponent || 'div';
  const LabelComponent = labelComponent || 'label';
  const ButtonComponent = buttonComponent || 'button';
  const ListComponent = listComponent || 'div';
  const OptionComponent = optionComponent || 'div';
  const HelperTextComponent = helperTextComponent || 'p';
  const CloseIcon = closeIcon || MdExpandMore;
  const OpenIcon = openIcon || MdExpandLess;

  const listboxRef = useRef(null);
  const [listboxVisible, setListboxVisible] = useState(false);

  const {
    buttonFocusVisible,
    getButtonProps,
    getListboxProps,
    getOptionProps,
    getOptionState,
    value: selectedValue
  } = useSelect({
    // optionStringifier
    defaultValue: '',
    disabled,
    listboxRef,
    multiple: false,
    onChange: (event, newValue) => onChange({ event, value: newValue }, newValue),
    onOpenChange: setListboxVisible,
    open: listboxVisible,
    options,
    value
  });
  const listboxProps = getListboxProps();
  const { 'aria-activedescendant': activeOptionId } = listboxProps;

  const scrollToActive = () => {
    const element = document.getElementById(activeOptionId);
    if (element) {
      const { scrollTop } = listboxRef.current;
      const { height: listHeight } = listboxRef.current.getBoundingClientRect();
      const { height: elementHeight } = element.getBoundingClientRect();
      const { offsetTop } = element;
      const offsetBottom = offsetTop + elementHeight;
      const scrollBottom = scrollTop + listHeight;
      if (offsetTop < scrollTop) {
        listboxRef.current.scrollTo(0, offsetTop);
      } else if (offsetBottom > scrollBottom) {
        listboxRef.current.scrollTo(0, offsetBottom - scrollBottom + scrollTop);
      }
    }
  };

  useEffect(() => {
    if (listboxVisible) {
      listboxRef.current?.focus();

      if (activeOptionId) scrollToActive();
    }
  }, [listboxVisible]);

  useEffect(() => {
    if (listboxVisible && activeOptionId) scrollToActive();
  }, [activeOptionId]);

  const buttonWrapperProps = {
    className: clsx(rootClassName, 'RootSelect-root', {
      error: isError,
      expanded: listboxVisible,
      disabled
    })
  };
  const rootButtonProps = getButtonProps();
  const selectButtonProps = {
    id,
    ...qaAttr(testID),
    'aria-required': required,
    'aria-invalid': !!error,
    className: clsx(buttonClassName, 'RootSelect-button', { error: isError }),
    ...rootButtonProps,
    ...buttonProps,
    onFocus: (e) => {
      if (onFocus) onFocus(e);
      buttonProps.onFocus?.(e);
    }
  };
  const selectLabelProps = {
    htmlFor: id,
    required,
    className: clsx(labelClassName, 'RootSelect-label', { error: isError }),
    ...labelProps
  };
  const selectErrorMessageProps = {
    ...(id ? { 'aria-describedby': `${id}-helper-text` } : {}),
    className: clsx(helperTextClassName, 'RootSelect-helperText', { error: isError }),
    ...helperTextProps
  };
  const selectIconProps = {
    className: clsx(iconClassName, 'RootSelect-icon', { disabled, error: isError }),
    ...iconProps
  };

  const iconRenderer = () => {
    if (!showIcon) return null;
    if (renderIcon) return renderIcon(selectIconProps);
    return listboxVisible ? <OpenIcon {...selectIconProps} /> : <CloseIcon {...selectIconProps} />;
  };

  return (
    <ContainerComponent className={clsx('RootSelect', className)}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {label && <LabelComponent {...selectLabelProps}>{label}</LabelComponent>}
      <div {...buttonWrapperProps}>
        {/* eslint-disable-next-line react/button-has-type */}
        <ButtonComponent {...selectButtonProps}>
          {renderSelectedValue(value, options) || (
            <span className="placeholder">{placeholder ?? ' '}</span>
          )}
        </ButtonComponent>
        {iconRenderer()}

        {renderList ? (
          renderList({
            ...props,
            listboxVisible,
            setListboxVisible,
            getListboxProps,
            getOptionState,
            getOptionProps
          })
        ) : (
          <ListComponent
            {...listboxProps}
            aria-hidden={!listboxVisible}
            className={clsx(listClassName, 'RootSelect-list', { hidden: !listboxVisible })}
          >
            {options.map((option, i) => {
              const { disabled: optionDisabled, highlighted, selected } = getOptionState(option);
              return (
                <OptionComponent
                  key={getKey ? getKey(i, option) : option.value}
                  {...(getOptionTestID ? qaAttr(getOptionTestID(i, option)) : {})}
                  {...getOptionProps(option)}
                  {...option.props}
                  className={clsx('RootSelect-option', {
                    disabled: optionDisabled,
                    highlighted,
                    selected
                  })}
                >
                  {option.label}
                </OptionComponent>
              );
            })}
          </ListComponent>
        )}
      </div>
      {(error || helperText) && (
        <HelperTextComponent {...selectErrorMessageProps}>
          {error || helperText}
        </HelperTextComponent>
      )}
    </ContainerComponent>
  );
}

RootSelect.propTypes = {
  value: PT.any.isRequired,
  options: PT.arrayOf(
    PT.shape({
      value: PT.any,
      label: PT.any,
      disabled: PT.bool,
      delimiter: PT.bool,
      props: PT.objectOf(PT.any)
    })
  ).isRequired,
  onChange: PT.func.isRequired, // (e, newValue) => {}
  onFocus: PT.func,
  error: PT.string,
  helperText: PT.string,
  helperTextProps: PT.objectOf(PT.any),
  label: PT.string,
  labelProps: PT.objectOf(PT.any),
  id: PT.any,
  required: PT.bool,
  placeholder: PT.string,
  testID: PT.any,
  getOptionTestID: PT.func,
  className: PT.string,
  containerComponent: PT.element,
  labelComponent: PT.element,
  buttonComponent: PT.element,
  buttonProps: PT.objectOf(PT.any),
  listComponent: PT.element,
  optionComponent: PT.element,
  helperTextComponent: PT.element,
  closeIcon: PT.element,
  openIcon: PT.element,
  iconProps: PT.objectOf(PT.any),
  renderList: PT.func,
  renderIcon: PT.func,
  disabled: PT.bool,
  getKey: PT.func,
  showIcon: PT.bool,
  classes: PT.shape({
    container: PT.string,
    root: PT.string,
    button: PT.string,
    label: PT.string,
    helperText: PT.string,
    icon: PT.string,
    list: PT.string
  })
};

RootSelect.defaultProps = {
  buttonComponent: null,
  buttonProps: {},
  classes: {},
  className: '',
  closeIcon: null,
  containerComponent: null,
  disabled: false,
  error: '',
  getKey: undefined,
  getOptionTestID: (i, optionProps) => '',
  helperText: '',
  helperTextComponent: null,
  helperTextProps: {},
  iconProps: {},
  id: '',
  label: '',
  labelComponent: null,
  labelProps: {},
  listComponent: null,
  onFocus: undefined,
  openIcon: null,
  optionComponent: null,
  placeholder: '',
  renderIcon: undefined,
  renderList: undefined,
  required: false,
  showIcon: true,
  testID: ''
};

export default RootSelect;
