import React, { useState, useEffect, useRef, useCallback } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { isSameDay, format as formatDate, formatISO } from 'date-fns';
import { Box, InputAdornment, styled } from 'components';
import { Button, IconButton, Input, PopupMenu } from 'components/shared';
import { MdChevronRight, MdAttachFile, MdArrowBack, MdMoreVert } from 'components/icons';
import { getUserId } from 'utils';
import { GET_CHAT, GET_JOB, GET_USER_PROFILE, POST_EMPLOYEE_CHAT, SET_EMPLOYER_CHAT } from 'api';
import styles from 'styles/Chat/Chat';
import defaultJobImg from 'assets/img/job_default.png';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import defaultEmployerImg from 'assets/img/employer_default.png';
import withApplicantActions from '../../hocs/withApplicantActions';
import withEmployeeJobActions from '../../hocs/withEmployeeJobActions';
import ChatDialogHeader from './ChatDialogHeader';
import ChatMessage from './ChatMessage';
import SuggestedMessageModal from './SuggestedMessageModal';

const StyledRoot = styled('div')(styles);

const enhance = (WrappedComponent) =>
  // eslint-disable-next-line react/prop-types
  function EnhancedComponent({ role, ...props }) {
    const Component =
      role === 'employer'
        ? withApplicantActions(WrappedComponent)
        : withEmployeeJobActions(WrappedComponent);
    return <Component role={role} {...props} />;
  };

function Chat(props) {
  const {
    afterApplicantReject,
    jobId,
    onClose,
    onRetract,
    rejectApplicant,
    receiverUserId,
    role,
    showHeaderActions,
    suggestMessageTemplate
  } = props;
  const currentUserId = Number(getUserId());
  const chatRef = useRef();

  const [typedMessage, setTypedMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [suggestedMessageModalOpen, setSuggestedMessageModalOpen] = useState(false);

  const [sendEmployeeMessage, { loading: sendEmployeeMsgLoading }] =
    useMutation(POST_EMPLOYEE_CHAT);
  const [sendEmployerMessage, { loading: sendEmployerMsgLoading }] = useMutation(SET_EMPLOYER_CHAT);

  const [fetchChat, { data: chatData = {}, loading: chatLoading = true }] = useLazyQuery(GET_CHAT, {
    variables: { role },
    fetchPolicy: 'no-cache'
  });

  const [fetchJob, { data: jobData = {}, loading: jobLoading = true }] = useLazyQuery(GET_JOB, {
    variables: { jobId },
    fetchPolicy: 'cache-first'
  });
  const job = jobData?.job || {};
  const { title: jobTitle, imageUrl: jobImageUrl, employerProfile, webUrl: jobWebUrl } = job;
  const {
    imageUrl: companyImageUrl,
    name: companyName,
    profile_id: employerPId
  } = employerProfile || {};

  const [fetchCandidate, { data: employeeProfileData }] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: 'cache-first'
  });
  const candidateProfile = employeeProfileData?.employeeProfile || {};
  const { name: candidateName, imageUrl: candidateImageUrl } = candidateProfile;

  useEffect(() => {
    if (chatData?.chat) {
      setMessages(chatData.chat);
    }
  }, [JSON.stringify(chatData)]);

  useEffect(() => {
    if (messages.length && chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages.length]);

  useEffect(() => {
    const commonArgs = { role, jobsId: jobId };
    fetchJob();
    if (role === 'employer') {
      fetchCandidate({ variables: { userId: receiverUserId } });
      fetchChat({
        variables: { ...commonArgs, messageTo: currentUserId, messageFrom: receiverUserId }
      });
    } else {
      fetchChat({
        variables: { ...commonArgs, messageTo: receiverUserId, messageFrom: currentUserId }
      });
    }
  }, [role, receiverUserId, employerPId, jobId]);

  const handleMessageSend = async (e) => {
    e.preventDefault();

    if (typedMessage.trim().length > 0 && currentUserId && jobId) {
      const date = new Date();
      const commonArgs = {
        jobsId: jobId,
        usersUserId: currentUserId,
        message: typedMessage
      };

      if (role === 'employer') {
        const employerArgs = {
          ...commonArgs,
          messageFrom: receiverUserId,
          messageTo: currentUserId
        };

        sendEmployerMessage({
          variables: employerArgs,
          fetchPolicy: 'no-cache'
        });

        setMessages((prev) => [
          ...prev,
          { ...employerArgs, id: date.valueOf(), createdAt: formatISO(date) }
        ]);
      } else {
        const employeeArgs = {
          ...commonArgs,
          messageFrom: currentUserId,
          messageTo: receiverUserId
        };

        sendEmployeeMessage({
          variables: employeeArgs,
          fetchPolicy: 'no-cache'
        });

        setMessages((prev) => [
          ...prev,
          { ...employeeArgs, id: date.valueOf(), createdAt: formatISO(date) }
        ]);
      }
      setTypedMessage('');
    }
  };

  const closeSuggestedMessageModal = () => setSuggestedMessageModalOpen(false);

  const renderJobCard = () => (
    <div className="chat__jobCard jobCard">
      <img src={jobImageUrl || defaultJobImg} alt="" className="jobCard__img" />
      <Box ml="16px" display="flex" flexDirection="column" justifyContent="center" textAlign="left">
        <Box mb="6px" className="mainText">
          Position:
        </Box>
        <div className="secondaryText">{jobTitle}</div>
      </Box>
      <Box ml="auto" mr={0} display="flex" alignItems="center">
        <MdChevronRight sx={{ ml: '8px' }} color="primary.main" />
      </Box>
    </div>
  );

  const renderPopupButton = useCallback(
    ({ openPopup, triggerProps }) => (
      <IconButton
        {...triggerProps}
        color="primary"
        aria-label="chat options"
        onClick={openPopup}
        testID="chat-options-button"
      >
        <MdMoreVert />
      </IconButton>
    ),
    []
  );

  const renderChatHeaderOptions = () => {
    if (!showHeaderActions) return null;
    return (
      <PopupMenu
        id="chat-options"
        disablePortal
        getTrigger={renderPopupButton}
        items={[
          ...(role === 'employer'
            ? [
                {
                  label: 'Reject Candidate',
                  isCandidateReject: true,
                  itemProps: { className: 'headerOptionsMenuItem' }
                }
              ]
            : [
                {
                  label: 'Retract Application',
                  isRetract: true,
                  itemProps: { className: 'headerOptionsMenuItem' }
                }
              ])
        ]}
        onItemClick={(e, i, item, { closePopup }) => {
          if (role === 'employer') {
            if (item.isCandidateReject && rejectApplicant) {
              rejectApplicant({
                jobId,
                jobWebUrl,
                applicantData: candidateProfile,
                onSuccess: () => {
                  if (afterApplicantReject) afterApplicantReject();
                }
              });
            }
          } else if (role === 'employee') {
            if (item.isRetract && onRetract) {
              onRetract(job);
            }
          }
          closePopup();
        }}
      />
    );
  };

  return (
    <StyledRoot className="chatContainer">
      <ChatDialogHeader
        title={role === 'employee' ? companyName : candidateName}
        withCloseButton
        closeButtonIcon={<MdArrowBack />}
        onClose={onClose}
        rightElement={renderChatHeaderOptions()}
      />
      {jobTitle && <div className="chat__jobCardContainer">{renderJobCard()}</div>}
      <div ref={chatRef} className="chat__messagesArea">
        {messages.map((messageData, i) => {
          const { usersUserId, message, createdAt } = messageData;
          const messageDate = new Date(createdAt);
          const parsedDate = isSameDay(messageDate, new Date())
            ? formatDate(messageDate, "'Today' hh:mm a")
            : formatDate(messageDate, 'MM.dd.yyyy hh:mm a');
          const employerImg = companyImageUrl || defaultEmployerImg;
          const employeeImg = candidateImageUrl || defaultEmployeeImg;
          const recipientImg = role === 'employer' ? employeeImg : employerImg;

          return (
            <div key={messageData.id}>
              {parsedDate && (
                <Box mb="16px" className="chat__messageDate">
                  {parsedDate}
                </Box>
              )}
              {usersUserId === currentUserId ? (
                <Box mb="16px" display="flex" justifyContent="flex-end">
                  <ChatMessage
                    className="chat__message userMessage"
                    textMessage={message}
                    senderImageUrl=""
                  />
                </Box>
              ) : (
                <Box mb="16px" display="flex" justifyContent="flex-start">
                  <ChatMessage
                    className="chat__message"
                    textMessage={message}
                    senderImageUrl={recipientImg}
                  />
                </Box>
              )}
            </div>
          );
        })}
      </div>
      <form className="chat__inputArea" onSubmit={handleMessageSend}>
        <Input
          value={typedMessage}
          variant="outlined"
          placeholder="Type your message"
          endAdornment={
            <InputAdornment position="end">
              <Button type="submit" className="chat__sendButton" testID="send-chat-message-button">
                Send
              </Button>
            </InputAdornment>
          }
          InputProps={{
            sx: { paddingRight: '16px' }
          }}
          htmlInputClassName="chat__input"
          onChange={(e) => setTypedMessage(e.target.value)}
          testID="chat-input"
        />
        {/* <IconButton
            disabled
            variant="outlined"
            color="primary"
            aria-label="attach file"
            sx={{ marginLeft: '16px' }}
          >
            <MdAttachFile />
          </IconButton> */}
      </form>

      <SuggestedMessageModal
        isOpen={suggestedMessageModalOpen}
        message={suggestMessageTemplate}
        onConfirm={() => {
          setTypedMessage(suggestMessageTemplate);
          closeSuggestedMessageModal();
        }}
        onClose={closeSuggestedMessageModal}
      />
    </StyledRoot>
  );
}

Chat.propTypes = {
  role: PT.oneOf(['employer', 'employee']).isRequired,
  jobId: PT.number.isRequired,
  onClose: PT.func.isRequired,
  receiverUserId: PT.number.isRequired,
  rejectApplicant: PT.func,
  afterApplicantReject: PT.func,
  onRetract: PT.func,
  suggestMessageTemplate: PT.string,
  showHeaderActions: PT.bool
};

Chat.defaultProps = {
  suggestMessageTemplate: '',
  showHeaderActions: true,
  rejectApplicant: () => {},
  afterApplicantReject: () => {},
  onRetract: () => {}
};

export default enhance(Chat);
