import React, { useMemo } from 'react';
import PT from 'prop-types';
import { head } from 'lodash';
import { Box, styled } from 'components';
import {
  GrowRecentBadgeIcon
  // GrowBadgeIcon1,
  // GrowBadgeIcon2,
  // GrowBadgeIcon3,
  // GrowBadgeIcon4,
  // GrowBadgeIcon5,
  // GrowBadgeIcon6,
  // GrowBadgeIcon7,
  // GrowBadgeIcon8,
  // GrowBadgeIcon9,
  // GrowBadgeIcon10,
  // GrowBadgeIcon11,
  // GrowBadgeIcon12,
  // GrowBadgeIcon13,
  // GrowBadgeIcon14,
  // GrowBadgeIcon15,
  // GrowBadgeIcon16
} from 'components/icons';
import BadgeCard from './BadgeCard';
import PageHeader from '../PageHeader';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .cardsContainer': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 10
  }
}));

export default function BadgesPage(props) {
  const { abilitiesBadges, skillsBadges, recentBadges } = props;

  const recentBadge = useMemo(() => {
    const recentAbilityBadge = head(recentBadges?.abilitiesBadges || []);
    const recentSkillBadge = head(recentBadges?.skillsBadges || []);
    return recentAbilityBadge || recentSkillBadge;
  }, [JSON.stringify(recentBadges)]);

  const isRecentBadge = ({ abilitiesBadgeId, skillsBadgeId }) => {
    if (abilitiesBadgeId) return recentBadge?.abilitiesBadgeId === abilitiesBadgeId;
    if (skillsBadgeId) return recentBadge?.skillsBadgeId === skillsBadgeId;
    return false;
  };

  return (
    <StyledRoot>
      <PageHeader
        title="BADGES"
        subtitle="See the badges you've completed and can get"
        icon={<GrowRecentBadgeIcon />}
      />
      <div className="cardsContainer">
        {abilitiesBadges.map((badge) => (
          <BadgeCard
            key={badge.abilitiesBadgeId}
            title={badge.name}
            isActive={badge.complete}
            isRecent={isRecentBadge({ abilitiesBadgeId: badge.abilitiesBadgeId })}
            badgeUrl={badge.badgeUrl}
          />
        ))}
        {skillsBadges.map((badge) => (
          <BadgeCard
            key={badge.skillsBadgeId}
            title={badge.name}
            isActive={badge.complete}
            isRecent={isRecentBadge({ skillsBadgeId: badge.skillsBadgeId })}
            badgeUrl={badge.badgeUrl}
          />
        ))}
      </div>
    </StyledRoot>
  );
}

const abilitiesBadgesPropTypes = PT.arrayOf(
  PT.shape({
    name: PT.string,
    badgeUrl: PT.string,
    abilitiesId: PT.number,
    abilitiesBadgeId: PT.number,
    complete: PT.bool
  })
);
const skillsBadgesPropTypes = PT.arrayOf(
  PT.shape({
    name: PT.string,
    badgeUrl: PT.string,
    skillsId: PT.number,
    skillsBadgeId: PT.number,
    complete: PT.bool
  })
);

BadgesPage.propTypes = {
  abilitiesBadges: abilitiesBadgesPropTypes.isRequired,
  skillsBadges: skillsBadgesPropTypes.isRequired,
  recentBadges: PT.shape({
    abilitiesBadges: abilitiesBadgesPropTypes,
    skillsBadges: skillsBadgesPropTypes
  }).isRequired
};
