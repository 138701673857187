import backgroundImg from 'assets/img/qhgrow-background.jpg';

export default ({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  '& .contentContainer': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#493E59', // @TODO: get from theme
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    backgroundPosition: 'cover',
    backgroundAttachment: 'fixed',
    backgroundImage: `url(${backgroundImg})`,
    [theme.breakpoints.down('desktopApp')]: {
      paddingTop: 56,
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundImage: 'none',
      backgroundColor: theme.palette.background.default
    }
  },
  '& .desktopContentContainer': {
    width: '100%',
    paddingTop: 71,
    display: 'grid',
    gridTemplateColumns: '20% auto 20%',

    '& .center': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      '& .top': {
        flex: 1,
        minHeight: 'calc(100vh - 60px)',
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        '& .centralBlockContainer': {
          flex: 1,
          padding: 25,
          borderRadius: '15px 15px 0 0',
          backgroundColor: theme.palette.moreColors.darkPurple,
          '&.pathContainer': {
            backgroundColor: 'transparent'
          }
        }
      },
      '& .bottom': {
        padding: 35,
        backgroundColor: theme.palette.common.white
      }
    },
    '& .rightAside, .leftAside': {
      display: 'flex',
      flexDirection: 'column',
      '& .top': {
        flex: 1,
        minHeight: 'calc(100vh - 60px)',
        padding: 20,
        backgroundColor: 'transparent',
        '& .suggestedQuizzes': {
          gridTemplateColumns: '1fr',
          gap: 32,
          '& .resourceCard': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          },
          '& .resourceCard__title': {
            color: theme.palette.moreColors.purpleLight_2
          },
          '& .resourceCard__img': {
            marginBottom: 15,
            backgroundColor: theme.palette.common.white
          }
        }
      },
      '& .bottom': {
        padding: 25,
        backgroundColor: '#F2EEF7' // @TODO: get from theme
      }
    }
  },
  '& .mobContentContainer': {
    width: '100%',
    flex: 1,
    '& .mainTitle': {
      marginBottom: 24,
      fontWeight: 700,
      fontSize: 31,
      lineHeight: '130%',
      textAlign: 'center'
    },
    '& .bottomContent': {
      padding: '24px 0 48px',
      backgroundColor: theme.palette.moreColors.grey_3
    }
  },
  '& .outlinedAction': {
    padding: '10px 24px',
    border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    borderRadius: 30,
    fontSize: 16,
    fontWeight: 800,
    lineHeight: '150%',
    color: theme.palette.moreColors.grey_3
  },
  '& .outlinedSelect': {
    width: '100%',
    '& .StyledInput-inputBase': {
      fontWeight: 700,
      lineHeight: '130%',
      borderColor: theme.palette.moreColors.purpleLight_2,
      borderRadius: 8,
      color: theme.palette.common.white
    },
    '& .StyledInput-inputBase__input': {
      padding: '10px 24px'
    },
    '&.big': {
      '& .StyledInput-inputBase, .MuiMenuItem-root': {
        fontSize: 25
      }
    }
  },
  '& .scoreToggleBtn': {
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '130%'
  },
  '& .section': {
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40,
      padding: '0 13px'
    }
  },
  '& .sectionHeader': {
    marginBottom: 14,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 16
    }
  },
  '& .sectionTitle': {
    margin: 0,
    fontSize: 12,
    color: '#3D3D3D',
    '&.light': {
      color: '#FFF'
    },
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 25,
      lineHeight: '130%',
      fontWeight: 700
      // @TODO: color: #312E38 (grey 6)
      // color: '#3D3D3D'
    }
  },
  '& .seeAllButton': {
    marginLeft: 12,
    padding: 0,
    flexShrink: 0,
    fontSize: 12,
    color: '#49469D', // @TODO: get from theme
    [theme.breakpoints.down('desktopApp')]: {
      padding: '8px 0',
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.moreColors.brand
    }
  },
  '& .elevatedCard': {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 0 5px rgb(0 0 0 / 25%)',
    borderRadius: 5
  },
  '& .customContentContainer': {
    '&.vertical': {
      display: 'flex',
      flexDirection: 'column',
      gap: 16
    },
    '&.horizontal': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: 15
    },
    '& .customContent__item': {
      width: '100%',
      borderRadius: 15,
      backgroundColor: theme.palette.moreColors.darkPurple
    }
  },
  '& .notYetAvailable': {
    position: 'relative',
    padding: '56px 16px 50px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    alignSelf: 'center',
    margin: '0 auto',
    textAlign: 'center',
    alignItems: 'center',
    color: theme.palette.common.white
  }
});
