import React, { memo } from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import clsx from 'clsx';
import parseHTML from 'html-react-parser';

const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) => `<a target="_blank" noopener noreferrer href="${url}">${url}</a>`
  );
};

const StyledRoot = styled('div')(({ theme }) => ({
  '&.chatMessage__container': {
    display: 'flex',
    alignItems: 'flex-start'
  },
  '& .chatMessage__txtMessage': {
    maxWidth: 258,
    padding: '11px 9px 11px 13px',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0,
    color: theme.palette.primary.text,
    borderRadius: 4,
    backgroundColor: theme.palette.background.default,
    wordBreak: 'break-word'
  },
  '& .chatMessage__senderImg': {
    width: 32,
    height: 32,
    marginRight: 16,
    marginTop: 6,
    objectFit: 'cover',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%'
  }
}));

function ChatMessage({ className, textMessage, senderImageUrl }) {
  const htmlText = textMessage ? urlify(textMessage) : '';
  const parsedHtml = htmlText ? parseHTML(htmlText) : '';
  return (
    <StyledRoot className={clsx('chatMessage__container', className)}>
      {senderImageUrl && <img src={senderImageUrl} alt="" className="chatMessage__senderImg" />}
      <div className="chatMessage__txtMessage">{parsedHtml}</div>
    </StyledRoot>
  );
}

ChatMessage.propTypes = {
  className: PT.string,
  senderImageUrl: PT.string,
  textMessage: PT.string
};

ChatMessage.defaultProps = {
  className: '',
  senderImageUrl: '',
  textMessage: ''
};

export default memo(ChatMessage);
