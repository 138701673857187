import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import { Draggable } from 'react-beautiful-dnd';
import { Box, styled, useTheme } from 'components';
import { Button, ButtonBase, IconButton, JobsInvitePopup } from 'components/shared';
import {
  MdClose,
  MdCheck,
  MdTrendingUp,
  MdChatBubbleOutline,
  MdMailOutline,
  PageviewOutlined
} from 'components/icons';
import { get, set, cloneDeep } from 'lodash';
import { getProfileId } from 'utils';
import { SEND_INVITE, RATE_EMPLOYEE_BY_EMPLOYER } from 'api';
import EmployerGrowContext from '../../EmployerGrowContext';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.employeeCard': {
    position: 'relative',
    userSelect: 'none',
    borderWidth: 2,
    borderStyle: 'solid',
    padding: 10,
    margin: 5,
    marginBottom: 13,
    borderRadius: 5,
    backgroundColor: theme.palette.background.default
  },
  '& .employeeCard__hiddenArea': {
    paddingTop: 10,
    display: 'none',
    '&.hovered': {
      display: 'block'
    }
  },
  '& .employeeCard__profileViewArea': {
    width: '100%',
    justifyContent: 'flex-start',
    textAlign: 'left'
  },
  '& .employeeCard__deleteBtn': {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 18,
    height: 18,
    padding: 0,
    fontSize: 14,
    color: '#FFF',
    backgroundColor: theme.palette.primary.main
  },
  '& .employeeCard__img': {
    height: 40,
    width: 40,
    minWidth: 40,
    // border: '2px solid #4743A2',
    borderRadius: 50,
    backgroundColor: '#ECECF5',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    marginRight: 10,
    overflow: 'auto',
    objectFit: 'cover'
  },
  '& .employeeCard__NA': {
    color: theme.palette.moreColors.grey_1
  },
  '& .employeeCard__secondaryText': {
    fontSize: 12,
    color: theme.palette.primary.text
  },
  '& .employeeCard__rateBtn': {
    border: '1px solid',
    borderColor: theme.palette.moreColors.grey_2,
    fontSize: 16,
    color: 'initial',
    '&:not(:first-of-type)': {
      marginLeft: 15
    }
  },
  '& .employeeCard__actionBtn': {
    padding: 0,
    fontSize: 12,
    '&:not(:last-of-type)': {
      marginBottom: 10
    },
    '& .StyledButton-startIcon': {
      marginLeft: 0,
      fontSize: 18,
      color: theme.palette.primary.main
    }
  },
  '& .employeeCard__invitedMessage': {
    maxWidth: 240,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '17px',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

const getItemStyle = (isDragging, draggableStyle, theme) => ({
  boxShadow: isDragging ? '0 0 20px rgba(0, 0, 0, .3)' : '0 0 6px rgba(0, 0, 0, .13)',
  borderColor: isDragging ? theme.palette.primary.dark : theme.palette.primary.main,
  // styles needed to apply on draggables
  ...draggableStyle
});

function EmployeeCard({ columnIndex, id, index, employeeProfile: emp }) {
  const { employeeProfileProfileId, careerDevelopment, employeeProfile } = emp || {};
  const { name, imageUrl, user_id, email, rate } = employeeProfile || {};
  const { curJobTypeId, futureJobTypeId, showEmployerData } = careerDevelopment || {};
  const theme = useTheme();

  const {
    closeEmployeeInvitePopup,
    currentChartIdx,
    deleteEmployee,
    employerCharts,
    hoveredEmployeeCardId,
    isEditable,
    jobs,
    jobTypes,
    showEmployeeProfile,
    setHoveredEmployeeCardId,
    updateEmployerCtx
  } = useContext(EmployerGrowContext);

  const currentJob = get(jobTypes, `[${curJobTypeId}].name`, 'N/A');
  const futureJob = get(jobTypes, `[${futureJobTypeId}].name`, 'N/A');
  const showMoreInfo = hoveredEmployeeCardId === user_id;

  const [invited, setInvited] = useState(false);

  const [sendInvite, { loading }] = useMutation(SEND_INVITE);
  const [rateEmployee] = useMutation(RATE_EMPLOYEE_BY_EMPLOYER, {
    fetchPolicy: 'no-cache'
  });

  const handleRateEmployee = (value) => {
    const pid = getProfileId();
    if (pid && employeeProfileProfileId) {
      rateEmployee({
        variables: {
          employeeProfileId: employeeProfileProfileId,
          employerProfileId: Number(pid),
          rate: value
        }
      });
      let updCharts = cloneDeep(employerCharts);
      updCharts = set(
        updCharts,
        [
          currentChartIdx,
          'columns',
          columnIndex,
          'employeeProfiles',
          index,
          'employeeProfile',
          'rate'
        ],
        value
      );

      updateEmployerCtx({ employerCharts: updCharts });
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteEmployee(employeeProfileProfileId);
  };

  const handleProfileView = () => {
    showEmployeeProfile(user_id);
  };

  const handleInvite = (jobId) => {
    if (user_id) {
      sendInvite({ variables: { employeeUserId: user_id, id: jobId } });
      setInvited(true);
    }
    closeEmployeeInvitePopup();
  };

  const handleHover = () => {
    setHoveredEmployeeCardId(user_id);
  };

  return (
    <Draggable key={id} draggableId={`${id}`} index={index}>
      {(provided, snapshot) => (
        <StyledRoot
          className="employeeCard"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...getItemStyle(snapshot.isDragging, provided.draggableProps.style, theme)
          }}
          onMouseEnter={handleHover}
        >
          {isEditable && (
            <IconButton
              aria-label="remove employee"
              className="employeeCard__deleteBtn"
              onClick={handleDelete}
              testID="team-structure-employee-delete-button"
            >
              <MdClose fontSize="inherit" color="inherit" />
            </IconButton>
          )}
          <ButtonBase
            disableRipple
            className="employeeCard__profileViewArea"
            onClick={handleProfileView}
            testID="team-structure-employee-profile-button"
          >
            <div>
              {imageUrl ? (
                <img className="employeeCard__img" src={imageUrl} alt="" />
              ) : (
                <span className="employeeCard__img">{name[0]}</span>
              )}
            </div>
            <div>
              <Box fontWeight={800}>{name}</Box>
              <Box fontSize={12} display={showMoreInfo ? 'none' : 'block'}>
                {showEmployerData ? (
                  <>
                    <span>{currentJob}</span>
                    <span> / </span>
                    <Box component="span" color="moreColors.grey_1">
                      {futureJob}
                    </Box>
                  </>
                ) : (
                  <span className="employeeCard__NA">N/A</span>
                )}
              </Box>
            </div>
          </ButtonBase>
          <div className={clsx('employeeCard__hiddenArea', showMoreInfo && 'hovered')}>
            <Box mb="20px" display="flex" fontSize={12}>
              <Box flex={1}>
                <div className="employeeCard__secondaryText">Current Role</div>
                <div>{currentJob}</div>
              </Box>
              <Box flex={1} ml="6px">
                <div className="employeeCard__secondaryText">Desired Role</div>
                <div>{futureJob}</div>
              </Box>
            </Box>
            {!rate && (
              <>
                <Box mb="10px" textAlign="center" className="employeeCard__secondaryText">
                  Satisfied with your hire?
                </Box>
                <Box mb="20px" display="flex" justifyContent="center">
                  <IconButton
                    className="employeeCard__rateBtn"
                    onClick={() => handleRateEmployee(4)}
                    testID="team-structure-employee-rate-0"
                  >
                    👍
                  </IconButton>
                  <IconButton
                    className="employeeCard__rateBtn"
                    onClick={() => handleRateEmployee(0)}
                    testID="team-structure-employee-rate-0"
                  >
                    👎
                  </IconButton>
                </Box>
              </>
            )}
            <Box mb="10px" className="employeeCard__secondaryText">
              Actions
            </Box>
            <Button
              component="a"
              href={`mailto:${email}`}
              className="employeeCard__actionBtn"
              startIcon={<PageviewOutlined fontSize="inherit" />}
            >
              Send Onboarding
            </Button>
            {/* <Button
              className="employeeCard__actionBtn"
              startIcon={<MdTrendingUp fontSize="inherit" />}
            >
              Send Training
            </Button>
            <Button
              className="employeeCard__actionBtn"
              startIcon={<MdChatBubbleOutline fontSize="inherit" />}
            >
              Send Message
            </Button> */}
            {invited ? (
              <div className="employeeCard__invitedMessage">
                <MdCheck color="inherit" fontSize="inherit" /> Invited
              </div>
            ) : (
              <JobsInvitePopup
                id={`invite-popup-${id}-${user_id}`}
                getItemKey={(job) => `vacancy__${user_id}-${job.id}`}
                jobs={jobs}
                triggerProps={{
                  className: 'employeeCard__actionBtn',
                  startIcon: <MdMailOutline fontSize="inherit" />
                }}
                onInvite={(job) => handleInvite(job.id)}
              />
            )}
          </div>
        </StyledRoot>
      )}
    </Draggable>
  );
}

EmployeeCard.propTypes = {
  id: PropTypes.number,
  index: PropTypes.number,
  columnIndex: PropTypes.number.isRequired,
  employeeProfile: PropTypes.objectOf(PropTypes.any)
};

EmployeeCard.defaultProps = {
  id: 0,
  index: 0,
  employeeProfile: {
    employeeProfileProfileId: null,
    employeeProfile: {
      name: '',
      imageUrl: '',
      user_id: null
    }
  }
};

export default EmployeeCard;
