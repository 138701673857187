import React, { useMemo, useCallback } from 'react';
import useJobQuery from './useJobQuery';
import useJobQuestionsQuery from './useJobQuestionsQuery';

export default function useCombinedJobQuery() {
  const jobQueryResult = useJobQuery({ autoFetch: false });
  const {
    job,
    fetch: fetchJob,
    refetch: refetchJob,
    getResultFields: getJobResult
  } = jobQueryResult;
  const questionsQueryResult = useJobQuestionsQuery({ autoFetch: false });
  const {
    questions,
    fetch: fetchQuestions,
    refetch: refetchQuestions,
    getResultFields: getQuestionsResult
  } = questionsQueryResult;

  const fullJob = useMemo(() => ({ ...job, questions: questions || [] }), [job, questions]);

  const fetchFullJob = useCallback(
    async (jobId, options) => {
      const { fetchJobOptions = {}, fetchQuestionsOptions = {} } = options || {};
      const [jobResponse, questionsResponse] = await Promise.allSettled([
        fetchJob({
          variables: { jobId: Number(jobId) },
          ...fetchJobOptions
        }),
        fetchQuestions({
          variables: { jobsJobsId: Number(jobId) },
          ...fetchQuestionsOptions
        })
      ]);
      const jobData = getJobResult(jobResponse?.value) || {};
      const questionsData = getQuestionsResult(questionsResponse?.value) || [];
      return { ...jobData, questions: questionsData };
    },
    [fetchJob, fetchQuestions, getJobResult, getQuestionsResult]
  );

  const refetchFullJob = useCallback(async () => {
    const [jobResponse, questionsResponse] = await Promise.allSettled([
      refetchJob(),
      refetchQuestions()
    ]);
    const jobData = getJobResult(jobResponse?.value) || {};
    const questionsData = getQuestionsResult(questionsResponse?.value) || [];
    return { ...jobData, questions: questionsData };
  }, [getJobResult, getQuestionsResult, refetchJob, refetchQuestions]);

  return useMemo(
    () => ({ fullJob, fetchFullJob, refetchFullJob, jobQueryResult, questionsQueryResult }),
    [fetchFullJob, fullJob, jobQueryResult, questionsQueryResult, refetchFullJob]
  );
}
