import { addAlpha } from 'styles/utils';

export default ({ theme }) => ({
  '&.container': {
    position: 'relative',
    overflowX: 'hidden',
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  '& .jobsContainer': {
    position: 'relative',
    // flex: 1,
    padding: '0 30px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: 'minmax(275px, 1fr)',
    gridGap: 30,
    [theme.breakpoints.down('desktopApp')]: {
      margin: 0,
      padding: '0 16px',
      display: 'flex',
      flexFlow: 'column',
      gridGap: 0,
      alignItems: 'center',
      overflow: 'auto'
    }
  },
  '& .jobsContainerSmall': {
    position: 'relative',
    minHeight: 398,
    padding: '0 30px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: 'minmax(100px, 1fr)',
    gridGap: 16,
    [theme.breakpoints.down('desktopApp')]: {
      margin: 0,
      padding: '0, 16px',
      display: 'flex',
      flexFlow: 'column',
      gridGap: 0,
      alignItems: 'center',
      overflow: 'auto'
    }
  },
  '& .jobsContainerTitle': {
    padding: '16px 30px',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 30
    }
  },
  '& .cardContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .card': {
      width: '100%',
      flex: 1
    },
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
      maxWidth: '100%',
      marginBottom: 10,
      padding: 0,
      display: 'block',
      '&:last-of-type': {
        marginBottom: 50
      }
    }
  },
  '& .cardContainerSmall': {
    overflow: 'none',
    width: '100%',
    maxHeight: '122px',
    display: 'flex',
    flexDirection: 'row',
    '& .card': {
      padding: 8,
      width: '100%',
      flex: 1,
      borderColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
      maxWidth: '100%',
      marginBottom: 10,
      padding: 0,
      display: 'block',
      '&:last-of-type': {
        marginBottom: 50
      }
    }
  },
  '& .stats_wrapper': {
    padding: 0,
    margin: 8
  },
  '& .list': {
    display: 'flex',
    maxWidth: '400px',
    flexDirection: 'column'
  },
  '& .jobsLoader': {
    width: '100%',
    // height: 72,
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: addAlpha(theme.palette.background.default, 0.7)
  },
  '& .mobileJobCard': {
    width: '100%',
    margin: '0 auto',
    padding: '18px 12px 8px 14px',
    border: '1px solid #E5E5E5',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  '& .mobileJobCard__hiredBtn': {
    position: 'relative',
    zIndex: 10,
    marginTop: 6,
    padding: '5px 14px',
    fontSize: 9,
    borderRadius: 4
  },
  '& .mobileJobCard__photo': {
    height: 60,
    width: 60,
    backgroundColor: theme.palette.background.default,
    borderRadius: 14,
    objectFit: 'cover'
  },
  '& .mobileJobCard__arrow': {
    alignSelf: 'center',
    color: theme.palette.primary.main
  },
  '& .jobsDropDownTrigger': {
    fontSize: 16,
    lineHeight: '18px',
    color: theme.palette.primary.text
  },
  '& .jobsDropDownPaper': {
    minWidth: 255,
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 5
    }
  },
  '& .jobsDropDownOptBtn': {
    width: '100%',
    height: 50,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: theme.palette.primary.text,
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(255,255,255,0.2)'
    },
    [theme.breakpoints.down('desktopApp')]: {
      height: 50,
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  '& .draftLabel': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.moreColors.grey_6,
    borderRadius: 3,
    lineHeight: 1,
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 10,
      fontSize: 12
    }
  },
  '& .filterInput': {
    '&.StyledInput-inputBase__input': {
      padding: '13px 21px',
      fontSize: 12
    }
  },
  '& .hirePrompt': {
    maxWidth: 294
  },
  '& .hirePrompt__content': {
    padding: '23px 20px'
  },
  '& .hirePrompt__title': {
    marginBottom: 18,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  '& .hirePrompt__descr': {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '14px'
  },
  '& .hirePrompt__jobTitle': {
    padding: 10,
    borderRadius: 6,
    fontSize: 11,
    lineHeight: '12px',
    backgroundColor: theme.palette.background.default
  },
  '& .hirePrompt__actions': {
    display: 'flex'
  },
  '& .hirePrompt__btn': {
    flex: 1,
    padding: '20px 0',
    borderTop: '1px solid #ECEBF4',
    borderRadius: 0,
    fontSize: 14,
    '&:first-child': {
      color: theme.palette.neutral.main
    },
    '&:not(:first-child)': {
      borderLeft: '1px solid #ECEBF4'
    }
  },
  '& .newJobPrompt__btn': {
    padding: '12px 20px'
  },
  '& .mobileJobMetrics': {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.moreColors.grey_2}`,
      borderRadius: 4,
      fontSize: 10,
      textAlign: 'center',
      padding: 7.5,
      marginRight: 10,
      '& > span': {
        fontSize: 14,
        fontWeight: 800,
        color: theme.palette.moreColors.grey_6
      }
    }
  },
  '& .jobsPaginatorWrapper': {
    width: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .employerJobsSearchContainer': {
    margin: '0px 30px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '& .employerJobsSearch': {
    alignSelf: 'stretch',
    flexGrow: 1,
    marginRight: '24px'
  },
  '& .resultsTitle': {
    margin: '0px 30px 15px'
  }
});
