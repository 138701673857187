import React, { useState, useCallback, useRef, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import { InputAdornment, styled } from 'components';
import { Autocomplete, IconButton, Input } from 'components/shared';
import { MdSearch, MdClose } from 'components/icons';
import { AUTOCOMPLETE_ALL_JOBS_SEARCH, AUTOCOMPLETE_MY_JOBS_SEARCH } from 'api';

const StyledSearchInput = styled(({ className, inputProps, inputComponentProps }) => (
  <Input
    className={clsx(className, 'searchInputContainer')}
    inputProps={inputProps}
    {...inputComponentProps}
  />
))(({ theme }) => ({
  '&.searchInputContainer': {
    border: `1px solid ${theme.palette.moreColors.lightPurple}`,
    borderRadius: 12,
    backgroundColor: theme.palette.moreColors.grey_7
  },
  '& .searchInputStartAdornment': {
    color: theme.palette.moreColors.grey_3
  },
  '& .searchInputInputWrapper': {
    flex: 1,
    paddingLeft: 16,
    color: theme.palette.moreColors.grey_3
  }
}));

const JobsSearch = memo((props) => {
  const {
    value,
    onChange,
    nativeInputProps,
    inputCompProps,
    onFetch,
    onClear,
    onSuggestionSelected,
    ...rest
  } = props;
  const inputRef = useRef();

  const shouldRenderSuggestions = useCallback((v) => v.trim().length > 2, []);

  const handleSelect = useCallback(
    (e, { suggestionValue, suggestion, ...params }) => {
      onSuggestionSelected(e, { ...params, suggestion, suggestionValue, inputRef });
    },
    [onSuggestionSelected]
  );

  const renderRootInput = useCallback(
    (inputProps, inputComponentProps) => (
      <StyledSearchInput inputProps={inputProps} inputComponentProps={inputComponentProps} />
    ),
    []
  );

  return (
    <Autocomplete
      nativeInputProps={{
        value,
        onChange,
        placeholder: 'Search by keyword',
        type: 'text',
        ...nativeInputProps
      }}
      getInputComponent={renderRootInput}
      isDebounced
      onFetch={onFetch}
      onSuggestionSelected={handleSelect}
      shouldRenderSuggestions={shouldRenderSuggestions}
      inputCompProps={{
        inputRef,
        inputClassName: 'searchInputInputWrapper',
        htmlInputClassName: 'searchInput',
        startAdornment: (
          <InputAdornment position="start" className="searchInputStartAdornment">
            <MdSearch color="inherit" />
          </InputAdornment>
        ),
        endAdornment:
          value.length > 0 ? (
            <InputAdornment position="end">
              <IconButton color="primary" onClick={onClear} testID="search-input-clear-button">
                <MdClose color="inherit" />
              </IconButton>
            </InputAdornment>
          ) : null,
        analyticParams: {
          key: 'Employee jobs search focused',
          trigger: 'focus'
        },
        testID: 'search-input',
        ...inputCompProps
      }}
      {...rest}
    />
  );
});

const AllJobsSearch = memo((props) => {
  const { value, onChange, onClear, onSelect, JobsSearchProps, companyId } = props;

  const [searchAllJobs] = useLazyQuery(AUTOCOMPLETE_ALL_JOBS_SEARCH, {
    fetchPolicy: 'no-cache'
  });

  const handleSearch = useCallback(
    async (query, { onSuccess }) => {
      const { data } = await searchAllJobs({
        variables: { query, employerProfileId: companyId }
      });
      onSuccess(data?.allJobsSearch || []);
    },
    [searchAllJobs, companyId]
  );

  return (
    <JobsSearch
      value={value}
      suggestionValPath="title"
      onChange={onChange}
      onFetch={handleSearch}
      onClear={onClear}
      onSuggestionSelected={onSelect}
      {...JobsSearchProps}
    />
  );
});

const MyJobsSearch = memo((props) => {
  const { value, onChange, onClear, onSelect, filterValue, JobsSearchProps } = props;

  const [searchMyJobs] = useLazyQuery(AUTOCOMPLETE_MY_JOBS_SEARCH, {
    fetchPolicy: 'no-cache'
  });

  const handleSearch = useCallback(
    async (val, { onSuccess }) => {
      const query = JSON.stringify({ search: val, selectedValue: filterValue });
      const { data } = await searchMyJobs({
        // query = "{ "search": "", "selectedValue": "" }"
        variables: { query }
      });
      onSuccess(data?.myJobsSearch || []);
    },
    [searchMyJobs, filterValue]
  );

  return (
    <JobsSearch
      value={value}
      suggestionValPath="jobs.title"
      onChange={onChange}
      onFetch={handleSearch}
      onClear={onClear}
      onSuggestionSelected={onSelect}
      {...JobsSearchProps}
    />
  );
});

const commonJobsSearchPT = {
  value: PT.string.isRequired,
  onChange: PT.func.isRequired,
  onClear: PT.func.isRequired
};
const commonPropTypes = {
  ...commonJobsSearchPT,
  onSelect: PT.func.isRequired,
  JobsSearchProps: PT.objectOf(PT.any)
};

JobsSearch.propTypes = {
  ...commonJobsSearchPT,
  onFetch: PT.func.isRequired,
  onSuggestionSelected: PT.func.isRequired,
  nativeInputProps: PT.objectOf(PT.any),
  inputCompProps: PT.objectOf(PT.any)
};

JobsSearch.defaultProps = {
  nativeInputProps: {},
  inputCompProps: {}
};

AllJobsSearch.propTypes = {
  ...commonPropTypes
};

AllJobsSearch.defaultProps = {
  JobsSearchProps: {}
};

MyJobsSearch.propTypes = {
  ...commonPropTypes,
  filterValue: PT.string.isRequired
};

MyJobsSearch.defaultProps = {
  JobsSearchProps: {}
};

export { JobsSearch, MyJobsSearch, AllJobsSearch };
