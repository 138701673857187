import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { nanoid } from 'nanoid/non-secure';
import { InputAdornment, styled } from 'components';
import { Button, IconButton, Input } from 'components/shared';
import { MdDelete } from 'components/icons';

const PREFIX = 'jobQuestions';
const classes = {
  container: `${PREFIX}-container`,
  inputLabel: `${PREFIX}-inputLabel`,
  submitButton: `${PREFIX}-submitButton`
};
const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {},
  [`& .${classes.inputLabel}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  [`& .${classes.submitButton}`]: {
    width: '100%',
    maxWidth: 256,
    height: 48,
    borderRadius: 5,
    fontSize: 13,
    lineHeight: '15px'
  }
}));

function EditableJobQuestions(props) {
  const { className, isEditMode, questions, bindInput, onAdd, onRemove } = props;

  const handleAddNew = () => onAdd({ name: '', tempId: nanoid() });

  return (
    <StyledRoot className={clsx(classes.container, className)}>
      {isEditMode ? (
        <>
          {questions.map(({ name: questionName, questions_id: id, tempId }, i, arr) => (
            <Input
              key={`optionalQuestion__${id || tempId}`}
              id={`question.${i}.name-input`}
              {...bindInput(`questions.${i}.name`)}
              variant="textfield"
              label={
                <div className={classes.inputLabel}>
                  <span>{`Option Applicant Question #${i + 1}`}</span>
                  <span>Y/N</span>
                </div>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => onRemove(id, i)}
                    testID={`delete-question-button-${i + 1}`}
                  >
                    <MdDelete color="error" />
                  </IconButton>
                </InputAdornment>
              }
              withHelperText
              multiline
              maxRows={5}
              inputProps={{ required: true }}
              FormControlProps={{ sx: { mb: i === arr.length - 1 ? '20px' : '16px' } }}
              analyticParams={{
                key: 'Job question focused',
                trigger: 'focus'
              }}
              testID={`question-input-${i + 1}`}
            />
          ))}
          <Button
            variant="outlined-primary"
            className={classes.submitButton}
            // disabled={jobProcessing}
            onClick={handleAddNew}
            testID="add-question-button"
          >
            + Add Applicant Question
          </Button>
        </>
      ) : (
        questions.map(({ name: questionName, questions_id: id }, i, arr) => (
          <div key={`question__${id}`}>
            <div>{`Option Applicant Question #${i + 1}`}</div>
            <div>{questionName}</div>
          </div>
        ))
      )}
    </StyledRoot>
  );
}

EditableJobQuestions.propTypes = {
  className: PT.string,
  isEditMode: PT.bool.isRequired,
  bindInput: PT.func,
  onAdd: PT.func,
  onRemove: PT.func,
  questions: PT.arrayOf(
    PT.shape({
      name: PT.string,
      questions_id: PT.oneOfType([PT.string, PT.number])
    })
  ).isRequired
};

EditableJobQuestions.defaultProps = {
  className: '',
  bindInput: () => {},
  onAdd: () => {},
  onRemove: () => {}
};

export default EditableJobQuestions;
