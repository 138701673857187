import React from 'react';
import PT from 'prop-types';
import { Badge, styled } from 'components';
import { IconButton } from 'components/shared';
import { MdMailOutline } from 'components/icons';
import { useCommonUI, useMediaQueryMatches } from 'hooks';

const MobileButton = styled(IconButton)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 7
}));
const Dot = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-dot': {
    height: 14,
    minWidth: 14,
    border: `2px solid ${theme.palette.background.default}`,
    borderRadius: '50%',
    backgroundColor: theme.palette.moreColors.purpleLanding
  }
}));

function ChatButton(props) {
  const { userRole, isNewMessage } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { openChatModal } = useCommonUI();

  const openChat = () => openChatModal({ role: userRole });

  return isDesktop ? (
    <IconButton
      variant="outlined"
      color="primary"
      aria-label="matches"
      className="headerElement chatButton"
      onClick={openChat}
      testID="chat-button"
    >
      <Dot invisible={!isNewMessage} variant="dot" color="primary" overlap="circular">
        <MdMailOutline />
      </Dot>
    </IconButton>
  ) : (
    <MobileButton
      color="primary"
      aria-label="matches"
      className="headerElement"
      onClick={openChat}
      testID="chat-button"
    >
      <Dot invisible={!isNewMessage} variant="dot" color="primary" overlap="circular">
        <MdMailOutline />
      </Dot>
    </MobileButton>
  );
}

ChatButton.propTypes = {
  isNewMessage: PT.bool.isRequired,
  userRole: PT.string.isRequired
};

export default ChatButton;
