import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { qaAttr, unformatPhone } from 'utils';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { useForm } from 'components/form';
import { Button, Input, PhoneInput, Spinner } from 'components/shared';
import { POST_USER_ZIP, POST_USER_PHONE } from 'api';
import styles from 'styles/Onboarding';
import PrivacyClauseButton from './PrivacyClauseButton';

function Contacts({ calledToasts, setCalledToasts, formKey, form, onChange, onGoToNextStep }) {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [postUserZip, { loading: postZipLoading }] = useMutation(POST_USER_ZIP);
  const [postPhone, { loading: postPhoneLoading }] = useMutation(POST_USER_PHONE);
  const loading = postPhoneLoading || postZipLoading;
  const { $, set, withValidation } = useForm({
    initial: { zip: form.zip, phone: form.phone },
    validations: {
      zip: ['presence', 'zip'],
      phone: ['presence', 'phone']
    }
  });

  const handleFieldChange = (e, { name }) => {
    const val = name === 'zip' ? e.target.value : unformatPhone(e.target.value);
    set(name, val);
    onChange({ ...form, [name]: val });
  };

  const next = withValidation(async (attrs) => {
    await postUserZip({ variables: { zip: attrs.zip } });
    await postPhone({ variables: { phone: unformatPhone(attrs.phone) } });
    onGoToNextStep();
  });

  return (
    <div className="pageContent">
      <FormContainer>
        <Box
          component="h1"
          maxWidth={350}
          mx="auto"
          mb="13px"
          className="pageTitle"
          {...qaAttr('onboarding-form-title')}
        >
          ZIP Code
        </Box>
        <Input
          id="zipCode"
          {...$('zip', handleFieldChange)}
          placeholder="12345"
          autoComplete="off"
          required
          withHelperText
          highlightInputOnError={false}
          disabled={loading}
          FormControlProps={{
            sx: { maxWidth: isDesktop ? '100%' : 286 }
          }}
          className="input"
          analyticParams={{
            key: 'Employee zip focused (onboarding)',
            trigger: 'focus'
          }}
          testID="onboarding-zip-input"
        />
        <div className="descTextBlock" style={{ marginTop: 12 }}>
          <p className="secondaryText">
            Your <span style={{ color: '#A4A1D0' }}>Zip Code</span> let's us customize local results
            for you!
          </p>
        </div>
      </FormContainer>
      <FormContainer>
        <Box
          component="h1"
          maxWidth={350}
          mx="auto"
          mb="13px"
          className="pageTitle"
          {...qaAttr('onboarding-form-title')}
        >
          Phone Number
        </Box>
        <PhoneInput
          id="phone"
          {...$('phone', handleFieldChange)}
          type="tel"
          placeholder="1-123-456-7890"
          autoComplete="off"
          required
          withHelperText
          highlightInputOnError={false}
          disabled={loading}
          FormControlProps={{
            sx: { maxWidth: isDesktop ? '100%' : 286 }
          }}
          className="input"
          analyticParams={{
            key: 'Employee phone focused (onboarding)',
            trigger: 'focus'
          }}
          testID="onboarding-phone-input"
        />
      </FormContainer>
      <div className="descTextBlock">
        <p className="secondaryText" style={{ marginBottom: 12 }}>
          We only use your phone number to text you about interviews and&nbsp;feedback.
        </p>
        <p className="secondaryText" style={{ marginBottom: 32, fontStyle: 'italic' }}>
          P.S. We don't take your information lightly. All information is&nbsp;encrypted.
        </p>
      </div>
      <Button
        variant="filled-primary"
        endIcon={loading && <Spinner size={24} />}
        disabled={loading}
        className="nextBtn"
        style={{ marginBottom: isDesktop ? '25px' : '13px' }}
        onClick={next}
        {...qaAttr('onboarding-next-button')}
      >
        Next
      </Button>
      <PrivacyClauseButton />
    </div>
  );
}

Contacts.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const FormContainer = styled('div')({
  width: '100%',
  marginBottom: 24,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});
const StyledContacts = styled(Contacts)(styles);

export default StyledContacts;
