import React, { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import clsx from 'clsx';
import { getRoutes, qaAttr } from 'utils';
import { useNotificationsSubscribe, useAuth, useMatchedRoutes, useMediaQueryMatches } from 'hooks';
import { useAppStoreSelector } from 'store';
import { styled, Tabs, Tab } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdInsights } from 'components/icons';
import styles from 'styles/Dashboard/Header';
import ChatButton from './ChatButton';
import LogoLink from './LogoLink';
import ProfileLink from './ProfileLink';
import ReturnLink from './ReturnLink';
import LogoutButton from './LogoutButton';
import PublicHeader from './PublicHeader';
import HeaderTemplate from './HeaderTemplate';
import FaqLink from './FaqLink';

const ROUTES = getRoutes();

// eslint-disable-next-line react/prop-types
function EmployeeHeader({ className }) {
  // const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { authed: isAuthenticated } = useAuth();
  const { subscribe: subscribeToNotifications, isNewMessage } = useNotificationsSubscribe({
    role: 'employee'
  });
  const employeeJobsTab = useAppStoreSelector((state) => state.employeeJobsTab);
  const setEmployeeJobsTab = useAppStoreSelector((state) => state.setEmployeeJobsTab);

  const isCareerDevelopmentAllowed = process.env.ALLOW_CAREER_DEVELOPMENT === 'true';

  const {
    isEmployeeGrowPage,
    isEmployeeDashboardPage,
    isEmployeeProfilePage,
    isEmployeeSkillsProgressPage,
    isEmployeeConnectionsProgressPage,
    isEmployeeHiredProgressPage: isStillHired,
    isPublicRoute
  } = useMatchedRoutes();

  const isCareerDevelopment = isCareerDevelopmentAllowed && isEmployeeGrowPage;

  useEffect(() => {
    subscribeToNotifications();
  }, []);

  const handleJobsTabChange = useCallback(
    (e, val) => {
      if (!isEmployeeDashboardPage) navigate(ROUTES.employee.dashboard, { replace: true });
      setEmployeeJobsTab(val);
    },
    [isEmployeeDashboardPage]
  );

  const renderLogo = (logoProps) => <LogoLink key="header-logo" {...logoProps} />;

  const renderLogoutButton = () => <LogoutButton key="header-logout" />;

  const renderJobsPageTabs = () => (
    <Tabs
      key="jobsPageTabs"
      value={employeeJobsTab}
      aria-label="content tabs"
      className="employeeJobsTabs headerElement"
      onChange={handleJobsTabChange}
    >
      <Tab label="All Jobs" value={0} className="employeeJobsTab" {...qaAttr('all-jobs-button')} />
      <Tab label="My Jobs" value={1} className="employeeJobsTab" {...qaAttr('my-jobs-button')} />
    </Tabs>
  );

  const renderGrowIconButton = () => (
    <IconButton
      key="header-grow"
      variant={isCareerDevelopment ? 'filled-primary' : 'outlined'}
      color="primary"
      aria-label="profile"
      className="headerElement growIconButton"
      onClick={() => (isCareerDevelopment ? null : navigate(ROUTES.employee.grow))}
      testID="wt-grow-button"
    >
      <MdInsights />
    </IconButton>
  );

  const renderGrowButton = () => (
    <Button
      key="growButton"
      endIcon={<MdInsights />}
      variant="filled-secondary"
      className="employeeCareerDevButton headerElement"
      onClick={() => (isCareerDevelopment ? null : navigate(ROUTES.employee.grow))}
      testID="wt-grow-button"
    >
      New! WT Grow
    </Button>
  );

  const renderChatButton = () => (
    <ChatButton key="header-chat" userRole="employee" isNewMessage={isNewMessage} />
  );

  const renderProfileButton = () => <ProfileLink key="header-profile" userRole="employee" />;
  const renderFaqButton = () => <FaqLink key="header-faq" userRole="employee" />;

  const renderBackButton = (title = '', btnProps = {}) => (
    <ReturnLink key="header-back" title={title} {...btnProps} />
  );

  const renderContent = () => {
    switch (true) {
      case isCareerDevelopment: {
        const left = [
          renderBackButton('View Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop
          ? [renderFaqButton(), renderChatButton(), renderGrowIconButton(), renderProfileButton()]
          : [renderChatButton()];

        return (
          <HeaderTemplate
            title="WT Grow Portal"
            titleQaId="enterprise-dashboard-title"
            leftElements={left}
            rightElements={right}
            className={clsx(isCareerDevelopment && 'employee-grow-header')}
          />
        );
      }
      case isEmployeeDashboardPage: {
        const left = isDesktop ? [renderLogo()] : [renderProfileButton()];
        const right = isDesktop
          ? [renderFaqButton(), renderChatButton(), renderProfileButton()]
          : [renderChatButton()];
        const center = isDesktop
          ? [renderJobsPageTabs(), renderGrowButton()]
          : [renderJobsPageTabs()];

        return <HeaderTemplate leftElements={left} rightElements={right} centerElements={center} />;
      }
      case isPublicRoute: {
        if (!isAuthenticated) return <PublicHeader />;

        const left = isDesktop ? [renderLogo()] : [renderProfileButton()];
        const right = isDesktop
          ? [renderFaqButton(), renderChatButton(), renderProfileButton()]
          : [renderChatButton()];
        const center = isDesktop
          ? [renderJobsPageTabs(), renderGrowButton()]
          : [renderJobsPageTabs()];

        return <HeaderTemplate leftElements={left} rightElements={right} centerElements={center} />;
      }
      case isEmployeeProfilePage: {
        const left = [
          renderBackButton('View Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop
          ? [renderLogoutButton(), renderFaqButton(), renderChatButton(), renderGrowIconButton()]
          : [renderChatButton(), renderLogoutButton()];

        return (
          <HeaderTemplate
            title="Account"
            titleQaId="employee-profile"
            leftElements={left}
            rightElements={right}
          />
        );
      }
      case isEmployeeSkillsProgressPage: {
        const left = [
          renderBackButton('My Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop
          ? [renderFaqButton(), renderChatButton(), renderProfileButton()]
          : [];
        const title = isDesktop ? '' : 'New Gained Skills';

        return (
          <HeaderTemplate title={title} titleQaId="" leftElements={left} rightElements={right} />
        );
      }
      case isEmployeeConnectionsProgressPage: {
        const left = [
          renderBackButton('My Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop
          ? [renderFaqButton(), renderChatButton(), renderProfileButton()]
          : [];
        const title = isDesktop ? '' : 'Suggested Connections';

        return (
          <HeaderTemplate title={title} titleQaId="" leftElements={left} rightElements={right} />
        );
      }
      case isStillHired: {
        const left = [
          renderBackButton('My Jobs', { isRouterLink: true, to: ROUTES.employee.dashboard })
        ];
        const right = isDesktop
          ? [renderFaqButton(), renderChatButton(), renderProfileButton()]
          : [];

        return <HeaderTemplate titleQaId="" leftElements={left} rightElements={right} />;
      }
      default:
        return null;
    }
  };

  return <div className={className}>{renderContent()}</div>;
}

const StyledHeader = styled(EmployeeHeader)(styles);

export default memo(StyledHeader);
