import React from 'react';
import PT from 'prop-types';
import { MuiButtonBase, styled } from 'components';

const StyledRoot = styled(MuiButtonBase, { shouldForwardProp: (prop) => prop !== 'active' })(
  ({ theme, active }) => ({
    '&.categoryLink': {
      width: 102,
      height: 86,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
      borderRadius: 3,
      ...(active && {
        borderColor: theme.palette.moreColors.yellow
      }),
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        '& .categoryLink__icon svg': {
          transform: 'rotate(-20deg)'
        }
      }
    },
    '& .categoryLink__content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .categoryLink__icon': {
      marginBottom: 10
    },
    '& .categoryLink__label': {
      fontSize: 10,
      color: '#F2F2F2'
    }
  })
);

export default function CategoryLink(props) {
  const { active, icon, title, ...rest } = props;

  return (
    <StyledRoot className="categoryLink" disableRipple active={active} {...rest}>
      <div className="categoryLink__content">
        {icon && <div className="categoryLink__icon">{icon}</div>}
        <div className="categoryLink__label">{title}</div>
      </div>
    </StyledRoot>
  );
}

CategoryLink.propTypes = {
  active: PT.bool,
  icon: PT.node,
  title: PT.string
};

CategoryLink.defaultProps = {
  active: false,
  icon: null,
  title: ''
};
