import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { find, findIndex } from 'lodash';
import { Box, Pagination } from 'components';
import {
  AllJobsEmpty,
  AllJobsSearch,
  EmployeeDashboardManual,
  EmployeeDashboardMobileManual,
  GrowLink,
  JobSearchWithGrowBanner,
  JobsSwiper
} from 'components/Dashboard/employee';
import { Button, IconButton, Spinner } from 'components/shared';
import { MdDisplaySettings } from 'components/icons';
import { EmployeeJobCard, SimpleJobCard } from 'components/Job';
import { useAuth, useMediaQueryMatches } from 'hooks';
import { getRoutes } from 'utils';
import { allJobsArgsVar, GET_EMPLOYEE_JOBS } from 'api';

const ROUTES = getRoutes();
const INIT_PAGE = 1;
const RE_PAGINATION = 'rePagination'; // split by pages
const NO_PAGINATION = 'noPagination'; // return full list (aka infinite scroll)

const getJobsTotal = (jobs) => find(jobs, (o) => o.total && o.total > 0)?.total || 0;
const calcTotalPages = (totalJobs, itemsPerPage) => Math.round(totalJobs / itemsPerPage);
const calcPageByOffset = (offset, itemsPerPage) => offset / itemsPerPage + 1;
const calcOffsetByPage = (page, itemsPerPage) => (page - 1) * itemsPerPage;

export default function JobCardsRenderer(props) {
  const {
    applyLoading,
    interviewSaveLoading,
    onApply,
    onReject,
    onRetract,
    onSaveInterview,
    onStar,
    rejectLoading,
    retractLoading,
    starLoading,
    jobs,
    jobsLoading,
    fetchAllJobs,
    fetchMore,
    render,
    mobileJobsView = 'swiper', // ['swiper', 'list']
    initOffset = 0,
    pageLimit = 10,
    paginationVariant = NO_PAGINATION, // [NO_PAGINATION, RE_PAGINATION]
    showEmptyJobsMessage = true
  } = props;

  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const { authed: isAuthenticated } = useAuth();

  const [swipeCount, setSwipeCount] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchResultSelected, setIsSearchResultSelected] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterSettings, setFilterSettings] = useState({ skills: [], industries: [] });
  // pagination
  const [canPaginate, setCanPaginate] = useState(false);
  const [paginationOffset, setPaginationOffset] = useState(initOffset);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(INIT_PAGE);
  //
  const [isManualVisible, setIsManualVisible] = useState(false);
  // swiper component
  const [jobSwiperKey, setJobSwiperKey] = useState(0);
  const [jobSwiperInitIndex, setJobSwiperInitIndex] = useState(0);

  const getFilterParams = useCallback(() => {
    const {
      skills = [],
      industries = [],
      payMin,
      payMax,
      currencyId,
      payPeriodId,
      hireDate
    } = filterSettings;
    return {
      skills: skills.length ? skills.map((o) => o.skill_id).join(', ') : '',
      industry: industries.length ? industries.map((o) => o.industry_id).join(', ') : '',
      payMin,
      payMax,
      currencyId,
      payPeriodId,
      hireDate
    };
  }, [JSON.stringify(filterSettings)]);

  const resetAll = useCallback(async () => {
    setSearchValue('');
    setPaginationOffset(0);
    setIsSearchResultSelected(false);
    // clear employeeJobs from cache
    client.cache.evict({ fieldName: 'employeeJobs', broadcast: true });
    client.cache.gc();
    await fetchAllJobs({
      variables: { offset: 0, limit: pageLimit, ...getFilterParams() }
    });
    // reset swiper
    setJobSwiperKey((key) => key + 1);
    setJobSwiperInitIndex(0);
  }, [client.cache, fetchAllJobs, getFilterParams, pageLimit]);

  useEffect(() => {
    const isUserInstructed = localStorage.getItem('user_instructed');
    if (!isUserInstructed) setIsManualVisible(true);
  }, []);

  useEffect(() => {
    (async () => {
      const { state, pathname } = location;
      const { refetchJobs = true, jobId, jobActions } = state || {};

      if (!refetchJobs) {
        const allJobsArgs = allJobsArgsVar();
        // pull jobs from cache without fetch
        const { data } = await fetchAllJobs({
          variables: { ...allJobsArgs },
          fetchPolicy: 'cache-only'
        });
        const fetchedJobs = data?.employeeJobs;

        if (isDesktop) {
          // scroll to previously watched card
          const card = document.querySelector(`[data-card="${jobId}"]`);
          if (card) card.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          const index = findIndex(fetchedJobs, { id: Number(jobId) });
          if (index !== -1) {
            const { approved = false, rejected = false } = jobActions || {};
            // if job was approved or rejected, show next card, otherwise show previously watched card
            const newIdx = approved || rejected ? index + 1 : index;
            setJobSwiperInitIndex(newIdx);
            setJobSwiperKey((prev) => prev + 1);
          }
        }
        // clear history state
        navigate(pathname, { replace: true, state: {} });
      } else {
        resetAll();
      }
    })();
  }, [isDesktop, JSON.stringify(filterSettings)]);

  useEffect(() => {
    if (jobs.length > 0 && paginationOffset === 0) {
      // set initial pagination offset and show pagination button
      const total = getJobsTotal(jobs);
      if (total && total > jobs.length) {
        const offset =
          paginationVariant === RE_PAGINATION
            ? calcOffsetByPage(INIT_PAGE, pageLimit)
            : jobs.length;
        setCanPaginate(true);
        setPaginationOffset(offset);
        setTotalPages(calcTotalPages(total, pageLimit));
        setCurrentPage(INIT_PAGE);
      }
    }
  }, [paginationOffset, jobs.length, jobs]);

  const completeManual = useCallback(() => {
    localStorage.setItem('user_instructed', 'true');
    setIsManualVisible(false);
  }, []);

  const loadMoreJobs = useCallback(
    async (nextPage) => {
      const offset =
        paginationVariant === RE_PAGINATION
          ? calcOffsetByPage(nextPage, pageLimit)
          : paginationOffset;
      const result = await fetchMore({
        variables: { paginationVariant, offset, limit: pageLimit }
      });

      if (result) {
        const incoming = result?.data?.employeeJobs || [];
        const total = getJobsTotal(incoming); // will change if some jobs were applied
        let nextPaginationOffset = offset;

        if (paginationVariant !== RE_PAGINATION) {
          // calc it only for "Load more" button
          nextPaginationOffset = incoming.length + paginationOffset;
          setCanPaginate(nextPaginationOffset < total);
        }

        setPaginationOffset(nextPaginationOffset);
        setCurrentPage(nextPage ?? calcPageByOffset(nextPaginationOffset, pageLimit));
      }
    },
    [fetchMore, pageLimit, paginationOffset, paginationVariant]
  );

  const swiperLazyLoad = useCallback(
    ({ index }) => {
      if (canPaginate && index === jobs.length - 3) loadMoreJobs();
    },
    [loadMoreJobs, canPaginate, jobs.length]
  );

  const handlePageChange = (e, page) => {
    loadMoreJobs(page);
  };

  const changeSearchValue = useCallback((e, { newValue }) => setSearchValue(newValue), []);

  const onSearchClear = useCallback(() => {
    setSearchValue('');
    resetAll();
  }, [resetAll]);

  const onSuggestionSelect = useCallback(
    (e, { suggestionValue, suggestion, inputRef }) => {
      // set selected job to cache instead of current
      client.cache.updateQuery(
        { query: GET_EMPLOYEE_JOBS, broadcast: false, overwrite: true },
        (d) => ({ employeeJobs: [{ ...suggestion }] })
      );
      setCanPaginate(false);
      setIsSearchResultSelected(true);
    },
    [client.cache]
  );

  const jobStarHandler = useCallback(
    (jobData, starred, options) => {
      onStar(jobData, true, options);
    },
    [onStar]
  );

  const jobRejectHandler = useCallback(
    (job, options) => {
      let extendedOptions = { ...options };

      if (isDesktop) {
        extendedOptions = {
          ...extendedOptions,
          onSuccess: () => {
            if (options?.onSuccess) options.onSuccess();
            // remove job from list (repeats both on AllJobs and EmployeeJob)
            client.cache.modify({
              fields: {
                employeeJobs(refs, { readField }) {
                  return refs.filter((ref) => job.id !== readField('id', ref));
                }
              }
            });
            // refresh all jobs if last one was removed
            if (jobs.length === 1) resetAll();
          }
        };
      }

      onReject(job, extendedOptions);
    },
    [isDesktop, onReject, client.cache, jobs.length, resetAll]
  );

  const renderSearch = (params) => {
    const {
      showSettingsButton = true,
      showMobileGrowBanner = true,
      companyId,
      searchProps
    } = params || {};

    if (isDesktop || (!isDesktop && !showMobileGrowBanner)) {
      return (
        <div className="autocompleteContainer">
          <AllJobsSearch
            value={searchValue}
            onChange={changeSearchValue}
            onClear={onSearchClear}
            onSelect={onSuggestionSelect}
            companyId={companyId}
            {...searchProps}
          />
          {showSettingsButton && (
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="open filters modal"
              sx={{ ml: '17px' }}
              onClick={() => setIsFilterModalOpen(true)}
              testID="search-filter-button"
            >
              <MdDisplaySettings />
            </IconButton>
          )}
        </div>
      );
    }

    return (
      <Box px="16px">
        <JobSearchWithGrowBanner
          renderGrowBanner={() => <GrowLink />}
          renderSearchComponent={() => (
            <AllJobsSearch
              value={searchValue}
              onChange={changeSearchValue}
              onClear={onSearchClear}
              onSelect={onSuggestionSelect}
              companyId={companyId}
              JobsSearchProps={{
                inputCompProps: {
                  className: 'animatedSearch__input',
                  startAdornment: null,
                  endAdornment: null,
                  FormControlProps: {}
                }
              }}
            />
          )}
          canClear={searchValue.length > 0}
          onClear={onSearchClear}
        />
      </Box>
    );
  };

  const handleActionIfAuthed = (callback, job) => {
    // handles jobs if the user is authenticated
    if (isAuthenticated) return callback;

    // re-routes in the event they are not authenticated
    const route = getRoutes({ id: job.id }).employee.job;
    return () =>
      navigate(ROUTES.login.default, {
        state: {
          from: {
            location: {
              ...location,
              pathname: route
            }
          }
        }
      });
  };

  const renderPaginationButtons = () => {
    if (jobs.length === 0 || !canPaginate) return null;

    if (paginationVariant === NO_PAGINATION && isDesktop) {
      return (
        <Box p="50px 20px" display="flex" justifyContent="center">
          <Button
            variant="text"
            sx={{ padding: '20px 30px', fontSize: 20 }}
            endIcon={jobsLoading && <Spinner width={20} />}
            disabled={jobsLoading}
            onClick={loadMoreJobs}
            testID="load-more-button"
          >
            Load more
          </Button>
        </Box>
      );
    }
    if (paginationVariant === RE_PAGINATION) {
      return (
        <Box p="48px 20px" display="flex" justifyContent="center">
          <Pagination
            count={totalPages}
            page={currentPage}
            disabled={jobsLoading}
            onChange={handlePageChange}
          />
        </Box>
      );
    }

    return null;
  };

  const renderJobs = () => {
    if (showEmptyJobsMessage && !jobsLoading && !jobs.length) return <AllJobsEmpty />;

    if (isDesktop) {
      return (
        <>
          <div className="jobsContainer">
            {jobs.map((job) => {
              const { passed } = job;
              // const hidden = passed && !isSearchResultSelected;
              return (
                <div className="jobCol" key={`jobCol__${job.id}`}>
                  <EmployeeJobCard
                    interviewSaveLoading={interviewSaveLoading}
                    job={job}
                    onApply={handleActionIfAuthed(onApply, job)}
                    onReject={handleActionIfAuthed(jobRejectHandler, job)}
                    onRetract={handleActionIfAuthed(onRetract, job)}
                    onSaveInterview={handleActionIfAuthed(onSaveInterview, job)}
                    onScheduleCall={handleActionIfAuthed(onApply, job)}
                    onStar={handleActionIfAuthed(jobStarHandler, job)}
                    routerLinkProps={{ state: { isAllJobsOpener: true } }}
                    withSchedule
                  />
                </div>
              );
            })}
          </div>
          {renderPaginationButtons()}

          {isManualVisible && (
            <EmployeeDashboardManual
              isOpen
              jobExampleData={jobs?.[0]}
              onComplete={completeManual}
            />
          )}
        </>
      );
    }

    // curried function because we do not have the job from the map since JobSwiper handles that
    const handleMobileAction = (callback) => (itemParams, callbackParams) => {
      if (isAuthenticated) callback(itemParams, callbackParams);
      else {
        const route = getRoutes({ id: itemParams.id }).employee.job;
        navigate(ROUTES.login.default, {
          state: {
            from: {
              location: {
                ...location,
                pathname: route
              }
            }
          }
        });
      }
    };

    const handleMobileReject = (callback) => (itemParams, callbackParams) => {
      setSwipeCount(swipeCount + 1);
      // do not care about authentication here, we only want this number to show a CTA after they have scrolled through all of the employer jobs.
      callback(itemParams, callbackParams);
    };

    if (mobileJobsView === 'list') {
      return (
        <div className="jobsContainer list">
          {jobs.map((job) => (
            <SimpleJobCard key={`job__${job.id}`} job={job} />
          ))}
          {renderPaginationButtons()}
        </div>
      );
    }

    return (
      <div className="jobsContainer swiper">
        <JobsSwiper
          applyLoading={applyLoading}
          initialIndex={jobSwiperInitIndex}
          interviewSaveLoading={interviewSaveLoading}
          jobs={jobs}
          jobsLoading={jobsLoading}
          key={jobSwiperKey}
          onApply={handleMobileAction(onApply)}
          onJobsRefresh={resetAll}
          onLoadMore={swiperLazyLoad}
          onReject={handleMobileReject(jobRejectHandler)}
          onRetract={onRetract}
          onSaveInterview={handleMobileAction(onSaveInterview)}
          onStar={handleMobileAction(jobStarHandler)}
          rejectLoading={rejectLoading}
          retractLoading={retractLoading}
          starLoading={starLoading}
        />
        {isManualVisible && <EmployeeDashboardMobileManual isOpen onComplete={completeManual} />}
      </div>
    );
  };

  return render({
    ...props,
    renderSearch,
    filterSettings,
    isFilterModalOpen,
    setFilterSettings,
    setIsFilterModalOpen,
    renderJobs,
    jobsLoading,
    swipeCount
  });
}
