import React, { useState, useEffect, useMemo, memo } from 'react';
import PT from 'prop-types';
import { format, isFuture, isToday, isEqual as isEqualDates, isThisWeek } from 'date-fns';
import { map, find } from 'lodash';
import clsx from 'clsx';
import { qaAttr, WEEK_DAYS_LONG } from 'utils';
import { getCurrentWeekDates, makeInterviewSlotsByWeek } from 'utils/schedules/schedules';
import { Box, Accordion, AccordionDetails, AccordionSummary, styled } from 'components';
import styles from 'styles/Dashboard/EmployeeJobInterviewSchedule';
import { MdArrowBack, MdArrowForward, MdClose, MdExpandMore } from '../../icons';
import Button, { IconButton } from '../../shared/Button';
import Spinner from '../../shared/Spinner';

const StyledRoot = styled('div')(styles);

const defaultWeek = () => Array(7).fill([]);

function JobInterviewSchedule(props) {
  const {
    confirmLoading,
    employerTimeZone,
    inactiveDates,
    interviewDate,
    onClose,
    onConfirm,
    onDateSelect,
    schedules,
    schedulesLoading,
    testID,
    userTimeZone,
    withCancel,
    withConfirmation
  } = props;
  const [interviewScheduleId, setInterviewScheduleId] = useState(null);
  const [currentWeekSlots, setCurrentWeekSlots] = useState(defaultWeek);
  const [currentWeekDates, setCurrentWeekDates] = useState(getCurrentWeekDates);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [chooseAnotherDateAlert, setChooseAnotherDateAlert] = useState(false);
  const [openedAccordion, setOpenedAccordion] = useState();
  const [error, setError] = useState('');
  const isCurrentWeek = useMemo(() => isThisWeek(currentWeekDates.start), [currentWeekDates.start]);

  useEffect(() => {
    setOpenedAccordion(null);

    if (
      schedules &&
      schedules.length &&
      currentWeekDates.start &&
      employerTimeZone &&
      userTimeZone
    ) {
      const weekDaysWithSlots = makeInterviewSlotsByWeek(
        schedules,
        currentWeekDates.start,
        employerTimeZone,
        userTimeZone
      );

      setCurrentWeekSlots(weekDaysWithSlots);
    }
  }, [JSON.stringify(schedules), currentWeekDates.start, userTimeZone, employerTimeZone]);

  useEffect(() => {
    if (
      currentWeekSlots.length &&
      currentWeekSlots.some((arr) => arr.length) &&
      inactiveDates.length
    ) {
      const currentWeekSlotsWithBooked = currentWeekSlots.map((slots, dayIdx) =>
        slots.map((slot) => {
          // check if dates were booked by inactiveDates and set "booked" field
          const booked = !!find(inactiveDates, (d) => isEqualDates(d, slot.date));
          return { ...slot, booked };
        })
      );

      setCurrentWeekSlots(currentWeekSlotsWithBooked);
    }
  }, [JSON.stringify(currentWeekSlots), JSON.stringify(inactiveDates)]);

  const handleConfirmDate = () => {
    if (onConfirm && interviewDate && interviewScheduleId) {
      onConfirm(interviewDate, interviewScheduleId, {
        onInterviewDeny: () => {
          setIsConfirmation(false);
          setChooseAnotherDateAlert(true);
        }
      });
    } else {
      let reason = '';
      if (!interviewDate) reason = 'Invalid date.';
      if (!interviewScheduleId) reason = 'Invalid schedule id.';
      setError(`Something went wrong! ${reason}`);
    }
  };

  const cancelConfirmation = () => {
    onDateSelect();
    setIsConfirmation(false);
    setChooseAnotherDateAlert(false);
  };

  const selectTime = (newDate, scheduleId, isSelected) => {
    setError('');

    if (!isSelected) {
      if (withConfirmation) setIsConfirmation(true);
      if (onDateSelect) {
        setInterviewScheduleId(scheduleId);
        onDateSelect(newDate, scheduleId);
      }
    } else {
      onDateSelect();
    }
  };

  const showNextWeek = () =>
    setCurrentWeekDates(
      getCurrentWeekDates(currentWeekDates.end.setDate(currentWeekDates.end.getDate() + 1))
    );

  const showPrevWeek = () =>
    setCurrentWeekDates(
      getCurrentWeekDates(currentWeekDates.start.setDate(currentWeekDates.start.getDate() - 1))
    );

  const handleAccordionChange = (e, expanded, index) => {
    setOpenedAccordion(expanded ? index : null);
  };

  const getOpenSlotsCount = (slots) =>
    slots.filter((o) => !o.isPast && !o.booked && !o.outOfDayLimit).length;

  if (chooseAnotherDateAlert) {
    return (
      <StyledRoot>
        <div className="confirm__header">
          <IconButton
            color="primary"
            className="confirm__closeBtn"
            onClick={cancelConfirmation}
            testID={`job-schedule-close-confirm-button-${testID}`}
          >
            <MdClose />
          </IconButton>
        </div>
        <div className="confirm__content">
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className="confirm__title" style={{ marginBottom: 16 }}>
              This time is no longer available
            </div>
            <Button
              variant="filled-primary"
              height={46}
              onClick={() => setChooseAnotherDateAlert(false)}
              testID={`job-schedule-submit-button-${testID}`}
            >
              Choose New Time
            </Button>
          </Box>
        </div>
      </StyledRoot>
    );
  }

  if (isConfirmation && interviewDate) {
    return (
      <StyledRoot>
        <div className="confirm__header">
          <IconButton
            color="primary"
            className="confirm__closeBtn"
            onClick={cancelConfirmation}
            testID={`job-schedule-close-confirm-button-${testID}`}
          >
            <MdClose />
          </IconButton>
        </div>
        <div className="confirm__content">
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className="confirm__title">Confirm interview on</div>
            <div className="confirm__date">
              {format(interviewDate, "EEEE, MMM do 'at' hh:mm a?")}
            </div>
            <Button
              variant="filled-primary"
              disabled={confirmLoading}
              endIcon={confirmLoading ? <Spinner size={24} /> : null}
              width={162}
              height={46}
              onClick={handleConfirmDate}
              testID={`job-schedule-submit-button-${testID}`}
            >
              Go get it!
            </Button>
            {error && (
              <Box my="5px" color="error.main" {...qaAttr('job-schedule-error-message')}>
                {error}
              </Box>
            )}
          </Box>
        </div>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot>
      <div className="header">
        <IconButton
          edge="start"
          disabled={isCurrentWeek}
          className="header__action"
          onClick={showPrevWeek}
          testID={`job-schedule-prev-week-button-${testID}`}
        >
          <MdArrowBack fontSize="inherit" />
        </IconButton>
        <div className="header__title">
          {`${format(currentWeekDates.start, 'MMM do')} - ${format(
            currentWeekDates.end,
            'MMM do'
          )}`}
        </div>
        <IconButton
          edge="end"
          className="header__action"
          onClick={showNextWeek}
          testID={`job-schedule-next-week-button-${testID}`}
        >
          <MdArrowForward fontSize="inherit" />
        </IconButton>
      </div>
      <div className="content">
        {schedulesLoading && (
          <div className="scheduleLoaderContainer">
            <Spinner size={24} />
          </div>
        )}
        <div className="days__container">
          {map(WEEK_DAYS_LONG, (day, i) => {
            const weekDayToDate = currentWeekDates.weekDates[i];
            const isDayBefore = !isToday(weekDayToDate) && !isFuture(weekDayToDate);
            const currentDaySlots = currentWeekSlots[i];
            const isDisabled = isDayBefore || !currentDaySlots.length;
            const openSlotsCount = getOpenSlotsCount(currentDaySlots);

            return (
              <Accordion
                key={`day__${i}`}
                expanded={openedAccordion === i}
                square
                elevation={0}
                disabled={isDisabled}
                classes={{
                  root: 'expansionPanel__root'
                }}
                onChange={(...params) => handleAccordionChange(...params, i)}
              >
                <AccordionSummary
                  expandIcon={
                    <MdExpandMore
                      fontSize="inherit"
                      color="primary"
                      className="expansionPanel__icon"
                      style={{ ...(isDisabled && { display: 'none' }) }}
                    />
                  }
                  classes={{
                    root: 'expansionPanel__summary',
                    content: 'expansionPanel__summaryContent'
                  }}
                  {...qaAttr(`job-schedule-accordion-button-${day}-${testID}`)}
                >
                  <span className="weekDay">{day}</span>
                  &nbsp;
                  {!isDisabled && (
                    <span className="openTimesCount">
                      {`| ${openSlotsCount} time${openSlotsCount > 1 ? 's' : ''} open`}
                    </span>
                  )}
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: 'expansionPanel__details' }}
                  {...qaAttr(`job-schedule-accordion-summary-${day}-${testID}`)}
                >
                  <div
                    className="timesContainer"
                    style={{
                      justifyContent: currentDaySlots.length > 1 ? 'flex-start' : 'center'
                    }}
                  >
                    {map(
                      currentDaySlots,
                      ({ date, key, _key, scheduleId, booked, outOfDayLimit, isPast }, k) => {
                        const slotStr = `${i}-${_key}`;
                        const isSelected = interviewDate
                          ? isEqualDates(date, interviewDate)
                          : false;
                        const isTimeDisabled = isSelected || isPast || booked || outOfDayLimit;

                        return (
                          <div key={`time__${k}`} className="timeItem">
                            <Button
                              disabled={isTimeDisabled}
                              aria-pressed={isSelected}
                              className={clsx(
                                'timeButton',
                                isTimeDisabled && 'disabled',
                                isSelected && 'selected'
                              )}
                              onClick={() => selectTime(date, scheduleId, isSelected)}
                              testID={`job-schedule-time-button-${slotStr}-${testID}`}
                            >
                              {format(date, 'hh:mm aaa')}
                            </Button>
                          </div>
                        );
                      }
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
      {withCancel && (
        <div className="footer">
          <Button
            variant="filled-primary"
            width={170}
            height={40}
            onClick={onClose}
            testID={`job-schedule-cancel-button-${testID}`}
          >
            Cancel
          </Button>
        </div>
      )}
    </StyledRoot>
  );
}

JobInterviewSchedule.propTypes = {
  confirmLoading: PT.bool,
  employerTimeZone: PT.string.isRequired,
  inactiveDates: PT.array.isRequired,
  interviewDate: PT.instanceOf(Date),
  onClose: PT.func,
  onConfirm: PT.func,
  onDateSelect: PT.func.isRequired,
  schedules: PT.arrayOf(
    PT.shape({
      count: PT.number,
      endDate: PT.instanceOf(Date),
      id: PT.number,
      length: PT.number,
      schedule: PT.objectOf(PT.arrayOf(PT.string)),
      startDate: PT.instanceOf(Date)
    })
  ).isRequired,
  schedulesLoading: PT.bool,
  testID: PT.string,
  userTimeZone: PT.string.isRequired,
  withCancel: PT.bool,
  withConfirmation: PT.bool
};

JobInterviewSchedule.defaultProps = {
  confirmLoading: false,
  interviewDate: null,
  onClose: () => undefined,
  onConfirm: undefined,
  schedulesLoading: false,
  testID: '',
  withCancel: true,
  withConfirmation: true
};

export const PureJobInterviewSchedule = JobInterviewSchedule;
export default memo(JobInterviewSchedule);
