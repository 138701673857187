import React from 'react';
import { SvgIcon } from 'components';

function GrowPathIcon(props) {
  return (
    <SvgIcon viewBox="0 0 29.5 31.5" {...props}>
      <g>
        <g>
          <g>
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#2D2B6F"
              points="13,0 0,13.7 10,31.5 23,17.8"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#49479D"
              points="7.6,10.2 21.1,0.1 23.5,21.4 10,31.5"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#EFB954"
              points="23.4,22.2 26.5,4.7 13.1,14 10,31.5"
            />
            <polygon
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F7EEEF"
              points="24.2,24.9 29.5,14.5 15.3,21.1 10,31.5"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default GrowPathIcon;
