import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { Box, styled } from 'components';
import { HorizontalProgress } from 'components/shared';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.scoreCard': {
    padding: '25px 15px 15px',
    borderRadius: 5,
    backgroundColor: '#332b3f', // @TODO: get from theme
    [theme.breakpoints.down('desktopApp')]: {
      padding: 10,
      borderRadius: 8,
      backgroundColor: theme.palette.moreColors.brand,
      '& .progress__line': {
        background: theme.palette.moreColors.grey_4
      }
    }
  },
  '& .scoreCard__title': {
    marginBottom: 20,
    fontSize: 15,
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 4,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '130%',
      color: theme.palette.common.white
    }
  },
  '& .scoreCard__metricItem': {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&.scoreCard__metricItem_complete': {
      '& .scoreCard__metricItem-title': {
        color: theme.palette.common.white
      }
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 16
    }
  },
  '& .scoreCard__metricItem-title': {
    fontSize: 12,
    color: 'rgba(164, 163, 164, 0.8)', // @TODO: get from theme
    [theme.breakpoints.down('desktopApp')]: {
      fontWeight: 700,
      lineHeight: '150%',
      color: theme.palette.moreColors.grey_5
    }
  },
  '& .scoreCard__metricItem-score': {
    marginLeft: 6,
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      fontWeight: 700,
      lineHeight: '150%'
    }
  }
}));

export default function ScoreCard(props) {
  const { score, scoreType, scoreAttrs } = props;

  return (
    <StyledRoot className="scoreCard">
      <div className="scoreCard__title">{scoreType}</div>
      <Box mb="20px">
        <HorizontalProgress percent={score} />
      </Box>
      {scoreAttrs.map(({ complete, label, value }, i) => (
        <div
          key={`scoreAttr__${i}`}
          className={clsx('scoreCard__metricItem', complete && 'scoreCard__metricItem_complete')}
        >
          <div className="scoreCard__metricItem-title">{label}</div>
          <div className="scoreCard__metricItem-score">{value}</div>
        </div>
      ))}
    </StyledRoot>
  );
}

ScoreCard.propTypes = {
  score: PT.number.isRequired,
  scoreType: PT.string.isRequired,
  scoreAttrs: PT.arrayOf(
    PT.shape({
      complete: PT.bool,
      label: PT.string,
      value: PT.number
    })
  ).isRequired
};

ScoreCard.defaultProps = {};
