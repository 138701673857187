import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { styled } from 'components';
import { MdStar } from 'components/icons';

const compName = 'SimpleRating';
const classes = {
  container: `${compName}-container`,
  star: `${compName}-star`,
  starIcon: `${compName}-starIcon`,
  fullStar: `${compName}-starIcon_full`,
  emptyStar: `${compName}-starIcon_empty`,
  halfStarWrapper: `${compName}-halfStar`
};
const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  [`& .${classes.star}`]: {
    position: 'relative',
    display: 'inline-flex'
  },
  [`& .${classes.starIcon}`]: {
    fontSize: 16,
    [`&.${classes.fullStar}`]: { color: theme.palette.primary.dark },
    [`&.${classes.emptyStar}`]: { color: theme.palette.moreColors.grey_3 }
  },
  [`& .${classes.halfStarWrapper}`]: {
    position: 'absolute',
    left: 0,
    width: '50%',
    zIndex: 1,
    overflow: 'hidden'
  }
}));

const stars = [1, 2, 3, 4, 5];

function SimpleRating({ className, rating }) {
  return (
    <StyledRoot className={clsx(className, classes.container)}>
      {stars.map((n, i) => {
        const isFullStar = n <= rating;
        const isHalfStar = n - rating === 0.5;
        return (
          <span key={`rateStar__${i}`} className={classes.star}>
            <MdStar
              className={clsx(
                classes.starIcon,
                isFullStar && !isHalfStar ? classes.fullStar : classes.emptyStar
              )}
            />
            {isHalfStar && (
              <span className={classes.halfStarWrapper}>
                <MdStar className={clsx(classes.starIcon, classes.fullStar)} />
              </span>
            )}
          </span>
        );
      })}
    </StyledRoot>
  );
}

SimpleRating.propTypes = {
  rating: PT.number.isRequired,
  className: PT.string
};

SimpleRating.defaultProps = {
  className: ''
};

export default SimpleRating;
