import React, { useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { format as formatDate } from 'date-fns';
import { useCountryStateQuery, useTrackJobView, useMediaQueryMatches } from 'hooks';
import { formatJobAddress, getRoutes } from 'utils';
import { Box, styled } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdClose, MdStarBorder, MdIosShare } from 'components/icons';
import { ShareJob } from 'components/Job';
import defaultImg from 'assets/img/job_default.png';
import defaultEmployerImg from 'assets/img/employer_default.png';
import { addAlpha } from 'styles/utils';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.card': {
    position: 'relative',
    // minHeight: 396,
    width: '100%',
    padding: 0,
    border: '1px solid rgba(0,0,0,0.1)',
    flexDirection: 'column',
    alignItems: 'initial',
    justifyContent: 'initial',
    borderRadius: 16,
    transition: 'box-shadow 300ms linear',
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px 0 rgba(0,0,0,0.16)',
      '& .card__company': {
        visibility: 'hidden'
      },
      '& .card__actions': {
        display: 'flex'
      }
    }
  },
  '& .card__shareButton': {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 100,
    width: 35,
    height: 35,
    fontSize: 18,
    backgroundColor: theme.palette.moreColors.purpleLight_1,
    '&:hover': {
      backgroundColor: theme.palette.moreColors.purpleLight_1
    }
  },
  '& .card__actions': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    padding: '60px 18px 25px',
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(180deg, ${addAlpha(
      theme.palette.background.default,
      0
    )} 0%, ${addAlpha(theme.palette.background.default, 0.9)} 30%, ${
      theme.palette.background.default
    } 100%)`,
    borderRadius: '0 0 16px 16px'
  },
  '& .card__actionArea': {
    width: '100%',
    padding: 0,
    display: 'block',
    flexDirection: 'row',
    fontWeight: 'normal'
  },
  '& .card__image': {
    height: 180,
    width: '100%',
    objectFit: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '16px 16px 0 0'
  },
  '& .card__name': {
    marginBottom: 8,
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0
  },
  '& .card__jobStats': {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    color: theme.palette.primary.main
  },
  // '& .navIcon': {
  //   display: 'inline-block',
  //   paddingLeft: 9,
  //   paddingRight: 5,
  //   verticalAlign: 'text-bottom'
  // },
  '& .card__jobDescription': {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0
  },
  '& .card__company': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .card__companyLogo': {
    height: 48,
    minWidth: 48,
    border: '0.5px solid rgba(0,0,0,0.1)',
    objectFit: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: '50%'
  },
  '& .card__companyName': {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0
  }
}));

function MyJobsCardItem(props) {
  const { applyDate, isApproved, isStarred, job, onRetract, onStar } = props;
  // const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { trackJobView } = useTrackJobView();
  const {
    approved,
    city,
    countryId,
    employerProfile,
    id,
    imageUrl,
    payRange,
    starred,
    stateId,
    title,
    userInterviewDate,
    zip
  } = job;
  const { name: employerName, imageUrl: employerImageUrl } = employerProfile || {};
  const jobStarred = isStarred || starred;
  const jobApproved = isApproved || approved;

  const { fetchCountryState, getCountryById, getStateById } = useCountryStateQuery();
  const selectedState = useMemo(() => getStateById(stateId), [getStateById, stateId]);
  const selectedCountry = useMemo(() => getCountryById(countryId), [getCountryById, countryId]);

  const jobAddress = formatJobAddress({
    city,
    zip,
    country: selectedCountry.code,
    state: selectedState.code
  });

  const formattedDate = useMemo(
    () => (applyDate ? formatDate(new Date(applyDate), 'MM.dd') : null),
    [applyDate]
  );

  useEffect(() => {
    fetchCountryState();
  }, []);

  const onJobClick = () => trackJobView(id);

  return (
    <StyledRoot className="card">
      <ShareJob jobId={id} jobTitle={title}>
        {(share) => (
          <IconButton color="primary" className="card__shareButton" onClick={share}>
            <MdIosShare fontSize="inherit" />
          </IconButton>
        )}
      </ShareJob>

      <Button
        isRouterLink
        to={getRoutes({ id }).employee.job}
        onClick={onJobClick}
        className="card__actionArea"
        testID={`job-${title}`}
      >
        <img className="card__image" src={imageUrl || defaultImg} alt="" />
        <Box p="22px 18px">
          <h2 className="card__name">{title}</h2>
          <Box mb="12px" className="card__jobStats">
            <Box textAlign="left">
              <span>{`Applied ${formattedDate} `}</span>
              <span>{` - ${jobAddress}`}</span>
            </Box>
          </Box>
          <Box mb="22px" textAlign="left" className="card__jobStats">
            {payRange || ''}
          </Box>
          <div className="card__company">
            <img
              className="card__companyLogo"
              src={employerImageUrl || defaultEmployerImg}
              alt=""
            />
            <Box ml="16px" className="card__companyName">
              {employerName}
            </Box>
          </div>
        </Box>
      </Button>

      <div className="card__actions">
        <div>
          <IconButton
            variant="outlined"
            color="primary"
            aria-label="retract"
            withTooltip
            toolTipProps={{ title: 'Retract application' }}
            sx={{ padding: '7px' }}
            onClick={() => onRetract(job)}
            testID="job-retract-button"
          >
            <MdClose />
          </IconButton>
          <IconButton
            variant="outlined"
            color="primary"
            aria-label={jobStarred ? 'unstar' : 'star'}
            withTooltip
            toolTipProps={{ title: starred ? 'Unstar' : 'Star' }}
            sx={{ padding: '7px', ml: '16px' }}
            onClick={() => onStar(job, !jobStarred)}
            testID="job-star-button"
          >
            <MdStarBorder />
          </IconButton>
        </div>
      </div>
    </StyledRoot>
  );
}

MyJobsCardItem.propTypes = {
  applyDate: PT.string.isRequired,
  isApproved: PT.bool.isRequired,
  isStarred: PT.bool.isRequired,
  job: PT.shape({
    approved: PT.bool,
    city: PT.string,
    countryId: PT.number,
    employerProfile: PT.shape({
      name: PT.string
    }),
    id: PT.number,
    imageUrl: PT.string,
    payRange: PT.string,
    stateId: PT.number,
    starred: PT.bool,
    title: PT.string,
    userInterviewDate: PT.string,
    zip: PT.string
  }).isRequired,
  onRetract: PT.func.isRequired,
  onStar: PT.func.isRequired
};

MyJobsCardItem.defaultProps = {};

export default MyJobsCardItem;
