import { addAlpha } from 'styles/utils';

const colors = {
  dark: {
    primary: {
      light: '#9972ec',
      light_2: 'rgb(85 67 144)',
      lightTransparent: 'rgba(153,114,236,0.44)',
      main: '#F5F4F9',
      mainTransparent: 'rgba(255, 255, 255, .15)',
      dark: '#FF743E',
      contrastText: '#201E48',
      contrastTextTransparent: 'rgba(32,30,72,0.25)',
      // text: '#c0b8d3',
      text: '#FAFAFA',
      textLight: '#9189ac',
      textHighlight: '#ed8a60',
      white: '#fff'
    },
    error: {
      main: '#ef5a68'
    },
    success: {
      main: '#6bd366'
    },
    neutral: {
      main: '#1597ff'
    },
    text: {
      primary: addAlpha('#FFFFFF', 0.87),
      secondary: addAlpha('#FFFFFF', 0.6),
      disabled: addAlpha('#FFFFFF', 0.38)
    },
    action: {
      active: addAlpha('#FFFFFF', 0.54),
      disabled: addAlpha('#FFFFFF', 0.26),
      selected: addAlpha('#FFFFFF', 0.08),
      hover: addAlpha('#FFFFFF', 0.04)
    },
    background: {
      default: '#201E48', //#1e1d30 looks nice
      paper: '#201E48', //#1e1d30 looks nice
      light: '#353178', //#1e1d30 looks nice
      dark: '#0d0c23',
      darkTransparent: addAlpha('#0d0c23', 0.5),
      error: '#481e2e'
    },
    moreColors: {
      brand: '#391A84',
      disabled: 'rgba(100, 99, 116, 0.5)',
      black: '#dfdde5',
      black_1: 'rgba(255,240,255,0.4)',
      black_2: '#E8E1F9',
      yellow: '#FDB717',
      gold: '#FFC35A',
      pink: '#F94EFD',
      darkPurple: '#2C2536',
      darkPurple_1: '#201E48',
      lightPurple: '#9D7FE6',
      purpleLanding: '#FF743E',
      purpleLight: '#554390',
      purpleLight_1: '#8783CD',
      purpleLight_2: '#8283BF',
      mainPrimaryLucid: 'rgba(71,67,162,0.5)',
      white: '#302d62',
      white_1: '#E8E1F9',
      grey_1: '#605668',
      grey_2: '#33303c', // @TODO: grey 2 - #EAEAEA (according to the new design)
      grey_3: '#CAC7D1',
      grey_4: '#9590A2',
      grey_5: '#625D6F',
      grey_6: '#bcb2d5', // @TODO: grey 6 - #312E38 (according to the new design)
      grey_7: '#121114',
      grey_8: '#423c4f',
      grey_9: '#9486a2'
    }
  },
  light: {
    primary: {
      light: '#796ed4',
      light_2: 'rgb(85 67 144)',
      lightTransparent: 'rgba(153,114,236,0.44)',
      main: '#4743A2',
      mainTransparent: addAlpha('#4743A2', 0.75),
      dark: '#051c72',
      contrastText: '#fff',
      contrastTextTransparent: addAlpha('#ffffff', 0.5),
      text: '#2C2C2C',
      textLight: '#616060',
      textHighlight: '#a19cce',
      white: '#fff'
    },
    error: {
      main: '#BE1E2D'
    },
    success: {
      main: '#2BB673'
    },
    neutral: {
      main: '#1C75BC'
    },
    background: {
      default: '#fff',
      light: '#c7c7c8', //#1e1d30 looks nice
      dark: '#c1c1c1',
      darkTransparent: addAlpha('#c1c1c1', 0.25),
      error: '#f4abb4'
    },
    moreColors: {
      brand: '#391A84',
      disabled: '#ECECF5',
      black: '#2C2C2C',
      black_1: 'rgba(44,44,44,0.4)',
      black_2: '#333',
      yellow: '#FFB810',
      gold: '#FFC35A',
      pink: '#F94EFD',
      darkPurple: '#2C2536',
      darkPurple_1: '#201E48',
      lightPurple: '#9D7FE6',
      purpleLanding: '#48479A',
      purpleLight: 'rgba(71,67,162,0.1)',
      purpleLight_1: '#8783CD',
      purpleLight_2: '#8283BF',
      mainPrimaryLucid: 'rgba(71,67,162,0.5)',
      white: '#f4f4f4',
      white_1: '#E8E1F9',
      grey_1: '#A4A4A5',
      grey_2: '#ECECF5',
      grey_3: '#A7A9AC',
      grey_4: '#E4E4E4',
      grey_5: '#808285',
      grey_6: '#58595b',
      grey_7: '#AFAFAF',
      grey_8: '#E2E2E2',
      grey_9: '#9486a2'
    }
  }
};

export default (themeMode = 'dark') => ({
  breakpoints: {
    keys: ['desktopApp', 'mobile', 'tablet', 'laptop', 'desktop', 'largeDesktop'],
    values: {
      desktopApp: 1024, // ≥
      /* -------------------- */
      mobile: 0,
      tablet: 481,
      laptop: 768,
      desktop: 1024,
      largeDesktop: 1200
    }
  },
  palette: colors[themeMode],
  typography: {
    // overrides font-family for all Material-ui components
    fontFamily: ['Nunito Sans', 'sans-serif'].join(', ')
  },
  mixins: {
    helperText: {
      margin: '8px 0',
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: '17px'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // [...fontFaces.join(' ')],
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
          fontFamily: "'Nunito Sans', sans-serif",
          color: colors[themeMode].primary.text,
          overflowX: 'hidden',
          backgroundColor: colors[themeMode].background.default
        },
        'p, h1, h2, h3, h4, h5, h6': {
          margin: 0
        },
        a: {
          textDecoration: 'none',
          color: colors[themeMode].primary.textHighlight
        },
        button: {
          fontFamily: 'inherit'
        },
        'input, textarea': {
          caretColor: colors[themeMode].primary.text
        },
        '::-webkit-scrollbar': {
          width: 5,
          height: 5
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: '#4f4e4e',
          borderRadius: 10
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#464545',
          borderRadius: 10
        },
        'input:-webkit-autofill, input:-webkit-autofill:focus, input:-webkit-autofill:invalid': {
          transition: 'background-color 600000s 0s, color 600000s 0s',
          boxShadow: `0 0 0 30px ${colors[themeMode].moreColors.purpleLight_1} inset`,
          'WebkitTextFillColor': colors[themeMode].primary.contrastText
        },
        'input[data-autocompleted]': {
          backgroundColor: 'transparent !important'
        }
      }
    },
    MuiDatePicker: {
      defaultProps: {
        PaperProps: {
          sx: {
            borderRadius: '10px'
          }
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(0, 0, 0, 0.1)'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: '1px solid rgba(255,255,255,0.2)',
          borderRadius: 15,
          backgroundColor: colors[themeMode].moreColors.purpleLight
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: addAlpha(colors[themeMode].background.default, 0.5)
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${colors[themeMode].moreColors.grey_9}`,
          borderRadius: 15,
          boxShadow: `0 8px 16px 0 ${addAlpha(colors[themeMode].background.dark, 0.45)}`,
          backgroundColor: colors[themeMode].moreColors.purpleLight_1,
          color: colors[themeMode].primary.text
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'inherit'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 59,
          height: 32,
          padding: 0,
          border: '1px solid rgba(255,255,255,0.2)',
          borderRadius: 16
        },
        switchBase: {
          color: colors[themeMode].background.default,
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(90%)',
            color: colors[themeMode].background.default,
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: colors[themeMode].primary.main
            },
            '& .MuiSwitch-thumb': {
              backgroundColor: colors[themeMode].background.default
            }
          }
        },
        thumb: {
          width: 26,
          height: 26,
          backgroundColor: '#D8D8D8',
          boxShadow: 'none'
        },
        track: {
          opacity: 1,
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors[themeMode].primary.text,
          '&.Mui-disabled': {
            color: colors[themeMode].moreColors.disabled
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: colors[themeMode].moreColors.disabled
          },
          input: {
            '&.Mui-disabled': {
              color: colors[themeMode].moreColors.disabled
            }
          }
        }
      }
    }
  }
});
