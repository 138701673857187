import React, { useEffect, useState, useContext, useMemo, memo } from 'react';
import { client } from 'api';
import clsx from 'clsx';
import { map, reduce, get, range } from 'lodash';
import { qaAttr } from 'utils';
import { usePrevious } from 'hooks';
import { MuiButtonBase } from 'components';
import { IconButton, Spinner } from 'components/shared';
import { MdChevronLeft, MdChevronRight, Filter } from 'components/icons';
import Pills from './Pills';
import growApi from '../../api';
import EmployerGrowContext from '../../EmployerGrowContext';
import StoresFilter from './StoresFilter';

const formatAdminProfiles = (arr = []) =>
  map(arr, (obj) => ({
    ...obj,
    profilesCount: Number(obj.profilesCount) || 0,
    analytic: obj.analytic
      ? reduce(
          JSON.parse(obj.analytic) || {},
          (res, val, key) => {
            if (key === 'approvedCount' || key === 'needActionCount' || key === 'rejectedCount') {
              res[key] = Number(val);
            } else {
              res[key] = val;
            }
            return res;
          },
          {}
        )
      : null
  }));

const PAGE_SIZE = 12;
const GAP = '...';
const START_PAGE = 1;

const calcPagination = (pagesCount = 0, page = START_PAGE, gap = GAP) => {
  const showFull = pagesCount <= 6;
  const isLong = pagesCount >= 9;

  if (pagesCount) {
    if (showFull) return range(START_PAGE, pagesCount + 1); // E.g. [1, 2, 3, 4, 5]

    if (!isLong) {
      const middle = Math.ceil(pagesCount / 2);
      return page <= middle
        ? [...range(START_PAGE, middle + 2), gap, pagesCount] // E.g [1, 2, 3, 4, 5, "...", 7]
        : [START_PAGE, gap, ...range(middle, pagesCount + 1)]; // E.g [1, "...", 4, 5, 6, 7]
    }
    const startEdge = 5;
    const endEdge = pagesCount - (startEdge - 1);

    if (page < startEdge) return [...range(START_PAGE, startEdge + 1), gap, pagesCount]; // E.g. [1, 2, 3, 4, 5, "...", 11]
    if (page > endEdge) return [START_PAGE, gap, ...range(endEdge, pagesCount + 1)]; // E.g. [1, "...", 7, 8, 9, 10, 11]
    return [START_PAGE, GAP, ...range(page - 1, page + 2), gap, pagesCount]; // E.g. [1, "...", 5, 6, 7, "...", 11]
  }
  return [];
};

const DEFAULT_FILTERS = {
  keywords: '',
  rejectedJobs: undefined,
  acceptedJobs: undefined,
  needActionJobs: undefined
};

function Stores() {
  const { jobPostingScores, updateEmployerCtx, jobStatistics } = useContext(EmployerGrowContext);
  const [employerJobPostingScores] = useState(jobPostingScores);
  const [page, setPage] = useState(START_PAGE);
  const [pages, setPages] = useState(0);
  const [pagePills, setPagePills] = useState([]);
  const [showTool, setShowTool] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [activePill, setActivePill] = useState(null);
  const [search, setSearch] = useState('');
  const [areProfilesLoading, setAreProfilesLoading] = useState(false);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const prevActivePill = usePrevious(activePill);
  const paginationScheme = useMemo(() => calcPagination(pages, page), [pages, page]);

  useEffect(() => {
    getAdminProfiles();
  }, []);

  useEffect(() => {
    if (prevActivePill !== activePill && activePill === null) {
      updateEmployerCtx({
        jobPostingScores: employerJobPostingScores
      });
    }
  }, [!activePill]);

  const getAdminProfiles = async (pageArg = START_PAGE, filterArgs, limit = PAGE_SIZE) => {
    const { keywords, rejectedJobs, acceptedJobs, needActionJobs } = filterArgs || filters;
    const userId = localStorage.getItem('userId');

    if (userId) {
      setAreProfilesLoading(true);
      try {
        const resp = await client.query({
          query: growApi.query.EMPLOYER_ADMIN_PROFILES,
          variables: {
            userId: Number(userId),
            page: pageArg,
            limit,
            keywords,
            rejectedJobs,
            acceptedJobs,
            needActionJobs
          },
          fetchPolicy: 'no-cache',
          errorPolicy: 'all'
        });

        if (resp?.data?.getEmployerAdminProfiles) {
          const profiles = formatAdminProfiles(resp.data.getEmployerAdminProfiles);
          const count = get(profiles, '[0].profilesCount', 0);
          setTotalCount(count);
          setPages(Math.ceil(count / PAGE_SIZE));
          setPagePills(profiles);

          if (showTool === null) {
            if (profiles.length) {
              setShowTool(true);
              updateEmployerCtx({ showJobsAnalytics: true, isAdmin: true });
            } else {
              updateEmployerCtx({ isAdmin: false });
            }
          }
        } else {
          updateEmployerCtx({ isAdmin: false });
        }
      } catch (error) {
        console.error('getAdminProfiles error', error);
      } finally {
        setAreProfilesLoading(false);
      }
    }
  };

  const handleFilter = (nextFilters) => {
    setPage(START_PAGE);
    setActivePill(null);
    setFilters(nextFilters);
    getAdminProfiles(START_PAGE, nextFilters);
  };

  const doSearch = () => {
    setPage(START_PAGE);
    setActivePill(null);
    setFilters((prev) => ({ ...DEFAULT_FILTERS, keywords: search }));
    getAdminProfiles(START_PAGE, { keywords: search });
  };

  const handleSearchClear = () => {
    setSearch('');
    setPage(START_PAGE);
    setActivePill(null);
    setFilters(DEFAULT_FILTERS);
    getAdminProfiles(START_PAGE, { keywords: '' });
  };

  const toggleFilter = (open) => () => setIsFilterOpen(open);

  const navToPage = (n) => {
    setPage(n);
    setActivePill(null);
    getAdminProfiles(n);
  };

  const nextPage = () => {
    navToPage(page + 1);
  };

  const previousPage = () => {
    navToPage(page - 1);
  };

  const handleOnChange = (e) => {
    setSearch(e.target.value);
  };

  if (!showTool && areProfilesLoading) {
    return (
      <div style={{ padding: '12px 0', display: 'flex', justifyContent: 'center' }}>
        <Spinner width={48} height={48} />
      </div>
    );
  }

  return (
    showTool && (
      <div className="storesTopSection">
        <div className="storesTopBar">
          <div className="storesTitle">
            <h1>Regional Management Tool</h1>
            <IconButton onClick={toggleFilter(true)} testID="stores-filter-modal-button">
              <Filter fill="#B3B3B3" />
            </IconButton>
            <StoresFilter
              filters={filters}
              isOpen={isFilterOpen}
              onSubmit={handleFilter}
              onClose={toggleFilter(false)}
            />
            <div id="stores-filter-modal-root" />
          </div>
          <div className="storesRightBar">
            {!!search.length && (
              <button
                type="button"
                onClick={handleSearchClear}
                {...qaAttr('stores-search-clear-button')}
              >
                Clear Search
              </button>
            )}
            <div className="storesSearch">
              <label htmlFor="stores-search">Search</label>
              <input
                id="stores-search"
                onChange={handleOnChange}
                type="text"
                value={search}
                {...qaAttr('stores-search-input')}
              />
              <button type="button" onClick={doSearch} {...qaAttr('stores-search-button')}>
                <MdChevronRight color="inherit" />
              </button>
            </div>
          </div>
        </div>
        <Pills
          pillData={pagePills}
          activePill={activePill}
          setActivePill={setActivePill}
          isLoading={areProfilesLoading}
        />
        {pages > 1 && (
          <div className="storesPagination">
            <MuiButtonBase
              disabled={page === START_PAGE}
              className={clsx('forwardBackButton', page === START_PAGE && 'disabled')}
              sx={{ display: 'block' }}
              onClick={previousPage}
              {...qaAttr('stores-pagination-prev')}
            >
              <MdChevronLeft color="inherit" />
            </MuiButtonBase>
            <div className="paginationButtonContainer">
              <div
                className="activePageOverlay"
                style={{ transform: `translateX(${paginationScheme.indexOf(page) * 40}px)` }}
              />
              {pages > START_PAGE &&
                map(paginationScheme, (n, i) => (
                  <MuiButtonBase
                    key={`pagination-${i}`}
                    className={clsx('paginationButton', page === n && 'activePage')}
                    sx={{ display: 'block' }}
                    style={{ cursor: n === GAP ? 'initial' : 'pointer', width: 40, height: 40 }}
                    onClick={() => (n === GAP ? undefined : navToPage(n))}
                    {...qaAttr(`stores-pagination-page-${n}`)}
                  >
                    {n}
                  </MuiButtonBase>
                ))}
            </div>
            <MuiButtonBase
              disabled={page === pages}
              className={clsx('forwardBackButton', page === pages && 'disabled')}
              sx={{ display: 'block' }}
              onClick={nextPage}
              {...qaAttr('stores-pagination-next')}
            >
              <MdChevronRight color="inherit" />
            </MuiButtonBase>
          </div>
        )}
      </div>
    )
  );
}

export default memo(Stores);
