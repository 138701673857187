import values from 'lodash/values';

export const getRoutes = (params = { id: null }) => {
  const idParam = params?.id || ':id';
  const companyIdParam = params?.companyId || ':companyId';
  return {
    employer: {
      dashboard: '/employer-dashboard',
      profile: '/employer-dashboard/profile',
      plans: '/employer-dashboard/plans',
      // !place all /employer-dashboard/... paths before job
      job: `/employer-dashboard/${idParam}`,
      job_new: '/employer-dashboard/new-job',
      job_edit: `/employer-dashboard/${idParam}/edit`,
      job_match: `/employer-dashboard/${idParam}/match`,
      job_approved: `/employer-dashboard/${idParam}/approved`,
      job_starred: `/employer-dashboard/${idParam}/starred`,
      job_rejected: `/employer-dashboard/${idParam}/rejected`,
      job_next_steps: `/employer-dashboard/${idParam}/next-steps`,
      enterprise: '/enterprise-dashboard',
      enterprise_search: '/enterprise-dashboard/search',
      enterprise_schedule: '/enterprise-dashboard/schedule',
      enterprise_grow: '/enterprise-dashboard/grow'
    },
    employee: {
      dashboard: '/employee-dashboard',
      profile: '/employee-dashboard/profile',
      grow: '/employee-dashboard/career-development',
      companyJobs: `/employee-dashboard/employer-jobs/${companyIdParam}`,
      // !place all /employee-dashboard/... paths before job
      job: `/employee-dashboard/${idParam}`,
      progress_skills: `/employee-dashboard/career-progress/skills`,
      progress_connections: `/employee-dashboard/career-progress/connections`,
      progress_hired: `/employee-dashboard/career-progress/still-hired`
    },
    login: {
      default: '/login',
      signUp: '/sign-up',
      verify: '/verify',
      newPwd: '/new-password'
    },
    onboarding: {
      default: '/onboarding'
    },
    blog: {
      default: '/blog',
      post: '/blog/:id'
    }
  };
};

export const dashboardRoutes = {
  employer: [...values(getRoutes().employer)],
  employee: [...values(getRoutes().employee)]
};

export const employeeStartPage = getRoutes().employee.grow;
export const employerStartPage = getRoutes().employer.enterprise;

export function getEmployerJobPaths() {
  return values(getRoutes().employer).filter((s) => s.includes('/:id'));
}

export function isEmployerJobPage(path) {
  const employerJobPaths = getEmployerJobPaths();
  return employerJobPaths.some((s) => s === path);
}

export function isEmployerNewJobPage(path) {
  return path === getRoutes().employer.job_new;
}
