import React from 'react';
import { styled } from 'components';
import { LandingHeader } from 'components/shared';
import LineBackground from 'assets/svg/lineBackground.svg';
import AppLayout from './AppLayout';

const StyledRoot = styled(AppLayout)(({ theme }) => ({
  '@keyframes subtleMove': {
    '0%, 100%': {
      transform: 'translate(0px, 0px)'
    },
    '25%': {
      transform: 'translate(-4px, 0px)'
    },
    '50%': {
      transform: 'translate(-4px, -8px)'
    },
    '75%': {
      transform: 'translate(-10px, -8px)'
    }
  },
  '& #siteLineBackground': {
    position: 'fixed',
    height: '270%',
    width: '280%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    opacity: 0.15,
    zIndex: -1,
    '& svg': {
      animation: 'subtleMove infinite 10s ease-in-out'
    }
  }
}));

// eslint-disable-next-line react/prop-types
export default function LoginLayout({ children, className }) {
  return (
    <StyledRoot className={className} headerElement={<LandingHeader />}>
      <div id="siteLineBackground">
        <LineBackground />
      </div>
      {children}
    </StyledRoot>
  );
}
