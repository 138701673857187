import React, { useState } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { useSelect } from '@mui/base';
import { Dialog, styled } from 'components';
import { MdCheck, MdClose } from 'components/icons';
import { addAlpha } from 'styles/utils';
import Button, { IconButton } from '../../Button';

const name = 'SelectModal';
const classes = {
  paper: `${name}-paper`,
  backdrop: `${name}-backdrop`,
  header: `${name}-header`,
  closeBtn: `${name}-closeBtn`,
  submitBtn: `${name}-submitBtn`,
  footer: `${name}-footer`,
  list: `${name}-list`,
  listDelimiter: `${name}-listDelimiter`,
  option: `${name}-option`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxHeight: '70vh',
    overflow: 'initial',
    margin: 0,
    padding: '16px 16px 57px',
    border: 0,
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    boxShadow: 'none',
    backgroundColor: theme.palette.moreColors.darkPurple_1
  },
  [`& .${classes.backdrop}`]: {
    backgroundColor: addAlpha(theme.palette.moreColors.grey_7, 0.7)
  },
  [`& .${classes.header}`]: {
    padding: '16px 0',
    borderBottom: `1px solid ${theme.palette.moreColors.lightPurple}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '150%',
    color: theme.palette.common.white
  },
  [`& .${classes.closeBtn}`]: {
    width: 'auto',
    height: 'auto',
    padding: 0
  },
  [`& .${classes.submitBtn}`]: {
    width: '100%',
    maxWidth: 224
  },
  [`& .${classes.footer}`]: {
    paddingTop: 10,
    textAlign: 'center'
  },
  [`& .${classes.list}`]: {
    overflowY: 'auto'
  },
  [`& .${classes.listDelimiter}`]: {
    margin: 0,
    borderColor: theme.palette.moreColors.grey_3
  },
  [`& .${classes.option}`]: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '150%',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&.selected': {
      fontWeight: 800,
      color: theme.palette.moreColors.lightPurple
    }
  }
}));

function SelectModal(props) {
  const { options, isOpen, onClose, onConfirm, title, getKey } = props;
  const [newSelected, setNewSelected] = useState('');

  const {
    getListboxProps,
    getOptionProps,
    getOptionState,
    value: selectedValue
  } = useSelect({
    listboxRef: null,
    multiple: false,
    defaultValue: '',
    onChange: (event, newValue) => setNewSelected(newValue),
    // onOpenChange: setListboxVisible,
    open: false,
    options,
    value: newSelected
  });

  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      maxWidth="100vw"
      fullWidth
      scroll="body"
      BackdropProps={{
        className: classes.backdrop
      }}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.header}>
        {title}
        <IconButton color="primary" className={classes.closeBtn} onClick={onClose}>
          <MdClose />
        </IconButton>
      </div>
      <div className={classes.list} {...getListboxProps()}>
        {options.map((option, i) => {
          const { value, label, props: optionProps, delimiter } = option;
          const { disabled, highlighted, selected } = getOptionState(option);

          if (delimiter) {
            return <hr key={`delimiter__${i}`} className={classes.listDelimiter} />;
          }

          return (
            <div
              key={getKey ? getKey(i, option) : option.value}
              {...getOptionProps(option)}
              {...optionProps}
              className={clsx(classes.option, {
                disabled,
                highlighted,
                selected
              })}
            >
              {label}
              {selected && <MdCheck />}
            </div>
          );
        })}
      </div>
      <div className={classes.footer}>
        <Button
          variant="filled-primary"
          className={classes.submitBtn}
          disabled={!newSelected}
          onClick={() => onConfirm({ value: newSelected }, newSelected)}
        >
          Select
        </Button>
      </div>
    </StyledDialog>
  );
}

SelectModal.propTypes = {
  options: PT.arrayOf(
    PT.shape({
      value: PT.any,
      label: PT.any,
      delimiter: PT.bool,
      disabled: PT.bool,
      props: PT.objectOf(PT.any)
    })
  ).isRequired,
  isOpen: PT.bool.isRequired,
  onConfirm: PT.func.isRequired,
  onClose: PT.func.isRequired,
  getKey: PT.func,
  title: PT.string
  // value: PT.any
};

SelectModal.defaultProps = {
  title: 'Select option',
  getKey: undefined
};

export default SelectModal;
