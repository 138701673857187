import React, { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { Box, styled } from 'components';
import { Spinner } from 'components/shared';
import { getProfileId } from 'utils';
import growApi from '../../api';
import { makeQueryFetch } from '../../api/util';
import EmployerGrowContext from '../../EmployerGrowContext';

const { EMPLOYER_HIRING_RATE, EMPLOYER_HIRING_RATE_ADMIN } = growApi.query;

const StyledRoot = styled('div')(({ theme }) => ({
  '&.hiringRateChart': {}
}));

function HiringRate(props) {
  const { isAdmin } = useContext(EmployerGrowContext);
  const client = useApolloClient();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [rate, setRate] = useState(0);

  const fetchData = async () => {
    const profileId = Number(getProfileId());

    if (profileId) {
      const data = await makeQueryFetch(
        {
          query: isAdmin ? EMPLOYER_HIRING_RATE_ADMIN : EMPLOYER_HIRING_RATE,
          path: isAdmin ? 'getHiringRateAdmin' : 'getHiringRateEmployer',
          variables: { employerProfileProfileId: profileId }
        },
        client
      );

      if (data?.hireMetrics) setRate(data.hireMetrics);
      else if (data?.orgHireMetrics) setRate(data.orgHireMetrics);

      setIsDataLoading(false);
    } else {
      throw new Error('getHiringRateEmployer error: profileId is not defined');
    }
  };

  useEffect(() => {
    if (isAdmin !== null) fetchData();
  }, [isAdmin]);

  return (
    <StyledRoot className="hiringRateChart chartCard">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Hiring Rate</h4>
      <Box pt="20px">
        <p className="chartDescription">
          The percentage of candidates that apply to your jobs vs the amount that your company has
          hired.
        </p>
        <div className="chartValue_big">{`${rate}%`}</div>
      </Box>
    </StyledRoot>
  );
}

export default HiringRate;
