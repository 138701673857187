import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon16(props) {
  return (
    <SvgIcon viewBox="0 0 41.94 32.32" {...props}>
      <path
        d="M38.64,21.62C44.88,3.34,22.97-3.32,6.31,12.04,26-10.16,51.02,1.49,38.64,21.62Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M0,21.73C3.8,14.59,11.54,8.22,19.99,5.95c11.29-3.02,19.58,2.42,18.51,12.16-.54,4.92-3.36,10-7.48,14.21,1.28-2.4,2.11-4.89,2.38-7.33,1.06-9.74-7.22-15.18-18.51-12.16-5.58,1.5-10.85,4.78-14.88,8.9Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M5.55,21.91c3.76-3.93,21.2-9.19,23.52-.54,.53,1.96-.45,4.07-2.45,5.89,.42-1,.53-2.01,.27-2.98-2.18-8.13-18.74-3.45-21.34-2.37Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default GrowBadgeIcon16;
