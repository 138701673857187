import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { Box, styled } from 'components';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.pageHeader': {
    marginBottom: 15,
    paddingBottom: 15,
    borderBottom: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    display: 'flex',
    alignItems: 'center'
  },
  '& .pageHeader__title': {
    marginBottom: 6,
    fontSize: 25,
    fontWeight: 'normal',
    color: theme.palette.common.white
  },
  '& .pageHeader__subTitle': {
    fontSize: 10,
    color: theme.palette.moreColors.purpleLight_2
  },
  '& .pageHeader__icon': {
    marginRight: 30,
    '& svg': {
      fontSize: 50,
      color: theme.palette.moreColors.yellow
    }
  }
}));

export default function PageHeader(props) {
  const { className, icon, title, subtitle } = props;
  return (
    <StyledRoot className={clsx(className, 'pageHeader')}>
      {icon && <div className="pageHeader__icon">{icon}</div>}
      <div>
        <h2 className="pageHeader__title">{title}</h2>
        <div className="pageHeader__subTitle">{subtitle}</div>
      </div>
    </StyledRoot>
  );
}

PageHeader.propTypes = {
  className: PT.string,
  icon: PT.node,
  title: PT.string,
  subtitle: PT.string
};

PageHeader.defaultProps = {
  className: '',
  icon: null,
  title: '',
  subtitle: ''
};
