import React from 'react';
import { useMediaQuery } from 'components';

const hookOpt = { noSsr: true };

export default function useMediaQueryMatches() {
  const isDesktopApp = useMediaQuery((theme) => theme.breakpoints.up('desktopApp'), hookOpt);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'), hookOpt);
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('tablet'), hookOpt);
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.up('laptop'), hookOpt);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('desktop'), hookOpt);
  const isLargeDesktop = useMediaQuery((theme) => theme.breakpoints.up('largeDesktop'), hookOpt);

  return { isDesktopApp, isMobile, isTablet, isLaptop, isDesktop, isLargeDesktop };
}
