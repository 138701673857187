import React from 'react';
import PropTypes from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import { styled } from 'components';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.progress': {
    position: 'relative',
    width: '100%',
    height: 2
  },
  '& .progress__bar': {
    height: '100%',
    zIndex: 1,
    top: 0,
    left: 0,
    position: 'absolute',
    background: theme.palette.moreColors.yellow
  },
  '& .progress__line': {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    background: theme.palette.moreColors.disabled
  }
}));

function HorizontalProgress({ percent }) {
  return (
    <Spring
      from={{ x: 0 }}
      to={{ x: percent || 0 }}
      config={{ duration: 1250, tension: 300, friction: 0, easing: easePolyOut.exponent(2.0) }}
    >
      {({ x }) => (
        <StyledRoot className="progress">
          <div className="progress__line" />
          <animated.div className="progress__bar" style={{ width: x.to((v) => `${v}%`) }} />
        </StyledRoot>
      )}
    </Spring>
  );
}

HorizontalProgress.propTypes = {
  percent: PropTypes.number
};

HorizontalProgress.defaultProps = {
  percent: 0
};

export default HorizontalProgress;
