import React from 'react';
import PT from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Box, styled } from 'components';

const AnimatedProgress = animated(({ value, ...rest }) => (
  <CircularProgressbar
    styles={{
      root: { transform: 'rotate(-135deg)' },
      path: { stroke: '#8283BF', strokeLinecap: 'round' },
      trail: { stroke: '#181828', strokeLinecap: 'round' }
    }}
    value={value}
    circleRatio={0.75}
    strokeWidth={8}
    {...rest}
  />
));

export default function CircularProgress({ score, barProps }) {
  return (
    <Spring
      config={{
        duration: 1250,
        tension: 300,
        friction: 0,
        easing: easePolyOut.exponent(2.0)
      }}
      from={{ score: 0 }}
      to={{ score }}
    >
      {({ score: animScore }) => <AnimatedProgress value={animScore} {...barProps} />}
    </Spring>
  );
}

CircularProgress.propTypes = {
  score: PT.number,
  barProps: PT.objectOf(PT.any)
};

CircularProgress.defaultProps = {
  score: 0,
  barProps: {}
};
