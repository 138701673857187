import { gql } from '@apollo/client';

export const GET_CAREER_DEV_WITH_HEALTH_SCORE = gql`
  query careerDevelopmentByUserId {
    careerDevelopmentByUserId {
      careerHealthScore {
        rating {
          name
        }
        score
        sectionScores {
          attributes {
            complete
            label
            value
          }
          careerScoreTypeId
          careerScoreType {
            name
          }
          impact {
            name
          }
          percent
          score
        }
      }
      curJobTypeId
      currentlySearching
      futureJobTypeId
      id
      industryId
      showEmployerData
    }
  }
`;

export const GET_CAREER_PATH = gql`
  query getCareerPathByEmployee($employeeProfileId: Int) {
    getCareerPathByEmployee(employeeProfileId: $employeeProfileId) {
      careerPathGroupItems {
        id
        index
        jobTypeId
        completed
        active
        careerPathGroupId
        careerPathGroups {
          id
          startJobTypeId
          endJobTypeId
        }
      }
      abilitiesBadges
      skillsBadges
    }
  }
`;

export const GET_RANDOM_CAREER_PATH_ITEMS = gql`
  query getRandomCareerPath($id: Int) {
    getRandomCareerPath(id: $id) {
      id
      startJobTypeId
      endJobTypeId
    }
  }
`;

// search by one or both params
export const GET_CAREER_PATH_ITEMS_BY_JOB_TYPES = gql`
  query getCareerPathByJobTypeIds($startJobTypeId: Int, $endJobTypeId: Int) {
    getCareerPathByJobTypeIds(startJobTypeId: $startJobTypeId, endJobTypeId: $endJobTypeId) {
      id
      startJobTypeId
      endJobTypeId
    }
  }
`;

export const CAREER_DEV_BY_USER_ID = gql`
  query connectionCareerDevelopmentByUserId($connectionUserId: Int) {
    connectionCareerDevelopmentByUserId(connectionUserId: $connectionUserId) {
      curJobTypeId
      employeeProfileProfileId
      futureJobTypeId
      id
      industryId
      employeeProfile {
        education {
          name
          pos
          startDate
          endDate
          currentJob
          education_id
        }
        experience {
          name
          pos
          startDate
          endDate
          currentJob
          experience_id
        }
        imageUrl
        industry {
          industry_id
          name
        }
        motto
        name
        skills {
          name
        }
        profile_id
        user_id
      }
    }
  }
`;

export const GET_GROW_INDUSTRIES = gql`
  query industries {
    industries {
      industry_id
      name
    }
  }
`;

export const GET_EMPLOYEE_RECENT_BADGE = gql`
  query getMostRecentBadgeByEmployee($employeeUserId: Int!) {
    getMostRecentBadgeByEmployee(employeeUserId: $employeeUserId) {
      abilitiesBadges
      skillsBadges
    }
  }
`;

// export const GET_EMPLOYEE_SKILLS_BADGES = gql`
//   query getSkillsBadges($employeeUserId: Int!) {
//     getSkillsBadges(employeeUserId: $employeeUserId) {
//       abilitiesBadges {
//         id
//         name
//         badgeUrl
//         abilitiesId
//       }
//       skillsBadges {
//         id
//         name
//         badgeUrl
//         skillsId
//         skillsBadgesToEmployees {
//           id
//           employeeUserId
//           skillsBadgeId
//           complete
//         }
//       }
//     }
//   }
// `;

export const GET_CUSTOM_CONTENT_BY_EMPLOYER = gql`
  query getEmployerCustomContent($employerProfileId: Int!) {
    getEmployerCustomContent(employerProfileId: $employerProfileId) {
      featured {
        id
        employerProfileId
        imageUrl
        required
        featured
      }
      generic {
        id
        employerProfileId
        imageUrl
        required
        featured
      }
    }
  }
`;

export const GET_EMPLOYMENT_TRENDS = gql`
  query employmentTrends {
    employmentTrends {
      myCareerOutlook {
        title
        outlook
        rating
      }
      similarCareerJobOutlook {
        title
        code
        competition
        outlook
      }
      brightOutlook
    }
  }
`;

export const GET_EMPLOYMENT_SALARY = gql`
  query employmentSalary {
    employmentSalary {
      myState {
        state
        payLabel
        payAmount
      }
      topStates {
        state
        percent
        payLabel
        payAmount
      }
      bottomStates {
        percent
        state
        payAmount
        payLabel
      }
      growingCareers {
        jobName
        avgPay
        highPay
        lowPay
        jobCount
      }
    }
  }
`;
