import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { qaAttr } from 'utils';

function HeaderTemplate(props) {
  const {
    children,
    className,
    centerElements,
    leftElements,
    rightElements,
    rightPart,
    leftPart,
    title,
    titleQaId
  } = props;

  return (
    <div className={className}>
      {leftPart && <div className="headerLeft">{leftElements}</div>}
      <div className="headerCenter">
        {title && (
          <h1 {...qaAttr(titleQaId)} className="headerElement title">
            {title}
          </h1>
        )}
        {centerElements}
        {children}
      </div>
      {rightPart && <div className="headerRight">{rightElements}</div>}
    </div>
  );
}

HeaderTemplate.propTypes = {
  children: PT.node,
  className: PT.string,
  centerElements: PT.arrayOf(PT.node),
  leftElements: PT.arrayOf(PT.node),
  rightElements: PT.arrayOf(PT.node),
  leftPart: PT.bool,
  rightPart: PT.bool,
  title: PT.oneOfType([PT.string, PT.node]),
  titleQaId: PT.string
};

HeaderTemplate.defaultProps = {
  children: null,
  className: '',
  centerElements: [],
  leftElements: [],
  rightElements: [],
  leftPart: true,
  rightPart: true,
  title: '',
  titleQaId: ''
};

const Styled = styled(HeaderTemplate)(({ theme }) => ({
  width: '100%',
  padding: '12px 30px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('desktopApp')]: {
    padding: '9px 16px',
    border: 'none'
  },
  '& .headerCenter': {
    flex: 1,
    paddingLeft: 27,
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      justifyContent: 'center'
    }
  },
  '& .headerRight': {
    display: 'flex',
    alignItems: 'center',
    '& .headerElement': {
      '&:not(:last-child)': {
        marginRight: 20,
        [theme.breakpoints.down('desktopApp')]: {
          marginRight: 10
        }
      }
    }
  },
  '& .title': {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: 0.67,
    lineHeight: '26px',
    textAlign: 'left',
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
      textAlign: 'center',
      fontSize: 16,
      lineHeight: '18px'
      // color: '#000'
    }
  }
}));

export default Styled;
