import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, MuiRadio, styled } from 'components';
import { qaAttr } from 'utils';
import { MdCheck } from '../../icons';

const PREFIX = 'StyledRadio';
const classes = {
  radio: `${PREFIX}-radio`,
  formControl: `${PREFIX}-formControl`,
  label: `${PREFIX}-label`
};

function Radio(props) {
  const { className, inputProps, radioProps, testID, ...rest } = props;

  return (
    <FormControlLabel
      control={
        <MuiRadio
          color="primary"
          icon={<svg style={{ display: 'none' }} />}
          checkedIcon={<MdCheck />}
          classes={{
            root: classes.radio
          }}
          inputProps={{
            ...(testID && qaAttr(`radio-input-${testID}`)),
            ...inputProps
          }}
          {...radioProps}
        />
      }
      className={className}
      classes={{
        root: classes.formControl,
        label: classes.label
      }}
      {...(testID && qaAttr(`radio-${testID}`))}
      {...rest}
    />
  );
}

Radio.propTypes = {
  className: PropTypes.string,
  inputProps: PropTypes.objectOf(PropTypes.any),
  radioProps: PropTypes.objectOf(PropTypes.any),
  testID: PropTypes.string
};

Radio.defaultProps = {
  className: '',
  inputProps: {},
  radioProps: {},
  testID: ''
};

const StyledRadio = styled(Radio)(({ theme, variant }) => ({
  minHeight: 50,
  width: '100%',
  margin: 0,
  padding: '0 16px',
  borderRadius: 10,
  border: '1px solid rgba(255,255,255,0.2)',
  [`& .${classes.label}`]: {
    marginLeft: 15,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '17px'
  },
  [`& .${classes.radio}`]: {
    minWidth: 24,
    padding: 0
  }
}));

export default StyledRadio;
