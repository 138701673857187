import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { getUserId } from 'utils';
import { SET_TIMEZONE, GET_EMPLOYER_PROFILE, GET_USER_PROFILE } from 'api';
import useEmployeeProfileQuery from './useEmployeeProfileQuery';
import useEmployerProfileQuery from './useEmployerProfileQuery';

function useTimezoneCommands(props) {
  const { ...useMutationOptions } = props || {};
  const [setTimezone] = useMutation(SET_TIMEZONE, {
    fetchPolicy: 'no-cache',
    ...useMutationOptions
  });

  const { refetch: refetchEmployeeProfile } = useEmployeeProfileQuery({ autoFetch: false });
  const { refetch: refetchEmployerProfile } = useEmployerProfileQuery({ autoFetch: false });

  const saveTimezone = useCallback(
    async (timeZone, role, userId) => {
      if (timeZone && role) {
        // let refetchQueries = [];
        // if (role === 'employer') refetchQueries = [GET_EMPLOYER_PROFILE];
        // if (role === 'employee') refetchQueries = [GET_USER_PROFILE];

        await setTimezone({
          variables: { timeZone, user_id: userId || Number(getUserId()) }
          // @TODO: refetchQueries doesn't work for current apollo version. Use it instead of profile hooks
          // refetchQueries
        });
        if (role === 'employer') refetchEmployerProfile();
        else if (role === 'employee') refetchEmployeeProfile();
      } else {
        throw new Error('saveTimezone: missed params');
      }
    },
    [refetchEmployeeProfile, refetchEmployerProfile, setTimezone]
  );

  return useMemo(() => ({ saveTimezone }), [saveTimezone]);
}

export default useTimezoneCommands;
