import React from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { styled } from 'components';
import { Spinner } from 'components/shared';
import { IndustryAndSkillsFilter } from 'components/Dashboard/employee';
import { withEmployeeJobActions } from 'hocs';
import { allJobsStyles } from 'styles/Dashboard/EmployeeDashboard';
import { GET_EMPLOYEE_JOBS } from 'api';
import JobCardsRenderer from 'containers/employee/JobCardsRenderer';

const PAGE_LIMIT = 10;
const OFFSET = 0;
const enhance = (Component) => withEmployeeJobActions(Component);
const StyledRoot = styled('div')(allJobsStyles);

function AllJobs(props) {
  const [fetchAllJobs, { data: jobsData, loading: jobsLoading = true, fetchMore }] = useLazyQuery(
    GET_EMPLOYEE_JOBS,
    {
      variables: {
        offset: OFFSET,
        limit: PAGE_LIMIT
      },
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  );

  const jobs = jobsData?.employeeJobs || [];

  return (
    <JobCardsRenderer
      {...props}
      jobs={jobs}
      fetchAllJobs={fetchAllJobs}
      jobsLoading={jobsLoading}
      fetchMore={fetchMore}
      initOffset={OFFSET}
      pageLimit={PAGE_LIMIT}
      render={(props) => (
        <StyledRoot className="container">
          {props.renderSearch()}
          <IndustryAndSkillsFilter
            filterSettings={props.filterSettings}
            isFilterModalOpen={props.isFilterModalOpen}
            setFilterSettings={props.setFilterSettings}
            setIsFilterModalOpen={props.setIsFilterModalOpen}
          />
          <div className="contentWrapper">
            {props.renderJobs()}
            {jobsLoading && (
              <div className="contentLoader">
                <Spinner size={30} />
              </div>
            )}
          </div>
        </StyledRoot>
      )}
    />
  );
}

AllJobs.propTypes = {
  applyLoading: PT.bool,
  onApply: PT.func.isRequired,
  onReject: PT.func.isRequired,
  onRetract: PT.func.isRequired,
  onSaveInterview: PT.func.isRequired,
  onStar: PT.func.isRequired,
  interviewSaveLoading: PT.bool,
  rejectLoading: PT.bool,
  retractLoading: PT.bool,
  starLoading: PT.bool
};

AllJobs.defaultProps = {
  applyLoading: false,
  interviewSaveLoading: false,
  rejectLoading: false,
  retractLoading: false,
  starLoading: false
};

export default enhance(AllJobs);
