import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { qaAttr } from 'utils';
import { Box, Dialog, styled } from 'components';
import { Button } from 'components/shared';

function ConfirmationDialog(props) {
  const {
    cancelBtnName,
    confirmBtnName,
    className,
    DialogProps,
    headerProps,
    isOpen,
    message,
    onClose,
    onConfirm,
    onCancel,
    titleProps,
    title
  } = props;
  return (
    <Dialog open={isOpen} fullWidth className={className} onClose={onClose} {...DialogProps}>
      <div className={clsx('content', message && 'withMessage')}>
        <Box
          mb="20px"
          display="flex"
          justifyContent="space-between "
          alignItems="center"
          {...headerProps}
        >
          <Button
            variant="text"
            sx={{ flex: 0.5, justifyContent: 'flex-start' }}
            onClick={onCancel}
            testID="confirm-modal-cancel-button"
          >
            {cancelBtnName}
          </Button>
          <h1 className="title" {...titleProps} {...qaAttr('confirm-modal-title')}>
            {title}
          </h1>
          <Button
            variant="text"
            sx={{ flex: 0.5, justifyContent: 'flex-end' }}
            onClick={onConfirm}
            testID="confirm-modal-accept-button"
          >
            {confirmBtnName}
          </Button>
        </Box>
        {message}
      </div>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  className: PropTypes.string.isRequired,
  cancelBtnName: PropTypes.string,
  confirmBtnName: PropTypes.string,
  DialogProps: PropTypes.objectOf(PropTypes.any),
  headerProps: PropTypes.objectOf(PropTypes.any),
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleProps: PropTypes.objectOf(PropTypes.any)
};

ConfirmationDialog.defaultProps = {
  cancelBtnName: 'Cancel',
  confirmBtnName: 'Send',
  DialogProps: {},
  headerProps: {},
  message: null,
  title: '',
  titleProps: {}
};

const Styled = styled(ConfirmationDialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: 416,
    [theme.breakpoints.down('desktopApp')]: {
      width: 'calc(100% - 20px)',
      maxHeight: 'calc(100% - 20px)',
      margin: 10,
      borderRadius: 5
    }
  },
  '& .content': {
    padding: '25px 27px 18px',
    '&.withMessage': {
      paddingBottom: 36
    },
    [theme.breakpoints.down('desktopApp')]: {
      padding: '16px'
    }
  },
  '& .title': {
    flex: 2,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '18px',
    textAlign: 'center',
    color: theme.palette.primary.light
  }
}));

export default memo(Styled);
