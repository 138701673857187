import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { styled } from 'components';
import { Button } from 'components/shared';
import { MdLocalFireDepartment, MdStar, DollarIcon } from 'components/icons';
import { getRoutes } from 'utils';
import jobDefaultImg from 'assets/img/job_default.png';

const StyledRoot = styled(Button)(({ theme }) => ({
  '&.jobFitCard': {
    padding: '11px 10px',
    border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'left'
  },
  '& .jobFitCard__image': {
    width: 42,
    minWidth: 42,
    height: 42,
    marginRight: 9,
    objectFit: 'cover',
    borderRadius: '50%',
    backgroundColor: '#CECECE'
  },
  '& .jobFitCard__title': {
    // marginBottom: 10,
    fontSize: 9,
    color: theme.palette.common.white
  },
  '& .jobFitCard__popularStatus': {
    fontSize: 8,
    fontWeight: 'bold',
    color: '#BE1E2D'
  },
  '& .jobFitCard__fitStatus': {
    fontSize: 8,
    fontWeight: 'bold',
    color: theme.palette.moreColors.purpleLight_2
  },
  '& .jobFitCard__greatPayStatus': {
    fontSize: 8,
    fontWeight: 'bold',
    color: '#2AB673'
  }
}));

export default function JobFitCard(props) {
  const { className, job, ...rest } = props;
  const {
    id,
    title = '',
    imageUrl = '',
    greatFit: isFit = false,
    popularJob: isPopular = false,
    greatPay: isGreatPay = false,
    applicantsCount = 0
  } = job || {};
  // TODO: should it be button?

  const renderStatus = () => {
    if (isFit) {
      return (
        <div className="jobFitCard__fitStatus">
          <MdStar color="primary" sx={{ fontSize: 10, marginRight: '8px' }} />
          {`Great Fit${applicantsCount ? ' | applicants' : ''}`}
        </div>
      );
    }
    if (isPopular) {
      return (
        <div className="jobFitCard__popularStatus">
          <MdLocalFireDepartment color="inherit" sx={{ fontSize: 10, marginRight: '8px' }} />
          Popular Job
        </div>
      );
    }
    if (isGreatPay) {
      return (
        <div className="jobFitCard__greatPayStatus">
          <DollarIcon color="inherit" sx={{ fontSize: 10, marginRight: '8px' }} />
          Great Pay
        </div>
      );
    }
    return null;
  };

  return (
    <StyledRoot
      isRouterLink
      to={getRoutes({ id }).employee.job}
      className={clsx('jobFitCard', className)}
      {...rest}
    >
      <img className="jobFitCard__image" src={imageUrl || jobDefaultImg} alt="" />
      <div>
        <div className="jobFitCard__title">{title}</div>
        {renderStatus()}
      </div>
    </StyledRoot>
  );
}

JobFitCard.propTypes = {
  className: PT.string,
  job: PT.shape({
    applicantsCount: PT.number,
    title: PT.string.isRequired,
    imageUrl: PT.string,
    id: PT.number.isRequired,
    greatPay: PT.bool,
    popularJob: PT.bool,
    greatFit: PT.bool
  }).isRequired
};

JobFitCard.defaultProps = {
  className: ''
};
