import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { getUserId } from 'utils';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import { withConnections } from 'hocs';
import { GET_CAREER_DEV_WITH_HEALTH_SCORE } from 'api';

const StyledRoot = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  maxWidth: 474 + 16 * 2,
  margin: '0 auto',
  padding: '0 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // justifyContent: 'center'
  '& .title': {
    marginBottom: 18,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 400,
    lineHeight: '42px',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .connectionsContainer': {
    width: '100%',
    overflow: 'auto',
    flex: 1
  },
  '& .connectionItem': {
    padding: '10px 0',
    borderBottom: '1px solid #E4E4E4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  '& .connectionItem__img': {
    width: 45,
    height: 45,
    minWidth: 45,
    minHeight: 45,
    marginRight: 10,
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    objectFit: 'cover'
  },
  '& .connectionItem__name': {
    fontSize: 12,
    fontWeight: 800
  },
  '& .connectionItem__pos': {
    fontSize: 10,
    color: 'grey'
  },
  '& .action': {
    width: 58,
    height: 22,
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: 11,
    borderRadius: 10,
    '&:not(:first-of-type)': {
      marginLeft: 12
    }
  }
}));

const enhance = (WrappedComponent) => withConnections(WrappedComponent);

function EmployeeProgressConnections(props) {
  const {
    addConnection,
    connectionsLoading,
    futureConnections,
    getConnections,
    showConnectionProfile
  } = props;
  const suggestedConnections = futureConnections.filter((o) => !o.starred);
  const connectionsUserIds = suggestedConnections.map((o) => o.user_id).filter(Boolean);

  const [fetchCareerDev, { data: careerDevData }] = useLazyQuery(GET_CAREER_DEV_WITH_HEALTH_SCORE, {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all'
  });
  const {
    curJobTypeId,
    futureJobTypeId,
    industryId,
    id: careerDevId
  } = careerDevData?.careerDevelopmentByUserId || {};

  const getCareerDev = () => {
    const uid = getUserId();
    fetchCareerDev({ variables: { userId: Number(uid) } });
  };

  useEffect(() => {
    getCareerDev();
  }, []);

  useEffect(() => {
    if (careerDevId) {
      getConnections({
        careerDevId,
        currentJobTypeId: curJobTypeId,
        futureJobTypeId,
        careerDevIndustryId: industryId
      });
    }
  }, [careerDevId]);

  const renderConnection = (connection, i) => (
    <div key={`connection__${i}`} className="connectionItem">
      <Box display="flex" alignItems="center">
        <img src={connection.imageUrl} alt="" className="connectionItem__img" />
        <div>
          <div className="connectionItem__name">{connection.name}</div>
          {connection.companyName && (
            <div className="connectionItem__pos">
              {`${connection.companyName} | ${connection.companyPosition}`}
            </div>
          )}
        </div>
      </Box>
      <div>
        <Button
          variant="filled-primary"
          className="action"
          onClick={() => addConnection(connection, careerDevId)}
        >
          Add +
        </Button>
        <Button
          variant="filled-primary"
          className="action"
          onClick={() => showConnectionProfile(connectionsUserIds, i, { careerDevId })}
        >
          View
        </Button>
      </div>
    </div>
  );

  return (
    <StyledRoot>
      <h1 className="title">Suggested Connections</h1>
      {suggestedConnections.length ? (
        <div className="connectionsContainer">{suggestedConnections.map(renderConnection)}</div>
      ) : (
        <Box py="20px" textAlign="center">
          No Connections at the moment
        </Box>
      )}
    </StyledRoot>
  );
}

EmployeeProgressConnections.propTypes = {};

export default enhance(EmployeeProgressConnections);
