import React from 'react';
import { SvgIcon } from 'components';

function GrowLevel1(props) {
  return (
    <SvgIcon viewBox="0 0 470.6 464.1" {...props}>
      <g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M88.3,370.1c7.1,0.3,10.9,5.6,8.8,13.5l-3.4,12.7l-10,3.7
			l5.5-20.5C90.2,375.7,89.8,372.5,88.3,370.1z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M78.9,376.1c3.8,0.2,6.7,1.8,8.2,4.5l-3.9,14.6l-6.7,2.5l3.2-12
			C80.7,381.7,80.3,378.4,78.9,376.1z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M72.4,382c2.4,0.1,4.3,0.7,5.9,1.9l-2.7,10l-3.3,1.2l1-3.6
			C74.3,387.6,73.9,384.4,72.4,382z"
          />
        </g>
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="248.1,438.4 245.4,448.7 237.9,448.7 229,433.3 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="239.7,464.1 232.2,456.6 236,450.1 253.7,450.1 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="221.7,444 231.9,441.3 235.6,447.7 226.8,463.1 		"
          />
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M379.5,374.4c8.7-5,18.6-1.8,18.6,9v2l-3.5,2l0-4
			c0-8.1-7-9.5-13.3-5.9l-6.4,3.7v-2.9c0-0.6,0.5-1.4,1.1-1.8L379.5,374.4z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M393.2,383.4c0-6.1-5-8.3-11.2-4.7l-7.4,4.3
			c-0.6,0.4-1.1,1.1-1.1,1.8v2.9l6.3-3.7l0,6.4c0,6.1,5,8.3,11.2,4.7l7.4-4.3c0.6-0.4,1.1-1.1,1.1-1.8v-2.9l-6.3,3.7V383.4
			L393.2,383.4z M383.3,382c2.4-1.4,6.3-3.1,6.3,1.4v8.5c-2.4,1.4-6.3,3.1-6.3-1.4V382z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M393.5,399.4c-8.7,5-18.6,1.8-18.6-9v-2l3.5-2v4
			c0,8.1,7,9.5,13.3,5.9l6.4-3.7v2.9c0,0.6-0.5,1.4-1.1,1.8L393.5,399.4z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M384.7,382.8v7.6c0,2.7,2.1,1.4,3.5,0.6v-7.6
			C388.3,380.7,386.2,382,384.7,382.8z"
          />
        </g>
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="29.8,219.3 20.4,228.4 33,237.6 5.9,229.1 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="3.2,247.5 12.7,238.4 0,229.3 27.1,237.7 		"
          />
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            d="M388.1,77.6c1.1-1.5,2.6-2.1,5.2-2.3l6.5-0.5
			c3-0.2,5-0.1,6.2,0.4l-4.8-6.1c-2.7-3.4-4.6-3.5-6.7-1.1l-4.2,4.9C388.8,74.7,388,76.1,388.1,77.6L388.1,77.6z M387,66.2
			c9-0.7,9.1-1.7,1.9,6.6c-1.5,1.7-2.2,3.1-2.1,4.7l-3.1-5.4C381.7,68.8,383.3,66.5,387,66.2z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            d="M395.8,77.8c0.8,1.7,0.5,3.3-0.6,5.6l-2.8,5.9
			c-1.3,2.7-2.4,4.3-3.4,5.1l7.7-1.1c4.3-0.6,5.3-2.3,4.3-5.2l-2.2-6.1C398,79.9,397.2,78.6,395.8,77.8L395.8,77.8z M406.3,82.6
			c-3.9,8.1-3,8.7-6.7-1.7c-0.8-2.2-1.6-3.5-3-4.2h6.3C406.7,76.8,407.8,79.3,406.3,82.6z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            d="M391.8,84.4c-1.8-0.2-3.1-1.3-4.5-3.4l-3.7-5.4
			c-1.7-2.5-2.5-4.3-2.8-5.5l-2.9,7.2c-1.6,4-0.7,5.8,2.4,6.3l6.4,1.2C388.9,85.3,390.5,85.3,391.8,84.4L391.8,84.4z M382.4,91.1
			c-5.1-7.4-6-7,4.8-5c2.3,0.4,3.8,0.4,5.2-0.5l-3.1,5.4C387.3,94.4,384.5,94.1,382.4,91.1z"
          />
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            d="M446.3,223.4l-1.8,1.8l2.1,2.1l1.8-1.8c2.3-2.3,6.5-2.3,8.8,0
			l1.8,1.8l2.1-2.1l-1.8-1.8C455.9,219.9,449.7,219.9,446.3,223.4L446.3,223.4z M442.9,226.7l2.1,2.1l-6.2,6.2l6.2,6.2l-2.1,2.1
			l-7.3-7.3c-0.9-0.9-0.9-1.2,0-2.1L442.9,226.7L442.9,226.7z M444.5,244.9l1.8,1.8c3.4,3.4,9.6,3.4,13,0l1.8-1.8l-2.1-2.1l-1.8,1.8
			c-2.3,2.3-6.5,2.3-8.8,0l-1.8-1.8L444.5,244.9L444.5,244.9z M462.7,243.4l-2.1-2.1l6.2-6.2l-6.2-6.2l2.1-2.1l7.3,7.3
			c0.9,0.9,0.9,1.2,0,2.1L462.7,243.4z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            d="M441.9,235l1.4,1.4l3-3l1.6,1.6l-3,3l1.7,1.7l3-3l1.6,1.6l-3,3
			l1.8,1.8c1.5,1.5,4.2,1.5,5.7,0l1.8-1.8l-3-3l1.6-1.6l3,3l1.7-1.7l-3-3l1.6-1.6l3,3l1.4-1.4l-4.7-4.7l-3,3l-1.6-1.6l3-3l-1.8-1.8
			c-1.5-1.5-4.2-1.5-5.7,0l-1.8,1.8l3,3l-1.6,1.6l-3-3L441.9,235L441.9,235z M451.2,241.5l1.6-1.6l1.6,1.6l-0.2,0.2
			c-0.7,0.7-2.1,0.7-2.8,0L451.2,241.5L451.2,241.5z M454.4,228.6l-1.6,1.6l-1.6-1.6l0.2-0.2c0.7-0.7,2.1-0.7,2.8,0L454.4,228.6
			L454.4,228.6z M451.2,235l1.6-1.6l1.6,1.6l-1.6,1.6L451.2,235z"
          />
        </g>
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M87.6,63.3l-12.4,7.1c-0.9,0.5-0.1,2,0.8,1.4l11.5-6.7
			c0.9-0.5,2.1-0.2,2.6,0.7L94.5,86l-5.8-10c-1-1.7-3.2-2.3-4.9-1.3L74.7,80c-0.9,0.5-2.1,0.2-2.6-0.7l-5.3-9.1
			c-0.5-0.9-0.2-2.1,0.7-2.6L87.6,63.3z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M71.8,92.9l12.4-7.1c0.9-0.5,0.1-2-0.8-1.4L71.8,91
			c-0.9,0.5-2.1,0.2-2.6-0.7l-4.3-20.1l5.8,10c1,1.7,3.2,2.3,4.9,1.3l9.1-5.3c0.9-0.5,2.1-0.2,2.6,0.7l5.3,9.1
			c0.5,0.9,0.2,2.1-0.7,2.6L71.8,92.9z"
          />
        </g>
        <g>
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="227.7,14.4 231.8,7.4 236.7,7.4 232.7,14.4 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="237.3,8.4 241.4,15.4 239,19.7 234.9,12.7 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="237.7,19.7 229.6,19.7 227.1,15.5 235.3,15.5 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="244.6,6.3 232.4,6.3 236.1,0 241,0 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="235.9,27.1 242,16.5 245.7,22.8 243.2,27.1 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="222.3,9.2 228.4,19.7 221.1,19.7 218.7,15.5 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="234.8,0 226.5,14.4 222.9,8.1 227.6,0 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="246.3,21.7 238,7.4 245.2,7.4 249.9,15.4 		"
          />
          <polygon
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FDB717"
            points="221.7,20.8 238.4,20.8 234.7,27.1 225.4,27.1 		"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M270.2,445.1c0.1,0.5,0.1,1,0.1,1.5c33.3-5.4,64.1-18.4,90.5-37.1c-0.3-0.4-0.7-0.8-1-1.2
			C333.6,426.8,303.1,439.7,270.2,445.1z"
          />
          <path
            fill="#FFFFFF"
            d="M57,106.6c-0.4-0.3-0.8-0.6-1.2-1C36.7,133,23.6,165,18.6,199.5c0.5,0,1,0,1.5,0
			C25.1,165.4,38.1,133.7,57,106.6z"
          />
          <path
            fill="#FFFFFF"
            d="M110.2,408.5c-0.3,0.4-0.7,0.8-1,1.2c26.8,18.9,58,31.9,91.7,37.2c0-0.5,0.1-1,0.1-1.5
			C167.7,440.1,136.8,427.2,110.2,408.5z"
          />
          <path
            fill="#FFFFFF"
            d="M409.3,360.1c0.4,0.3,0.8,0.7,1.2,1c19.7-26.6,33.6-57.8,39.7-91.6c-0.5,0-1-0.1-1.5-0.1
			C442.5,302.8,428.8,333.7,409.3,360.1z"
          />
          <path
            fill="#FFFFFF"
            d="M201.3,16.5c-0.1-0.5-0.1-1-0.2-1.5c-35.9,5.6-69,20-96.9,40.9c0.3,0.4,0.7,0.8,1,1.1
			C132.8,36.3,165.6,22.1,201.3,16.5z"
          />
          <path
            fill="#FFFFFF"
            d="M21.1,268.8c-0.5,0-1,0.1-1.5,0.1c6,34.1,20,65.6,39.9,92.3c0.4-0.4,0.7-0.7,1.1-1
			C40.9,333.7,27.1,302.6,21.1,268.8z"
          />
          <path
            fill="#FFFFFF"
            d="M449.7,200.2c0.5,0,1-0.1,1.5-0.1c-5-35.1-18.3-67.5-37.9-95.2c-0.4,0.3-0.8,0.7-1.2,1
			C431.6,133.3,444.8,165.4,449.7,200.2z"
          />
          <path
            fill="#FFFFFF"
            d="M363.9,56.5c0.3-0.4,0.7-0.8,1-1.1C337.5,35,305.2,21,270.2,15.2c-0.1,0.5-0.1,1-0.2,1.5
			C304.7,22.4,336.7,36.4,363.9,56.5z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default GrowLevel1;
