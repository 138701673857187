import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { useMediaQueryMatches } from 'hooks';
import { styled, useTheme } from 'components';
import { addAlpha } from 'styles/utils';
import { LEVELS_CONTAINER_WIDTH, LEVELS_CONTAINER_MOB_WIDTH } from './utils';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.growLevel': {
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    fontSize: 432,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) scale(0.93)',
      width: '100%',
      height: '100%',
      boxShadow: '0 0 50px rgb(0 0 0 / 50%)',
      borderRadius: '50%',
      zIndex: -1
    },
    '& .growLevel__pathSvg': {
      position: 'absolute'
    },
    '&.level-0': {
      opacity: 0,
      transform: 'rotate(-30deg) scale(1.3)',
      transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out'
    },
    '&.level-1': {
      opacity: 1,
      transform: 'rotate(0deg) scale(1)',
      transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out'
    },
    '&.level-2': {
      opacity: 0.3,
      transform: 'rotate(-30deg) scale(0.7)',
      transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out'
    },
    '&.level-3': {
      opacity: 0,
      transform: 'rotate(-60deg) scale(0.3)',
      transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out'
    }
  }
}));

const ICON_WIDTH = 60;
const ICON_MOB_WIDTH = 20;

function GrowLevel(props) {
  const { active, activeLevel, level, abilitiesBadges, skillsBadges } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const theme = useTheme();
  const pathStrokeColor = useMemo(
    () => addAlpha(theme.palette.common.white, 1),
    [theme.palette.common.white]
  );
  const allBadges = [...(abilitiesBadges || []), ...(skillsBadges || [])];

  const [badgeCoords, setBadgeCoords] = useState([]);
  const [arcCoords, setArcCoords] = useState([]);
  const [svgWidth, setSvgWidth] = useState(0);
  const [windowSize, setWindowSize] = useState([0, 0]);
  const iconWidth = isDesktop ? ICON_WIDTH : ICON_MOB_WIDTH;

  const currentState = () => {
    const count = activeLevel - level;
    if (count < 0) return 'level-0'; // up to bat
    if (count === 0) {
      return 'level-1'; // currently active
    }
    if (count === 1) return 'level-2'; // 1 level in
    if (count >= 2) return 'level-3'; // more than 1 level in

    return 'level-0';
  };

  const drawCirclePoints = (points, radius, center) => {
    const badgeCoordsArr = [];
    const arcCoordsArr = [];
    const degreeSpacing = 9;
    if (points === 1) {
      // handle one item and put it at the bottom center.
      const newX = center[0];
      const newY = center[1] + radius;
      const arcCoord = [
        [radius - degreeSpacing - iconWidth / 2, newY],
        [svgWidth - radius + degreeSpacing + iconWidth / 2, newY]
      ];
      badgeCoordsArr.push([newX, newY]);
      arcCoordsArr.push(arcCoord);
    } else {
      const pi2X = 2 * Math.PI;
      const slice = pi2X / points;
      const slicePadding = Math.PI * (degreeSpacing / 180);
      // handle all other instances where badge count is more than 1
      for (let i = 0; i < points; i++) {
        const angle = slice * i;
        const anglePaddingStart = slice * i + slicePadding;
        const anglePaddingEnd = slice * (i + 1) - slicePadding;
        const newX = center[0] + radius * Math.cos(angle);
        const newY = center[1] + radius * Math.sin(angle);

        const arcCoord = [
          [
            center[0] + radius * Math.cos(anglePaddingStart),
            center[1] + radius * Math.sin(anglePaddingStart)
          ],
          [
            center[0] + radius * Math.cos(anglePaddingEnd),
            center[1] + radius * Math.sin(anglePaddingEnd)
          ]
        ];

        badgeCoordsArr.push([newX, newY]);
        arcCoordsArr.push(arcCoord);
      }
    }
    return [badgeCoordsArr, arcCoordsArr];
  };

  const triggerDraw = () => {
    const circleSections = allBadges.length;
    const { clientWidth } = document.body;
    // using 0.28 because we're adding ~2% buffer in the container.
    // Parent container qhGrowLevels is 30vw giving us the basis for these calculations
    const svgContainerWidth =
      clientWidth * ((isDesktop ? LEVELS_CONTAINER_WIDTH : LEVELS_CONTAINER_MOB_WIDTH - 2) / 100);
    setSvgWidth(svgContainerWidth);
    const svgRadius = svgContainerWidth / 2;
    const [badgeCoordsItem, arcCoordsItem] = drawCirclePoints(circleSections, svgRadius, [
      svgRadius,
      svgRadius
    ]);
    setBadgeCoords(badgeCoordsItem);
    setArcCoords(arcCoordsItem);
  };

  useEffect(() => {
    triggerDraw();
  }, []);

  useEffect(() => {
    triggerDraw();
  }, [windowSize]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const svgWidthWithPadding = svgWidth + iconWidth;

  return svgWidth > 0 ? (
    <StyledRoot className={clsx('growLevel', active && 'active', currentState())}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className="growLevel__pathSvg"
        style={{ height: svgWidthWithPadding, width: svgWidthWithPadding }}
        viewBox={`0 0 ${svgWidthWithPadding} ${svgWidthWithPadding}`}
      >
        <g transform={`translate(${iconWidth / 2}, ${iconWidth / 2})`}>
          {/*This will denote badge locations*/}
          {badgeCoords.map(([x, y], i) => (
            <g
              key={`g__${level}_${i}`}
              transform={`translate(${x - iconWidth / 2}, ${y - iconWidth / 2})`}
            >
              <image width={iconWidth} height={iconWidth} href={allBadges?.[i]?.badgeUrl || ''} />
            </g>
          ))}
          {/*This is the white lines between each badge*/}
          {arcCoords.map(([start, end], i) => (
            <path
              key={`path__${level}_${i}`}
              d={[
                'M',
                end[0],
                end[1],
                'A',
                svgWidth / 2,
                svgWidth / 2,
                0,
                allBadges.length === 1 ? 1 : 0,
                0,
                start[0],
                start[1]
              ].join(' ')}
              // stroke={pathStrokeColor}
              stroke="#FFF"
              fill="none"
            />
          ))}
        </g>
      </svg>
    </StyledRoot>
  ) : null;
}

GrowLevel.propTypes = {
  active: PT.bool.isRequired,
  activeLevel: PT.number.isRequired,
  level: PT.number.isRequired,
  abilitiesBadges: PT.arrayOf(
    PT.shape({
      abilitiesBadgeId: PT.number,
      abilitiesId: PT.number,
      badgeUrl: PT.string,
      complete: PT.bool,
      name: PT.string
    })
  ).isRequired,
  skillsBadges: PT.arrayOf(
    PT.shape({
      skillsBadgeId: PT.number,
      skillsId: PT.number,
      badgeUrl: PT.string,
      complete: PT.bool,
      name: PT.string
    })
  ).isRequired
  // levelProps: PT.number.isRequired
};

export default GrowLevel;
