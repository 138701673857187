import React, { useState, useEffect, memo } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { format as formatDate } from 'date-fns';
import { styled } from 'components';
import { Button, StyledSelect, DatePicker } from 'components/shared';
import { MdExpandMore } from 'components/icons';
import PayRangeModal from './PayRangeModal';

const compName = 'JobsFilter';
const classes = {
  container: `${compName}-container`,
  filterButtonWrapper: `${compName}-filterBtnWrap`,
  filterButton: `${compName}-filterBtn`,
  filterButtonActive: `${compName}-filterBtn_active`,
  filterButtonReset: `${compName}-filterBtn_reset`,
  filterSelectRoot: `${compName}-selectRoot`,
  filterSelectList: `${compName}-selectList`
};
const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    gap: 24
  },
  [`& .${classes.filterButtonWrapper}`]: {
    flexShrink: 0
  },
  // use && to increase css specificity
  [`&& .${classes.filterButton}`]: {
    padding: '10px 24px',
    borderRadius: 30,
    fontSize: 18,
    fontWeight: 800,
    lineHeight: '150%',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.moreColors.lightPurple,
    [`&.${classes.filterButtonActive}`]: {
      fontWeight: 400,
      color: theme.palette.moreColors.grey_7,
      backgroundColor: theme.palette.moreColors.gold
    },
    [`&.${classes.filterButtonReset}`]: {
      fontWeight: 400,
      color: 'inherit',
      backgroundColor: 'inherit'
    },
    // RootSelect class
    [`& .placeholder`]: {
      color: 'inherit'
    }
  },
  [`&& .${classes.filterSelectRoot}`]: {
    border: 'none'
  },
  [`&& .${classes.filterSelectList}`]: {
    minWidth: 290,
    zIndex: 500,
    marginTop: 16,
    borderRadius: 0
  }
}));

function FilterButton(props) {
  // eslint-disable-next-line react/prop-types
  const { children, className, active, ...rest } = props;
  return (
    <Button
      className={clsx(className, classes.filterButton, active && classes.filterButtonActive)}
      endIcon={<MdExpandMore />}
      disableRipple
      {...rest}
    >
      {children}
    </Button>
  );
}

function JobsFilter(props) {
  const { className, filters, setFilters } = props;
  const [state, setState] = useState({
    payRange: filters?.payRange || {},
    hireDate: filters?.hireDate || ''
  });
  const { payRange, hireDate } = state;
  const [hireDatePickerOpen, setHireDatePickerOpen] = useState(false);
  const [payRangeModalOpen, setPayRangeModalOpen] = useState(false);

  const getPayRangeString = (obj) => {
    const { payMin, payMax, currency, period, currencyId, payPeriodId } = obj || {};
    return (
      payMin >= 0 &&
      payMax >= 0 &&
      currency &&
      period &&
      `${payMin}-${payMax} ${currency} ${period}`
    );
  };

  const selectedPayRange = getPayRangeString(payRange);
  const filterActive = selectedPayRange || hireDate;

  const handleChange = (fieldName) => (value) => {
    let nextFilters;
    setState((prev) => {
      nextFilters = { ...prev, [fieldName]: value };
      return nextFilters;
    });
    const { payRange: payR, hireDate: hireD } = nextFilters;

    setFilters({
      payMin: payR.payMin,
      payMax: payR.payMax,
      currencyId: payR.currencyId,
      payPeriodId: payR.payPeriodId,
      hireDate: hireD ? formatDate(hireD, 'yyyy-MM-dd') : ''
    });
  };

  const reset = () => {
    setState({ payRange: {}, hireDate: '' });
    setFilters({});
  };

  return (
    <StyledRoot className={clsx(className, classes.container)}>
      <div className={classes.filterButtonWrapper}>
        {/* <StyledSelect
          id="pay-range-filter-button"
          value={payRange}
          placeholder="Pay range"
          buttonComponent={FilterButton}
          buttonProps={{ active: !!payRange }}
          mobileModalProps={{ title: 'Pay range' }}
          showIcon={false}
          classes={{
            root: classes.filterSelectRoot,
            list: classes.filterSelectList
          }}
          onChange={(_, newValue) => setPayRange(newValue)}
          options={payRangeOptions}
          disabled={!payRangeOptions.length}
          testID="pay-range-filter-button"
          getOptionTestID={(i, option) => `pay-range-filter-${option.value}-option`}
        /> */}
        <FilterButton
          active={!!selectedPayRange}
          onClick={() => setPayRangeModalOpen(true)}
          testID="pay-range-filter-button"
        >
          {selectedPayRange || 'Pay range'}
        </FilterButton>
        <PayRangeModal
          isOpen={payRangeModalOpen}
          onConfirm={handleChange('payRange')}
          onClose={() => setPayRangeModalOpen(false)}
        />
      </div>

      <div className={classes.filterButtonWrapper}>
        <FilterButton
          active={!!hireDate}
          onClick={() => setHireDatePickerOpen(true)}
          testID="hire-date-filter-button"
        >
          {hireDate ? formatDate(hireDate, 'MM/dd/yyyy') : 'Hire date'}
        </FilterButton>
        <div>
          <DatePicker
            value={hireDate}
            open={hireDatePickerOpen}
            onChange={handleChange('hireDate')}
            onClose={() => setHireDatePickerOpen(false)}
            InputComponentProps={{
              FormControlProps: {
                sx: {
                  // hide picker input
                  visibility: 'hidden',
                  position: 'absolute',
                  width: 0,
                  height: 0
                }
              }
            }}
          />
        </div>
      </div>

      {filterActive && (
        <div className={classes.filterButtonWrapper}>
          <Button
            className={clsx(className, classes.filterButton, classes.filterButtonReset)}
            variant="outlined-secondary"
            disableRipple
            onClick={reset}
          >
            Reset
          </Button>
        </div>
      )}
    </StyledRoot>
  );
}

JobsFilter.propTypes = {
  className: PT.string,
  filters: PT.shape({
    payRange: PT.any,
    hireDate: PT.any
  }),
  setFilters: PT.func.isRequired
};

JobsFilter.defaultProps = {
  className: '',
  filters: { hireDate: '', payRange: {} }
};

export default memo(JobsFilter);
