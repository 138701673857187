import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { get, debounce } from 'lodash';
import { styled } from 'components';
import Input from '../Input';

function Autocomplete(props) {
  const {
    className,
    getInputComponent,
    getSuggestionValue,
    getSuggestion,
    inputCompProps,
    isDebounced,
    nativeInputProps,
    onFetch,
    onSuggestionSelected,
    suggestionValPath,
    wait,
    ...rest
  } = props;
  const [suggestions, setSuggestions] = useState([]);

  const defaultInputProps = {
    ...nativeInputProps
  };

  const debouncedFetch = useCallback(
    debounce((...params) => onFetch(...params), wait),
    [onFetch, wait]
  );

  const onSuggestionsFetchRequested = useCallback(
    ({ value, reason }) => {
      const args = [
        value,
        { setSuggestions, reason, onSuccess: (result) => setSuggestions(result) }
      ];

      if (isDebounced) debouncedFetch(...args);
      else onFetch(...args);
    },
    [onFetch, isDebounced, debouncedFetch]
  );

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  const onSuggestionSelect = useCallback(
    (e, params) => {
      onSuggestionSelected(e, { ...params, suggestions });
    },
    [onSuggestionSelected, suggestions]
  );

  const handleGetSuggestionValue = useCallback(
    (suggestion) =>
      getSuggestionValue ? getSuggestionValue(suggestion) : get(suggestion, suggestionValPath, ''),
    [suggestionValPath]
  );

  const renderSuggestion = useCallback(
    (suggestion, { query, isHighlighted }) =>
      getSuggestion ? (
        getSuggestion(suggestion, { query, isHighlighted })
      ) : (
        <span>
          {getSuggestionValue
            ? getSuggestionValue(suggestion)
            : get(suggestion, suggestionValPath, '')}
        </span>
      ),
    [getSuggestion, suggestionValPath]
  );

  const renderInput = useCallback(
    (inputProps) =>
      getInputComponent ? (
        getInputComponent(inputProps, inputCompProps)
      ) : (
        <Input inputProps={inputProps} {...inputCompProps} />
      ),
    [getInputComponent, inputCompProps]
  );

  return (
    <Autosuggest
      containerProps={{
        className: `${className} autosuggest-container`
      }}
      suggestions={suggestions}
      getSuggestionValue={handleGetSuggestionValue}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelect}
      renderInputComponent={renderInput}
      renderSuggestion={renderSuggestion}
      inputProps={defaultInputProps}
      theme={{
        suggestion: 'autosuggest__suggestion',
        suggestionFirst: 'autosuggest__firstSuggestion',
        suggestionHighlighted: 'autosuggest__highlighted',
        suggestionsContainer: 'autosuggest__suggestionsContainer',
        suggestionsContainerOpen: 'autosuggest__suggestionsContainerOpen',
        suggestionsList: 'autosuggest__suggestionsList'
      }}
      {...rest}
    />
  );
}

Autocomplete.propTypes = {
  className: PropTypes.string,
  getInputComponent: PropTypes.func,
  getSuggestion: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  isDebounced: PropTypes.bool,
  inputCompProps: PropTypes.objectOf(PropTypes.any),
  nativeInputProps: PropTypes.objectOf(PropTypes.any).isRequired,
  onFetch: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  suggestionValPath: PropTypes.string,
  wait: PropTypes.number
};

Autocomplete.defaultProps = {
  className: '',
  getInputComponent: undefined,
  getSuggestion: undefined,
  getSuggestionValue: undefined,
  isDebounced: false,
  inputCompProps: {},
  // nativeInputProps: {
  //   value: '',
  //   onChange: (event, { newValue }) => {}
  // },
  // onFetch: () => {},
  // onSuggestionSelected: (
  //   event,
  //   { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  // ) => {},
  suggestionValPath: '',
  wait: 500
};

const StyledAutosuggest = styled(Autocomplete)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  '& .autosuggest__suggestion': {
    display: 'block',
    padding: 20,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:last-child': {
      borderRadius: '0 0 10px 10px'
    },
    [theme.breakpoints.down('desktopApp')]: {
      padding: 15
    }
  },
  '& .autosuggest__firstSuggestion': {
    borderRadius: '10px 10px 0 0',
    '&:last-child': {
      borderRadius: 10
    }
  },
  '& .autosuggest__highlighted': {
    backgroundColor: 'rgba(255,255,255,0.1)'
  },
  '& .autosuggest__suggestionsContainer': {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 500,
    maxHeight: 6 * 60,
    overflowY: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      maxHeight: 4 * 50
    }
  },
  '& .autosuggest__suggestionsContainerOpen': {
    backgroundColor: theme.palette.background.light,
    border: '1px solid rgba(255,255,255,0.2)',
    borderRadius: 10
  },
  '& .autosuggest__suggestionsList': {
    margin: 0,
    padding: 0
  }
}));

export default memo(StyledAutosuggest);
