import React from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { Button } from 'components/shared';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.classCard': {
    padding: 15,
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    backgroundColor: '#332b3f', // @TODO: get from theme
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 8,
      backgroundColor: theme.palette.common.white
    }
  },
  '& .classCard__body': {
    marginBottom: 30,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 16
    }
  },
  '& .classCard__title': {
    marginBottom: 21,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 12,
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '150%',
      color: '#312E38' // @TODO: get from theme (grey 6)
    }
  },
  '& .classCard__description': {
    fontSize: 14,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '150%',
      color: theme.palette.moreColors.grey_5
    }
  },
  '& .classCard__views': {
    fontSize: 10,
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '150%',
      color: '#312E38' // @TODO: get from theme (grey 6)
    }
  },
  '& .classCard__action': {
    padding: '10px 25px',
    border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    borderRadius: 50,
    outline: 'none',
    alignSelf: 'flex-start',
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    [theme.breakpoints.down('desktopApp')]: {
      backgroundColor: theme.palette.moreColors.lightPurple
    }
  }
}));

function ClassCard(props) {
  const { complete, taken, name, description, takenCount, onTake } = props;

  return (
    <StyledRoot className="classCard">
      <div className="classCard__body">
        <h4 className="classCard__title">{name}</h4>
        {description && <p className="classCard__description">{description}</p>}
        {/* <div>{`${takenCount} people took this`}</div> */}
      </div>
      {!complete && !taken && (
        <Button className="classCard__action" onClick={onTake}>
          Take Class
        </Button>
      )}
    </StyledRoot>
  );
}

ClassCard.propTypes = {
  complete: PT.bool,
  description: PT.string,
  name: PT.string.isRequired,
  onTake: PT.func,
  taken: PT.bool,
  takenCount: PT.number
};

ClassCard.defaultProps = {
  complete: false,
  description: '',
  onTake: () => {},
  taken: false,
  takenCount: 0
};

export default ClassCard;
