import React from 'react';
import { Outlet } from 'react-router-dom';
import flowRight from 'lodash/flowRight';
import { withCommonUI, withAlerts, withNotifications } from 'hocs';

const enhance = flowRight(withAlerts, withCommonUI, withNotifications);

function RootLayout() {
  return <Outlet />;
}

export default enhance(RootLayout);
