import React from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { HorizontalProgress, IconButton } from 'components/shared';
import { MdPersonOutline, MdChat, MdSearch, MdPersonRemove, MdAdd } from 'components/icons';
import employeeDefault from 'assets/img/employee_default.png';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.connectionCard': {
    padding: 15,
    borderRadius: 5,
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundColor: '#332b3f', // @TODO: get from theme
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 8,
      backgroundColor: theme.palette.common.white
    }
  },
  '& .connectionCard__img': {
    minWidth: 50,
    height: 50,
    marginBottom: 15,
    objectFit: 'cover',
    borderRadius: '50%',
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 48,
      height: 48,
      marginBottom: 12
    }
  },
  '& .connectionCard__descriptionContainer': {
    marginBottom: 25,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 12
    }
  },
  '& .connectionCard__title': {
    marginBottom: 5,
    fontWeight: 100,
    fontSize: 16,
    color: 'inherit',
    [theme.breakpoints.down('desktopApp')]: {
      fontWeight: 700,
      fontSize: 14,
      color: theme.palette.moreColors.grey_7
    }
  },
  '& .connectionCard__pos': {
    fontSize: 11,
    color: '#A4A3A4', // @TODO: get from theme
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 12,
      fontWeight: 700,
      fontSize: 12,
      color: theme.palette.moreColors.grey_5
    }
  },
  '& .connectionCard__action': {
    width: 'auto',
    height: 'auto',
    padding: '0 6px',
    fontSize: 15,
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 9px',
      fontSize: 20,
      color: theme.palette.moreColors.lightPurple
    }
  },
  '& .connectionCard__progressContainer': {
    marginBottom: 30,
    textAlign: 'left',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 14,
      '& .progress__line': {
        backgroundColor: theme.palette.moreColors.grey_4
      }
    }
  },
  '& .connectionCard__progressLevel': {
    fontSize: 10,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '150%',
      color: theme.palette.moreColors.grey_5
    }
  }
}));

function ConnectionCard(props) {
  const {
    companyName,
    companyPosition,
    employeesCount,
    imageUrl,
    level,
    name,
    levelProgress,
    onProfileShow,
    onRemove,
    onAdd,
    starred,
    type
  } = props;
  const isCompany = type === 'company';

  return (
    <StyledRoot className="connectionCard">
      <img
        src={imageUrl || employeeDefault}
        alt=""
        width={50}
        height={50}
        className="connectionCard__img"
      />
      <div className="connectionCard__descriptionContainer">
        <h4 className="connectionCard__title">{name}</h4>
        <span className="connectionCard__pos">
          {isCompany
            ? `${employeesCount} employees`
            : `${companyName || 'N/A'} | ${companyPosition || 'N/A'}`}
        </span>
      </div>
      {!isCompany && (
        <div className="connectionCard__progressContainer">
          <div className="connectionCard__progressLevel">{`level ${level}`}</div>
          <HorizontalProgress percent={levelProgress} />
        </div>
      )}
      <Box mb="10px" display="flex" justifyContent="center">
        <div>
          {/* <IconButton className="connectionCard__action">
            <MdSearch color="inherit" fontSize="inherit" />
          </IconButton>
          <IconButton className="connectionCard__action">
            <MdChat color="inherit" fontSize="inherit" />
          </IconButton> */}
          <IconButton className="connectionCard__action" onClick={onProfileShow}>
            <MdPersonOutline color="inherit" fontSize="inherit" />
          </IconButton>
          {starred ? (
            <IconButton className="connectionCard__action" onClick={onAdd}>
              <MdAdd color="inherit" fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton className="connectionCard__action" onClick={onRemove}>
              <MdPersonRemove color="inherit" fontSize="inherit" />
            </IconButton>
          )}
        </div>
      </Box>
    </StyledRoot>
  );
}

ConnectionCard.propTypes = {
  companyName: PT.string,
  companyPosition: PT.string,
  employeesCount: PT.oneOfType([PT.string, PT.number]),
  imageUrl: PT.string.isRequired,
  name: PT.string.isRequired,
  level: PT.number,
  levelProgress: PT.number,
  onProfileShow: PT.func.isRequired,
  onAdd: PT.func.isRequired,
  onRemove: PT.func.isRequired,
  starred: PT.bool.isRequired,
  type: PT.oneOf(['person', 'company']).isRequired
};

ConnectionCard.defaultProps = {
  companyName: '',
  companyPosition: '',
  employeesCount: 0,
  level: 1,
  levelProgress: 0
};

export default ConnectionCard;
