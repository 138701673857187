import React from 'react';
import PT from 'prop-types';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import PageHeader from '../PageHeader';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.employeeRatings': {
    marginTop: 48,
    marginBottom: 40
  },
  '& .employeeRatings__emptyContent': {
    textAlign: 'center'
  },
  '& .employeeRatings__emptyContentTitle': {
    marginBottom: 17,
    fontSize: 31,
    fontWeight: 700,
    lineHeight: '130%'
  },
  '& .employeeRatings__emptyContentMessage': {
    maxWidth: 256,
    margin: '0 auto 17px',
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '150%'
  },
  '& .employeeRatings__emptyContentSmile': {
    fontSize: 50
  }
}));

function RatingsPage(props) {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  return (
    <StyledRoot className="employeeRatings">
      {isDesktop && <PageHeader title="EMPLOYEE RATINGS" subtitle="" icon={null} />}
      <div className="employeeRatings__content">
        <div className="employeeRatings__emptyContent">
          <h3 className="employeeRatings__emptyContentTitle">No ratings yet</h3>
          <p className="employeeRatings__emptyContentMessage">
            When you receive a rating from your employer it will appear here.
          </p>
          <div className="employeeRatings__emptyContentSmile">🥳</div>
        </div>
      </div>
    </StyledRoot>
  );
}

RatingsPage.propTypes = {};

export default RatingsPage;
