import React from 'react';
import { useParams, useLocation, matchPath } from 'react-router-dom';
import { getRoutes } from 'utils';

export default function useMatchedRoutes() {
  const { pathname } = useLocation();
  const { id, companyId } = useParams();
  const routesWithId = getRoutes({ id, companyId });
  const { employer, employee, login, onboarding } = routesWithId;

  const matches = {
    // EMPLOYER
    isEmployerProfilePage: employer.profile === pathname,
    isEmployerDashboardPage: employer.enterprise === pathname,
    isEmployerApplicantsSearchPage: employer.enterprise_search === pathname,
    isEmployerSchedulePage: employer.enterprise_schedule === pathname,
    isEmployerGrowPage: employer.enterprise_grow === pathname,
    isEmployerPlansPage: employer.plans === pathname,
    isEmployerJobsPage: employer.dashboard === pathname,
    isEmployerJobPage: employer.job === pathname,
    isEmployerNewJobPage: employer.job_new === pathname,
    isEmployerEditJobPage: employer.job_edit === pathname,
    // isJobMatchedApplicantsPage: employer.job_match === pathname,
    isApprovedApplicantsPage: employer.job_approved === pathname,
    isStarredApplicantsPage: employer.job_starred === pathname,
    isRejectedApplicantsPage: employer.job_rejected === pathname,
    isNextStepsApplicantsPage: employer.job_next_steps === pathname,
    // EMPLOYEE
    isEmployeeProfilePage: employee.profile === pathname,
    isEmployeeGrowPage: employee.grow === pathname,
    isEmployeeSkillsProgressPage: employee.progress_skills === pathname,
    isEmployeeConnectionsProgressPage: employee.progress_connections === pathname,
    isEmployeeHiredProgressPage: employee.progress_hired === pathname,
    isEmployeeDashboardPage: employee.dashboard === pathname,
    isEmployeeJobPage: employee.job === pathname,
    isCompanyJobsPage: employee.companyJobs === pathname
  };

  matches.isPublicRoute = matches.isEmployeeJobPage || matches.isCompanyJobsPage;

  return matches;
}
