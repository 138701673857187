import React, { useEffect, useMemo, useCallback } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { format as formatDate } from 'date-fns';
import { useCountryStateQuery, useTrackJobView, useMediaQueryMatches } from 'hooks';
import { formatJobAddress, getRoutes, qaAttr } from 'utils';
import { Box, styled } from 'components';
import { Button, PopupMenu, IconButton } from 'components/shared';
import { MdMoreVert, MdChevronRight, MdStar } from 'components/icons';
import defaultImg from 'assets/img/job_default.png';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.rowItem': {},
  '& .rowItem__innerContainer': {
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    alignItems: 'center',
    '&:hover .popupTrigger': {
      display: 'flex'
    },
    [theme.breakpoints.down('desktopApp')]: {
      borderBottom: 'none'
    }
  },
  '& .rowItem__actionArea': {
    width: '100%',
    padding: '9px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    fontWeight: 'normal',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 10,
      paddingRight: 8,
      backgroundColor: theme.palette.background.dark
    }
  },
  '& .rowItem__imgWrapper': {
    marginRight: 16,
    position: 'relative'
  },
  '& .rowItem__img': {
    minWidth: 38,
    width: 38,
    height: 38,
    borderRadius: 3,
    objectFit: 'cover',
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 76,
      width: 76,
      height: 76,
      backgroundColor: theme.palette.background.default
    }
  },
  '& .rowItem__statusIcon': {
    position: 'absolute',
    right: -6,
    bottom: -4,
    width: 24,
    height: 24,
    border: '2px solid #fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: '#fff',
    borderRadius: '50%',
    backgroundColor: theme.palette.moreColors.yellow,
    [theme.breakpoints.down('desktopApp')]: {
      width: 22,
      height: 22,
      bottom: -7,
      right: -7,
      borderColor: '#ECECF5'
    }
  },
  '& .rowItem__dataContainer': {
    display: 'flex',
    alignItems: 'center',
    gap: 16
  },
  '& .secondaryText': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    opacity: 0.6,
    [theme.breakpoints.down('desktopApp')]: {
      fontWeight: 'bold'
    }
  },
  '& .mainText': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    opacity: 1,
    [theme.breakpoints.down('desktopApp')]: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  '& .ellipsisText': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  '& .popupTrigger': {
    marginLeft: 16,
    padding: 7,
    display: 'none'
  },
  '& .optionsPopupPaper': {
    minWidth: 255
  },
  '& .optionsPopupBtn': {
    width: '100%',
    height: 55,
    textAlign: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: 0,
    color: '#000',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0,0,0,0.1)'
    }
  }
}));

function MyJobsRowItem(props) {
  const { applyDate, className, isApproved, isStarred, job, onRetract, onStar } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { trackJobView } = useTrackJobView();
  const {
    approved,
    city,
    countryId,
    employerProfile,
    id,
    imageUrl,
    payRange,
    starred,
    stateId,
    title,
    userInterviewDate,
    zip
  } = job;
  const { name: employerName } = employerProfile || {};
  const jobStarred = isStarred || starred;
  const jobApproved = isApproved || approved;

  const { fetchCountryState, getCountryById, getStateById } = useCountryStateQuery();
  const selectedState = useMemo(() => getStateById(stateId), [getStateById, stateId]);
  const selectedCountry = useMemo(() => getCountryById(countryId), [getCountryById, countryId]);

  const jobAddress = formatJobAddress({
    city,
    zip,
    country: selectedCountry.code,
    state: selectedState.code
  });

  const formattedDate = useMemo(
    () => (applyDate ? formatDate(new Date(applyDate), 'MMM dd') : null),
    [applyDate]
  );

  useEffect(() => {
    fetchCountryState();
  }, []);

  const onJobClick = () => trackJobView(id);

  const renderStatusIcon = () => {
    if (jobStarred) {
      return (
        <div className="rowItem__statusIcon">
          <MdStar fontSize="inherit" color="inherit" />
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    if (isDesktop) {
      return (
        <Box width="100%" display="flex" justifyContent="space-between">
          <div className="rowItem__dataContainer ">
            <div className="mainText ellipsisText">{title}</div>
            <div className="secondaryText ellipsisText">{`@${employerName}`}</div>
            <div className="secondaryText ellipsisText">{jobAddress}</div>
            <div className="secondaryText">{payRange}</div>
          </div>
          {formattedDate && <div className="secondaryText">{`Applied ${formattedDate}`}</div>}
        </Box>
      );
    }
    return (
      <Box width="100%" display="flex" justifyContent="space-between">
        <Box width="100%">
          <Box width="100%" mb="8px" display="flex" justifyContent="space-between">
            <div className="mainText">{title}</div>
            <div className="secondaryText">{formattedDate}</div>
          </Box>
          <div className="secondaryText">{`@${employerName}`}</div>
          <div className="secondaryText">{`${jobAddress} • ${payRange}`}</div>
        </Box>
        <MdChevronRight sx={{ ml: '6px', color: 'primary.main', alignSelf: 'center' }} />
      </Box>
    );
  };

  const getPopupTrigger = useCallback(
    ({ openPopup, triggerProps }) => (
      <IconButton
        variant="outlined"
        color="primary"
        aria-label="job options"
        className="popupTrigger"
        onClick={openPopup}
        {...triggerProps}
        testID="job-options-button"
      >
        <MdMoreVert />
      </IconButton>
    ),
    []
  );

  const renderPopup = () => (
    <PopupMenu
      id={`job-popup-${id}`}
      disablePortal
      classes={{ paper: 'optionsPopupPaper' }}
      // triggerProps={{}}
      getTrigger={getPopupTrigger}
      items={[
        {
          label: 'Retract Application',
          isRetractButton: true,
          itemProps: { className: 'optionsPopupBtn', ...qaAttr('job-retract-button') }
        },
        {
          label: jobStarred ? 'Unstar' : 'Star',
          isStarButton: true,
          itemProps: { className: 'optionsPopupBtn', ...qaAttr('job-star-button') }
        }
      ]}
      onItemClick={(e, i, item, { setAnchorEl, closePopup }) => {
        closePopup();
        if (item.isRetractButton) onRetract(job);
        if (item.isStarButton) onStar(job, !jobStarred);
      }}
    />
  );

  return (
    <StyledRoot className={clsx('rowItem', className)}>
      <div className="rowItem__innerContainer">
        <Button
          isRouterLink
          to={getRoutes({ id }).employee.job}
          onClick={onJobClick}
          className="rowItem__actionArea"
          testID={`job-${title}`}
        >
          <div className="rowItem__imgWrapper">
            <img className="rowItem__img" src={imageUrl || defaultImg} alt="" />
            {renderStatusIcon()}
          </div>
          {renderContent()}
        </Button>
        {isDesktop && renderPopup()}
      </div>
    </StyledRoot>
  );
}

MyJobsRowItem.propTypes = {
  applyDate: PT.string.isRequired,
  className: PT.string,
  isApproved: PT.bool.isRequired,
  isStarred: PT.bool.isRequired,
  job: PT.shape({
    approved: PT.bool,
    city: PT.string,
    countryId: PT.number,
    employerProfile: PT.shape({
      name: PT.string
    }),
    id: PT.number,
    imageUrl: PT.string,
    payRange: PT.string,
    stateId: PT.number,
    starred: PT.bool,
    title: PT.string,
    userInterviewDate: PT.string,
    zip: PT.string
  }).isRequired,
  onRetract: PT.func.isRequired,
  onStar: PT.func.isRequired
};

MyJobsRowItem.defaultProps = {
  className: ''
};

export default MyJobsRowItem;
