import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon5(props) {
  return (
    <SvgIcon viewBox="0 0 33.54 32.28" {...props}>
      <path
        d="M12.34,13.35c1.21-1.7,2.98-2.32,5.84-2.53l7.28-.56c3.38-.26,5.59-.08,6.93,.43l-5.37-6.88C24.04-.02,21.8-.09,19.5,2.59l-4.75,5.54c-1.65,1.93-2.48,3.47-2.41,5.23h0ZM11.14,.51c10.08-.77,10.23-1.95,2.16,7.46-1.68,1.96-2.51,3.52-2.4,5.32l-3.53-6.12c-2.19-3.78-.37-6.35,3.77-6.67Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M21.03,13.58c.87,1.9,.52,3.74-.72,6.32l-3.16,6.58c-1.47,3.06-2.73,4.88-3.84,5.79l8.64-1.21c4.81-.67,5.99-2.57,4.82-5.9l-2.42-6.89c-.84-2.4-1.76-3.88-3.32-4.7h0Zm11.73,5.38c-4.37,9.11-3.43,9.84-7.54-1.86-.86-2.44-1.8-3.94-3.41-4.74h7.06c4.37,0,5.68,2.86,3.89,6.6Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M16.49,21c-2.08-.2-3.49-1.42-5.11-3.79l-4.12-6.03c-1.92-2.8-2.86-4.8-3.1-6.22L.88,13.05c-1.82,4.5-.77,6.47,2.7,7.12l7.17,1.35c2.5,.47,4.24,.41,5.73-.53h0Zm-10.52,7.47C.26,20.12-.84,20.57,11.35,22.86c2.54,.48,4.31,.41,5.81-.58l-3.53,6.12c-2.19,3.78-5.31,3.49-7.66,.06Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default GrowBadgeIcon5;
