import React from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import { GrowRecentBadgeIcon } from 'components/icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.progressCard': {
    display: 'flex',
    alignItems: 'flex-start'
  },
  '& .progressCard__icon': {
    marginLeft: 15,
    fontSize: 25,
    color: theme.palette.moreColors.yellow
  },
  '& .progressCard__score': {
    minWidth: 50,
    height: 50,
    marginRight: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    fontSize: 20,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.moreColors.purpleLight_2
  },
  '& .progressCard__title': {
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white
  },
  '& .progressCard__date': {
    fontSize: 11,
    color: theme.palette.moreColors.purpleLight_2
  },
  '& .progressCard__notableMessage': {
    margin: '15px 0',
    padding: 10,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: 5,
    fontSize: 10,
    color: theme.palette.common.white
  },
  '& .progressCard__description': {
    marginBottom: 16,
    fontSize: 12,
    color: theme.palette.common.white
  },
  '& .progressCard__action': {
    padding: '10px 25px',
    border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    borderRadius: 50,
    outline: 'none',
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: 'transparent'
  }
}));

function ProgressCard(props) {
  const { completed, completionDate, description, notableMessage, name, path, score } = props;
  const completionMessage = completed ? 'Completed' : /* `Complete By: ${completionDate}` */ '';
  // TODO: enroll button

  return (
    <StyledRoot className="progressCard">
      <div className="progressCard__score">
        <span>{`+${score}`}</span>
      </div>
      <div>
        <h4 className="progressCard__title">
          {name}
          {/* {path && <GrowRecentBadgeIcon className="progressCard__icon" />} */}
        </h4>
        <div className="progressCard__date">{completionMessage}</div>
        {/* {notableMessage && <div className="progressCard__notableMessage">{notableMessage}</div>} */}
        {/* {description && <div className="progressCard__description">{description}</div>} */}
        {/* {!completed && <Button className="progressCard__action">Enroll</Button>} */}
      </div>
    </StyledRoot>
  );
}

ProgressCard.propTypes = {
  completed: PT.bool,
  completionDate: PT.string,
  description: PT.string,
  notableMessage: PT.string,
  name: PT.string.isRequired,
  path: PT.bool,
  score: PT.number.isRequired
};

ProgressCard.defaultProps = {
  completed: false,
  completionDate: '',
  description: '',
  notableMessage: '',
  path: false
};

export default ProgressCard;
