import React from 'react';
import { SvgIcon } from 'components';

function GrowConnectionsIcon({ fill, ...props }) {
  return (
    <SvgIcon viewBox="0 0 29.5 31.5" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || '#EFB954'}
          d="M26.7,18c0.1-6-4.2-14.9-10.1-17.5c-6.8-3-9.3,8.8-5.4,13.3
		C14.3,4.7,23.8,13.4,26.7,18z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || '#F7EEEF'}
          d="M3,23.4c5.2,3.1,15,3.8,20.2,0c6-4.4-2.9-12.4-8.8-11.3
		C20.7,19.4,8.4,23.2,3,23.4z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={fill || '#2D2B6F'}
          d="M10.2,0.2C4.9,3.1-0.6,11.3,0.1,17.8c0.8,7.4,12.3,3.7,14.2-2
		C4.8,17.6,7.7,5,10.2,0.2z"
        />
      </g>
    </SvgIcon>
  );
}

export default GrowConnectionsIcon;
