import React from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { Button } from 'components/shared';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.suggestedCourses': {
    [theme.breakpoints.down('desktopApp')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '8px 16px'
    }
  },
  '& .suggestedCourses__card': {
    marginBottom: 20,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('desktopApp')]: {
      margin: 0,
      borderRadius: 8,
      flexDirection: 'column',
      '&.elevatedCard': {
        boxShadow: 'none'
      }
    }
  },
  '& .suggestedCourses__card-title': {
    marginBottom: 0,
    fontSize: 15,
    fontWeight: 100,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 12,
      fontSize: 14,
      fontWeight: 700,
      color: '#312E38' // @TODO: get from theme (grey 6)
    }
  },
  '& .suggestedCourses__card-action': {
    marginLeft: 10,
    padding: '5px 18px',
    borderRadius: 50,
    fontSize: 12,
    backgroundColor: theme.palette.background.light,
    [theme.breakpoints.down('desktopApp')]: {
      margin: 0,
      padding: '10px 24px',
      backgroundColor: theme.palette.moreColors.lightPurple
    }
  }
}));

function SuggestedClasses({ courses, onTake }) {
  const slicedCourses = courses.slice(0, 3);
  return (
    <StyledRoot className="suggestedCourses">
      {slicedCourses.map((o) => {
        const { name, id } = o;
        return (
          <div key={`suggestedClass__${id}`} className="elevatedCard suggestedCourses__card">
            <div>
              <h5 className="suggestedCourses__card-title">{name}</h5>
            </div>
            <Button
              variant="filled-primary"
              className="suggestedCourses__card-action"
              onClick={() => onTake(o)}
            >
              Sign Up
            </Button>
          </div>
        );
      })}
    </StyledRoot>
  );
}

SuggestedClasses.propTypes = {
  courses: PT.arrayOf(
    PT.shape({
      complete: PT.bool,
      employeeProfileId: PT.number,
      employerProfileId: PT.number,
      id: PT.number,
      link: PT.string,
      name: PT.string,
      taken: PT.bool,
      verified: PT.bool
    })
  ).isRequired,
  onTake: PT.func.isRequired
};

export default SuggestedClasses;
