import React from 'react';
import { SvgIcon } from 'components';

function GrowClassesIcon(props) {
  return (
    <SvgIcon viewBox="0 0 29.5 28.4" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FDB714"
          d="M30.2,6.9c0,8.3-6.7,15-15,15v-15H30.2z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EFB954"
          d="M0,15.1C0,6.8,6.8,0,15.1,0v15.1H0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#2D2B6F"
          d="M15.1,11l0,16.4c-5.9,0-10.7-4.7-11-10.5l0-5.9H15.1z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7EEEF"
          d="M15.1,21.9l0-16.4c5.9,0,10.7,4.7,11,10.5l0,5.9H15.1z"
        />
      </g>
    </SvgIcon>
  );
}

export default GrowClassesIcon;
