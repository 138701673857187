import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon15(props) {
  return (
    <SvgIcon viewBox="0 0 28.49 28.49" {...props}>
      <path
        d="M5.18,18.81v-5.86h15.54v-3.27c0-1.03-.87-1.91-1.91-1.91H0v11.04c0,5.32,4.36,9.68,9.68,9.68H26.58c1.03,0,1.91-.88,1.91-1.91v-3.27H9.68c-2.46,0-4.5-2.04-4.5-4.5Z"
        fill="currentColor"
      />
      <path
        d="M18.81,0H1.91C.87,0,0,.88,0,1.91v3.27H18.81c2.46,0,4.5,2.04,4.5,4.5v5.86H7.77v3.27c0,1.03,.87,1.91,1.91,1.91H28.49V9.68C28.49,4.36,24.13,0,18.81,0Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export default GrowBadgeIcon15;
