import React from 'react';
import PT from 'prop-types';
import { Spring, animated } from 'react-spring';
import { easePolyOut } from 'd3-ease';
import { MuiButtonBase, styled } from 'components';
import { CircularProgress } from 'components/shared';
import { PAGES } from '../data';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.totalScore': {
    // paddingBottom: 16
  },
  '& .totalScore__progressContainer': {
    width: 124,
    margin: '0 auto',
    position: 'relative',
    [theme.breakpoints.down('desktopApp')]: {
      width: 135
    }
  },
  '& .totalScore__innerContent': {
    width: '100%',
    position: 'absolute',
    left: '50%',
    top: 30,
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('desktopApp')]: {
      top: 38
    }
  },
  '& .totalScore__score': {
    textAlign: 'center',
    fontSize: 40,
    fontWeight: 'bold',
    lineHeight: 1,
    color: '#FFF',
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 44,
      lineHeight: '130%'
    }
  },
  '& .totalScore__title': {
    marginTop: 8,
    textAlign: 'center',
    fontSize: 9,
    fontWeight: 'bold',
    color: '#FFF',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .totalScore__detailsButtonContainer': {
    marginTop: -10,
    textAlign: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 0
    }
  },
  '& .totalScore__detailsButton': {
    width: 66,
    height: 23,
    border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    borderRadius: 12,
    fontSize: 9,
    color: '#FFF',
    [theme.breakpoints.down('desktopApp')]: {
      width: 'auto',
      height: 'auto',
      padding: '10px 24px',
      borderRadius: 30,
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '150%',
      color: theme.palette.moreColors.grey_3
    }
  }
}));

export default function TotalGrowScore({ score, setPage, detailsButtonTitle }) {
  return (
    <StyledRoot className="totalScore">
      <div className="totalScore__progressContainer">
        <CircularProgress score={score} />
        <div className="totalScore__innerContent">
          <div className="totalScore__score">
            <Spring
              from={{ score: 0 }}
              to={{ score }}
              config={{
                duration: 1250,
                easing: easePolyOut.exponent(2.0)
              }}
            >
              {({ score: animScore }) => (
                <animated.span>{animScore.to((v) => v.toFixed()) || 0}</animated.span>
              )}
            </Spring>
            <div className="totalScore__title">WT Grow Score</div>
          </div>
        </div>
      </div>
      <div className="totalScore__detailsButtonContainer">
        <MuiButtonBase className="totalScore__detailsButton" onClick={() => setPage(PAGES.metrics)}>
          {detailsButtonTitle}
        </MuiButtonBase>
      </div>
    </StyledRoot>
  );
}

TotalGrowScore.propTypes = {
  score: PT.number,
  setPage: PT.func.isRequired,
  detailsButtonTitle: PT.string
};

TotalGrowScore.defaultProps = {
  score: 0,
  detailsButtonTitle: 'Details'
};
