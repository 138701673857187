import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import LogRocket from 'logrocket';
import '@fontsource/nunito-sans';
import App from './App';

// initialization of LogRocket bug tracking and screen recording software.
(() => {
  if (process.env.NODE_ENV === 'production')
    try {
      LogRocket.init('cpndap/quickhire-web');
    } catch (e) {
      console.log('log rocket initialization failed');
      console.log(e);
    }
})();

render(<App />, document.getElementById('root'));
