import React from 'react';
import { useMutation } from '@apollo/client';
import { track } from 'utils/segmentAnalytics';
import { VIEWED_JOB } from 'api';

export default function useTrackJobView() {
  const [viewedJob] = useMutation(VIEWED_JOB, { fetchPolicy: 'no-cache' });

  const trackJobView = (jobId) => {
    viewedJob({ variables: { jobId } });
    track('Vacancy Viewed');
  };

  return { trackJobView };
}
