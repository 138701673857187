import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import {
  GrowPathIcon,
  GrowTrendsIcon,
  GrowRatingsIcon,
  GrowSalaryIcon,
  GrowConnectionsIcon,
  GrowClassesIcon
} from 'components/icons';
import CategoryLink from './CategoryLink';
import { PAGES } from '../data';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.categoriesNavigator': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 25
  }
}));

export default function CategoriesNavigator(props) {
  const { currentPage, onSelect } = props;

  return (
    <StyledRoot className="categoriesNavigator">
      <CategoryLink
        title="Path"
        icon={<GrowPathIcon />}
        active={currentPage === PAGES.path}
        onClick={() => onSelect(PAGES.path)}
      />
      <CategoryLink
        title="Trends"
        icon={<GrowTrendsIcon />}
        active={currentPage === PAGES.trends}
        onClick={() => onSelect(PAGES.trends)}
      />
      <CategoryLink
        title="Ratings"
        icon={<GrowRatingsIcon />}
        active={currentPage === PAGES.ratings}
        onClick={() => onSelect(PAGES.ratings)}
      />
      <CategoryLink
        title="Salary"
        icon={<GrowSalaryIcon />}
        active={currentPage === PAGES.salary}
        onClick={() => onSelect(PAGES.salary)}
      />
      <CategoryLink
        title="Connections"
        icon={<GrowConnectionsIcon />}
        active={currentPage === PAGES.connections}
        onClick={() => onSelect(PAGES.connections)}
      />
      <CategoryLink
        title="Classes"
        icon={<GrowClassesIcon />}
        active={currentPage === PAGES.classes}
        onClick={() => onSelect(PAGES.classes)}
      />
    </StyledRoot>
  );
}

CategoriesNavigator.propTypes = {
  currentPage: PT.string.isRequired,
  onSelect: PT.func.isRequired
};
