import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import format from 'date-fns/format';
import map from 'lodash/map';
import { qaAttr } from 'utils';
import { useCommonUI, useEmployerProfileQuery } from 'hooks';
import { Box, styled } from 'components';
import { Button, IconButton, Input, Spinner } from 'components/shared';
import {
  MdStar,
  MdTrendingUp,
  MdCheck,
  MdClose,
  MdExpandLess,
  MdExpandMore
} from 'components/icons';
import styles from 'styles/ApplicantsDashboard/ListRowItem';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import ApplicantActionsPopup from './ApplicantActionsPopup';
import {
  STARRED_TAB,
  QH_MATCHES_TAB,
  APPROVED_TAB,
  REJECTED_TAB,
  APPLICANTS_TAB,
  NEXT_STEPS_TAB,
  REJECTED_STATUS,
  ACCEPTED_STATUS,
  NEXT_STATUS,
  CHAT_MESSAGE_TEMPLATE
} from './data';
import { getCandidateActionName, getApplicantUserId, getApplicantProfile } from './utils';

const StyledRoot = styled('div', { shouldForwardProp: (prop) => prop !== 'variant' })(styles);

function ListRowItem(props) {
  const {
    approveStatus,
    data,
    jobId,
    notesLoading,
    onSelect,
    onAccept,
    onReject,
    onStar,
    onMoveToNext,
    onRejectFromChatSuccess,
    onNotesSave,
    starred: starredProp,
    type,
    webUrl,
    withActions,
    withNotes
  } = props;
  const { profile: employerProfile } = useEmployerProfileQuery();
  const allowEmployeeActions = employerProfile?.restrictions?.allowEmployeeActions || false;
  const { openChatModal } = useCommonUI();
  const actionNames = getCandidateActionName(webUrl);
  const withQuestions = type === APPLICANTS_TAB;

  const [notesValue, setNotes] = useState('');
  const [showJobPostApps, setShowJobPostApps] = useState(true);

  useEffect(() => {
    setNotes(data.userToJobsNotes || '');
  }, [data.userToJobsNotes]);

  const styleVariant = type === APPLICANTS_TAB || type === QH_MATCHES_TAB ? 'default' : 'filled';

  const { updatedAt, jobs, starredJob, approvedJob, passedJob } = data; // TODO: need status for moved to next
  const userId = getApplicantUserId(data);
  const profile = getApplicantProfile(data) || {};
  const { imageUrl = '', motto = '', name = '' } = profile;
  const answers = jobs?.questions || [];
  const isStarred = starredProp || type === STARRED_TAB || starredJob;
  const isRejected = type === REJECTED_TAB || approveStatus === REJECTED_STATUS || passedJob;
  const isApproved = type === APPROVED_TAB || approveStatus === ACCEPTED_STATUS || approvedJob;

  const withApproveActions =
    type === APPLICANTS_TAB ||
    type === QH_MATCHES_TAB ||
    type === STARRED_TAB ||
    type === NEXT_STEPS_TAB;
  const showRejectButton = !(isRejected || isApproved) && withApproveActions;
  const showApproveButton = !(isRejected || isApproved) && withApproveActions;
  const showStarButton =
    type === STARRED_TAB || (!(isRejected || isApproved) && withApproveActions);
  const showMoveToNextButton =
    type !== NEXT_STEPS_TAB && !(isRejected || isApproved) && withApproveActions;

  const showApprovingStatus =
    type !== REJECTED_TAB && type !== APPROVED_TAB && (approveStatus || approvedJob || passedJob);

  let imgIcon =
    type === STARRED_TAB ? (
      <div className="avatarIcon">
        <MdStar fontSize="inherit" color="inherit" />
      </div>
    ) : null;
  if (type === QH_MATCHES_TAB) {
    imgIcon = (
      <div className="avatarIcon">
        <MdTrendingUp fontSize="inherit" color="inherit" />
      </div>
    );
  }

  const showHideButton = type !== QH_MATCHES_TAB &&
    type !== STARRED_TAB &&
    type !== REJECTED_TAB && (
      <button className="applicantShowToggle" onClick={() => setShowJobPostApps(!showJobPostApps)}>
        {showJobPostApps ? (
          <IconButton fontSize="100px" color="inherit">
            <MdExpandLess fontSize="inherit" color="inherit" />
          </IconButton>
        ) : (
          <IconButton fontSize="100px" color="inherit">
            <MdExpandMore fontSize="inherit" color="inherit" />
          </IconButton>
        )}
      </button>
    );

  const openChat = () => {
    openChatModal({
      role: 'employer',
      initialView: 'chat',
      initialChatViewProps: {
        jobId,
        receiverUserId: userId,
        afterApplicantReject: onRejectFromChatSuccess,
        showHeaderActions: withApproveActions,
        suggestMessageTemplate: CHAT_MESSAGE_TEMPLATE
      }
    });
  };

  const renderStatus = () => {
    // if (isStarred) {
    //   return (
    //     <span className="statusText">
    //       <MdStar fontSize="inherit" />
    //       <Box component="span" ml="6px">
    //         Starred
    //       </Box>
    //     </span>
    //   );
    // }
    if (isApproved) {
      return (
        <span className="statusText">
          <MdCheck fontSize="inherit" />
          <Box component="span" ml="6px">
            Accepted
          </Box>
        </span>
      );
    }
    if (isRejected) {
      return (
        <span className="statusText error">
          <MdClose fontSize="inherit" />
          <Box component="span" ml="6px">
            Rejected
          </Box>
        </span>
      );
    }
    return null;
  };

  const renderApplyDate = () => (updatedAt ? format(new Date(updatedAt), "'Applied' MM.dd") : '');

  return (
    <StyledRoot
      variant={styleVariant}
      className="container"
      {...qaAttr(`candidates-table-applicant-item-${name}`)}
    >
      <div className="innerContainer">
        <Button
          className="clickablePart"
          disableTouchRipple
          onClick={() => onSelect(data)}
          {...qaAttr(`candidates-table-applicant-button-${name}`)}
        >
          <div className="dataCell">
            <div className="avatar">
              <img src={imageUrl || defaultEmployeeImg} alt="" width="38" height="38" />
              {imgIcon}
            </div>
          </div>
          <div className="dataCell detailsContainer">
            <div className="dataCell textMain">{name}</div>
            <div className="dataCell textSecondary mottoExcerpt">{motto}</div>
          </div>
          <div className="dataCell_last textSecondary">
            {(() => {
              if (showApprovingStatus) return renderStatus();
              if (!isRejected) return renderApplyDate();
              return '';
            })()}
          </div>
          {/* <MdChevronRight className="chevronIcon" /> */}
        </Button>
        {withActions && (
          <div className={clsx('dataCell', 'dataCell_last')}>
            <ApplicantActionsPopup
              actionTitles={{
                accept: actionNames.accept.title,
                reject: actionNames.reject.title,
                star: actionNames.star.title
              }}
              disableAccept={!allowEmployeeActions}
              disableReject={!allowEmployeeActions}
              disableStar={!allowEmployeeActions}
              disableMoveToNext={!allowEmployeeActions}
              id={name || userId}
              isStarred={isStarred}
              onChat={() => openChat(data)}
              onAccept={() => onAccept(data)}
              onReject={() => onReject(data)}
              onStar={() => onStar(data, !isStarred)}
              onMoveToNext={() => onMoveToNext(data)}
              withAccept={showApproveButton}
              withReject={showRejectButton}
              withStar={showStarButton}
              withMoveToNext={showMoveToNextButton}
              withChat
            />
          </div>
        )}
        {showHideButton}
      </div>

      {withNotes &&
        (showJobPostApps ? (
          <Box mb="10px">
            <Input
              value={notesValue}
              label="Notes"
              multiline
              maxRows={10}
              className="notesInput"
              onChange={(e) => setNotes(e.target.value)}
              testID={`candidates-notesValue-input-${name}`}
            />
            <Button
              variant="filled-primary"
              endIcon={notesLoading && <Spinner size={12} />}
              disabled={notesLoading}
              onClick={() => onNotesSave(data, notesValue)}
              testID={`candidates-notesValue-button-${name}`}
            >
              Save Notes
            </Button>
          </Box>
        ) : null)}
      {!!answers.length &&
        withQuestions &&
        (showJobPostApps ? (
          <div className="questionsContainer">
            <div className="questionsContainer__title">Questions</div>
            {map(answers, ({ answer, name: question, questions_id }, i) => (
              <div key={`answer__${i}`} className="questionItem">
                <div className="questionItem__q">{question}</div>
                <div className="questionItem__a">{answer === 'true' ? 'yes' : 'no'}</div>
              </div>
            ))}
          </div>
        ) : null)}
    </StyledRoot>
  );
}

ListRowItem.propTypes = {
  approveStatus: PT.oneOf(['', ACCEPTED_STATUS, REJECTED_STATUS, NEXT_STATUS]),
  data: PT.objectOf(PT.any).isRequired,
  jobId: PT.number.isRequired,
  notesLoading: PT.bool,
  onAccept: PT.func,
  onReject: PT.func,
  onStar: PT.func,
  onMoveToNext: PT.func,
  onSelect: PT.func.isRequired,
  onRejectFromChatSuccess: PT.func,
  onNotesSave: PT.func,
  starred: PT.bool,
  type: PT.oneOf([
    '',
    STARRED_TAB,
    QH_MATCHES_TAB,
    APPROVED_TAB,
    REJECTED_TAB,
    APPLICANTS_TAB,
    NEXT_STEPS_TAB
  ]),
  webUrl: PT.string,
  withActions: PT.bool,
  withNotes: PT.bool
};

ListRowItem.defaultProps = {
  approveStatus: '',
  notesLoading: false,
  onAccept: () => {},
  onReject: () => {},
  onStar: () => {},
  onMoveToNext: () => {},
  onRejectFromChatSuccess: () => {},
  onNotesSave: () => {},
  starred: false,
  type: '',
  webUrl: '',
  withActions: true,
  withNotes: false
};

export default ListRowItem;
