export default ({ theme }) => ({
  '& .container': {
    position: 'relative',
    maxWidth: 1024,
    margin: '0 auto'
  },
  '& .paper': {
    position: 'absolute',
    right: 0,
    top: 0,
    maxWidth: 414,
    minHeight: 'calc(100vh - 22px)',
    margin: '11px 13px',
    [theme.breakpoints.down('desktopApp')]: {
      position: 'relative',
      top: 'initial',
      right: 'initial',
      minHeight: 'calc(100% - 50px)',
      maxHeight: 'calc(100% - 50px)',
      width: 'calc(100% - 32px)',
      margin: '20px 16px 15px'
    }
  },
  '& .backdrop': {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('desktopApp')]: {
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  '& .messagesContent': {
    padding: '0 17px 33px',
    flex: '1 1 auto',
    overflowY: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '1px 17px 33px'
    },
    '&.empty': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .chatItem:not(:last-of-type)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }
  },
  '& .emptyMessagesMessage': {
    width: '100%',
    maxWidth: 260,
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '22px',
    color: 'rgba(0,0,0,0.4)'
  },
  '& .secondaryText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '15px',
    color: '#000'
  },
  '& .mainText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: 0,
    color: '#000',
    opacity: 0.4
  },
  '& .tipMessage': {
    margin: '16px 0',
    width: '100%',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.darkTransparent,
    borderRadius: 10
  },
  '& .tipMessage__text': {
    marginLeft: 14,
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: 0,
    color: theme.palette.primary.text
  }
});
