import React, { useState, useEffect, useCallback } from 'react';
import { Box, Dialog, styled } from 'components';
import { Button, RadioGroup, Input, DatePicker, Spinner } from 'components/shared';
import { useForm } from 'components/form';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .textarea': {
    height: 105,
    alignItems: 'flex-start',
    borderRadius: 5
  },
  '& .textareaInput': {
    padding: 6
  },
  '& .paylocity_saveBtn': {
    width: '100%',
    maxWidth: 200,
    height: 45,
    '&:not(:first-of-type)': {
      marginLeft: 20
    }
  }
}));

function Paylocity(props) {
  const { hired, jobId, isOpen, onConfirm, onClose, title } = props;

  const { $, validate, set, attrs } = useForm({
    initial: {
      start_date: new Date().toISOString().slice(0, 10),
      tax_form: 'W2',
      pay_type: 'HOURLY',
      employment_type: 'RFT',
      costCenter1: '2',
      costCenter2: '100'
    },
    validations: {
      start_date: ['presence'],
      tax_form: ['presence'],
      pay_rate: ['presence']
    }
  });

  function handleDateChange(field) {
    return (date, inputValue) => {
      set(field, date);
    };
  }

  const handleSave = () => {
    const startDate = new Date(attrs.start_date).toISOString().slice(0, 10);
    const dateParts = startDate.split('-');
    // format needed "09-29-2022"
    const finalDate = `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`;
    onConfirm({
      start_date: finalDate,
      tax_form: attrs.tax_form,
      pay_type: attrs.pay_type,
      employment_type: attrs.employment_type,
      costCenter1: attrs.costCenter1,
      costCenter2: attrs.costCenter2
    });
  };

  const handleFieldChange = (field) => (e) => {
    const val = e.target.value;
    set(field, val);
  };

  return (
    <StyledDialog open={isOpen} fullWidth onClose={onClose}>
      <Box py="10px" px="20px" display="flex" flexDirection="column" alignItems="center">
        <h1>{title}</h1>
        <Box mt="10px">
          <DatePicker
            {...$('start_date')}
            onChange={handleDateChange('start_date')}
            format="mm-dd-yyyy"
            variant="inline"
            rightArrowButtonProps={{ color: 'primary', edge: 'end' }}
            leftArrowButtonProps={{ color: 'primary', edge: 'start' }}
            TextFieldComponent={Input}
            label="Start Date"
            inputVariant="textfield"
            id="start_date"
            InputLabelProps={{
              htmlFor: `start_date`
            }}
            boxProps={{
              display: 'flex',
              flexDirection: 'column'
            }}
          />
        </Box>
        <Box mt="10px">
          <Input
            {...$('tax_form')}
            variant="textfield"
            id="tax_form"
            required
            label="Tax Form"
            labelClassName="label"
            className="rowInput"
            placeholder="W2"
            onChange={handleFieldChange('tax_form')}
          />
        </Box>
        <Box mt="10px">
          <Box>Employment Type</Box>
          <RadioGroup
            data={[
              { value: 'RFT', label: 'Regular Full Time' },
              { value: 'RPT', label: 'Regular Part Time' },
              { value: 'SNL', label: 'Seasonal' },
              { value: 'TFT', label: 'Temporary Full Time' },
              { value: 'TPT', label: 'Temporary Part Time' }
            ]}
            defaultValue="RFT"
            aria-label="employmentType"
            sx={{ maxHeight: 268, mb: '20px' }}
            analyticParams={{
              key: 'Paylocity Employment Type',
              trigger: 'focus'
            }}
            id="employment_type"
            onChange={handleFieldChange('employment_type')}
          />
        </Box>
        <Box mt="10px">
          <Box>Pay Type</Box>
          <RadioGroup
            data={[
              { value: 'HOURLY', label: 'Hourly' },
              { value: 'SALARY', label: 'Salary' }
            ]}
            defaultValue="HOURLY"
            aria-label="payType"
            sx={{ maxHeight: 268, mb: '20px' }}
            analyticParams={{
              key: 'Paylocity Pay Type',
              trigger: 'focus'
            }}
            id="pay_type"
            onChange={handleFieldChange('pay_type')}
          />
        </Box>
        <Box mt="10px">
          <Box>Choose a Cost Center #1</Box>
          <RadioGroup
            data={[
              { label: 'Louisburg', value: '2' },
              { label: 'Independence - Noland', value: '100' },
              { label: 'Excelsior Springs', value: '200' },
              { label: 'Lenexa - Woodland', value: '300' },
              { label: 'Lenexa - 87th', value: '350' },
              { label: 'Independence - 23rd &amp; 291', value: '400' },
              { label: 'Leawood', value: '500' },
              { label: 'Overland Park', value: '600' },
              { label: 'Independence - Sterling', value: '650' },
              { label: 'Olathe', value: '700' },
              { label: 'Parkville', value: '900' },
              { label: 'Lees Summit', value: '950' }
            ]}
            defaultValue="2"
            aria-label="costCenter1"
            sx={{ maxHeight: 268, mb: '20px' }}
            analyticParams={{
              key: 'Paylocity costCenter1',
              trigger: 'focus'
            }}
            name="costCenter1"
            id="costCenter1"
            onChange={handleFieldChange('costCenter1')}
          />
        </Box>
        <Box mt="10px">
          <Box>Choose a Cost Center #2</Box>
          <RadioGroup
            data={[
              { label: 'Security', value: '100' },
              { label: 'Nonfoods', value: '150' },
              { label: 'Sackers', value: '200' },
              { label: 'Produce', value: '300' },
              { label: 'Floral', value: '350' },
              { label: 'Meat', value: '400' },
              { label: 'Deli', value: '500' },
              { label: 'Seafood', value: '550' },
              { label: 'Bakery', value: '600' },
              { label: 'Cashiers', value: '700' },
              { label: 'Night Stockers', value: '800' },
              { label: 'Day Stockers', value: '900' },
              { label: 'Managers', value: '950' },
              { label: 'Cash Office/Customer Service', value: '999' }
            ]}
            defaultValue="100"
            aria-label="costCenter2"
            sx={{ maxHeight: 268, mb: '20px' }}
            analyticParams={{
              key: 'Paylocity costCenter2',
              trigger: 'focus'
            }}
            name="costCenter2"
            id="costCenter2"
            onChange={handleFieldChange('costCenter2')}
          />
        </Box>
        <Box mt="10px">
          <Button
            variant="filled-primary"
            className="paylocity_saveBtn"
            onClick={handleSave}
            testID="paylocity-save-button"
          >
            Save
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
}

export default Paylocity;
