import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon3(props) {
  return (
    <SvgIcon viewBox="0 0 40.1 32.14" {...props}>
      <path
        d="M12.73,2.91l-2.06,2.06,2.34,2.34,2.06-2.06c2.59-2.59,7.37-2.59,9.95,0l2.06,2.06,2.34-2.34-2.06-2.06c-3.88-3.88-10.76-3.88-14.64,0h0Zm-3.82,3.82l2.34,2.34-7,7,7,7-2.34,2.34L.74,17.24c-.99-.99-.99-1.35,0-2.34L8.91,6.73h0Zm1.76,20.44l2.06,2.06c3.88,3.88,10.76,3.88,14.64,0l2.06-2.06-2.34-2.34-2.06,2.06c-2.59,2.59-7.37,2.59-9.95,0l-2.06-2.06-2.34,2.34h0Zm20.51-1.76l-2.34-2.34,7-7-7-7,2.34-2.34,8.17,8.17c.99,.99,.99,1.35,0,2.34l-8.17,8.17Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7.78,16.07l1.61,1.61,3.37-3.38,1.76,1.76-3.37,3.38,1.86,1.86,3.37-3.37,1.76,1.76-3.38,3.37,2.05,2.05c1.67,1.67,4.78,1.67,6.44,0l2.05-2.05-3.38-3.37,1.76-1.76,3.38,3.37,1.86-1.86-3.37-3.38,1.76-1.76,3.37,3.38,1.61-1.61-5.24-5.24-3.38,3.37-1.76-1.76,3.38-3.38-2.05-2.05c-1.67-1.67-4.78-1.67-6.44,0l-2.05,2.05,3.38,3.38-1.76,1.76-3.37-3.37-5.24,5.24h0Zm10.47,7.26l1.8-1.8,1.8,1.8-.22,.21c-.78,.74-2.38,.74-3.16,0l-.22-.21h0Zm3.61-14.51l-1.8,1.8-1.8-1.8,.22-.21c.78-.74,2.38-.74,3.16,0l.22,.21h0Zm-3.6,7.25l1.8-1.8,1.8,1.8-1.8,1.8-1.8-1.8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default GrowBadgeIcon3;
