import React from 'react';
import { SvgIcon } from 'components';

function GrowTrendsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 29.5 28.4" {...props}>
      <g>
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EFB954"
          points="24.3,4.7 21.8,14.1 14.9,14.1 6.8,0"
        />
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#2D2B6F"
          points="16.6,28.4 9.7,21.5 13.2,15.5 29.5,15.5"
        />
        <polygon
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7EEEF"
          points="0,9.8 9.4,7.3 12.9,13.3 4.7,27.4"
        />
      </g>
    </SvgIcon>
  );
}

export default GrowTrendsIcon;
