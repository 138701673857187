export default ({ theme }) => ({
  '&.container': {
    flex: 1,
    overflow: 'auto'
  },
  '& .bgPurple': {
    backgroundColor: theme.palette.background.light
  },
  '& .section': {
    maxWidth: 1024,
    width: '100%',
    margin: '0 auto'
  },
  '& .titleSection': {
    padding: '50px 0 144px'
  },
  '& .plans__title': {
    marginBottom: 21,
    fontSize: 28,
    fontWeight: 'bold',
    lineHeight: '31px',
    textAlign: 'center',
    color: theme.palette.primary.text
  },
  '& .plans__text': {
    fontSize: 17,
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.primary.text
  },
  '& .plansItems': {
    position: 'relative',
    top: -100,
    maxWidth: 754,
    margin: '0 auto -100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  },
  '& .planItem': {
    position: 'relative',
    width: '100%',
    maxWidth: 239,
    minHeight: 464,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #E5E5E5',
    borderRadius: 15,
    '&:nth-of-type(1)': {
      '& .planItem__header': {
        backgroundColor: 'rgba(255,255,255,0.3)'
      }
    },
    '&:nth-of-type(2)': {
      border: `2px solid ${theme.palette.moreColors.purpleLanding}`,
      '& .planItem__header': {
        backgroundColor: 'rgba(255,255,255,0.2)'
      }
    },
    '&:nth-of-type(3)': {
      '& .planItem__header': {
        backgroundColor: 'rgba(255,255,255,0.1)'
      }
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 22
    }
  },
  '& .planItem__bestLabelContainer': {
    width: '100%',
    position: 'absolute',
    left: '50%',
    top: -11,
    zIndex: 1,
    transform: 'translateX(-50%)',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  '& .planItem__bestLabel': {
    position: 'relative',
    zIndex: 1,
    height: 21,
    width: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: '10px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.moreColors.yellow,
    '&::before': {
      content: '""',
      width: 21,
      height: 21,
      position: 'absolute',
      bottom: -10,
      left: -5,
      display: 'block',
      backgroundColor: 'inherit',
      transform: 'rotate(25deg)'
    }
  },
  '& .planItem__header': {
    position: 'relative',
    width: '100%',
    padding: '23px 0 10px',
    borderRadius: '13px 13px 0 0',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.moreColors.purpleLanding,
      top: 0,
      zIndex: -1,
      borderRadius: '13px 13px 0 0'
    }
  },
  '& .planItem__title': {
    marginBottom: 12,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  '& .planItem__use': {
    maxWidth: 150,
    margin: '0 auto',
    fontSize: 9,
    lineHeight: '16px',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  '& .planItem__body': {
    width: '100%',
    padding: '20px 14px 22px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    borderRadius: '0 0 15px 15px'
  },
  '& .planItem__price': {
    width: '100%',
    height: 31,
    marginBottom: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 17,
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.primary.text,
    backgroundColor: theme.palette.background.default
  },
  '& .planItem__features': {
    width: '100%',
    flex: 1
  },
  '& .planItem__feature': {
    width: '100%',
    '&:not(:last-of-type)': {
      paddingBottom: 7,
      borderBottom: '1px solid #ECECF5'
    },
    '&:not(:first-of-type)': {
      paddingTop: 7
    }
  },
  '& .planItem__featureTitle': {
    fontSize: 11,
    fontWeight: 'bold',
    lineHeight: '12px',
    color: theme.palette.primary.main
  },
  '& .planItem__featureDescr': {
    marginTop: 7,
    fontSize: 11,
    lineHeight: '12px',
    color: theme.palette.primary.light
  },
  '& .planItem__btn': {
    width: 110,
    height: 35,
    fontSize: 11,
    lineHeight: '12px',
    borderRadius: 3
  },
  '& .plansContact': {
    padding: '48px 0 42px'
  },
  '& .plansContact__title': {
    marginBottom: 11,
    fontSize: 29,
    fontWeight: 'bold',
    lineHeight: '32px',
    textAlign: 'center',
    color: theme.palette.primary.main
  },
  '& .plansContact__text': {
    marginBottom: 32,
    fontSize: 17,
    fontWeight: 'bold',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.primary.light
  }
});
