import React, { useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import { getProfileId, getUserId } from 'utils';
import { Box, styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { MdArrowDownward } from 'components/icons';
import styles from 'styles/Onboarding';
import { withEmployeeGrow } from 'hocs';
import { useJobTypesQuery } from 'hooks';
import { CREATE_CAREER_DEV_ON_REGISTRATION, GET_CAREER_DEV_WITH_HEALTH_SCORE } from 'api';

const enhance = (Component) => withEmployeeGrow(Component);

function CareerPathConfirmation(props) {
  const { className, onFinish, setStep, form, createCareerPath, createCareerPathLoading } = props;
  const { curJobTypeId, futureJobTypeId, industryId } = form || {};

  const [createCareerDev, { loading: careerDevSaveLoading }] = useMutation(
    CREATE_CAREER_DEV_ON_REGISTRATION
  );
  const [fetchCareerDev] = useLazyQuery(GET_CAREER_DEV_WITH_HEALTH_SCORE, {
    fetchPolicy: 'cache-and-network'
  });

  const { getJobTypes, findJobTypeById, jobTypes, jobTypesLoading } = useJobTypesQuery();

  const loading = careerDevSaveLoading || createCareerPathLoading;

  useEffect(() => {
    getJobTypes();
  }, []);

  const currentJob =
    useMemo(
      () => findJobTypeById(curJobTypeId, jobTypes),
      [curJobTypeId, findJobTypeById, jobTypes]
    )?.name || '';

  const futureJob =
    useMemo(
      () => findJobTypeById(futureJobTypeId, jobTypes),
      [futureJobTypeId, findJobTypeById, jobTypes]
    )?.name || '';

  const handleFinish = () => {
    onFinish(async () => {
      const userId = Number(getUserId());
      const profileId = Number(getProfileId());

      if (!userId) throw new Error('Missed userId');
      if (!profileId) throw new Error('Missed profileId');

      await createCareerDev({ variables: { userId, confirm: true } });
      fetchCareerDev({ variables: { userId } });
      await createCareerPath({
        startJobTypeId: curJobTypeId,
        endJobTypeId: futureJobTypeId,
        profileId
      });
    });
  };

  return (
    <div className={clsx(className, 'pageContent')} style={{ maxWidth: 461, alignItems: 'normal' }}>
      <Box mb="24px">
        <div className="title1">Where I’m at:</div>
        <div className="jobTypeContainer">
          {jobTypesLoading ? <Spinner /> : <span>{currentJob}</span>}
          <Button
            variant="text"
            className="jobTypeContainer__editBtn"
            onClick={() => setStep('pathStart')}
          >
            Edit
          </Button>
        </div>
      </Box>
      <Box mb="24px" textAlign="center" fontSize={36} lineHeight={0} color="moreColors.lightPurple">
        <MdArrowDownward fontSize="inherit" color="inherit" />
      </Box>
      <Box mb="48px">
        <div className="title1">Where I’m going:</div>
        <div className="jobTypeContainer">
          {jobTypesLoading ? <Spinner /> : <span>{futureJob}</span>}
          <Button
            variant="text"
            className="jobTypeContainer__editBtn"
            onClick={() => setStep('pathEnd')}
          >
            Edit
          </Button>
        </div>
      </Box>
      <Box width="100%" maxWidth={224} mx="auto">
        <Button
          variant="filled-primary"
          disabled={!industryId || !currentJob || !futureJob || loading}
          endIcon={loading ? <Spinner size={24} /> : null}
          className="nextBtn"
          onClick={handleFinish}
          testID="onboarding-finish-button"
        >
          Finish
        </Button>
      </Box>
    </div>
  );
}

CareerPathConfirmation.propTypes = {
  className: PT.string,
  createCareerPath: PT.func.isRequired,
  createCareerPathLoading: PT.bool.isRequired,
  onFinish: PT.func.isRequired,
  setStep: PT.func.isRequired,
  form: PT.objectOf(PT.any).isRequired
};

CareerPathConfirmation.defaultProps = {
  className: ''
};

const StyledCareerPathConfirmation = styled(CareerPathConfirmation)(({ theme }) => ({
  ...styles({ theme }),
  '& .jobTypeContainer': {
    width: '100%',
    padding: 16,
    border: `1px solid ${theme.palette.moreColors.lightPurple}`,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '130%',
    color: theme.palette.primary.main
  },
  '& .jobTypeContainer__editBtn': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '150%',
    color: theme.palette.moreColors.lightPurple
  }
}));

export default enhance(StyledCareerPathConfirmation);
