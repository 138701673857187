import { addAlpha } from 'styles/utils';

export default ({ theme }) => ({
  '& .container': {
    position: 'relative',
    maxWidth: 1024,
    margin: '0 auto'
  },
  '& .paper': {
    position: 'absolute',
    right: 0,
    top: 0,
    maxWidth: 414,
    minHeight: 'calc(100vh - 22px)',
    margin: '11px 13px',
    flexDirection: 'row',
    [theme.breakpoints.down('desktopApp')]: {
      position: 'relative',
      top: 'initial',
      right: 'initial',
      minHeight: 'calc(100% - 50px)',
      maxHeight: 'calc(100% - 50px)',
      width: 'calc(100% - 32px)',
      margin: '20px 0 15px'
    }
  },
  '& .backdrop': {
    backgroundColor: 'transparent',
    [theme.breakpoints.down('desktopApp')]: {
      backgroundColor: theme.palette.background.darkTransparent
    }
  },
  '& .loaderOverlay': {
    width: '100%',
    position: 'absolute',
    inset: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .dialogHeader': {
    padding: '13px 13px 12px',
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '5px 17px'
    }
  },
  '& .dialogTitle': {
    flex: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0
  },
  '& .headerAction_start': {
    flex: '0 0 44px'
  },
  '& .headerAction_end': {
    flex: '0 0 44px'
  },
  '& .dialogContent': {
    padding: '0 16px 33px'
  },
  '& .imagePreview': {
    position: 'relative',
    width: '100%',
    height: 370,
    marginBottom: 24,
    borderRadius: '15px 15px 0 0',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.background.default
  },
  '& .imagePreview__overlay': {
    position: 'absolute',
    inset: 0,
    display: 'none',
    background: 'linear-gradient(180deg, #000000 0%, rgba(255,255,255, 0) 100%)',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block'
    }
  },
  '& .pdfLink': {
    width: '100%',
    maxWidth: 72,
    height: 28,
    borderRadius: 14,
    fontSize: 14
  },
  '& .userName': {
    marginBottom: 8,
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '27px',
    letterSpacing: 0
  },
  '& .mainText': {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0
  },
  '& .secondaryText': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    color: theme.palette.moreColors.black,
    opacity: 0.4
  },
  '& .secondaryBoldText': {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '15px',
    letterSpacing: 0,
    opacity: 0.4
  },
  '& .resumeLink': {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0,
    color: theme.palette.primary.main
  },
  '& .trendIcon': {
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    color: theme.palette.primary.white,
    borderRadius: '50%',
    backgroundColor: theme.palette.moreColors.yellow
  },
  '& .videoPreview': {
    height: 210
  },
  '& .editableInputTrigger': {
    width: 'auto',
    height: 'auto',
    padding: 0
  },
  '& .expItem': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  '& .expItem__name': {
    maxWidth: 182
  },
  '& .interview': {
    marginBottom: 23,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.primary.text,
    background: addAlpha(theme.palette.primary.main, 0.2),
    padding: 5,
    borderRadius: 5,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12
    }
  },
  '& .interviewIcon': {
    marginRight: 6,
    marginBottom: -2,
    color: theme.palette.primary.textLight
  },
  '& .addCourseBtn': {
    width: 25,
    height: 25
  },
  '& .courseList': {
    paddingLeft: 17,
    '& li': {
      marginBottom: 10
    }
  },
  '& .course__item': {
    color: theme.palette.primary.text
  },
  '& .course__name': {
    color: theme.palette.primary.text,
    fontWeight: 'bold',
    fontSize: 14
  },
  '& .course__closeBtn': {
    width: 25,
    height: 25,
    fontSize: 18,
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.error.main
  },
  '& .course__completeBtn': {
    width: 25,
    height: 25,
    marginLeft: 10,
    fontSize: 18,
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.success.main
  },
  '& .courseContainer': {
    '&:not(:last-of-type)': {
      marginBottom: 15
    }
  }
});
