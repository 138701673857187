import React, { useState } from 'react';
import PT from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails, Box, styled } from 'components';
import { IconButton } from 'components/shared';
import { MdExpandMore, MdClose, MdAdd } from 'components/icons';
import { FiltersModal } from 'components/dialogs';
import { useMediaQueryMatches } from 'hooks';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .filterOptionContainer': {
    marginTop: -13,
    marginRight: -13,
    marginBottom: 16,
    padding: '0 30px',
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0
    }
  },
  '& .filterOption': {
    position: 'relative',
    height: 28,
    marginTop: 13,
    marginRight: 13,
    padding: '0 18px',
    paddingRight: 45,
    border: '1px solid #D3D3DD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 14,
    color: '#D3D3DD',
    userSelect: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      height: 23,
      marginTop: 10,
      padding: '0 12px',
      paddingRight: 30,
      fontSize: 12
    }
  },
  '& .filterOption__deleteBtn': {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 26,
    width: 26,
    fontSize: 15,
    color: '#8782BE',
    backgroundColor: '#ECECF6',
    [theme.breakpoints.down('desktopApp')]: {
      height: 20,
      width: 20,
      padding: 0
    }
  },
  '& .filterAddBtn': {
    height: 28,
    width: 28,
    marginTop: 13,
    marginRight: 15,
    borderColor: theme.palette.primary.main,
    fontSize: 15,
    [theme.breakpoints.down('desktopApp')]: {
      height: 23,
      width: 23,
      marginTop: 10
    }
  },
  '& .filtersAccordionHeader': {
    minHeight: 15,
    '&.Mui-expanded': {
      minHeight: 15
    }
  },
  '& .filtersAccordionHeader__content': {
    margin: 0,
    '&.Mui-expanded': {
      margin: '10px 0'
    }
  },
  '& .filtersAccordionHeader__expandIcon': {
    padding: 6
  }
}));

function AllJobsFilter({
  filterSettings,
  setFilterSettings,
  isFilterModalOpen,
  setIsFilterModalOpen
}) {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const closeFilterModal = () => setIsFilterModalOpen(false);

  const changeFilterSettings = ({ skills = [], industries = [] }) => {
    closeFilterModal();
    setFilterSettings({ skills, industries });
  };

  const deleteFilterOption = (option, filterName) => {
    let array = [...filterSettings[filterName]];
    array = array.filter(({ name }) => name !== option.name);
    setFilterSettings((prev) => ({ ...prev, [filterName]: array }));
  };

  const renderSelectedFilters = () => (
    <div className="filterOptionContainer">
      {filterSettings.skills.map((obj, i) => (
        <div key={`skillsFilter__${i}`} className="filterOption">
          <span>{obj.name}</span>
          <IconButton
            aria-label={`remove ${obj.name} filter option`}
            className="filterOption__deleteBtn"
            onClick={() => deleteFilterOption(obj, 'skills')}
            testID={`remove-filter-by-${obj.name}`}
          >
            <MdClose fontSize="inherit" />
          </IconButton>
        </div>
      ))}
      {filterSettings.industries.map((obj, i) => (
        <div key={`industriesFilter__${i}`} className="filterOption">
          <span>{obj.name}</span>
          <IconButton
            aria-label={`remove ${obj.name} filter option`}
            className="filterOption__deleteBtn"
            onClick={() => deleteFilterOption(obj, 'industries')}
            testID={`remove-filter-by-${obj.name}`}
          >
            <MdClose fontSize="inherit" />
          </IconButton>
        </div>
      ))}
      <IconButton
        variant="outlined"
        color="primary"
        aria-label="open filters modal"
        className="filterAddBtn"
        onClick={() => setIsFilterModalOpen(true)}
      >
        <MdAdd fontSize="inherit" />
      </IconButton>
    </div>
  );

  const renderDesktopSelectedFilters = () =>
    filterSettings.skills.length || filterSettings.industries.length
      ? renderSelectedFilters()
      : null;

  const renderMobileSelectedFilters = () => (
    <Box mb="10px">
      <Accordion square elevation={1}>
        <AccordionSummary
          expandIcon={<MdExpandMore fontSize="inherit" color="inherit" />}
          classes={{
            root: 'filtersAccordionHeader',
            content: 'filtersAccordionHeader__content',
            expandIcon: 'filtersAccordionHeader__expandIcon'
          }}
        >
          Filters
        </AccordionSummary>
        <AccordionDetails classes={{ root: 'sectionBody' }}>
          {renderSelectedFilters()}
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  return (
    <StyledRoot>
      {isDesktop ? renderDesktopSelectedFilters() : renderMobileSelectedFilters()}
      <FiltersModal
        isOpen={isFilterModalOpen}
        onClose={closeFilterModal}
        onSubmit={changeFilterSettings}
        skills={filterSettings.skills}
        industries={filterSettings.industries}
      />
    </StyledRoot>
  );
}

AllJobsFilter.propTypes = {
  filterSettings: PT.shape({
    skills: PT.arrayOf(PT.shape({ name: PT.string })),
    industries: PT.arrayOf(PT.shape({ name: PT.string }))
  }).isRequired,
  setFilterSettings: PT.func.isRequired,
  isFilterModalOpen: PT.bool.isRequired,
  setIsFilterModalOpen: PT.func.isRequired
};

export default AllJobsFilter;
