import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/public/createUploadLink';
import apolloLogger from 'apollo-link-logger';
import { sortBy, find } from 'lodash';
import { authVar, setAllJobsCommonVar } from '../reactiveVars';

const { URI, URI_BASE, URI_UPLOAD, NODE_ENV, EXPOSE_QUERY_NAME } = process.env;
const isProd = NODE_ENV === 'production';
const showQueryName = EXPOSE_QUERY_NAME === 'true';

// Development
// const URI = 'http://qh-api.galogram.site:4000/apiV1';
// const URI_BASE = 'http://qh-api.galogram.site:4000/';

// Production
// const URI = 'http://api.getquickhire.com:4000/apiV1';
// const URI_BASE = 'http://api.getquickhire.com:4000';

const httpLink = createUploadLink({
  uri: URI
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const logout = authVar()?.logout;

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `%c[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`,
        'color: red;'
      )
    );

    if (find(graphQLErrors, { message: 'Not authenticated' }) && logout) {
      logout();
    }
  }

  if (networkError) {
    console.log(`%c[Network error]: ${networkError}`, 'color: red;');

    if (networkError.statusCode === 401 && logout) {
      logout();
    }
  }
});

const namedLink = new ApolloLink((operation, forward) => {
  // enable distinguishable graphql query names in browser network tab
  if (showQueryName) {
    operation.setContext(() => ({
      uri: `${URI}?${operation.operationName}`
    }));
  }
  return forward ? forward(operation) : null;
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searchmatch: {
          // Results belong to the same list only if query argument match exactly
          keyArgs: ['query'],
          // Concatenate the incoming list items with
          // the existing list items.
          // eslint-disable-next-line default-param-last
          merge(existing = [], incoming, { args: { offset = 1 } }) {
            const merged = [...existing, ...incoming];
            return merged;
          }
        },
        myJobs: {
          keyArgs: false
        },
        employeeJobs: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,
          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing, incoming, { variables, args, readField, storage }) {
            const { paginationVariant, offset } = variables;

            setAllJobsCommonVar((prev) => ({ ...prev, ...args }));
            // eslint-disable-next-line no-param-reassign
            storage.paginationVariant = paginationVariant; // pass pagination type to read()
            // eslint-disable-next-line no-param-reassign
            storage.variables = variables; // pass fresh variables if they weren't updated during fetchMore() call

            const incomingArr = incoming || [];
            let merged = existing ? existing.slice(0) : [];
            const existingIds = merged.map((job) => readField('id', job));

            for (let i = 0; i < incomingArr.length; ++i) {
              if (paginationVariant === 'rePagination') {
                // merged[offset + i] = incoming[i]; // <-- this is better to use with read()
                // @apollo/client v3.6.2 has buggy read() (see below), which always returns current page and not new
                // even if it was already fetched and cached, so instead of merging new pages, just replace prev with incoming
                merged = incoming;
              } else {
                const id = readField('id', incomingArr[i]);
                if (id && existingIds.indexOf(id) === -1) merged.push(incomingArr[i]);
              }
            }

            return merged;
          }
          // read(existing, opts) {
          //   const { offset, limit } = opts.variables;
          //   const { paginationVariant, variables } = opts.storage;

          //   return (
          //     existing &&
          //     (paginationVariant === 'rePagination' // rePagination || noPagination
          //       ? existing.slice(variables.offset, variables.offset + variables.limit)
          //       : existing)
          //   );
          // }
        },
        employerProfile: {
          merge(existing, incoming, { readField }) {
            return incoming || undefined; // do not store null in cache, cause it will be returned from query
          }
        },
        employeeProfile: {
          merge(existing, incoming, { readField }) {
            return incoming || undefined; // do not store null in cache, cause it will be returned from query
          }
        },
        getSkills: {
          read(skills) {
            return skills ? sortBy(skills, ['name']) : skills;
          }
        },
        getIndustry: {
          read(industries) {
            return industries ? sortBy(industries, ['name']) : industries;
          }
        },
        getJobsTypes: {
          read(jobTypes, { readField }) {
            return jobTypes
              ? sortBy(jobTypes, (obj) => {
                  const name = readField('name', obj);
                  return name ? name.toLowerCase() : undefined;
                })
              : jobTypes;
          }
        }
      }
    },
    EmployerToScheduleTimes: {
      keyFields: ['id', 'jobId', 'user_id'] // id field is not unique, so custom cache id is used instead
    }
  }
});

const client = new ApolloClient({
  cache,
  link: from([apolloLogger, namedLink, authLink, errorLink, httpLink]),
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
});

export default client;

export { URI, URI_BASE, URI_UPLOAD };
