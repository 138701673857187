import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import PT from 'prop-types';
import clsx from 'clsx';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import { withApplicantActions, withApplicantProfileDialog } from 'hocs';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Spinner, IconButton, Button } from "components/shared";
import { MdExpandMore, MdExpandLess, AssignmentIcon, MdTrendingUp } from 'components/icons';
import { GET_APPLICANTS, GET_QUICK_MATCH } from 'api';
import { useAppStoreSelector } from 'store';
import styles from 'styles/ApplicantsDashboard';
import ApplicantsSearch from './ApplicantsSearch';
import ListRowItem from './ListRowItem';
import ListCardItem from './ListCardItem';
import EmptyApplicants from './EmptyApplicants';
import ApplicantsSwiper from './ApplicantsSwiper';
import {
  APPLICANTS_TAB,
  QH_MATCHES_TAB,
  ACCEPTED_STATUS,
  REJECTED_STATUS,
  NEXT_STATUS,
  GRID_LAYOUT,
  LIST_LAYOUT
} from './data';
import { filterActiveApplicants, getApplicantUserId } from './utils';

const StyledRoot = styled('div')(styles);

const enhance = (Component) => withApplicantActions(withApplicantProfileDialog(Component));

function NewApplicants(props) {
  const {
    // look at withApplicantActions and withApplicantProfileDialog for some props
    acceptApplicant,
    cancelApplicant,
    cancelLoading,
    closeApplicantProfileDialog,
    jobId,
    layoutType,
    moveToNextSteps,
    openApplicantProfileDialog,
    rejectApplicant,
    starApplicant,
    setApplicantProfileDialogProps,
    webUrl
  } = props;
  const client = useApolloClient();
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const applicantsDashboardLayout = useAppStoreSelector((state) => state.applicantsDashboardLayout);

  const swiperRef = useRef();
  const searchRef = useRef();

  const [{ applicants: applicantsList, qhMatches: qhMatchesList, joinedList }, setList] = useState({
    applicants: [],
    qhMatches: [],
    joinedList: []
  });
  const [processedSwiperCards, setProcessedSwiperCards] = useState([]); // only approved/rejected
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [processedApplicantStatuses, setProcessedApplicantStatuses] = useState({}); // only approved/rejected
  const [starredApplicants, setStarredApplicants] = useState({});
  const [loading, setLoading] = useState(true);
  const [showMatches, setShowMatches] = useState(true);
  const [hideRejectedMatches, setHideRejectedMatches] = useState(true);

  const fetchApplicants = useCallback(async () => {
    setLoading(true);
    setSwiperIndex(0);
    setProcessedSwiperCards([]);
    setProcessedApplicantStatuses({});

    const applicantsResult = await client.query({
      query: GET_APPLICANTS,
      variables: { jobId: Number(jobId) },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    const activeApplicants = filterActiveApplicants(applicantsResult?.data?.applicants || []).map(
      (o) => ({
        ...o,
        _type: APPLICANTS_TAB
      })
    );

    const matchesResult = await client.query({
      query: GET_QUICK_MATCH,
      variables: { jobId: Number(jobId) },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    const activeMatches = filterActiveApplicants(
      matchesResult?.data?.quickmatch || [],
      'employeeProfile'
    ).map((o) => ({ ...o, _type: QH_MATCHES_TAB }));

    setProcessedApplicantStatuses({});
    setProcessedSwiperCards([]);
    setList({
      applicants: activeApplicants,
      qhMatches: activeMatches,
      joinedList: [...activeMatches, ...activeApplicants]
    });
    setLoading(false);
  }, [client, jobId]);

  useEffect(() => {
    fetchApplicants();
  }, []);

  const onSuggestionSelect = (suggestion) => {
    if (suggestion) {
      const { data: applicantData, type } = suggestion;
      const arr = [{ ...applicantData, _type: type }];

      setList({
        applicants: type === APPLICANTS_TAB ? arr : [],
        qhMatches: type === QH_MATCHES_TAB ? arr : [],
        joinedList: arr
      });
      setSwiperIndex(0);
    }
  };

  const onSearchClear = () => {
    fetchApplicants();
  };

  const refreshPassedCards = useCallback(() => {
    const searchBar = searchRef.current;
    const query = searchBar.getCurrentValue();
    // clear search and refresh cards if last one was approved/rejected
    if (query.length) searchBar.clearSearch();
    else fetchApplicants();
  }, [fetchApplicants]);

  const handleMoveToNextSteps = useCallback(
    (applicant, extraParams = {}) => {
      const { index } = extraParams;
      const nextIndex = index + 1;
      const applicantUID = getApplicantUserId(applicant);
      if (index !== undefined) setSwiperIndex(nextIndex); // mobile only
      closeApplicantProfileDialog();

      moveToNextSteps({
        applicantData: applicant,
        jobId,
        onSuccess: () => {
          setProcessedApplicantStatuses((prev) => ({ ...prev, [applicantUID]: NEXT_STATUS }));
          setProcessedSwiperCards((prev) => [...prev, applicant]); // mobile only

          if (nextIndex >= joinedList.length) refreshPassedCards(); // mobile only
        },
        onCancel: () => {
          if (index !== undefined) {
            // mobile only
            setSwiperIndex((i) => i - 1);
            swiperRef.current?.swipeBack();
          }
        }
      });
    },
    [jobId, joinedList, moveToNextSteps, closeApplicantProfileDialog, refreshPassedCards]
  );

  const handleAccept = useCallback(
    (applicant, extraParams = {}) => {
      const { index } = extraParams;
      const nextIndex = index + 1;
      const applicantUID = getApplicantUserId(applicant);
      if (index !== undefined) setSwiperIndex(nextIndex); // mobile only
      closeApplicantProfileDialog();

      if (processedApplicantStatuses[applicantUID] === ACCEPTED_STATUS) return; // mobile only

      acceptApplicant({
        applicantData: applicant,
        jobId,
        jobWebUrl: webUrl,
        onSuccess: () => {
          setProcessedApplicantStatuses((prev) => ({ ...prev, [applicantUID]: ACCEPTED_STATUS }));
          setProcessedSwiperCards((prev) => [...prev, applicant]); // mobile only

          if (nextIndex >= joinedList.length) refreshPassedCards(); // mobile only
        },
        onCancel: () => {
          if (index !== undefined) {
            // mobile only
            setSwiperIndex((i) => i - 1);
            swiperRef.current?.swipeBack();
          }
        }
      });
    },
    [
      jobId,
      webUrl,
      joinedList.length,
      acceptApplicant,
      closeApplicantProfileDialog,
      refreshPassedCards,
      processedApplicantStatuses
    ]
  );

  const handleReject = useCallback(
    (applicant, extraParams = {}) => {
      const { index } = extraParams;
      const nextIndex = index + 1;
      const applicantUID = getApplicantUserId(applicant);
      if (index !== undefined) setSwiperIndex(nextIndex); // mobile only
      closeApplicantProfileDialog();

      if (processedApplicantStatuses[applicantUID] === ACCEPTED_STATUS) return; // mobile only

      rejectApplicant({
        applicantData: applicant,
        jobId,
        jobWebUrl: webUrl,
        onSuccess: () => {
          setProcessedApplicantStatuses((prev) => ({ ...prev, [applicantUID]: REJECTED_STATUS }));
          setProcessedSwiperCards((prev) => [...prev, applicant]); // mobile only

          if (nextIndex >= joinedList.length) refreshPassedCards(); // mobile only
        },
        onCancel: () => {
          if (index !== undefined) {
            // mobile only
            setSwiperIndex((i) => i - 1);
            swiperRef.current?.swipeBack();
          }
        }
      });
    },
    [
      jobId,
      webUrl,
      joinedList.length,
      rejectApplicant,
      closeApplicantProfileDialog,
      refreshPassedCards,
      processedApplicantStatuses
    ]
  );

  const onRejectFromChatSuccess = useCallback(() => {
    fetchApplicants();
  }, [fetchApplicants]);

  const handleStar = useCallback(
    (applicant, starred = true, isProfileDialog = false) => {
      const applicantUID = getApplicantUserId(applicant);
      starApplicant({
        applicantData: applicant,
        jobId,
        starred,
        onSuccess: () => {
          if (isProfileDialog) {
            setApplicantProfileDialogProps((prev) => ({ ...prev, isStarred: starred }));
          }
          setStarredApplicants((prev) => ({ ...prev, [applicantUID]: starred }));
        }
      });
    },
    [jobId, starApplicant, setApplicantProfileDialogProps]
  );

  const openApplicantProfile = useCallback(
    (applicant, extraParams = {}) => {
      const { index, dialogProps = {} } = extraParams;
      const applicantUID = getApplicantUserId(applicant);
      const isStarred = starredApplicants[applicantUID] || applicant.starredJob;
      const isApproved =
        processedApplicantStatuses[applicantUID] === ACCEPTED_STATUS || applicant.approvedJob;
      const isRejected =
        processedApplicantStatuses[applicantUID] === REJECTED_STATUS || applicant.passedJob;
      let approveStatus = '';
      if (isApproved) approveStatus = 'approved';
      if (isRejected) approveStatus = 'rejected';

      openApplicantProfileDialog({
        id: applicantUID,
        jobId,
        jobLink: webUrl,
        isQhMatch: applicant._type === QH_MATCHES_TAB,
        isStarred,
        approveStatus,
        withActions: !processedApplicantStatuses[applicantUID],
        onAccept: () =>
          index !== undefined // mobile only
            ? swiperRef.current?.handleAccept()
            : handleAccept(applicant, { index }),
        onReject: () =>
          index !== undefined // mobile only
            ? swiperRef.current?.handleReject()
            : handleReject(applicant, { index }),
        onStar: (starred) => handleStar(applicant, starred, true),
        ...dialogProps
      });
    },
    [
      JSON.stringify(processedApplicantStatuses),
      JSON.stringify(starredApplicants),
      jobId,
      webUrl,
      openApplicantProfileDialog,
      handleAccept,
      handleReject,
      handleStar
    ]
  );

  const handleSkipSwiperCard = useCallback((nextIdx) => setSwiperIndex(nextIdx), []);

  const handleCancelAction = useCallback(
    (applicant, extraParams = {}) => {
      const { index } = extraParams;
      const applicantUID = getApplicantUserId(applicant);

      if (index !== undefined) {
        // mobile only
        const processedIdx = findIndex(
          processedSwiperCards,
          (o) => getApplicantUserId(o) === applicantUID
        );
        setSwiperIndex(index);

        if (processedIdx === -1) return;
      }

      cancelApplicant({
        applicantData: applicant,
        jobId,
        onSuccess: () => {
          setProcessedApplicantStatuses((prev) => ({ ...prev, [applicantUID]: '' }));
          if (index !== undefined) {
            setProcessedSwiperCards((prev) =>
              // mobile only
              [...prev].filter((o) => getApplicantUserId(o) !== applicantUID)
            );
          }
        }
      });
    },
    [processedSwiperCards, jobId, cancelApplicant]
  );

  const renderLoader = () =>
    loading && (
      <div className="boardLoader">
        <Spinner />
      </div>
    );

  const applicantsProps = {
    jobId,
    webUrl,
    onSelect: openApplicantProfile,
    onReject: handleReject,
    onAccept: handleAccept,
    onStar: handleStar,
    onMoveToNext: handleMoveToNextSteps,
    onRejectFromChatSuccess
  };

  const getApplicantsProps = (applicant, applicantUID) => ({
    ...applicantsProps,
    data: applicant,
    approveStatus: processedApplicantStatuses[applicantUID] || '',
    starred: starredApplicants[applicantUID] || false,
    withActions: processedApplicantStatuses[applicantUID] !== NEXT_STATUS
  });

  const renderMobileCards = () =>
    !joinedList.length && !loading ? (
      <Box pt="20px">
        <EmptyApplicants />
      </Box>
    ) : (
      <ApplicantsSwiper
        ref={swiperRef}
        cards={joinedList}
        cardIndex={swiperIndex}
        onSkip={handleSkipSwiperCard}
        onReturn={handleCancelAction}
        onMoveToNext={handleMoveToNextSteps}
        cancelLoading={cancelLoading}
        starredApplicantsByUID={starredApplicants}
        {...applicantsProps}
      />
    );

  const renderList = () => (
    <div className="scrollable">
      <div className="boardTitleBar _BPaddingReducer _TopPaddingCollapse">
        <h2 className="boardTitle _withIcon">
          <MdTrendingUp fontSize="inherit" color="inherit" />
          <Box component="span" ml="6px">
            WorkTorch Matches
          </Box>
        </h2>
        <Box
          display={'flex'}
          justifyContent={'space-around'}
          alignItems={'center'}
        >
          <Button
            variant="outlined-secondary"
            sx={{
              width: 130,
              height: 44,
              fontSize: 12,
              marginRight: 1
            }}
            onClick={() => setHideRejectedMatches(!hideRejectedMatches)}
          >
          {hideRejectedMatches ? 'Show Rejected' : 'Hide Rejected'}
          </Button>
          <button
            type="button"
            className="applicantShowToggle"
            onClick={() => setShowMatches(!showMatches)}
          >
            {showMatches ? (
              <IconButton fontSize="100px" color="inherit">
                <MdExpandLess fontSize="inherit" color="inherit" />
              </IconButton>
            ) : (
              <IconButton fontSize="100px" color="inherit">
                <MdExpandMore fontSize="inherit" color="inherit" />
              </IconButton>
            )}
          </button>
        </Box>
      </div>
      <div className="hr" />
      {showMatches && (
        <Box className="dropList" position="relative">
          {renderLoader()}
          <div
            className={clsx(
              'boardListContainer',
              layoutType === GRID_LAYOUT && 'boardListContainer_grid'
            )}
          >
            {map(qhMatchesList, (applicant, i) => {
              const applicantUID = getApplicantUserId(applicant);
              if(hideRejectedMatches && applicant.passedJob) return null;
              return layoutType === GRID_LAYOUT ? (
                <ListCardItem
                  key={`qh_applicant__${applicantUID}`}
                  type={QH_MATCHES_TAB}
                  {...getApplicantsProps(applicant, applicantUID)}
                />
              ) : (
                <ListRowItem
                  key={`qh_applicant__${applicantUID}`}
                  type={QH_MATCHES_TAB}
                  {...getApplicantsProps(applicant, applicantUID)}
                />
              );
            })}
          </div>
        </Box>
      )}
      <div className="boardTitleBar">
        <h2 className="boardTitle _withIcon">
          <AssignmentIcon fontSize="inherit" color="inherit" />
          <Box component="span" ml="6px">
            Applicants
          </Box>
        </h2>
      </div>
      <div className="hr" />
      <Box className="dropList" position="relative">
        {renderLoader()}
        <div
          className={clsx(
            'boardListContainer',
            layoutType === GRID_LAYOUT && 'boardListContainer_grid'
          )}
        >
          {applicantsList.length ? (
            map(applicantsList, (applicant, i) => {
              const applicantUID = getApplicantUserId(applicant);

              return layoutType === GRID_LAYOUT ? (
                <ListCardItem
                  key={`applicant__${applicantUID}`}
                  type={APPLICANTS_TAB}
                  {...getApplicantsProps(applicant, applicantUID)}
                />
              ) : (
                <ListRowItem
                  key={`applicant__${applicantUID}`}
                  type={APPLICANTS_TAB}
                  {...getApplicantsProps(applicant, applicantUID)}
                />
              );
            })
          ) : (
            <EmptyApplicants />
          )}
        </div>
      </Box>
    </div>
  );

  return (
    <StyledRoot role="tabpanel" className="boardContainer">
      <div className="searchContainer">
        <ApplicantsSearch
          ref={searchRef}
          jobId={jobId}
          type={APPLICANTS_TAB}
          onSelect={onSuggestionSelect}
          onClear={onSearchClear}
        />
      </div>
      {(() => {
        if (isDesktop || (!isDesktop && applicantsDashboardLayout === LIST_LAYOUT)) {
          return renderList();
        }
        return renderMobileCards();
      })()}
    </StyledRoot>
  );
}

NewApplicants.propTypes = {
  acceptApplicant: PT.func.isRequired,
  cancelApplicant: PT.func.isRequired,
  cancelLoading: PT.bool,
  closeApplicantProfileDialog: PT.func.isRequired,
  jobId: PT.number.isRequired,
  layoutType: PT.string.isRequired,
  moveToNextSteps: PT.func.isRequired,
  openApplicantProfileDialog: PT.func.isRequired,
  rejectApplicant: PT.func.isRequired,
  starApplicant: PT.func.isRequired,
  setApplicantProfileDialogProps: PT.func.isRequired,
  webUrl: PT.string.isRequired
};

NewApplicants.defaultProps = {
  cancelLoading: false
};

export default enhance(NewApplicants);
