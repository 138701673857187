import React, { useCallback, useEffect, useState, memo } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { qaAttr } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useAlerts, useMediaQueryMatches } from 'hooks';
import { styled, Switch } from 'components';
import { useForm } from 'components/form';
import { Spinner } from 'components/shared';
import { UPDATE_EMPLOYER_NOTIFICATIONS } from 'api';
import styles from 'styles/Profile/EmployerProfile';
import SectionLayout from '../SectionLayout';

const StyledRoot = styled('div')(styles);

function Notifications(props) {
  const { expanded, jumpLink, notifications, onExpand, sectionName } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { setSimpleAlert } = useAlerts();

  const [updateCompanyNotification] = useMutation(UPDATE_EMPLOYER_NOTIFICATIONS);
  const [isDirty, setIsDirty] = useState(false);
  const { attrs, set, get, $ } = useForm();
  const {
    inappNewApplicants,
    emailNewApplicants,
    inappInboxMessages,
    emailInboxMessages,
    inappTimeRespondEnds,
    emailTimeRespondEnds
  } = attrs;

  useEffect(() => {
    set(notifications);
  }, [JSON.stringify(notifications)]);

  useEffect(() => {
    const update = async () => {
      await updateCompanyNotification({
        variables: { ...attrs }
      });
    };
    if (isDirty) update();
  }, [attrs]);

  const handleSwitchChange = useCallback((event, { name }) => {
    setIsDirty(true);
    if (name === 'emailInboxMessages' && !event.target.checked) {
      setSimpleAlert({
        isOpen: true,
        title: 'Attention!',
        subtitle:
          'Opting out of messages will remove you from the Daily Job Status email list. If you wish to continue, click "OK", If you wish to receive Daily Job Status emails, click cancel.',
        modalContainerStyles: { zIndex: 9999 },
        variant: 1,
        onSuccess: () => set(name, false),
        onCancel: () => setSimpleAlert({ isOpen: false })
      });
    } else {
      set(name, event.target.checked);
    }
  }, []);

  const sectionHeader = isDesktop ? (
    <div className="notice__sectionHeader">
      <div className="notice__sectionHeader__item">In-App</div>
      <div className="notice__sectionHeader__item">Email</div>
    </div>
  ) : null;

  return (
    <SectionLayout
      expanded={expanded}
      jumpLink={jumpLink}
      onExpand={onExpand}
      sectionName={sectionName}
      sectionHeaderContent={sectionHeader}
      sectionHeaderProps={{
        style: { display: 'flex', justifyContent: 'space-between' }
      }}
    >
      <StyledRoot className="section notice__container">
        <div className="notice__header">
          <div className="notice__header__item">In-App</div>
          <div className="notice__header__item">Email</div>
        </div>
        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">New Applicants</h3>
            </div>
            <div className="notice__actionsContainer">
              <Switch
                {...$('inappNewApplicants', handleSwitchChange)}
                checked={inappNewApplicants || false}
                color="primary"
                inputProps={{
                  'aria-label': 'in-app new applicants',
                  ...qaAttr('in-app-new-applicants-checkbox'),
                  onFocus: () => {
                    track('In-app new applicants focused (employer profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
              <Switch
                {...$('emailNewApplicants', handleSwitchChange)}
                checked={emailNewApplicants || false}
                color="primary"
                inputProps={{
                  'aria-label': 'email new applicants',
                  ...qaAttr('email-new-applicants-checkbox'),
                  onFocus: () => {
                    track('Email new applicants focused (employer profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
            </div>
          </div>
          <div className="notice__descr">
            <p className="label notice__descr__text">
              Notifies you via push notification or email that you have received new applicants on
              your job posting
            </p>
          </div>
        </div>

        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">Inbox Messages</h3>
            </div>
            <div className="notice__actionsContainer">
              <Switch
                {...$('inappInboxMessages', handleSwitchChange)}
                checked={inappInboxMessages || false}
                color="primary"
                inputProps={{
                  'aria-label': 'in-app inbox messages',
                  ...qaAttr('in-app-inbox-checkbox'),
                  onFocus: () => {
                    track('In-app inbox messages focused (employer profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
              <Switch
                {...$('emailInboxMessages', handleSwitchChange)}
                checked={emailInboxMessages || false}
                color="primary"
                inputProps={{
                  'aria-label': 'email inbox messages',
                  ...qaAttr('email-inbox-checkbox'),
                  onFocus: () => {
                    track('Email inbox messages focused (employer profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
            </div>
          </div>
          <div className="notice__descr">
            <p className="label notice__descr__text">
              Notifications about daily Job statistics, WT Matches and other items relative to your
              postings.
            </p>
          </div>
        </div>

        <div className="notice__group">
          <div className="notice__groupHeader">
            <div className="notice__labelContainer">
              <h3 className="notice__group__name value">48 Hour Response</h3>
            </div>
            <div className="notice__actionsContainer">
              <Switch
                {...$('inappTimeRespondEnds', handleSwitchChange)}
                checked={inappTimeRespondEnds || false}
                color="primary"
                inputProps={{
                  'aria-label': 'in-app time to respond ends',
                  ...qaAttr('in-app-respond-time-checkbox'),
                  onFocus: () => {
                    track('In-app time to respond focused (employer profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
              <Switch
                {...$('emailTimeRespondEnds', handleSwitchChange)}
                checked={emailTimeRespondEnds || false}
                color="primary"
                inputProps={{
                  'aria-label': 'email time to respond ends',
                  ...qaAttr('email-respond-time-checkbox'),
                  onFocus: () => {
                    track('Email time to respond focused (employer profile)');
                  }
                }}
                classes={{ root: 'notice__switchRoot' }}
              />
            </div>
          </div>
          {/*<div className={classes.notice__descr}>*/}
          {/*  <p className={`${classes.label} ${classes.notice__descr__text}`}></p>*/}
          {/*</div>*/}
        </div>
      </StyledRoot>
    </SectionLayout>
  );
}

Notifications.propTypes = {
  expanded: PT.bool.isRequired,
  jumpLink: PT.string,
  onExpand: PT.func.isRequired,
  sectionName: PT.string.isRequired,
  notifications: PT.shape({
    inappNewApplicants: PT.bool,
    emailNewApplicants: PT.bool,
    inappInboxMessages: PT.bool,
    emailInboxMessages: PT.bool,
    inappTimeRespondEnds: PT.bool,
    emailTimeRespondEnds: PT.bool
  }).isRequired
};

Notifications.defaultProps = {
  jumpLink: ''
};

export default memo(Notifications);
