import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { format as formatDate } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { qaAttr, getRoutes } from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useAppStoreSelector } from 'store';
import { useCountryStateQuery, useEmployerProfileQuery, useMediaQueryMatches } from 'hooks';
import {
  Box,
  Dialog,
  FormControlLabel,
  MuiRadio,
  MuiRadioGroup,
  Pagination,
  styled,
  TextField
} from 'components';
import { Autocomplete, Button, Spinner, Select } from 'components/shared';
import { MdChevronRight, MdMenu, MdGridOn } from 'components/icons';
import { JobCard } from 'components/Dashboard/employer';
import { GET_EMPLOYER_JOBS } from 'api';
import styles from 'styles/Dashboard/EmployerJobs';
import defaultJobImg from 'assets/img/job_default.png';

const StyledRoot = styled('div')(styles);

const ROUTES = getRoutes();

function EmployerJobs(props) {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const navigate = useNavigate();
  const employerJobsFilter = useAppStoreSelector((state) => state.employerJobsFilter);
  const setEmployerJobsFilter = useAppStoreSelector((state) => state.setEmployerJobsFilter);
  const hirePromptWasShown = useAppStoreSelector((state) => state.hirePromptWasShown);
  const setHirePromptWasShown = useAppStoreSelector((state) => state.setHirePromptWasShown);
  const newJobPromptWasShown = useAppStoreSelector((state) => state.newJobPromptWasShown);
  const setNewJobPromptWasShown = useAppStoreSelector((state) => state.setNewJobPromptWasShown);
  const [hirePromptOpen, setHirePromptOpen] = useState(false);
  const [newJobPromptOpen, setNewJobPromptOpen] = useState(false);

  const { canPostJob } = useEmployerProfileQuery();
  const { fetchCountryState, getStateById, getCountryById } = useCountryStateQuery();

  const [fetchJobs, { data: jobsData = {}, loading: jobsLoading = true, called }] = useLazyQuery(
    GET_EMPLOYER_JOBS,
    {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  );
  const jobsFetchComplete = !jobsLoading && called;
  const jobs = useMemo(() => jobsData?.employerJobs || [], [JSON.stringify(jobsData)]);

  const oldestJob = useMemo(() => {
    const jobByDays = jobs
      .map((job) => {
        const diff = new Date().getTime() - +job.createdAt;
        return [Math.ceil(diff / (1000 * 3600 * 24)), job];
      })
      .filter(([days, job]) => days >= 7);
    const { length } = jobByDays;

    if (length > 0) {
      const idx = Math.floor(Math.random() * (length - 0) + 0);
      return jobByDays[idx][1];
    }

    return null;
  }, [JSON.stringify(jobs)]);

  const getJobAddress = (job) => {
    const { city, stateId, countryId, zip } = job;
    const selectedState = getStateById(stateId);
    const selectedCountry = getCountryById(countryId);

    return city && selectedCountry.name && selectedState.code && zip
      ? `${city}, ${selectedState.code}, ${zip}, ${selectedCountry.code}`
      : '';
  };

  useEffect(() => {
    fetchJobs({ variables: { active: employerJobsFilter === 0 } });
  }, [employerJobsFilter]);

  useEffect(() => {
    fetchCountryState();
  }, []);

  useEffect(() => {
    if (employerJobsFilter === 0 && oldestJob && !hirePromptWasShown) {
      setHirePromptWasShown(true);
      setHirePromptOpen(true);
    }
  }, [employerJobsFilter, oldestJob, hirePromptWasShown]);

  useEffect(() => {
    if (employerJobsFilter === 0 && !newJobPromptWasShown && jobsFetchComplete && !jobs.length) {
      setNewJobPromptWasShown(true);
      setNewJobPromptOpen(true);
    }
  }, [employerJobsFilter, jobsFetchComplete, jobs.length]);

  const renderDesktopJobCard = (job) => (
    <JobCard
      data={job}
      isRouterLink
      to={getRoutes({ id: job.id }).employer[job.draft ? 'job_edit' : 'job']}
      onClick={() => {
        track('Employer Job Card Clicked');
      }}
    />
  );

  const renderHirePrompt = () => (
    <Dialog
      open={hirePromptOpen}
      container={() => document.getElementById('modals-root')}
      classes={{ paper: 'hirePrompt' }}
      onClose={() => setHirePromptOpen(false)}
    >
      <div className="hirePrompt__content">
        <div className="hirePrompt__title">Hey There</div>
        <div className="hirePrompt__descr">
          Looks like this jobs has been open for awhile, have you hired someone for it yet?
        </div>
        <div className="hirePrompt__jobTitle">{oldestJob?.title}</div>
      </div>
      <div className="hirePrompt__actions">
        <Button
          isRouterLink
          to={getRoutes({ id: oldestJob?.id }).employer.job_approved}
          className="hirePrompt__btn"
        >
          Hired Someone
        </Button>
        <Button className="hirePrompt__btn" onClick={() => setHirePromptOpen(false)}>
          Not Yet
        </Button>
      </div>
    </Dialog>
  );

  const renderNewJobPrompt = () => (
    <Dialog
      open={newJobPromptOpen}
      container={() => document.getElementById('modals-root')}
      classes={{ paper: 'hirePrompt' }}
      onClose={() => setNewJobPromptOpen(false)}
    >
      <Box p="20px">
        <Box component="p" textAlign="center">
          Looks like you don't have any active jobs
        </Box>
      </Box>
      <Box pb="20px" textAlign="center">
        <Button
          variant="filled-primary"
          isRouterLink
          to={ROUTES.employer.job_new}
          className="newJobPrompt__btn"
          disabled={!canPostJob}
        >
          Post a Job
        </Button>
      </Box>
    </Dialog>
  );

  const renderMobileJobCard = (job) => {
    const {
      qmCount = 'N/A',
      total = 'N/A',
      rejectedCount = 'N/A',
      needsActionCount = 'N/A',
      starredCount = 'N/A'
    } = job.applicants;

    const address = getJobAddress(job);
    return (
      <div
        // isRouterLink
        // to={getRoutes({ id: job.id }).employer[job.draft ? 'job_edit' : 'job']}
        className="mobileJobCard"
        {...qaAttr(`job-link-${job.title}`)}
      >
        <Box display="flex" flexDirection="column">
          <img src={job.imageUrl || defaultJobImg} alt="" className="mobileJobCard__photo" />
          <Button
            isRouterLink
            to={getRoutes({ id: job.id }).employer.job_approved}
            variant="filled-primary"
            className="mobileJobCard__hiredBtn"
          >
            Hired
          </Button>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignSelf="normal"
          onClick={() =>
            navigate(getRoutes({ id: job.id }).employer[job.draft ? 'job_edit' : 'job'])
          }
        >
          <Box width="100%" ml="12px" mb="10px" textAlign="left">
            <Box mb="4px" fontSize={11} color="#FFF" fontWeight="bold">
              {job.title}
            </Box>
            {address && (
              <Box mb="13px" fontSize={10} color="#8283BF" style={{ textTransform: 'uppercase' }}>
                {address}
              </Box>
            )}
            <Box mb="5px" display="flex" alignItems="center">
              <Box fontSize={10} lineHeight="12px" color="#A4A3A4">
                {!job.active ? (
                  'Inactive'
                ) : (
                  <div className="mobileJobMetrics">
                    <div>
                      <span>{total}</span> applied
                    </div>
                    <div>
                      <span>{qmCount}</span> matches
                    </div>
                    <div>
                      <span>{needsActionCount}</span> todo
                    </div>
                  </div>
                )}
              </Box>
              {job.draft && <div className="draftLabel">Draft</div>}
            </Box>
            {!job.active && (
              <div>
                <Box mb="2px" fontSize={10}>
                  <span>Opening date: </span>
                  <Box component="span" color="#A4A3A4">
                    {formatDate(Number(job.createdAt), 'MM/dd/yyyy')}
                  </Box>
                </Box>
                <Box mb="5px" fontSize={10}>
                  <span>Closing date: </span>
                  <Box component="span" color="#A4A3A4">
                    {job.closedDate
                      ? formatDate(Number(job.closedDate), 'MM/dd/yyyy')
                      : formatDate(Number(job.updatedAt), 'MM/dd/yyyy')}
                  </Box>
                </Box>
                <Box fontSize={10}>
                  <span>
                    {total || 0} applied{' | '}
                  </span>
                  <span>
                    {qmCount || 0} matches{' | '}
                  </span>
                  <span>{rejectedCount || 0} rejected</span>
                </Box>
              </div>
            )}
          </Box>
          <MdChevronRight className="mobileJobCard__arrow" />
        </Box>
      </div>
    );
  };

  const renderFilter = () => (
    <Box px="16px" pb="13px">
      <Select
        value={employerJobsFilter}
        inputVariant="outlined"
        options={[
          { label: 'Current Jobs', value: 0, ...qaAttr('my-jobs-dropdown-option-button') },
          { label: 'Archived Jobs', value: 1, ...qaAttr('archived-jobs-dropdown-option-button') }
        ]}
        InputComponentProps={{
          htmlInputClassName: 'filterInput'
        }}
        onChange={(e) => setEmployerJobsFilter(e.target.value)}
        testID="jobs-dashboard-dropdown-button"
      />
    </Box>
  );

  const [tableLayout, setTableLayout] = useState('grid');

  function changeTableLayout(e) {
    setTableLayout(e.target.value);
  }

  useEffect(() => {
    setIndexOfFirstJob(0);
    searchTerm === ''
      ? setCurrentJobs(jobs.slice(indexOfFirstJob, indexOfFirstJob + jobsPerPage))
      : setCurrentJobs(filteredJobs.slice(indexOfFirstJob, indexOfFirstJob + jobsPerPage));
  }, [tableLayout]);

  const jobsPerPage = 9;
  const [indexOfFirstJob, setIndexOfFirstJob] = useState(0);
  const [currentJobs, setCurrentJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setCurrentJobs(jobs.slice(indexOfFirstJob, indexOfFirstJob + jobsPerPage));
  }, [jobs]);

  const filteredJobs = jobs.filter((job) => {
    if (isEmpty(searchTerm)) {
      return job;
    } else if (job.title.toLowerCase().includes(searchTerm.toLowerCase())) {
      return job;
    }
  });

  const handleOnChange = (event, value) => {
    const onChangeCurrentPage = value;
    const onChangeIndexOfFirstJob = onChangeCurrentPage * jobsPerPage - jobsPerPage;
    setIndexOfFirstJob(onChangeIndexOfFirstJob);
    searchTerm === ''
      ? setCurrentJobs(jobs.slice(onChangeIndexOfFirstJob, onChangeIndexOfFirstJob + jobsPerPage))
      : setCurrentJobs(
          filteredJobs.slice(onChangeIndexOfFirstJob, onChangeIndexOfFirstJob + jobsPerPage)
        );
  };

  useEffect(() => {
    setCurrentJobs(filteredJobs.slice(indexOfFirstJob, indexOfFirstJob + jobsPerPage));
  }, [searchTerm]);

  return (
    <StyledRoot className="container">
      <h2 className="jobsContainerTitle">My Jobs</h2>
      <div className="employerJobsSearchContainer">
        <TextField
          id="outlined-basic"
          className="employerJobsSearch"
          label="Search Jobs"
          variant="outlined"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />

        {isDesktop && (
          <MuiRadioGroup
            value={tableLayout}
            aria-label="table layout"
            row
            className="layoutRadioGroup"
            onChange={changeTableLayout}
          >
            <FormControlLabel
              value="list"
              control={
                <MuiRadio
                  color="primary"
                  icon={<MdMenu />}
                  checkedIcon={<MdMenu />}
                  inputProps={{
                    'aria-label': 'list layout',
                    ...qaAttr('my-jobs-list-layout-radio-input')
                  }}
                  {...qaAttr('my-jobs-list-layout-radio')}
                  classes={{ root: 'layoutRadio', checked: 'layoutRadio_checked' }}
                />
              }
            />
            <FormControlLabel
              value="grid"
              control={
                <MuiRadio
                  color="primary"
                  icon={<MdGridOn />}
                  checkedIcon={<MdGridOn />}
                  inputProps={{
                    'aria-label': 'grid layout',
                    ...qaAttr('my-jobs-grid-layout-radio-input')
                  }}
                  {...qaAttr('my-jobs-grid-layout-radio')}
                  classes={{ root: 'layoutRadio', checked: 'layoutRadio_checked' }}
                />
              }
            />
          </MuiRadioGroup>
        )}
      </div>
      <div className="resultsTitle">
        {searchTerm ? <span>Showing results for '{searchTerm}'</span> : <span> </span>}
      </div>

      {!isDesktop && renderFilter()}
      <div className={`jobsContainerSmall ${tableLayout}`}>
        {jobsLoading && (
          <div className="jobsLoader">
            <Spinner size={30} />
          </div>
        )}
        {(tableLayout === 'grid' ? currentJobs : filteredJobs).map((job, i) => (
          <div key={`job__${job.id}`} className="cardContainerSmall">
            {isDesktop ? renderDesktopJobCard(job, i) : renderMobileJobCard(job, i)}
          </div>
        ))}
      </div>
      {renderHirePrompt()}
      {renderNewJobPrompt()}
      <div id="modals-root" />
      {tableLayout === 'grid' && (
        <div className="jobsPaginatorWrapper">
          <Pagination
            count={Math.ceil(
              searchTerm !== '' ? filteredJobs.length / jobsPerPage : jobs.length / jobsPerPage
            )}
            boundaryCount={10}
            onChange={handleOnChange}
          />
        </div>
      )}
    </StyledRoot>
  );
}

export default EmployerJobs;
