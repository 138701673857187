import React from 'react';
import PT from 'prop-types';
import { useAppStoreSelector } from 'store';
import AllJobs from './AllJobs';
import MyJobs from './MyJobs';

function NewEmployeeDashboard(props) {
  const employeeJobsTab = useAppStoreSelector((state) => state.employeeJobsTab);

  return employeeJobsTab === 0 ? <AllJobs /> : <MyJobs />;
}

NewEmployeeDashboard.propTypes = {};

export default NewEmployeeDashboard;
