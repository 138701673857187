import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  getRoutes,
  BLOG_URL,
  FAQ_URL,
  TERMS_URL,
  PRIVACY_URL,
  CAREERS_URL,
  DEMO_REQUEST_URL,
  PRESS_EMAIL,
  SUPPORT_EMAIL
} from 'utils';
import { useMatchedRoutes, useAuth } from 'hooks';
import { Box, styled } from 'components';
import { Button, Input, LangSwitchPopup } from 'components/shared';
import { useForm } from 'components/form';
import {
  BrandLogo,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  googlePlayBadgeUrl,
  appStoreBadgeUrl
} from 'assets';

const { LANDING_ORIGIN, PLAY_MARKET_URL, APP_STORE_URL } = process.env;
const ROUTES = getRoutes();

function Footer({ className }) {
  const { authed } = useAuth();
  const { isEmployeeGrowPage } = useMatchedRoutes();

  const { $, set, withValidation } = useForm({
    initial: { email: '' },
    validations: { email: ['presence', 'email'] }
  });

  const submit = withValidation(({ email }) => {
    // @TODO: subscribe
    set({ email: '' });
  });

  const onSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  const changeEmail = (e, { name }) => {
    set(name, e.target.value);
  };

  return (
    <footer className={`${className} footer__container`}>
      <div className="footer__innerContainer">
        <div className="contentContainer-1">
          <div className="logoBlock">
            <div className="footer__logo">
              <a href={LANDING_ORIGIN}>
                <BrandLogo />
              </a>
            </div>
            {!authed && (
              <a className="footer__demoBtn" href={DEMO_REQUEST_URL}>
                Request a demo
              </a>
            )}
          </div>
          <div className="pagesBlock">
            <div className="footer__pagesContainer">
              <h2 className="footer__subTitle">Products</h2>
              <ul className="footer__pagesList">
                <li>
                  <a href={LANDING_ORIGIN}>WorkTorch</a>
                </li>
                <li>
                  <Link to={ROUTES.employee.grow}>WorkTorch Grow</Link>
                </li>
                <li>
                  <a href={TERMS_URL}>Terms of Use</a>
                </li>
              </ul>
            </div>
            <div className="footer__pagesContainer">
              <h2 className="footer__subTitle">About Us</h2>
              <ul className="footer__pagesList">
                {/* <li>
                  <a href="#">About</a>
                </li> */}
                <li>
                  <a href={BLOG_URL}>Blog</a>
                </li>
                <li>
                  <a href={CAREERS_URL}>Careers</a>
                </li>
                <li>
                  <a href={FAQ_URL}>FAQ</a>
                </li>
              </ul>
            </div>
            <div className="footer__pagesContainer">
              <h2 className="footer__subTitle">Contact</h2>
              <ul className="footer__pagesList">
                <li>
                  <a href={`mailto:${SUPPORT_EMAIL}`}>Support</a>
                </li>
                <li>
                  <a href={`mailto:${PRESS_EMAIL}`}>Press Inquiries</a>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="subscribeBlock">
            <form onSubmit={onSubmit} noValidate>
              <h2 className="footer__subTitle">Sign up for our newsletter</h2>
              <Input
                {...$('email', changeEmail)}
                id="email-input"
                type="email"
                placeholder="Email address"
                className="footer__signUpInput"
                withHelperText
              />
              <Box display="flex" justifyContent="flex-end">
                <Button type="submit" className="footer__signUpBtn">
                  Sign-up
                </Button>
              </Box>
            </form>
          </div> */}
        </div>
        <div className="contentContainer-2">
          <div>
            {/* <Box mb="24px">
              <LangSwitchPopup />
            </Box> */}
            <div className="footer__socials">
              <a
                href="https://www.facebook.com/worktorch.io/"
                target="_blank"
                title="facebook"
                rel="noreferrer noopener"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://instagram.com/worktorch?igshid=YmMyMTA2M2Y="
                target="_blank"
                title="instagram"
                rel="noreferrer noopener"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://twitter.com/worktorch?s=21&t=lrde-uz-MAewKMCt7pkcBw"
                target="_blank"
                title="twitter"
                rel="noreferrer noopener"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/worktorch.io/"
                target="_blank"
                title="linkedIn"
                rel="noreferrer noopener"
              >
                <LinkedinIcon />
              </a>
              {/* <a href="" target="_blank" title="youtube" rel="noreferrer noopener">
                <YoutubeIcon />
              </a> */}
            </div>
            {isEmployeeGrowPage && (
              <div className="onetContainer">
                <p>
                  <a
                    href="https://services.onetcenter.org/"
                    title="This site incorporates information from O*NET Web Services. Click to learn more."
                  >
                    <img
                      src="https://www.onetcenter.org/image/link/onet-in-it.svg"
                      style={{ width: 52, height: 24, border: 'none' }}
                      alt="O*NET in-it"
                    />
                  </a>
                </p>
              </div>
            )}
            <div>
              2022 WorkTorch |{' '}
              <a href={PRIVACY_URL} style={{ color: 'inherit' }}>
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="footer__mobileAppBadges">
            <a href={APP_STORE_URL} target="_blank" rel="noreferrer noopener">
              <img height="40" src={appStoreBadgeUrl} alt="" />
            </a>
            <a href={PLAY_MARKET_URL} target="_blank" rel="noreferrer noopener">
              <img width="135" height="40" src={googlePlayBadgeUrl} alt="" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  containerProps: PropTypes.objectOf(PropTypes.any)
};

Footer.defaultProps = {
  className: '',
  containerProps: {}
};

const StyledFooter = styled(Footer)(({ theme }) => ({
  '&.footer__container': {
    padding: '64px 12px 68px',
    color: '#FFF',
    backgroundColor: theme.palette.moreColors.darkPurple_1,
    [theme.breakpoints.down('desktopApp')]: {
      paddingLeft: 26,
      paddingRight: 26
    }
  },
  '& .footer__innerContainer': {
    maxWidth: 1150,
    margin: '0 auto'
  },
  '& .pagesBlock': {
    maxWidth: 600,
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    [theme.breakpoints.down('desktopApp')]: {
      order: 2,
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      [theme.breakpoints.down(768)]: {
        gridTemplateColumns: '1fr 1fr'
      }
    }
  },
  '& .subscribeBlock': {
    [theme.breakpoints.down('desktopApp')]: {
      order: 1
    }
  },
  '& .contentContainer-1': {
    marginBottom: 114,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 90,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 48,
      flexDirection: 'column',
      alignItems: 'center',
      gap: 48
    }
  },
  '& .contentContainer-2': {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > div:first-of-type': {
        marginBottom: 33
      }
    }
  },
  '& .footer__logo': {
    fontWeight: 700,
    fontSize: 37,
    lineHeight: '42px'
  },
  '& .footer__demoBtn': {
    marginTop: 24,
    width: 200,
    height: 44,
    borderRadius: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '20px',
    color: theme.palette.moreColors.darkPurple_1,
    backgroundColor: theme.palette.moreColors.yellow
  },
  '& .footer__subTitle': {
    marginBottom: 24,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '20px'
  },
  '& .footer__pagesContainer': {
    marginBottom: 46
  },
  '& .footer__pagesList': {
    margin: 0,
    padding: 0,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '20px',
    listStyleType: 'none',
    '& li': {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '18px',
      '&:not(:last-of-type)': {
        marginBottom: 16
      },
      '& a': {
        color: theme.palette.moreColors.white_1
      }
    }
  },
  '& .footer__signUpInput': {
    marginBottom: 16,
    '& .StyledInput-inputBase': {
      border: '1px solid #CBD6E2',
      borderRadius: 4,
      backgroundColor: '#FFF'
    },
    '& .StyledInput-inputBase__input': {
      padding: '12px 16px',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '16px',
      color: '#707070'
    }
  },
  '& .footer__signUpBtn': {
    width: 90,
    height: 32,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '16px',
    background: '#D7CEED',
    borderRadius: 8,
    color: theme.palette.moreColors.darkPurple_1
  },
  '& .footer__socials': {
    marginBottom: 24,
    display: 'flex',
    '& a': {
      display: 'flex',
      fontSize: 20,
      color: theme.palette.moreColors.white_1,
      '&:not(:first-of-type)': {
        marginLeft: 20
      }
    },
    [theme.breakpoints.down('desktopApp')]: {
      justifyContent: 'center'
    }
  },
  '& .onetContainer': {
    marginBottom: 24,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  '& .footer__mobileAppBadges': {
    display: 'flex',
    alignItems: 'flex-end',
    '& a': {
      '&:not(:first-of-type)': {
        marginLeft: 16
      }
    }
  }
}));

export default StyledFooter;
