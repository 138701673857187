import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { getUserId } from 'utils';
import getEmployerSubscriptionInfo from 'utils/getEmployerSubscriptionInfo';
import { GET_EMPLOYER_PROFILE, setEmployerSubscriptionVar, employerSubscriptionVar } from 'api';

function useEmployerProfileQuery(props) {
  const { autoFetch = true, ...queryOptions } = props || {};

  const [fetch, result] = useLazyQuery(GET_EMPLOYER_PROFILE, {
    variables: { userId: Number(getUserId()) },
    ...queryOptions
  });
  const { data } = result;
  const profile = data?.employerProfile;
  const {
    allowPlan = false,
    allowJobPosting = false,
    restrictions,
    trialTimePlan,
    showPlanModal,
    subscriptionPlan,
    newSubscriptionPlan
  } = profile || {};
  const {
    jobPosting = false,
    allowEnterprise = false,
    allowEmployeeActions = false,
    numberOfJobPosting = 0
  } = restrictions || {};
  const canPostJob = allowJobPosting && jobPosting;
  const canAllowEnterprise = allowPlan && allowEnterprise;

  const subscriptionInfo = useReactiveVar(employerSubscriptionVar);

  useEffect(() => {
    if (autoFetch) fetch({ ...queryOptions });
  }, [autoFetch]);

  useEffect(() => {
    setEmployerSubscriptionVar(
      getEmployerSubscriptionInfo({
        trialTimePlan,
        showPlanModal,
        subscriptionPlan,
        newSubscriptionPlan
      })
    );
  }, [
    trialTimePlan,
    showPlanModal,
    JSON.stringify(subscriptionPlan),
    JSON.stringify(newSubscriptionPlan)
  ]);

  const model = useMemo(
    () => ({ profile, canAllowEnterprise, canPostJob }),
    [canAllowEnterprise, canPostJob, profile]
  );

  return useMemo(
    () => ({
      fetch,
      subscriptionInfo,
      setSubscriptionInfo: setEmployerSubscriptionVar,
      ...result,
      ...model
    }),
    [fetch, subscriptionInfo, result, model]
  );
}

export default useEmployerProfileQuery;
