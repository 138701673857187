import React from 'react';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import LogoLink from './LogoLink';

// eslint-disable-next-line react/prop-types
function PublicHeader({ className }) {
  return (
    <div className={className}>
      <LogoLink />
      <Box flex={1} />
      <Button isRouterLink to="/login" variant="filled-primary" className="publicHeader__loginBtn">
        Login
      </Button>
    </div>
  );
}

const Styled = styled(PublicHeader)(({ theme }) => ({
  width: '100%',
  padding: '12px 30px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('desktopApp')]: {
    padding: '9px 16px',
    border: 'none'
  },
  '& .publicHeader__loginBtn': {
    width: 120,
    height: 40,
    borderRadius: 20
  }
}));

export default Styled;
