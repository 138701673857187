import React, { useState, useCallback } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { useEmployerProfileQuery } from 'hooks';
import { CloseJobDialog } from 'components/dialogs';
import { CLOSE_JOB } from 'api';

function ToggleJobActiveState(props) {
  const { children, jobId, jobTitle } = props;
  const [closeFeedbackModal, setCloseFeedbackModal] = useState({
    isOpen: false,
    onSuccess: () => {},
    onCancel: () => {}
  });

  const { refetch: refetchEmployerProfile } = useEmployerProfileQuery({ autoFetch: false });

  const [activeJob, { loading = false }] = useMutation(CLOSE_JOB);

  const openJob = useCallback(
    async (args) => {
      const { onSuccess, onError } = args || {};
      try {
        await activeJob({
          variables: {
            id: Number(jobId),
            active: true
          }
        });
        refetchEmployerProfile(); // update restrictions
        if (onSuccess) onSuccess();
      } catch (error) {
        if (onError) onError();
      }
    },
    [activeJob, jobId, refetchEmployerProfile]
  );

  const deactivateJob = useCallback(
    async (args) => {
      const { onSuccess, onError } = args || {};
      try {
        await activeJob({
          variables: {
            id: Number(jobId),
            active: false
          }
        });
        refetchEmployerProfile(); // update restrictions
        if (onSuccess) onSuccess();
      } catch (error) {
        if (onError) onError();
      }
    },
    [activeJob, jobId, refetchEmployerProfile]
  );

  const manageCloseFeedbackModal = useCallback(
    async () =>
      new Promise((resolve, reject) => {
        setCloseFeedbackModal((prev) => ({
          ...prev,
          isOpen: true,
          onSuccess: () => {
            resolve({ success: true });
            setCloseFeedbackModal({ isOpen: false });
          },
          onCancel: () => {
            resolve({ canceled: true });
            setCloseFeedbackModal({ isOpen: false });
          }
        }));
      }),
    []
  );

  const closeJob = useCallback(
    async (args) => {
      const { onSuccess, onError } = args || {};
      const { canceled, success } = await manageCloseFeedbackModal();
      if (success) deactivateJob({ onSuccess, onError });
    },
    [deactivateJob, manageCloseFeedbackModal]
  );

  return (
    <>
      {children({ loading, openJob, closeJob })}
      {closeFeedbackModal.isOpen && (
        <CloseJobDialog
          isOpen={closeFeedbackModal.isOpen}
          jobId={jobId}
          jobTitle={jobTitle}
          loading={loading}
          onSuccess={closeFeedbackModal.onSuccess}
          onClose={closeFeedbackModal.onCancel}
        />
      )}
    </>
  );
}

ToggleJobActiveState.propTypes = {
  children: PT.func.isRequired,
  jobId: PT.number.isRequired,
  jobTitle: PT.string.isRequired
};

export default ToggleJobActiveState;
