import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getRoutes } from 'utils';
import { useAppStoreSelector } from 'store';
import {
  RootLayout,
  EmployeeCareerProgress,
  EmployeeProgressSkills,
  EmployeeProgressConnections,
  EmployeeStillHired,
  EmployeeDashboard,
  EmployeeJob,
  EmployeeGrow,
  EmployerSchedule,
  EmployerProfile,
  EmployeeProfile,
  EmployerPlans,
  EmployerJobs,
  EmployerJobsView,
  EmployerJobDetails,
  EnterpriseDashboard,
  EnterpriseSearch,
  JobPosting,
  NotFound,
  Onboarding,
  SignIn,
  SignUp,
  PasswordReset
} from 'containers';
import { MobileAppBanner } from 'components/shared';
import ProtectedEmployerRoute from './ProtectedEmployerRoute';
import ProtectedEmployeeRoute from './ProtectedEmployeeRoute';
import ProtectedOnboardingRoute from './ProtectedOnboardingRoute';
import EmployerCareerDevelopment from '../components/Grow/employer';
import ScrollToTop from './ScrollToTop';

// const ROUTES = getRoutes();

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<SignIn />} />
        <Route path="login" element={<SignIn />} />
        <Route path="verify" element={<SignIn />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="new-password" element={<PasswordReset />} />
        <Route path="onboarding" element={<ProtectedOnboardingRoute />}>
          <Route index element={<Onboarding />} />
        </Route>
        <Route path="enterprise-dashboard" element={<ProtectedEmployerRoute />}>
          <Route index element={<EnterpriseDashboard />} />
          <Route path="schedule" element={<EmployerSchedule />} />
          <Route path="grow" element={<EmployerCareerDevelopment />} />
          <Route path="search" element={<EnterpriseSearch />} />
        </Route>
        <Route path="employer-dashboard" element={<ProtectedEmployerRoute />}>
          <Route index element={<EmployerJobs />} />
          <Route path="profile" element={<EmployerProfile />} />
          <Route path="plans" element={<EmployerPlans />} />
          <Route path=":id" element={<EmployerJobDetails />}>
            <Route path="match" />
            <Route path="approved" />
            <Route path="starred" />
            <Route path="rejected" />
            <Route path="next-steps" />
          </Route>
          <Route path=":id/edit" element={<JobPosting />} />
          <Route path="new-job" element={<JobPosting />} />
        </Route>
        <Route path="employee-dashboard" element={<ProtectedEmployeeRoute />}>
          <Route index element={<EmployeeDashboard />} />
          <Route path="profile" element={<EmployeeProfile />} />
          <Route path="employer-jobs/:companyId" element={<EmployerJobsView />} />
          <Route path=":id" element={<EmployeeJob />} />
          <Route path="career-development" element={<EmployeeGrow />} />
          <Route path="career-progress" element={<EmployeeCareerProgress />}>
            <Route path="skills" element={<EmployeeProgressSkills />} />
            <Route path="connections" element={<EmployeeProgressConnections />} />
            <Route path="still-hired" element={<EmployeeStillHired />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function AppRouter() {
  const setExternalReferrer = useAppStoreSelector((state) => state.setExternalReferrer);

  useEffect(() => {
    setExternalReferrer(document.referrer);
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRoutes />
      <MobileAppBanner />
    </BrowserRouter>
  );
}

AppRouter.propTypes = {};

export default memo(AppRouter);
