export default ({ theme }) => ({
  '&.card': {
    width: '100%',
    padding: '0 10px',
    border: '1px solid rgba(0,0,0,0.1)',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '16px !important',
    transition: 'box-shadow 300ms linear',
    '&:hover, &:focus': {
      boxShadow: '0 10px 20px 0 rgba(255,255,255, 0.16)'
    }
  },
  '& .card__photoAreaSmall': {
    position: 'relative',
    minHeight: 71,
    width: '25%',
    objectFit: 'cover',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 16
  },
  '& .card__name': {
    marginBottom: 8,
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    wordBreak: 'break-word',
    textAlign: 'left'
  },
  '& .card__stats': {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0
  },
  '& .draftLabel': {
    backgroundColor: theme.palette.background.light,
    color: theme.palette.primary.text,
    borderRadius: 3,
    lineHeight: 1,
    padding: '5px 10px'
  },
  '& .card__dateTitle': {
    fontSize: 12
  },
  '& .card__date': {
    fontSize: 12,
    color: theme.palette.primary.text
  },
  '& .card__metrics': {
    display: 'flex',
    width: '100%',
    color: theme.palette.moreColors.grey_6,
    '& > span': {
      padding: '4px 8px',
      margin: 4,
      borderRadius: 4,
      display: 'flex',
      minWidth: 60,
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: 16,
      fontWeight: 800,
      border: `1px solid ${theme.palette.moreColors.grey_6}`,
      '> span': {
        fontSize: 10
      }
    },
    '& > .anomaly': {
      display: 'flex',
      minWidth: 60,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        height: 7.5,
        width: 7.5,
        borderRadius: 20,
        opacity: 0,
        zIndex: 1
      },
      '&.__success': {
        '&::before': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1
        }
      },
      '&.__error': {
        '&::before': {
          backgroundColor: theme.palette.error.main,
          opacity: 1
        }
      }
    }
  }
});
