import React from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { IconButton, Spinner } from 'components/shared';
import { AddConnectionIcon } from 'components/icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.suggestedConnections__card': {
    padding: 20
  },
  '& .suggestedConnections__item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-of-type)': {
      marginBottom: 15
    }
  },
  '& .suggestedConnections__item-img': {
    minWidth: 55,
    height: 55,
    marginRight: 15,
    borderRadius: '50%',
    objectFit: 'cover'
  },
  '& .suggestedConnections__item-title': {
    fontSize: 12,
    color: '#3D3D3D'
  },
  '& .suggestedConnections__item-descr': {
    fontSize: 10,
    color: '#A4A3A4'
  },
  '& .suggestedConnections__addBtn': {
    color: '#49469D'
  }
}));

function SuggestedConnections(props) {
  const { connections, onAdd, loading, connectionByProfileIdLoading } = props;
  // const arr = connections.slice(0, 3);

  return (
    <StyledRoot className="elevatedCard suggestedConnections__card">
      {(() => {
        if (loading)
          return (
            <Box textAlign="center">
              <Spinner size={20} />
            </Box>
          );

        return connections.length ? (
          connections.map((connection) => {
            const { profile_id, imageUrl, name, companyName, companyPosition } = connection;
            return (
              <div
                key={`suggestedConnection__${profile_id}`}
                className="suggestedConnections__item"
              >
                <Box display="flex" alignItems="center">
                  <img
                    src={imageUrl}
                    alt=""
                    width={55}
                    height={55}
                    className="suggestedConnections__item-img"
                  />
                  <div>
                    <div className="suggestedConnections__item-title">{name}</div>
                    <div className="suggestedConnections__item-descr">
                      {`${companyName} | ${companyPosition}`}
                    </div>
                  </div>
                </Box>
                <IconButton
                  color="primary"
                  width={28}
                  height={28}
                  className="suggestedConnections__addBtn"
                  sx={{ padding: 0, fontSize: 18 }}
                  disabled={connectionByProfileIdLoading === profile_id}
                  onClick={() => onAdd(connection)}
                >
                  {connectionByProfileIdLoading === profile_id ? (
                    <Spinner size={16} />
                  ) : (
                    <AddConnectionIcon fontSize="inherit" />
                  )}
                </IconButton>
              </div>
            );
          })
        ) : (
          <Box textAlign="center" color="#000">
            No Connections at the moment.
          </Box>
        );
      })()}
    </StyledRoot>
  );
}

SuggestedConnections.propTypes = {
  connections: PT.arrayOf(
    PT.shape({
      user_id: PT.number,
      profile_id: PT.number,
      name: PT.string,
      imageUrl: PT.string,
      experience: PT.arrayOf(PT.any),
      companyName: PT.string,
      companyPosition: PT.string,
      starred: PT.bool
    })
  ).isRequired,
  onAdd: PT.func.isRequired,
  loading: PT.bool,
  connectionByProfileIdLoading: PT.bool
};

SuggestedConnections.defaultProps = {
  loading: false,
  connectionByProfileIdLoading: false
};

export default SuggestedConnections;
