import React, { useState, useEffect } from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { employerStartPage, employeeStartPage, getRoutes, saveRequiredUserValues } from 'utils';
import { Spinner } from 'components/shared';
import { useAuth, useEmployerProfileQuery, useEmployeeProfileQuery } from 'hooks';
import { OnboardingLayout } from 'containers';

const ROUTES = getRoutes();

function ProtectedOnboardingRoute() {
  const { authed } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetch: fetchEmployerProfile } = useEmployerProfileQuery({ autoFetch: false });
  const { fetch: fetchEmployeeProfile } = useEmployeeProfileQuery({ autoFetch: false });

  const [isUserRoleChecked, setIsUserRoleChecked] = useState(false);

  const checkProfileByRole = async () => {
    const { data: employerData } = await fetchEmployerProfile();

    if (employerData?.employerProfile) {
      const { profileWizard, regStep } = employerData.employerProfile;

      if (profileWizard) {
        saveRequiredUserValues(employerData.employeeProfile);
        navigate(employerStartPage);
        return false;
      }

      if (employerData.employeeProfile.profile_id) {
        saveRequiredUserValues(employerData.employeeProfile);
      }

      if (regStep) {
        navigate(ROUTES.onboarding.default, {
          replace: true,
          state: { regStep: `employer_${regStep}` }
        });
      }
      return true;
    }

    const { data: employeeData } = await fetchEmployeeProfile();

    if (employeeData?.employeeProfile) {
      const { profileWizard, regStep } = employeeData.employeeProfile;

      if (profileWizard) {
        saveRequiredUserValues(employeeData.employeeProfile);
        navigate(employeeStartPage);
        return false;
      }

      if (employeeData.employeeProfile.profile_id) {
        saveRequiredUserValues(employeeData.employeeProfile);
      }

      if (regStep) {
        navigate(ROUTES.onboarding.default, {
          replace: true,
          state: { regStep: `employee_${regStep}` }
        });
      }
      return true;
    }

    return true;
  };

  const authMiddleware = async () => {
    if (authed) {
      const checked = await checkProfileByRole();
      setIsUserRoleChecked(checked);
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  const renderContent = () =>
    isUserRoleChecked ? (
      <OnboardingLayout>
        <Outlet />
      </OnboardingLayout>
    ) : (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner size={30} />
      </div>
    );

  return authed ? renderContent() : <Navigate to={ROUTES.login.default} />;
}

export default ProtectedOnboardingRoute;
