import React, { useState, useEffect } from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { getLocaleTimeZone, getRoutes, employeeStartPage, saveRequiredUserValues } from 'utils';
import { group, identify } from 'utils/segmentAnalytics';
import { useTimezoneCommands, useAuth, useEmployerProfileQuery } from 'hooks';
import { Spinner } from 'components/shared';
import { PrivateLayout } from 'containers';

const ROUTES = getRoutes();

function ProtectedEmployerRoute() {
  const { authed, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { saveTimezone } = useTimezoneCommands();
  const { fetch: fetchEmployerProfile } = useEmployerProfileQuery();

  const [isUserRoleChecked, setIsUserRoleChecked] = useState(false);

  const initUserInSegment = (profile) => {
    identify({ name: profile?.name, email: profile?.email, profileType: 'employer' });
    group(
      {
        address: profile?.address,
        industry: profile?.industry,
        size: profile?.size,
        jobCount: profile?.jobCount
      },
      'employer'
    );
  };

  const checkProfileByRole = async () => {
    const { data } = await fetchEmployerProfile();
    const profile = data?.employerProfile;

    if (profile) {
      const { timeZone, profileWizard } = profile;
      if (!timeZone) saveTimezone(getLocaleTimeZone(), 'employer');
      initUserInSegment(profile);

      if (profileWizard) saveRequiredUserValues(profile);

      return {
        checked: profileWizard,
        cb: profileWizard ? undefined : () => navigate(ROUTES.onboarding.default)
      };
    }

    return {
      checked: false,
      cb: !location?.state?.notEmployee
        ? // check if user is an employee if he wasn't checked before
          () => navigate(employeeStartPage, { state: { notEmployer: true } })
        : logout
    };
  };

  const authMiddleware = async () => {
    if (authed) {
      const { checked, cb } = await checkProfileByRole();
      setIsUserRoleChecked(checked);
      if (cb) cb();
    } else {
      navigate(ROUTES.login.default, { state: { from: { location } } });
    }
  };

  useEffect(() => {
    authMiddleware();
  }, []);

  const renderContent = () =>
    isUserRoleChecked ? (
      <PrivateLayout userRole="employer">
        <Outlet />
      </PrivateLayout>
    ) : (
      <div
        style={{
          position: 'fixed',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Spinner size={30} />
      </div>
    );

  return authed ? renderContent() : <Navigate to={ROUTES.login.default} />;
}

export default ProtectedEmployerRoute;
