import React, { useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { Dialog, styled } from 'components';
import { IconButton, Spinner } from 'components/shared';
import { MdClose, MdErrorOutline } from 'components/icons';
import { getUserId } from 'utils';
import { GET_APPROVED_JOBS_MESSAGES, GET_APPROVED_APPLICANTS_MESSAGES } from 'api';
import styles from 'styles/Chat/ChatDialog';
import Chat from './Chat';
import ChatItem from './ChatItem';
import ChatDialogHeader from './ChatDialogHeader';

const StyledDialog = styled(Dialog)(styles);

const VIEWS_MAP = {
  messages: 'matches',
  chat: 'chat'
};

const sortMessages = (messages) => {
  const fullChats = messages.filter((o) => o.message);
  const sortedByDate = sortBy(fullChats, (o) => new Date(o.updatedAt).valueOf());
  const sortedByNew = sortBy(sortedByDate, (o) => o.messagesCount > 0).reverse();
  return sortedByNew;
};

function ChatDialog(props) {
  const { dialogProps, isOpen, initialView, initialChatViewProps, onClose, role } = props;

  const [view, setView] = useState(initialView);
  const [chatViewProps, setChatViewProps] = useState(initialChatViewProps);
  const [isTipOpen, setIsTipOpen] = useState(role === 'employee');

  const [fetchEmployeeMessages, { data: employeeMessagesData, loading: employeeMessagesLoading }] =
    useLazyQuery(GET_APPROVED_JOBS_MESSAGES, { fetchPolicy: 'no-cache' });
  const employeeMessages = useMemo(() => {
    const messages = employeeMessagesData?.getApprovedJobMessages || [];
    return sortMessages(messages);
  }, [JSON.stringify(employeeMessagesData)]);

  const [fetchEmployerMessages, { data: employerMessagesData, loading: employerMessagesLoading }] =
    useLazyQuery(GET_APPROVED_APPLICANTS_MESSAGES, { fetchPolicy: 'no-cache' });
  const employerMessages = useMemo(() => {
    const messages = employerMessagesData?.getApprovedEmployerJobMessages || [];
    return sortMessages(messages);
  }, [JSON.stringify(employerMessagesData)]);

  useEffect(() => {
    if (view === VIEWS_MAP.messages) {
      const uid = getUserId();
      if (role === 'employer') fetchEmployerMessages({ variables: { usersUserId: Number(uid) } });
      else fetchEmployeeMessages({ variables: { usersUserId: Number(uid) } });
    }
  }, [view, role]);

  const renderMessagesList = () => {
    const messages = role === 'employer' ? employerMessages : employeeMessages;
    const isLoading = employeeMessagesLoading || employerMessagesLoading;

    return (
      <>
        <ChatDialogHeader title="Inbox" withCloseButton onClose={onClose} />
        {isLoading || !messages.length ? (
          <div className="messagesContent empty">
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="emptyMessagesMessage">{`No matches yet. ${
                role === 'employee' ? 'Apply for jobs first to get some matches' : ''
              }`}</div>
            )}
          </div>
        ) : (
          <div className="messagesContent">
            {messages.map((obj) => (
              <ChatItem
                key={obj.id}
                role={role}
                imageUrl={obj?.users?.employeeProfile?.imageUrl || ''}
                title={obj?.jobs?.title || ''}
                subTitle={obj?.users?.employeeProfile?.name || ''}
                message={obj.message}
                messagesCount={obj.messagesCount}
                onClick={() => {
                  setChatViewProps({
                    jobId: obj?.jobs?.id,
                    receiverUserId: role === 'employer' ? obj.messageFrom : obj.messageTo
                  });
                  setView(VIEWS_MAP.chat);
                }}
              />
            ))}
            {isTipOpen && (
              <div className="tipMessage">
                <MdErrorOutline color="inherit" />
                <p className="tipMessage__text">
                  A response within 48 hours will increase your chances for hire
                </p>
                <IconButton
                  edge="end"
                  color="primary"
                  sx={{ ml: '20px', mr: '-10px', p: '10px' }}
                  onClick={() => setIsTipOpen(false)}
                >
                  <MdClose color="inherit" />
                </IconButton>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <StyledDialog
      open={isOpen}
      fullWidth
      classes={{ paper: 'paper', container: 'container' }}
      BackdropProps={{
        classes: { root: 'backdrop' }
      }}
      scroll="paper"
      onClose={onClose}
      {...dialogProps}
    >
      {view === VIEWS_MAP.messages ? (
        renderMessagesList()
      ) : (
        <Chat role={role} onClose={() => setView(VIEWS_MAP.messages)} {...chatViewProps} />
      )}
    </StyledDialog>
  );
}

ChatDialog.propTypes = {
  dialogProps: PT.objectOf(PT.any),
  isOpen: PT.bool.isRequired,
  initialView: PT.oneOf(Object.values(VIEWS_MAP)),
  initialChatViewProps: PT.shape({
    jobId: PT.number,
    receiverUserId: PT.number
  }),
  onClose: PT.func.isRequired,
  role: PT.oneOf(['employee', 'employer']).isRequired
};

ChatDialog.defaultProps = {
  dialogProps: {},
  initialChatViewProps: {},
  initialView: VIEWS_MAP.messages
};

export default ChatDialog;
