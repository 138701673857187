import React, { useContext, useEffect, useRef, useState } from 'react';
import PT from 'prop-types';
import { useApolloClient } from '@apollo/client';
import {
  CategoryScale,
  Chart,
  BarController,
  BarElement,
  Legend,
  LinearScale,
  LineController
} from 'chart.js';
import { styled } from 'components';
import { getBarChartData } from 'components/Grow/employer/charts/util';
import { Spinner } from 'components/shared';
import EmployerGrowContext from '../../EmployerGrowContext';

Chart.register(CategoryScale, BarController, BarElement, Legend, LinearScale, LineController);

const StyledRoot = styled('div')(({ theme }) => ({
  '&.tenureChart': {},
  '& .tenureChart__content': {
    flex: 1,
    paddingTop: 20,
    maxWidth: 'calc(1024px / 3 - 100)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(0.875)'
  },
  '& .tenureChart__canvas': {
    width: '100%',
    height: '100%'
  }
}));

function EmployeeTenure({ showLegend, chartLabels, chartData }) {
  const client = useApolloClient();
  // const [chartData, setChartData] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const chartCtxRef = useRef(null);
  const chartRef = useRef(null);
  const { jobTypes } = useContext(EmployerGrowContext);

  //{'jobTypeName': count}
  // const fetchEmployeeTenure = async () => {
  //   setIsDataLoading(true);

  //   const employeeTenureData = await makeQueryFetch(
  //     {
  //       query: growApi.query.EMPLOYEE_TENURE_BY_EMPLOYER,
  //       path: 'employeeTenureByEmployer'
  //     },
  //     client
  //   ).then((data) =>
  //     // map job type id to job type name
  //     data
  //       .map((emp) => ({
  //         ...emp,
  //         curJobType: get(jobTypes, `${[emp.curJobTypeId]}.name`, 'N/A')
  //       }))
  //       // reduce to {[jobTypeName]: [tenureByMonthsToDate]}
  //       .reduce(
  //         (acc, emp) => ({
  //           ...acc,
  //           // [emp.curJobType || emp.currentPosition]: [
  //           [emp.curJobType]: [
  //             ...(acc[emp.curJobType || emp.currentPosition] || []),
  //             differenceInCalendarMonths(new Date(), new Date(emp.startDate))
  //           ]
  //         }),
  //         {}
  //       )
  //   );
  //   // format chart data by averaging month values and turning the data into
  //   // {labels: [label: string], data: [averageMonth: number]}
  //   const formattedChartData = Object.keys(employeeTenureData).reduce(
  //     (acc, key) => ({
  //       labels: [...acc.labels, key],
  //       data: [...acc.data, calculateAverageTenure(employeeTenureData[key])]
  //     }),
  //     { labels: [], data: [] }
  //   );
  //   setChartData(formattedChartData);
  //   setIsDataLoading(false);
  // };

  useEffect(() => {
    // fetchEmployeeTenure();
  }, []);

  useEffect(() => {
    if (chartCtxRef.current && chartData != null) {
      if (chartRef.current) chartRef.current.destroy();

      chartRef.current = new Chart(chartCtxRef.current, {
        type: 'bar',
        data: {
          // ...getBarChartData({ ...chartData, rootLabel: '# of Months' })
          ...getBarChartData({ data: chartData, labels: chartLabels, rootLabel: '# of Months' })
        },
        // ln 3553
        options: {
          indexAxis: 'x',
          plugins: {
            legend: {
              display: showLegend,
              position: 'bottom',
              labels: {
                color: '#ffffff'
              }
            }
          },
          scales: {
            x: {
              grid: {
                color: 'rgba(255,255,255,0.1)',
                borderColor: 'white'
              },
            },
            y: {
              grid: {
                color: 'rgba(255,255,255,0.1)',
                borderColor: 'white'
              }
            }
          }
        }
      });
    }
  }, [chartCtxRef.current, chartData]);

  return (
    <StyledRoot className="tenureChart chartCard">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Avg Tenure</h4>
      <div className="tenureChart__content">
        <canvas id="tenure-chart" height="225" className="tenureChart__canvas" ref={chartCtxRef} />
      </div>
    </StyledRoot>
  );
}

EmployeeTenure.propTypes = {
  showLegend: PT.bool
};

EmployeeTenure.defaultProps = {
  showLegend: true
};

export default EmployeeTenure;
