import React from 'react';
import PT from 'prop-types';
import map from 'lodash/map';
import { styled } from 'components';
import { GrowBadgeIcon17 } from 'components/icons';
import PageHeader from '../PageHeader';
import ResourceCard from './ResourceCard';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .quizzesContainer': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 18
  }
}));

function QuizzesPage({ quizzes }) {
  return (
    <StyledRoot>
      <PageHeader
        title="Quizzes"
        subtitle="Jumpstart your career today"
        icon={<GrowBadgeIcon17 sx={{ fill: '#EFB954' }} />}
      />
      <div className="quizzesContainer">
        {map(quizzes, ({ name, value }, i) => (
          <ResourceCard key={`quiz__${i}`} title={name} link={value} />
        ))}
      </div>
    </StyledRoot>
  );
}

QuizzesPage.propTypes = {
  quizzes: PT.arrayOf(
    PT.shape({
      name: PT.string,
      value: PT.string
    })
  ).isRequired
};

export default QuizzesPage;
