import { gql } from '@apollo/client';

export const SEARCH_APPLICANTS = gql`
  query searchCandidates($jobsId: Int!, $query: String!, $type: String!) {
    searchCandidates(jobsId: $jobsId, query: $query, type: $type) {
      usersUserId
      id
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      userToJobsNotes
      users {
        createdAt
        updatedAt
        user_id
        message
        employeeProfile {
          education {
            endDate
            name
            pos
            startDate
          }
          gender
          imageUrl
          motto
          name
          profile_id
          resumeUrl
          videoUrl
          zip
        }
      }
    }
  }
`;
export const SEARCH_QUICK_MATCHES = gql`
  query searchQuickMatchCandidates($jobId: Int!, $query: String) {
    searchQuickMatchCandidates(jobId: $jobId, query: $query) {
      createdAt
      updatedAt
      user_id
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      employeeProfile {
        imageUrl
        motto
        name
        profile_id
        race
        zip
      }
    }
  }
`;

export const GET_ALL_APPLICANTS = gql`
  query allApplicants($jobId: Int!) {
    allApplicants(jobId: $jobId) {
      approved
      id
      passed
      starred
      users {
        employeeProfile {
          name
          profile_id
        }
        user_id
      }
    }
  }
`;

export const GET_APPLICANTS = gql`
  query applicants($jobId: Int!) {
    applicants(jobId: $jobId) {
      createdAt
      updatedAt
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      id
      jobs {
        questions {
          answer
          name
          questions_id
        }
      }
      users {
        employeeProfile {
          education {
            endDate
            name
            pos
            startDate
          }
          gender
          imageUrl
          motto
          name
          profile_id
          resumeUrl
          videoUrl
          zip
        }
        user_id
      }
    }
  }
`;

export const GET_QUICK_MATCH = gql`
  query quickmatch($jobId: Int!) {
    quickmatch(jobId: $jobId) {
      createdAt
      updatedAt
      user_id
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      employeeProfile {
        imageUrl
        motto
        name
        profile_id
        race
        zip
      }
    }
  }
`;

export const GET_APPROVED = gql`
  query approved($jobId: Int!) {
    approved(jobId: $jobId) {
      createdAt
      updatedAt
      id
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      users {
        employeeProfile {
          education {
            endDate
            name
            pos
            startDate
          }
          gender
          imageUrl
          motto
          name
          profile_id
          resumeUrl
          user_id
          videoUrl
          zip
        }
        message
        user_id
      }
    }
  }
`;

export const GET_REJECTED = gql`
  query rejected($jobId: Int!) {
    rejected(jobId: $jobId) {
      createdAt
      updatedAt
      id
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      users {
        employeeProfile {
          education {
            endDate
            name
            pos
            startDate
          }
          gender
          imageUrl
          motto
          name
          profile_id
          resumeUrl
          videoUrl
          zip
        }
        message
        user_id
      }
    }
  }
`;

export const GET_STARRED = gql`
  query starred($jobId: Int!) {
    starred(jobId: $jobId) {
      createdAt
      updatedAt
      id
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      users {
        user_id
        employeeProfile {
          imageUrl
          motto
          name
          profile_id
          race
          zip
        }
      }
    }
  }
`;

export const GET_NEXT_STEPS_APPLICANTS = gql`
  query nextSteps($jobId: Int!) {
    nextSteps(jobId: $jobId) {
      createdAt
      updatedAt
      id
      approved
      approvedJob
      passed
      passedJob
      starred
      starredJob
      userToJobsNotes
      users {
        user_id
        employeeProfile {
          imageUrl
          motto
          name
          profile_id
          race
          zip
        }
      }
    }
  }
`;

export const GET_SEARCH_MATCH = gql`
  query searchmatch($query: String!, $limit: Int, $offset: Int) {
    searchmatch(query: $query, limit: $limit, offset: $offset) {
      createdAt
      updatedAt
      usersCount
      user_id
      employeeProfile {
        imageUrl
        motto
        name
        profile_id
        zip
        videoUrl
        introVideoUrl
        resumeUrl
        website
        notes
        industry {
          industry_id
          name
        }
        education {
          education_id
          name
          pos
          startDate
          endDate
          currentJob
        }
        experience {
          experience_id
          name
          pos
          startDate
          endDate
          currentJob
          years
        }
        skills {
          skill_id
          name
        }
      }
    }
  }
`;
