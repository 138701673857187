import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef
} from 'react';
import PT from 'prop-types';
import { useApolloClient } from '@apollo/client';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import find from 'lodash/find';
import { Box, InputAdornment } from 'components';
import { Autocomplete, IconButton } from 'components/shared';
import { MdClose, MdSearch } from 'components/icons';
import { SEARCH_APPLICANTS, SEARCH_QUICK_MATCHES } from 'api';
import { getApplicantUserId, getApplicantProfile } from './utils';
import {
  QH_MATCHES_TAB,
  APPLICANTS_TAB,
  APPROVED_TAB,
  REJECTED_TAB,
  STARRED_TAB,
  NEXT_STEPS_TAB
} from './data';

const getSuggestionValue = (suggestion, suggestionType) =>
  suggestionType === QH_MATCHES_TAB
    ? get(suggestion, 'data.employeeProfile.name')
    : get(suggestion, 'data.users.employeeProfile.name');

const ApplicantsSearch = forwardRef((props, ref) => {
  const { type, jobId, onSelect, onClear } = props;
  const client = useApolloClient();

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useRef(() => {});

  const handleSearch = useCallback(
    async (query, { setSuggestions, searchReason }) => {
      const applicantsResult = await client.query({
        query: SEARCH_APPLICANTS,
        variables: { query, type, jobsId: Number(jobId) },
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      });
      const applicants = applicantsResult?.data?.searchCandidates || [];
      const filled = applicants.filter(getApplicantProfile).map((o) => ({ type, data: o }));

      const qmResult =
        type === APPLICANTS_TAB
          ? await client.query({
              query: SEARCH_QUICK_MATCHES,
              variables: { query, jobId: Number(jobId) },
              fetchPolicy: 'no-cache',
              errorPolicy: 'all'
            })
          : [];
      const qmApplicants = qmResult?.data?.searchQuickMatchCandidates || [];
      const filledQmApplicants = qmApplicants
        .filter((o) => {
          const uniq = !find(
            filled,
            (obj) => getApplicantUserId(obj.data) === getApplicantUserId(o)
          );
          return getApplicantProfile(o) && uniq;
        })
        .map((o) => ({ type: QH_MATCHES_TAB, data: o }));

      setSuggestions([...filled, ...filledQmApplicants]);
    },
    [type]
  );

  useEffect(() => {
    debouncedSearch.current = debounce(handleSearch, 500);
  }, [handleSearch]);

  const handleSuggestionSelect = useCallback(
    (e, params) => {
      const { suggestion, suggestionValue, suggestionIndex, sectionIndex, method } = params;
      onSelect(suggestion);
    },
    [onSelect]
  );

  const handleChange = useCallback((e, { newValue, method }) => {
    setSearchQuery(newValue);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchQuery('');
    onClear();
  }, [onClear]);

  const shouldRenderSuggestions = useCallback((valueParam) => valueParam.trim().length > 2, []);

  const suggestionValueGetter = useCallback(
    (suggestion) => getSuggestionValue(suggestion, suggestion.type),
    []
  );

  const renderSuggestion = useCallback(
    (suggestion, { query, isHighlighted }) => (
      <Box display="flex" justifyContent="space-between">
        <div>{getSuggestionValue(suggestion, suggestion.type)}</div>
        {suggestion.type === QH_MATCHES_TAB && <Box color="primary.main">WT Match</Box>}
      </Box>
    ),
    []
  );

  const getCurrentValue = useCallback(() => searchQuery, [searchQuery]);

  useImperativeHandle(
    ref,
    () => ({
      clearSearch,
      getCurrentValue
    }),
    [getCurrentValue, clearSearch]
  );

  return (
    <Autocomplete
      nativeInputProps={{
        value: searchQuery,
        onChange: handleChange,
        placeholder: 'Search by Applicant',
        id: 'applicants-search'
      }}
      inputCompProps={{
        testID: 'applicants-search',
        sx: { paddingLeft: '16px' },
        startAdornment: (
          <InputAdornment position="start" sx={{ color: 'rgba(255,255,255, 0.5)' }}>
            <MdSearch color="inherit" />
          </InputAdornment>
        ),
        endAdornment:
          searchQuery.length > 0 ? (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                onClick={clearSearch}
                testID="applicants-search-clear-button"
              >
                <MdClose color="inherit" />
              </IconButton>
            </InputAdornment>
          ) : null
      }}
      getSuggestionValue={suggestionValueGetter}
      getSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      onSuggestionSelected={handleSuggestionSelect}
      onFetch={debouncedSearch.current}
    />
  );
});

ApplicantsSearch.propTypes = {
  type: PT.oneOf([
    QH_MATCHES_TAB,
    APPLICANTS_TAB,
    APPROVED_TAB,
    REJECTED_TAB,
    STARRED_TAB,
    NEXT_STEPS_TAB
  ]).isRequired,
  jobId: PT.number.isRequired,
  onSelect: PT.func.isRequired,
  onClear: PT.func
};

ApplicantsSearch.defaultProps = {
  onClear: () => {}
};

export default ApplicantsSearch;
