import React from 'react';
import { makeForm } from 'components/form';
import {
  DEFAULT_SCHEDULE_CRITERIA,
  MIN_COUNT,
  getInitialEmployerSchedule
} from 'utils/schedules/schedules';

const [FormProvider, useSchedulesForm] = makeForm({
  initial: {
    schedules: [getInitialEmployerSchedule()],
    criteria: { ...DEFAULT_SCHEDULE_CRITERIA }
  },
  validations: {
    'schedules.*.count': { numericality: { greaterThanOrEqual: MIN_COUNT } },
    'schedules.*.length': 'presence',
    'schedules.*.startDate': {
      rules: ['presence', 'date'],
      deps: ['schedules.*.endDate']
    }
    // 'schedules.*.endDate': {
    //   rules: [
    //     'presence',
    //     function (value, { name, attrs }) {
    //       const index = +name.split('.')[1];
    //       const startDate = attrs.schedules[index].startDate;

    //       if (!startDate) return;

    //       if (isAfter(startDate, value) || isSameDay(startDate, value)) {
    //         return `Should be after start date`;
    //       }
    //     }
    //   ],
    //   deps: ['schedules.*.startDate']
    // }
    // 'schedules.*.active': [{ minLength: { min: 1, message: 'Schedule should not be empty' } }]
  }
});

export { FormProvider, useSchedulesForm };
