export default ({ theme }) => ({
  zIndex: 2000,
  // '-webkit-filter': 'blur(3px)',
  // filter: 'blur(3px)',
  alignItems: 'normal',
  justifyContent: 'normal',
  backdropFilter: 'blur(2px)',
  backgroundColor: 'rgba(255,255,255,0.6)',
  overflow: 'auto',
  '& .contentContainer': {
    width: '100%',
    maxWidth: 382,
    margin: '0 auto 24px',
    paddingTop: 123
  },
  '& .deckContainer': {
    marginBottom: 24,
    paddingTop: 24,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  '& .text': {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: 0,
    color: theme.palette.primary.white,
    textShadow: '0 0 14px 0 rgba(255,255,255, 0.5)'
  },
  '& .actions': {
    width: '100%',
    padding: '0 16px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .actionTip': {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  }
});
