export default ({ theme }) => ({
  '&.jobDashboardContainer': {
    position: 'relative',
    width: '100%',
    display: 'flex',
    paddingLeft: 30,
    flex: 1,
    overflow: 'hidden',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0,
      flexDirection: 'column',
      overflowX: 'hidden',
      overflowY: 'auto'
    }
  },
  '& .jobDashboardMobHeader': {
    width: '100%',
    padding: '4px 16px',
    paddingRight: 36,
    display: 'flex',
    alignItems: 'center'
  },
  '& .jobDashboardMobHeader__titleWrapper': {
    width: '100%',
    textAlign: 'center'
  },
  '& .applicantsContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('desktopApp')]: {
      margin: 0,
      padding: '0 16px',
      flex: 1
    }
  },
  '& .detailsCardWrapper': {
    width: 300,
    minWidth: 300,
    overflow: 'auto'
  },
  '& .detailsCard': {
    borderRadius: 16,
    backgroundColor: theme.palette.background.darkTransparent,
    opacity: 1,
    transition: 'opacity 0.4s linear'
  },
  '& .detailsCard_transparent': {
    opacity: 0.5
  },
  '& .detailsCard__photo': {
    width: '100%',
    height: 171,
    display: 'block',
    borderRadius: '16px 16px 0 0',
    objectFit: 'cover'
  },
  '& .detailsCard__title': {
    fontWeight: 'bold',
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    wordBreak: 'break-word'
  },
  '& .textMain': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0
  },
  '& .textSecondary': {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: 0,
    opacity: 0.4
  },
  '& .jobEditBtn': {
    padding: '6px 16px',
    fontSize: 10,
    borderRadius: 4
  },
  '& .hiredLink': {
    marginBottom: 23,
    fontSize: 12
  },
  '& .selectorPopupPaper': {
    maxWidth: 253,
    boxShadow: `0 8px 24px 0 ${theme.palette.background.darkTransparent}`,
    [theme.breakpoints.down('desktopApp')]: {
      borderRadius: 5
    }
  },
  '& .selectorTriggerBtn': {
    color: theme.palette.primary.text
  },
  '& .selectorBtn': {
    width: '100%',
    height: 50,
    textAlign: 'center',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: theme.palette.primary.text,
    borderRadius: '0px !important',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(255,255,255,0.2)'
    },
    [theme.breakpoints.down('desktopApp')]: {
      height: 50,
      fontSize: 16,
      lineHeight: '18px'
    }
  },
  '& .title': {
    fontSize: 16,
    lineHeight: '18px'
  },
  '& .jobLoader': {
    width: '100%',
    position: 'absolute',
    inset: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
