import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon17(props) {
  return (
    <SvgIcon viewBox="0 0 41.4 40.9" enableBackground="new 0 0 41.4 40.9" x="0" y="0" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M-55,34.9c-6.2-9.8-5.1-25.9,4.6-35.2
C-71.7,6.1-84.4,41.1-55,34.9z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M-44.3,30.9c-7.2-7.3-7.5-19.5-5.7-29.9
C-59.4,10.6-60.7,37.1-44.3,30.9z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M-39.9,26.5c-4.3-5.3-7.6-13.4-9.2-20.8
C-50.3,11.6-48.5,31-39.9,26.5z"
        />
      </g>
    </SvgIcon>
  );
}

export default GrowBadgeIcon17;
