import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { Box, styled } from 'components';
import { SimpleRating } from 'components/shared';
import { employerDefaultImg } from 'assets';

const compName = 'CompanyProfile';
const classes = {
  container: `${compName}-container`,
  image: `${compName}-image`,
  industries: `${compName}-industries`,
  title: `${compName}-title`,
  address: `${compName}-address`,
  jobs: `${compName}-jobs`,
  reviews: `${compName}-reviews`
};
const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'column'
    }
  },
  [`& .${classes.image}`]: {
    objectFit: 'cover',
    width: 224,
    height: 198,
    marginRight: 32,
    [theme.breakpoints.down('desktopApp')]: {
      width: 64,
      height: 56
    }
  },
  [`& .${classes.industries}`]: {
    marginBottom: 16,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '130%',
    color: theme.palette.primary.main
  },
  [`& .${classes.title}`]: {
    marginBottom: 8,
    fontWeight: 700,
    fontSize: 31,
    lineHeight: '130%',
    color: theme.palette.primary.main
  },
  [`& .${classes.address}`]: {
    marginBottom: 16,
    fontWeight: 800,
    fontSize: 18,
    lineHeight: '150%',
    color: theme.palette.moreColors.grey_3
  },
  [`& .${classes.jobs}`]: {
    marginBottom: 12,
    fontWeight: 700,
    fontSize: 25,
    lineHeight: '130%',
    color: theme.palette.primary.dark
  },
  [`& .${classes.reviews}`]: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '150%',
    color: theme.palette.moreColors.grey_3
  },
  [`& .SimpleRating-container`]: {
    marginRight: 16
  }
}));

function CompanyProfile(props) {
  const { className, imageUrl, industries, name, address, openJobsCount, reviewsCount, rating } =
    props;
  return (
    <StyledRoot className={clsx(className, classes.container)}>
      <img src={imageUrl || employerDefaultImg} alt="" className={classes.image} />
      <div>
        <div className={classes.industries}>{industries?.map((o) => o.name).join(', ')}</div>
        <h3 className={classes.title}>{name}</h3>
        <div className={classes.address}>{address}</div>
        <div className={classes.jobs}>{`${openJobsCount} open jobs`}</div>
        <Box display="flex" alignItems="center">
          <SimpleRating rating={rating} />
          <span className={classes.reviews}>{`${reviewsCount} reviews`}</span>
        </Box>
      </div>
    </StyledRoot>
  );
}

CompanyProfile.propTypes = {
  className: PT.string,
  name: PT.string.isRequired,
  imageUrl: PT.string.isRequired,
  address: PT.string.isRequired,
  openJobsCount: PT.number.isRequired,
  reviewsCount: PT.number.isRequired,
  rating: PT.number.isRequired,
  industries: PT.arrayOf(
    PT.shape({
      name: PT.string
    })
  ).isRequired
};

CompanyProfile.defaultProps = {
  className: ''
};

export default CompanyProfile;
