import React, { useState, useContext } from 'react';
import PT from 'prop-types';
import { useQuery } from '@apollo/client';
import map from 'lodash/map';
import clsx from 'clsx';
import { qaAttr } from 'utils';
import { Box, styled } from 'components';
import { MdExpandMore } from 'components/icons';
import { Select } from 'components/shared';
import styles from 'styles/Dashboard/EmployerShedule';
import { useSchedulesForm } from 'containers/employer/EmployerScheduleFormProvider';
import EmployerScheduleContext from 'containers/employer/EmployerScheduleContext';
import { GET_INDUSTRY, GET_SKILLS } from 'api';
import { criteriaFilters } from './data';

const StyledRoot = styled('div')(styles);

function Criteria() {
  const { selectedScheduleIdx, updateScheduleCtx } = useContext(EmployerScheduleContext);
  const { attrs, set } = useSchedulesForm();

  const { data: industriesData } = useQuery(GET_INDUSTRY);
  const industries = industriesData?.getIndustry || [];
  const { data: skillsData } = useQuery(GET_SKILLS);
  const skills = skillsData?.getSkills || [];

  // const currentSchedule = attrs.schedules?.[selectedScheduleIdx] || {};

  const handleOnChange = (e, criteria) => {
    set('criteria', {
      ...attrs.criteria,
      [criteria]: e.target.value || null
    });
  };

  return (
    <StyledRoot className="criteriaContentContainer">
      <p style={{ marginBottom: 16 }}>
        You can limit the amount of applicants that can schedule their own interviews by the
        following criteria. The more you limit, the less applicants will be able to apply, so be
        careful not to limit too much!
      </p>

      <div key="criteria__industry" className="criteriaSelectWrapper">
        <label htmlFor="industry">By Industry:</label>
        <Select
          id="industry"
          name="industry"
          value={attrs.criteria.industry || ''}
          native
          inputVariant="outlined"
          InputComponentProps={{
            className: 'criteriaSelectContainer',
            inputClassName: clsx(
              'criteriaSelect',
              attrs.criteria.industry && 'criteriaSelect_selected'
            ),
            htmlInputClassName: 'criteriaSelect__select',
            analyticParams: {
              key: `Applicant criteria by industry focused`,
              trigger: 'focus'
            }
          }}
          onChange={(e) => handleOnChange(e, 'industry')}
          testID="criteria-filter-select-industry"
        >
          <Box component="option" value="" color="rgba(255,255,255, 0.5)">
            Select One or None
          </Box>
          {map(industries, ({ name, industry_id: indId }, idx) => (
            <Box component="option" color="#000" key={`opt_${name}_${idx}`} value={indId}>
              {name}
            </Box>
          ))}
        </Select>
      </div>

      <div key="criteria__skills" className="criteriaSelectWrapper">
        <label htmlFor="skills">By Skills:</label>
        <Select
          id="skills"
          name="skills"
          value={attrs.criteria.skills || ''}
          native
          InputComponentProps={{
            className: 'criteriaSelectContainer',
            inputClassName: clsx(
              'criteriaSelect',
              attrs.criteria.skills && 'criteriaSelect_selected'
            ),
            htmlInputClassName: 'criteriaSelect__select',
            analyticParams: {
              key: `Applicant criteria by skills focused`,
              trigger: 'focus'
            }
          }}
          onChange={(e) => handleOnChange(e, 'skills')}
          testID="criteria-filter-select-skills"
        >
          <Box component="option" value="" color="rgba(255,255,255, 0.5)">
            Select One or None
          </Box>
          {map(skills, ({ name, skill_id: skillId }, idx) => (
            <Box component="option" color="#000" key={`opt_${name}_${idx}`} value={skillId}>
              {name}
            </Box>
          ))}
        </Select>
      </div>

      {map(criteriaFilters, (item, i) => (
        <div key={`criteria__${i}`} className="criteriaSelectWrapper">
          <label htmlFor={item.criteria}>{item.label}</label>
          <Select
            id={item.criteria}
            name={item.criteria}
            value={attrs.criteria[item.criteria] || ''}
            native
            inputVariant="outlined"
            InputComponentProps={{
              className: 'criteriaSelectContainer',
              inputClassName: clsx(
                'criteriaSelect',
                attrs.criteria[item.criteria] && 'criteriaSelect_selected'
              ),
              htmlInputClassName: 'criteriaSelect__select',
              analyticParams: {
                key: `Applicant criteria by ${item.criteria} focused`,
                trigger: 'focus'
              }
            }}
            onChange={(e) => handleOnChange(e, item.criteria)}
            testID={`criteria-filter-select-${item.criteria}`}
          >
            <Box component="option" value="" color="rgba(255,255,255, 0.5)">
              Select One or None
            </Box>
            {map(item.fields, (field, idx) => (
              <Box
                component="option"
                color="#000"
                key={`opt_${field.name}_${idx}`}
                value={field.value}
                {...qaAttr(`criteria-filter-option-${field.name}`)}
              >
                {field.name}
              </Box>
            ))}
          </Select>
        </div>
      ))}
    </StyledRoot>
  );
}

export default Criteria;
