import React, { useMemo } from 'react';
import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, createTheme } from '@mui/material/styles';
import LiveChat from 'react-livechat';
import TagManager from 'react-gtm-module';
import { client, authVar } from 'api';
import { AdapterDateFns, CssBaseline, LocalizationProvider, ThemeProvider } from 'components';
import { AppRouter } from 'router';
import { useAppStore, useAppStoreSelector } from 'store';
import getDesignTokens from 'styles/theme';
import { fbPixel } from 'utils/fbpixel';
import { GoogleOAuthProvider } from '@react-oauth/google';

const { LIVE_CHAT_LICENSE, GTM_ID, FB_PIXEL_ID, GOOGLE_CLIENT_ID } = process.env;
const NUM_LIVE_CHAT_LICENSE = Number(LIVE_CHAT_LICENSE);

if (GTM_ID) TagManager.initialize({ gtmId: GTM_ID });
if (FB_PIXEL_ID) fbPixel();

const appLogout = useAppStore.getState().logout;
authVar({ logout: () => appLogout() });

function App() {
  const colorMode = useAppStoreSelector((state) => state.colorMode);
  const theme = useMemo(() => createTheme(getDesignTokens(colorMode)), [colorMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <AppRouter />
            </LocalizationProvider>
          </GoogleOAuthProvider>
        </ThemeProvider>
        {LIVE_CHAT_LICENSE && <LiveChat license={NUM_LIVE_CHAT_LICENSE} />}
      </ApolloProvider>
    </StyledEngineProvider>
  );
}

export default App;
