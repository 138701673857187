import React, { useState } from 'react';
import PT from 'prop-types';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { Button } from 'components/shared';
import { GrowClassesIcon } from 'components/icons';
import PageHeader from '../PageHeader';
import ClassCard from './ClassCard';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.classesPage': {
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 48
    }
  },
  '& .classesPage__subTitle': {
    '& .classesPage__seeAll': {
      display: 'none'
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 16,
      padding: '0 22px 0 12px',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '130%',
      '& .classesPage__seeAll': {
        display: 'inline-flex',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: '150%',
        color: theme.palette.moreColors.lightPurple
      }
    }
  },
  '& .classesPage__itemsContainer': {
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 12px'
    }
  },
  '& .classesPage__coursesContainer': {
    marginBottom: 15,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '25px 18px',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 30,
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: 16
    }
  },
  '& .classesPage__finCoursesContainer': {
    marginTop: 15,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 16,
    [theme.breakpoints.down('desktopApp')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  }
}));

function ClassesPage(props) {
  const { courses, onTake } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const finishedCourses = courses.filter((o) => o.complete);
  const finishedSliced = finishedCourses.slice(0, 4);
  const unFinishedCourses = courses.filter((o) => !o.complete);
  const unFinishedSliced = unFinishedCourses.slice(0, 4);

  const [showAllAvailable, setShowAllAvailable] = useState(isDesktop);
  const [showAllFinished, setShowAllFinished] = useState(isDesktop);

  return (
    <StyledRoot className="classesPage">
      {isDesktop && (
        <PageHeader title="CLASSES" subtitle="Get Your Learn On!" icon={<GrowClassesIcon />} />
      )}
      <div>
        {!isDesktop && (
          <div className="classesPage__subTitle">
            <h3 className="sectionTitle light">Available classes</h3>
            {!!unFinishedCourses.length && !showAllAvailable && (
              <Button
                variant="text"
                className="classesPage__seeAll"
                onClick={() => setShowAllAvailable(true)}
              >
                See all
              </Button>
            )}
          </div>
        )}
        <div className="classesPage__itemsContainer classesPage__coursesContainer">
          {(showAllAvailable ? unFinishedCourses : unFinishedSliced).map((o) => (
            <ClassCard
              key={`class__${o.id}`}
              taken={o.taken}
              name={o.name}
              onTake={() => onTake(o)}
            />
          ))}
        </div>
        <div className="classesPage__subTitle">
          <h3 className="sectionTitle light">Finished Courses</h3>
          {!!finishedCourses.length && !showAllFinished && (
            <Button
              variant="text"
              className="classesPage__seeAll"
              onClick={() => setShowAllFinished(true)}
            >
              See all
            </Button>
          )}
        </div>
        <div className="classesPage__itemsContainer classesPage__finCoursesContainer">
          {(showAllFinished ? finishedCourses : finishedSliced).map(({ id, name }) => (
            <ClassCard key={`class__${id}`} complete taken name={name} />
          ))}
        </div>
      </div>
    </StyledRoot>
  );
}

ClassesPage.propTypes = {
  courses: PT.arrayOf(
    PT.shape({
      complete: PT.bool,
      employeeProfileId: PT.number,
      employerProfileId: PT.number,
      id: PT.number,
      link: PT.string,
      name: PT.string,
      taken: PT.bool,
      verified: PT.bool
    })
  ).isRequired,
  onTake: PT.func
};

ClassesPage.defaultProps = {
  onTake: (course) => {}
};

export default ClassesPage;
