import React, { useCallback, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useGetPlacePredictions } from 'hooks';
import { InputAdornment } from 'components';
import { MdClose } from '../../icons';
import { IconButton } from '../Button';
import Autocomplete from './Autocomplete';

function LocationAutocomplete(props) {
  const {
    AutocompleteProps,
    inputCompProps,
    nativeInputProps,
    onSuggestionSelect,
    onChange,
    value,
    ...rest
  } = props;
  const { getPlacePredictions } = useGetPlacePredictions();
  const autocompleteInputRef = useRef();

  const handleSearch = useCallback(
    (searchedValue, { setSuggestions, searchReason }) => {
      getPlacePredictions({ input: searchedValue }, (predictions, status) => {
        if (status === 'OK') {
          setSuggestions(map(predictions, 'description'));
        } else {
          setSuggestions([]);
        }
      });
    },
    [getPlacePredictions]
  );

  const handleSearchChange = useCallback((e, { newValue }) => {
    if (onChange) onChange(newValue);
  }, []);

  const handleSearchClear = useCallback(() => {
    if (onChange) onChange('');
  }, []);

  const getSuggestionValue = useCallback((suggestion) => suggestion, []);

  const handleSuggestionSelect = useCallback((e, args) => {
    if (onSuggestionSelect) onSuggestionSelect(e, args);
    if (autocompleteInputRef.current) autocompleteInputRef.current.blur();
  }, []);

  const shouldRenderSuggestions = useCallback((val) => val.trim().length > 2, []);

  return (
    <Autocomplete
      onFetch={handleSearch}
      shouldRenderSuggestions={shouldRenderSuggestions}
      onSuggestionSelected={handleSuggestionSelect}
      getSuggestionValue={getSuggestionValue}
      nativeInputProps={{
        value,
        onChange: handleSearchChange,
        type: 'text',
        ...nativeInputProps
      }}
      inputCompProps={{
        inputRef: autocompleteInputRef,
        endAdornment:
          value > 0 ? (
            <InputAdornment position="end">
              <IconButton color="primary" onClick={handleSearchClear}>
                <MdClose color="inherit" />
              </IconButton>
            </InputAdornment>
          ) : null,
        ...inputCompProps
      }}
      {...AutocompleteProps}
      {...rest}
    />
  );
}

LocationAutocomplete.propTypes = {
  AutocompleteProps: PropTypes.objectOf(PropTypes.any),
  inputCompProps: PropTypes.objectOf(PropTypes.any),
  nativeInputProps: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func,
  value: PropTypes.string.isRequired
};

LocationAutocomplete.defaultProps = {
  AutocompleteProps: {},
  inputCompProps: {},
  nativeInputProps: {},
  onSuggestionSelect: undefined,
  value: ''
};

export default memo(LocationAutocomplete);
