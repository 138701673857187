import React, { useEffect, useContext } from 'react';
import PT from 'prop-types';
import reduce from 'lodash/reduce';
import { useMediaQueryMatches } from 'hooks';
import { Dialog } from 'components';
import { useForm } from 'components/form';
import { Button, Input } from 'components/shared';
import EmployerGrowContext from '../../EmployerGrowContext';

const getInit = (args) => ({
  keywords: args.keywords || '',
  rejectedJobs: args.rejectedJobs >= 0 ? args.rejectedJobs : '',
  acceptedJobs: args.acceptedJobs >= 0 ? args.acceptedJobs : '',
  needActionJobs: args.needActionJobs >= 0 ? args.needActionJobs : ''
});

function StoresFilter({ filters, isOpen, onSubmit, onClose }) {
  // const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  // const { } = useContext(EmployerGrowContext);
  const { $, attrs, set, withValidation } = useForm({
    initial: { ...getInit(filters) },
    validations: {
      rejectedJobs: {
        numericality: { greaterThanOrEqual: 0 }
      },
      acceptedJobs: {
        numericality: { greaterThanOrEqual: 0 }
      },
      needActionJobs: {
        numericality: { greaterThanOrEqual: 0 }
      }
    }
  });

  useEffect(() => {
    set(getInit(filters));
  }, [JSON.stringify(filters)]);

  const handleChange = (e, { name }) => {
    set(name, e.target.value);
  };

  const handleSubmit = withValidation((validatedAttrs) => {
    const { keywords, ...numeric } = validatedAttrs;
    onSubmit({
      keywords,
      ...reduce(
        numeric,
        (res, val, key) => {
          const num = parseInt(val);
          res[key] = num >= 0 ? num : undefined;
          return res;
        },
        {}
      )
    });
  });

  return (
    <Dialog
      open={isOpen}
      fullScreen
      container={() => document.getElementById('stores-filter-modal-root')}
      classes={{
        paper: 'storesFilterModal__paper'
      }}
      onClose={onClose}
    >
      <div className="storesFilterContainer">
        <Input
          {...$('keywords', handleChange)}
          variant="textfield"
          id="keywords"
          label="Keywords"
          FormControlProps={{ sx: { mb: '29px' } }}
          testID="stores-filter-keywords-input"
        />
        <Input
          {...$('rejectedJobs', handleChange)}
          variant="textfield"
          id="rejected"
          label="Rejected jobs"
          FormControlProps={{ sx: { mb: '29px' } }}
          withHelperText
          testID="stores-filter-rejected-input"
        />
        <Input
          {...$('acceptedJobs', handleChange)}
          variant="textfield"
          id="accepted"
          label="Accepted jobs"
          FormControlProps={{ sx: { mb: '29px' } }}
          withHelperText
          testID="stores-filter-accepted-input"
        />
        <Input
          {...$('needActionJobs', handleChange)}
          variant="textfield"
          id="needsAttention"
          label="Needs Attention"
          FormControlProps={{ sx: { mb: '29px' } }}
          withHelperText
          testID="stores-filter-needsAttention-input"
        />
        <Button
          variant="filled-primary"
          sx={{ width: '100%', height: 50 }}
          onClick={handleSubmit}
          testID="stores-filter-submit-button"
        >
          Search
        </Button>
      </div>
    </Dialog>
  );
}

StoresFilter.propTypes = {
  isOpen: PT.bool.isRequired,
  onSubmit: PT.func.isRequired,
  onClose: PT.func.isRequired
};

export default StoresFilter;
