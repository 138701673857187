import { addAlpha } from 'styles/utils';

const pillMargin = 10;
const pillHeight = 35;
const visibleHours = 6;

export default ({ theme }) => ({
  '&.container': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.down('desktopApp')]: {
      overflow: 'auto'
    }
  },
  '& .sectionsContainer': {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      paddingLeft: 16,
      paddingRight: 16,
      flexDirection: 'column',
      overflow: 'initial'
    }
  },
  '& .criteriaTitleContainer': {
    minHeight: 40,
    margin: '24px 0 20px',
    display: 'flex',
    alignItems: 'center',
    '& > h2': {
      fontSize: 16
    }
  },
  '& .tabs': {
    margin: '24px 0 20px',
    display: 'flex',
    alignItems: 'center'
  },
  '& .tabBtn': {
    height: 40,
    borderRadius: 20,
    fontSize: 13,
    lineHeight: '16px',
    '&:not(:first-of-type)': {
      marginLeft: 13
    }
  },
  '& .tab': {
    marginRight: 10,
    padding: '8px 16px',
    borderRadius: '5px 5px 0 0',
    border: '1px solid #e8e8e8',
    borderBottom: 'none',
    fontSize: 14,
    boxShadow: '0 -6px 7px rgb(0 0 0 / 5%)',
    backgroundColor: theme.palette.background.default,
    transition: 'background-color .25s ease-out',
    '&.active': {
      backgroundColor: theme.palette.background.dark
    }
  },
  '& .mobileTabBtn': {
    padding: '15px 20px',
    justifyContent: 'space-between',
    borderRadius: 0,
    '& .title': {
      fontSize: 14
    }
  },
  '& .scheduleContainer': {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('desktopApp')]: {
      flex: 1,
      marginBottom: 0
    }
  },
  '& .interviewsSectionContainer': {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('desktopApp')]: {
      paddingBottom: 30
    }
  },
  '& .criteriaContainer': {
    flex: 1,
    marginLeft: 30,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 0
    }
  },
  '& .criteriaFormContainer': {
    flex: 1,
    paddingRight: 10,
    // overflow: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0
    }
  },
  '& .submitBtnContainer': {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 30,
      justifyContent: 'center'
    }
  },
  '&.schedulesContentContainer': {
    padding: '20px 15px',
    border: `1px solid ${theme.palette.moreColors.grey_3}`,
    borderRadius: 8,
    backgroundColor: addAlpha(theme.palette.background.light, 0.25),
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0,
      border: 'none'
    }
  },
  '& .scheduleTip': {
    marginBottom: 24,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .schedulesControllersContainer': {
    marginBottom: 25,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 0
    }
  },
  '& .scheduleBtnsContainer': {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'row'
    }
  },
  '& .scheduleBtn': {
    width: 117,
    height: 40,
    border: `1px solid ${theme.palette.moreColors.grey_3}`,
    borderRadius: 7,
    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.primary.light,
    '&:not(:last-of-type)': {
      marginRight: 14
    },
    '&.selected': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 12,
      '&:not(:last-child)': {
        marginRight: 12
      }
    }
  },
  '& .scheduleAddBtn': {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.background.darkTransparent
  },
  '& .scheduleFormLabel': {
    marginBottom: 13,
    fontSize: 9,
    lineHeight: '13px',
    color: theme.palette.primary.text
  },
  '& .scheduleFormInput': {
    padding: '6px 0 4px',
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 13
    }
  },
  '& .scheduleFormInputError': {
    fontSize: 12
  },
  '& .graphContainer': {
    maxHeight: `calc(${pillMargin + pillHeight}px * ${visibleHours} * 2)`,
    position: 'relative',
    overflowY: 'scroll',
    overflowX: 'hidden',
    display: 'grid',
    // gridTemplateColumns: '130px 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridTemplateColumns: '130px 1fr',
    columnGap: 10,
    paddingRight: 5,
    [theme.breakpoints.down('desktopApp')]: {
      overflowX: 'scroll',
      maxHeight: '100%',
      paddingRight: 0,
      gridTemplateColumns: '60px calc(7 * 62px)',
      columnGap: 8
    },
    [theme.breakpoints.down(600)]: {
      gridTemplateColumns: '60px 250px 30px'
    },
    [theme.breakpoints.down(375)]: {
      gridTemplateColumns: '60px 180px 30px'
    }
  },
  '& .graphMobileContainer': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      marginLeft: 70,
      marginBottom: 15,
      overflowX: 'hidden',
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 62px)'
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: 250
    },
    [theme.breakpoints.down(375)]: {
      maxWidth: 180,
      gridTemplateColumns: 'repeat(7, 30px)'
    }
  },
  '& .graphMobileContainer__col': {
    width: 62,
    textAlign: 'center',
    '& > div:first-of-type': {
      marginBottom: 4,
      fontSize: 14
    },
    '& > div:last-of-type': {
      fontSize: 9,
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('desktopApp')]: {
      width: 'auto'
    }
  },
  '& .rightScrollBtn': {
    display: 'none',
    [theme.breakpoints.down(600)]: {
      width: 20,
      height: 20,
      display: 'flex',
      backgroundColor: theme.palette.moreColors.purpleLight
    }
  },
  '& .graphHeader': {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    height: 55,
    // maxWidth: 70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    '& > div': {
      '&:first-of-type': {
        fontSize: 22,
        fontWeight: 100,
        color: 'grey'
      },
      '&:last-of-type': {
        marginBottom: 5,
        fontSize: 14
      }
    },
    [theme.breakpoints.down('desktopApp')]: {
      width: 50,
      display: 'none'
    }
  },
  '& .graphColumnsContainer': {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    columnGap: 10,
    [theme.breakpoints.down('desktopApp')]: {
      // maxWidth: 250,
      padding: '13px 0',
      border: `1px solid ${theme.palette.moreColors.grey_3}`,
      borderRadius: 6,
      gridTemplateColumns: 'repeat(7, 62px)',
      columnGap: 0,
      overflowX: 'auto'
    },
    [theme.breakpoints.down(375)]: {
      gridTemplateColumns: 'repeat(7, 30px)'
    }
  },
  '& .graphTimes': {
    marginBottom: 40,
    backgroundColor: theme.palette.moreColors.white,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 0,
      paddingTop: 13,
      backgroundColor: 'transparent'
    }
  },
  '& .graphTimesTop': {
    height: 55,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.moreColors.white,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .timesSpacer': {
    height: 20,
    width: '100%',
    display: 'block'
  },
  '& .scrollArrow': {
    position: 'relative',
    zIndex: 1,
    height: pillHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 40,
    fontWeight: 100,
    color: theme.palette.moreColors.purpleLight_1,
    backgroundColor: theme.palette.moreColors.white,
    cursor: 'pointer',
    '& > span': {
      position: 'absolute',
      top: 7,
      left: '50%',
      transform: 'translateX(-50%)'
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      filter: 'grayscale(1)'
    },
    '&.scrollUp': {
      width: 120,
      [theme.breakpoints.down('desktopApp')]: {
        display: 'none'
      }
    },
    '&.scrollDown': {
      position: 'sticky',
      bottom: 0,
      width: 120,
      [theme.breakpoints.down('desktopApp')]: {
        display: 'none'
      }
    }
  },
  '& .pillColumn': {
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  '& .pill': {
    position: 'relative',
    height: pillHeight,
    width: '100%',
    // maxWidth: 70,
    marginBottom: pillMargin,
    border: `1px solid ${theme.palette.moreColors.grey_5}`,
    borderRadius: 4,
    overflow: 'hidden',
    '& > div': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) scale(0)',
      backgroundColor: theme.palette.moreColors.purpleLight_1,
      transition: '0.15s transform ease-out',
      borderRadius: '50%',
      [theme.breakpoints.down('desktopApp')]: {
        backgroundColor: theme.palette.primary.main
      }
    },
    '&.active': {
      '& > div': {
        transform: 'translate(-50%, -50%) scale(1.5)'
      }
    },
    [theme.breakpoints.down('desktopApp')]: {
      width: 18,
      height: 18,
      borderRadius: '100%'
    }
  },
  '& .timeSlot': {
    height: pillHeight,
    marginBottom: pillMargin,
    lineHeight: `${pillHeight - 5}px`,
    textAlign: 'center',
    color: theme.palette.moreColors.grey_5,
    '& sup': {
      fontSize: 10
    },
    [theme.breakpoints.down('desktopApp')]: {
      height: 18,
      paddingTop: 2,
      marginBottom: 10,
      fontSize: 12,
      lineHeight: 1.5,
      textAlign: 'left',
      '& sup': {
        verticalAlign: 'initial',
        fontSize: 'inherit'
      }
    }
  },
  '&.criteriaContentContainer': {
    padding: 18,
    border: `1px solid ${theme.palette.moreColors.white}`,
    borderRadius: 7,
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0,
      border: 'none'
    }
  },
  '& .criteriaSelectWrapper': {
    position: 'relative',
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: 12,
      color: theme.palette.moreColors.grey_6
    }
  },
  '& .select': {
    padding: '10px 5px'
  },
  '& .criteriaSelectContainer': {
    marginTop: 8
  },
  '& .criteriaSelect': {
    fontSize: 13,
    color: theme.palette.moreColors.grey_6,
    borderRadius: 4,
    borderColor: theme.palette.moreColors.grey_4,
    '&.criteriaSelect_selected': {
      color: 'initial'
    },
    '& .criteriaSelect__select': {
      padding: '10px 5px',
      paddingLeft: 21,
      borderRadius: 'inherit'
    },
    '& .StyledSelect-select__icon': {
      color: theme.palette.primary.main
    }
  },
  '&.interviews__container': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
    // overflow: 'hidden'
  },
  '& .interviews__contentContainer': {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  '& .interviews__weekController': {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 2,
      border: `1px solid ${theme.palette.moreColors.grey_6}`,
      borderRadius: 20
    }
  },
  '& .interviews__weekController__range': {
    padding: '0 25px',
    fontSize: 15,
    color: 'grey',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 15px'
    }
  },
  '& .interviews__weekController__action': {
    padding: 5,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('desktopApp')]: {
      backgroundColor: theme.palette.background.dark
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      cursor: 'auto'
    }
  },
  '& .interviews__daysContainer': {
    flex: 1,
    overflow: 'auto'
  },
  '& .interviews__daySlot': {
    padding: '10px 15px 25px',
    borderBottom: '1px solid #F4F4F4',
    '&:nth-of-type(even)': {
      backgroundColor: addAlpha(theme.palette.background.dark, 0.2)
    }
  },
  '& .interviews__pastDate': {
    opacity: 0.25
  },
  '& .interviews__dayTitle': {
    margin: 0,
    padding: '5px 0 15px',
    fontSize: 13,
    fontWeight: 100,
    color: theme.palette.primary.white
  },
  '& .interviews__timeSlots': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(200px, 308px))',
    gridGap: 7.5,
    [theme.breakpoints.down('desktopApp')]: {
      gridTemplateColumns: 'minmax(100%, 307px)',
      justifyItems: 'center'
    }
  },
  '& .interviews__timeSlotWrapper': {
    width: '100%'
  },
  '& .interviews__timeSlot': {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 2px 8px rgb(0 0 0 / 10%)'
  },
  '& .interviews__timeSlot__clickableArea': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  '& .interviews__timeSlot__mainInfo': {
    width: '100%'
  },
  '& .interviews__timeSlot__actionsContainer': {
    marginLeft: 10
  },
  '& .interviews__jobTitle': {
    marginBottom: 6,
    fontSize: 11,
    color: 'grey'
  },
  '& .interviews__timeSlotImg': {
    width: 65,
    minWidth: 65,
    height: 65,
    marginRight: 10,
    objectFit: 'cover',
    borderRadius: '50%',
    border: `3px solid ${theme.palette.background.darkTransparent}`
  },
  '& .interviews__timeSlotImg_confirmed': {
    borderColor: theme.palette.success.main
  },
  '& .interviews__timeSlotImg_not_coming': {
    borderColor: theme.palette.error.main
  },
  '& .interviews__timeSlotImg_not_coming_needs_reschedule': {
    borderColor: theme.palette.neutral.main
  },
  '& .interviews__timeSlotTime': {
    paddingBottom: 5,
    fontSize: 16,
    fontWeight: 800,
    color: theme.palette.moreColors.black_2
  },
  '& .interviews__timeslot_timeofday': {
    fontSize: 10
  },
  '& .interviews__timeslot_status': {
    fontSize: 10,
    boxShadow: `0 2px 5px ${theme.palette.background.dark}`,
    padding: '5px 15px',
    borderRadius: 3.5,
    display: 'inline-block',
    fontWeight: 800
  },
  '& .timeslot_confirmed': {
    color: theme.palette.success.main
  },
  '& .timeslot_not_coming': {
    color: theme.palette.error.main
  },
  '& .timeslot_not_coming_needs_reschedule': {
    color: theme.palette.neutral.main
  },
  '& .interviews__timeSlotName': {
    fontSize: 14,
    fontWeight: 800,
    color: theme.palette.moreColors.black_2
  },
  '& .optionsPopupPaper': {
    minWidth: 255
  },
  '& .optionsPopupBtn': {
    width: '100%',
    height: 55,
    extAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '15px',
    letterSpacing: 0,
    color: theme.palette.moreColors.black,
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.moreColors.black}`
    }
  }
});
