import React, { useEffect } from 'react';
import PT from 'prop-types';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { getUserId } from 'utils';
import { Box, styled } from 'components';
import { Button, Spinner, StyledSelect } from 'components/shared';
import { useForm } from 'components/form';
import styles from 'styles/Onboarding';
import {
  GET_GROW_INDUSTRIES,
  CREATE_CAREER_DEV_ON_REGISTRATION,
  GET_CAREER_DEV_WITH_HEALTH_SCORE
} from 'api';

function CareerIndustry(props) {
  const { onGoToNextStep, onChange, form } = props;
  const industryId = form.industryId || '';
  const curJobTypeId = form.curJobTypeId || '';

  const [createCareerDev, { loading: careerDevSaveLoading }] = useMutation(
    CREATE_CAREER_DEV_ON_REGISTRATION
  );
  const [fetchCareerDev] = useLazyQuery(GET_CAREER_DEV_WITH_HEALTH_SCORE, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: industriesData, loading: industriesLoading } = useQuery(GET_GROW_INDUSTRIES);
  const industries = industriesData?.industries || [];

  const { $, set, withValidation } = useForm({
    initial: { industryId }
  });

  useEffect(() => {
    set({ industryId });
  }, [industryId]);

  const next = withValidation(async (attrs) => {
    const userId = Number(getUserId());
    await createCareerDev({ variables: { userId, industryId: Number(industryId) } });
    await fetchCareerDev({ variables: { userId } });
    onGoToNextStep();
  });

  const handleFieldChange = (value, { name }) => {
    const values = { curJobTypeId, industryId, [name]: value };
    if (name === 'industryId') values.curJobTypeId = '';
    set(values);
    onChange({ ...form, ...values });
  };

  return (
    <div className="pageContent" style={{ maxWidth: 461, alignItems: 'normal' }}>
      <Box component="p" mb="56px" className="text1">
        By choosing an industry, it helps us connect you with relevant jobs and make more
        personalized suggestions. You can always change this later.
      </Box>

      <Box mb="56px">
        <p className="label">Industry:</p>
        <StyledSelect
          id="industry"
          {...$('industryId', (obj, meta) => handleFieldChange(obj.value, meta))}
          required
          placeholder="Choose an industry"
          variant="outlined"
          options={industries.map((ind) => ({ value: ind.industry_id, label: ind.name }))}
          mobileModalProps={{
            title: 'Select an Industry'
          }}
          testID="onboarding-grow-industry-select"
          getOptionTestID={(i) => `onboarding-grow-industry-option-${i}`}
        />
      </Box>

      <Box width="100%" maxWidth={224} mx="auto">
        <Button
          variant="filled-primary"
          disabled={!industryId || careerDevSaveLoading || industriesLoading}
          endIcon={careerDevSaveLoading ? <Spinner size={24} /> : null}
          className="nextBtn"
          onClick={next}
          testID="onboarding-next-button"
        >
          Next
        </Button>
      </Box>
    </div>
  );
}

CareerIndustry.propTypes = {
  form: PT.objectOf(PT.any).isRequired,
  onGoToNextStep: PT.func.isRequired,
  onChange: PT.func.isRequired
};

const StyledCareerIndustry = styled(CareerIndustry)(styles);

export default StyledCareerIndustry;
