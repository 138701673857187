import { gql } from '@apollo/client';

export const ADD_EMAIL_FOR_NOTIFICATIONS = gql`
  mutation postEmailsForNotifications(
    $employerUserId: Int!
    $email: String!
    $notificationsType: String
  ) {
    postEmailsForNotifications(
      employerUserId: $employerUserId
      email: $email
      notificationsType: $notificationsType
    ) {
      id
      email
      employerUserId
      notificationsType
    }
  }
`;

export const REMOVE_EMAIL_FOR_NOTIFICATIONS = gql`
  mutation removeEmailsForNotifications($employerUserId: Int!, $email: String!) {
    removeEmailsForNotifications(employerUserId: $employerUserId, email: $email) {
      id
    }
  }
`;

export const SET_EMPLOYER_INTERVIEW_SCHEDULE = gql`
  mutation setScheduleTimesEmployer(
    $employerProfileId: Int!
    $scheduling: [employerMultischeduleInputObjectType]
  ) {
    setScheduleTimesEmployer(employerProfileId: $employerProfileId, scheduling: $scheduling) {
      schedule
      careerHealthScore
      day
      employerProfileId
      experience
      id
      industry
      location
      skills
      timeZone
    }
  }
`;

// export const SET_EMPLOYEE_INTERVIEW_SCHEDULE = gql`
//   mutation setScheduleTimesEmployee(
//     $employeeProfileId: Int!
//     $jobId: Int!
//     $days: [employerToScheduleTimesInputType]
//   ) {
//     setScheduleTimesEmployee(employeeProfileId: $employeeProfileId, jobId: $jobId, days: $days) {
//       createdAt
//       id
//     }
//   }
// `;

export const CANCEL_INTERVIEW = gql`
  mutation cancelInterview(
    $interviewId: Int!
    $jobId: Int!
    $employeeProfileId: Int!
    $isReschedule: Boolean
  ) {
    cancelInterview(
      interviewId: $interviewId
      jobId: $jobId
      employeeProfileId: $employeeProfileId
      isReschedule: $isReschedule
    ) {
      id
      employeeProfileId
    }
  }
`;

export const HIRE_EMPLOYEE = gql`
  mutation hiredEmployee(
    $jobId: Int
    $notes: String
    $hired: Boolean
    $employeeId: Int
    $hiredReasons: [hiredReasonsInputType]
    $mergeData: mergeObjectType
  ) {
    hiredEmployee(
      jobId: $jobId
      notes: $notes
      hired: $hired
      employeeId: $employeeId
      hiredReasons: $hiredReasons
      mergeData: $mergeData
    ) {
      jobId
      hired
      hiredReasons {
        id
        reason
      }
      integrationResponse {
        status
      }
    }
  }
`;
