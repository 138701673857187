import React, { useState, useContext, useMemo, memo } from 'react';
import PT from 'prop-types';
import format from 'date-fns/format';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import { FormControlLabel, MuiRadio, MuiRadioGroup } from 'components';
import { Spinner } from 'components/shared';
import EmployerGrowContext from '../../EmployerGrowContext';

const DATE_FORMAT = 'MM-dd-yyyy';

const orders = {
  ASC: 'asc',
  DESC: 'desc',
  BEST: 'best',
  WORST: 'worst'
};
const orderVariants = [
  { value: orders.ASC, label: 'Asc' },
  { value: orders.DESC, label: 'Desc' },
  { value: orders.BEST, label: 'Best' },
  { value: orders.WORST, label: 'Worst' }
];

const sortData = (array = [], order = orders.ASC) => {
  const isQualityOrder = order === orders.BEST || order === orders.WORST;
  const qualityOrderConfig = [['applicants'], [order === orders.BEST ? 'desc' : 'asc']];
  const nameOrderConfig = [['title'], [order]];
  return orderBy(array, ...(isQualityOrder ? qualityOrderConfig : nameOrderConfig));
};

function JobStatistics(props) {
  const { isMainDataLoading, jobStatistics = [] } = useContext(EmployerGrowContext);
  const [order, setOrder] = useState(orders.ASC);

  const orderedData = useMemo(
    () => sortData(jobStatistics, order),
    [JSON.stringify(jobStatistics), order]
  );

  const renderSortControllers = ({ value, label }, i) => {
    const checked = value === order;
    return (
      <FormControlLabel
        value={value}
        key={`orderController__${i}`}
        label={label}
        control={
          <MuiRadio
            color="primary"
            icon={<span style={{ display: 'none' }} />}
            checkedIcon={<span style={{ display: 'none' }} />}
            inputProps={{
              'aria-label': label
            }}
            style={{ position: 'absolute', padding: 0 }}
          />
        }
        classes={{
          root: `sortController ${checked ? 'checked' : ''}`,
          label: 'sortControllerLabel'
        }}
      />
    );
  };

  return (
    <div className="jobStatisticsContainer">
      {isMainDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <div className="jobStatisticsHeader">
        <h4 className="chartTitle jobStatisticsTitle">Job Statistics</h4>
        <div className="sortControllersContainer">
          <div className="sortControllersContainerTitle">Sort By</div>
          <MuiRadioGroup
            value={order}
            row
            aria-label="sort by"
            onChange={(e) => setOrder(e.target.value)}
          >
            {map(orderVariants, renderSortControllers)}
          </MuiRadioGroup>
        </div>
      </div>
      <div className="jobStatisticsTableContainer">
        <table className="jobStatisticsTable">
          <thead>
            <tr>
              <th scope="col" align="left">
                Title
              </th>
              <th scope="col">Location</th>
              <th scope="col">Created Date</th>
              <th scope="col">Applicants</th>
              <th scope="col">Approved</th>
              <th scope="col">WT Matches</th>
              <th scope="col">Starred</th>
              <th scope="col">In Your court</th>
            </tr>
          </thead>
          <tbody>
            {map(
              orderedData,
              (
                { title, location, createdAt, applicants, approved, starred, qhMatches, rejected },
                i
              ) => (
                <tr key={`tr__${i}`}>
                  <th scope="row" align="left" className="rowHeadCell">
                    {title}
                  </th>
                  <td align="center" className="accentedCell">
                    {location}
                  </td>
                  <td align="center" className="accentedCell">
                    {format(new Date(createdAt), DATE_FORMAT)}
                  </td>
                  <td align="center">{applicants}</td>
                  <td align="center">{approved}</td>
                  <td align="center">{qhMatches}</td>
                  <td align="center">{starred}</td>
                  <td align="center">{rejected}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

JobStatistics.propTypes = {};

export default memo(JobStatistics);
