import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import map from 'lodash/map';
import { nanoid } from 'nanoid/non-secure';
import { IS_NEW_MESSAGES, GET_NEW_MESSAGES } from 'api';
import { getUserId } from 'utils';
import useCommonUI from './useCommonUI';
import useNotifications from './useNotifications';

function useNotificationsSubscribe({ interval = 30000, role }) {
  const { show: showNotification } = useNotifications();
  const { openChatModal } = useCommonUI();
  const [isNewMessage, setIsNewMessage] = useState(false);
  const intervalIdRef = useRef();

  const [checkNewMessages, { data: checkNewMessagesData, stopPolling: stop, startPolling: start }] =
    useLazyQuery(IS_NEW_MESSAGES, {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
      // pollInterval: interval // @TODO: broken on @apollo/client v3.6.2-3.6.8
    });

  const [fetchNewMessages, { data: newMessagesData }] = useLazyQuery(GET_NEW_MESSAGES, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      if (intervalIdRef.current) clearInterval(intervalIdRef.current); // @TODO: remove it when pollInterval will be fixed
    };
  }, []);

  useEffect(() => {
    const userId = getUserId();
    const count = checkNewMessagesData?.isNewMessages?.[0]?.message || 0;
    setIsNewMessage(count > 0);

    if (userId && role && count > 0)
      fetchNewMessages({ variables: { type: role, messageTo: Number(userId) } });

    return () => {
      if (stop) stop();
    };
  }, [JSON.stringify(checkNewMessagesData)]);

  useEffect(() => {
    if (newMessagesData?.getNewMessages) {
      const messages = newMessagesData.getNewMessages || [];
      showNotification(
        map(messages, (mess, i) => {
          const profile =
            role === 'employer' ? mess?.users?.employeeProfile : mess?.jobs?.employerProfile;
          const messageInfo = mess?.message || '';
          const notificationKey = nanoid();
          return {
            notificationKey,
            message: messageInfo.length > 30 ? `${messageInfo.substring(0, 30)}...` : messageInfo,
            sender: profile?.name || '',
            image: profile?.imageUrl,
            type: 'newMessage',
            onNotificationClick: () => {
              openChatModal({
                role,
                initialView: 'chat',
                initialChatViewProps: {
                  jobId: mess.jobsId,
                  receiverUserId: role === 'employer' ? mess.messageFrom : mess.messageTo
                }
              });
              return { closeCurrent: true };
            }
          };
        })
      );
    }
  }, [JSON.stringify(newMessagesData)]);

  const subscribe = useCallback(() => {
    const userId = getUserId();
    if (userId && role) {
      checkNewMessages({
        variables: { type: role, messageTo: Number(userId) }
      });

      intervalIdRef.current = setInterval(() => {
        // @TODO: remove it when pollInterval will be fixed
        checkNewMessages({
          variables: { type: role, messageTo: Number(userId) }
        });
      }, interval);
    }
  }, [role, checkNewMessages, interval]);

  return { subscribe, /* stop, start, */ isNewMessage };
}

export default useNotificationsSubscribe;
