import React from 'react';
import PT from 'prop-types';
import map from 'lodash/map';
import { styled } from 'components';
import { GrowBadgeIcon18 } from 'components/icons';
import PageHeader from '../PageHeader';
import ResourceCard from './ResourceCard';
import { careerDevBlogs } from './employeeBlogsData';

const StyledRoot = styled('div')(({ theme }) => ({
  '& .resourcesContainer': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 18
  }
}));

function ResourcesPage(props) {
  return (
    <StyledRoot>
      <PageHeader title="Resources to guide your journey" subtitle="" icon={<GrowBadgeIcon18 />} />
      <div className="resourcesContainer">
        {map(careerDevBlogs, ({ title, link }, i) => (
          <ResourceCard key={`blog__${i}`} title={title} link={link} />
        ))}
      </div>
    </StyledRoot>
  );
}

ResourcesPage.propTypes = {};

export default ResourcesPage;
