import React, { useState, useEffect, useMemo } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import { map, isEmpty } from 'lodash';
import clsx from 'clsx';
import { getUserId, qaAttr } from 'utils';
import { useEmployerProfileQuery } from 'hooks';
import { Box, MenuItem, styled } from 'components';
import { MdExpandMore, MdLocationCity } from 'components/icons';
import { GET_ADMIN_ACCOUNTS, GENERATE_NEW_TOKEN, GET_ADMIN_BY_SUB_EMPLOYER_ID } from 'api';
import Spinner from '../Spinner';
import Button from '../Button';
import PopupMenu from './PopupMenu';

// common data which we don't need to refetch
// const APIS_EXCLUDED_FROM_REFETCH = [
//   'getJobTypes',
//   'getAllPayPeriods',
//   'getAllCurrencies',
//   'getAllStates',
//   'getAllCountries',
//   'getIndustry',
//   'getSkills',
//   'getAdminAccounts'
// ];

const PREFIX = 'linkedAccountsSwitchPopup';
const classes = {
  triggerButton: `${PREFIX}__trigger`,
  menu: `${PREFIX}__menu`,
  menuItem: `${PREFIX}__menu-item`,
  image: `${PREFIX}__image`,
  imageContainer: `${PREFIX}__imageContainer`,
  imageIcon: `${PREFIX}__imagePlaceholderIcon`,
  name: `${PREFIX}__name`,
  email: `${PREFIX}__email`
};

const popupTrigger = ({ triggerProps, openPopup }) => {
  const { currentUser, loading, ...buttonProps } = triggerProps;
  const { email, name, imageUrl } = currentUser;

  return (
    <Button className={classes.triggerButton} disableRipple onClick={openPopup} {...buttonProps}>
      {loading ? (
        <div className="loader">
          <Spinner size={20} />
        </div>
      ) : (
        <>
          <div className={classes.imageContainer}>
            {imageUrl ? (
              <img src={imageUrl} alt="" className={classes.image} />
            ) : (
              <MdLocationCity color="primary" sx={{ fontSize: 16 }} />
            )}
          </div>
          <Box flex={1}>
            <div className={classes.name}>{name || 'N/A'}</div>
            {email && <div className={classes.email}>{email}</div>}
          </Box>
          <MdExpandMore />
        </>
      )}
    </Button>
  );
};

function LinkedAccountsSwitchPopup({ className }) {
  // const client = useApolloClient();
  const [adminProfile, setAdminProfile] = useState({});
  const [currentUser, setCurrentUser] = useState({});

  const getEmployerUserId = (employer) => employer?.userId || employer?.user_id;
  const getEmployerProfileId = (employer) =>
    employer?.profileId || employer?.profile_id || employer?.employerProfileId;

  const [createNewToken] = useMutation(GENERATE_NEW_TOKEN, {
    fetchPolicy: 'no-cache'
  });

  // profile is stored in cache and changes on every user switch, so admin must be stored separately
  const {
    fetch: fetchEmployerProfile,
    profile,
    loading: profileLoading
  } = useEmployerProfileQuery({ autoFetch: false });

  const [fetchAdminProfile, { loading: adminLoading }] = useLazyQuery(
    GET_ADMIN_BY_SUB_EMPLOYER_ID,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [fetchUsers, { data: usersData, loading: usersLoading }] = useLazyQuery(GET_ADMIN_ACCOUNTS);
  const users = usersData?.getAdminAccounts || [];
  const activeUsers = users.filter((o) => o.active);

  useEffect(() => {
    const uid = getUserId();
    fetchEmployerProfile();
    fetchUsers({ variables: { userId: Number(uid) } });
  }, []);

  useEffect(() => {
    (async () => {
      if (isEmpty(adminProfile) && profile && users.length) {
        // set admin user data
        const adminUID = Number(users[0].adminUserId);
        const adminPID = Number(users[0].adminProfileId);
        const profileUID = Number(getEmployerUserId(profile));

        if (adminUID === profileUID) {
          setAdminProfile(profile);
        } else {
          const { data } = await fetchAdminProfile({
            variables: { employerProfileId: adminPID }
          });
          // CAREFULLY! employerProfile and getAdminBySubEmployerId have different fields
          setAdminProfile(data?.getAdminBySubEmployerId);
        }

        setCurrentUser(profile);
      }
    })();
  }, [profile, users]);

  const switchUser = async (selectedUser, isAdmin) => {
    const adminUID = Number(getEmployerUserId(adminProfile));
    const currentUID = Number(getEmployerUserId(currentUser));
    const selectedUID = Number(getEmployerUserId(selectedUser));
    const selectedPID = isAdmin
      ? selectedUser?.profile_id || selectedUser?.adminProfileId // get profile id from employerProfile || getAdminBySubEmployerId
      : Number(getEmployerProfileId(selectedUser));

    if (currentUID === selectedUID) return;

    setCurrentUser(selectedUser);
    const response = await createNewToken({
      variables: {
        userId: adminUID,
        employerId: selectedPID
      }
    });
    const newToken = response?.data?.generateNewToken?.token;

    if (newToken) {
      localStorage.setItem('token', newToken);
      localStorage.setItem('userId', selectedUID);
      localStorage.setItem('profileId', selectedPID);
      // client.refetchQueries({
      //   include: 'active',
      //   onQueryUpdated: (observableQuery) => {
      //     if (!APIS_EXCLUDED_FROM_REFETCH.includes(observableQuery.queryName)) {
      //       return observableQuery.refetch();
      //     }
      //   }
      // });
      window.location.reload();
    }
  };

  const renderMenuItem = (user, closePopup, isAdmin) => {
    const { id, imageUrl, name, email } = user;
    return (
      <MenuItem
        key={id}
        className={clsx(classes.menuItem, id === currentUser.id && 'selected')}
        disableRipple
        onClick={() => {
          closePopup();
          switchUser(user, isAdmin);
        }}
        {...qaAttr(`admin-switch-user-${name}`)}
      >
        <div className={classes.imageContainer}>
          {imageUrl ? (
            <img src={imageUrl} alt="" className={classes.image} />
          ) : (
            <MdLocationCity sx={{ fontSize: 16, color: isAdmin ? 'primary.main' : '#E5E5E5' }} />
          )}
        </div>
        <div>
          <div className={classes.name}>{name || 'N/A'}</div>
          {email && <div className={classes.email}>{email}</div>}
        </div>
      </MenuItem>
    );
  };

  if (!activeUsers.length) return null;

  return (
    <div className={className}>
      <PopupMenu
        id="admin-switch-popup"
        getTrigger={popupTrigger}
        triggerProps={{
          currentUser,
          loading: profileLoading || usersLoading || adminLoading,
          testID: 'admin-switch-popup-trigger'
        }}
        disablePortal
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        {({ closePopup }) => (
          <div className={classes.menu}>
            {renderMenuItem(adminProfile, closePopup, true)}
            {map(activeUsers, (user) => renderMenuItem(user, closePopup))}
          </div>
        )}
      </PopupMenu>
    </div>
  );
}

LinkedAccountsSwitchPopup.propTypes = {
  className: PT.string.isRequired
};

const StyledLinkedAccountsSwitchPopup = styled(LinkedAccountsSwitchPopup)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent'
  },
  '.linkedAccountsSwitchPopup__menu': {
    background: theme.palette.background.light
  },
  '& .MuiPaper-root': {
    maxWidth: 250,
    minWidth: 240,
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    boxShadow: '0px 7px 5px #0000001A'
  },
  [`& .${classes.triggerButton}`]: {
    position: 'relative',
    minHeight: 52,
    maxWidth: 240,
    minWidth: 200,
    padding: 6,
    border: '1px solid #E5E5E5',
    borderRadius: 5,
    textAlign: 'left',
    '& .loader': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }
  },
  [`& .${classes.menu}`]: {},
  [`& .${classes.menuItem}`]: {
    position: 'relative',
    padding: '9px 8px',
    '&.selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&:not(:last-of-type)': {
      '&:after': {
        content: '""',
        display: 'inline-block',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        height: 1,
        margin: '0 8px',
        backgroundColor: '#E5E5E5'
      }
    }
  },
  [`& .${classes.imageContainer}`]: {
    minWidth: 38,
    height: 38,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  [`& .${classes.image}`]: {
    width: 38,
    height: 38,
    objectFit: 'cover',
    borderRadius: 4
  },
  [`& .${classes.name}`]: {
    marginBottom: 4,
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 150
  },
  [`& .${classes.email}`]: {
    fontSize: 12,
    lineHeight: '14px',
    color: '#B3B3B3',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 140
  }
}));

export default StyledLinkedAccountsSwitchPopup;
