import React, { useMemo } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { useMediaQueryMatches } from 'hooks';
import { MuiButtonBase, Box, styled } from 'components';
import { HorizontalProgress } from 'components/shared';
import { MdChevronRight, MdExpandMore } from 'components/icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.levelCompletion': {
    width: '80%',
    margin: '0 auto',
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%'
    }
  },
  '& .levelCompletion__title': {
    width: '100%',
    fontSize: 11,
    padding: '8px 0 2px',
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      paddingBottom: 10,
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '130%',
      color: theme.palette.moreColors.lightPurple,
      '& .levelCompletion__titleProgress': {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '150%'
      }
    }
  },
  '& .levelCompletion__items': {
    padding: '10px 0',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'repeat(4, auto)',
    gridGap: 15,
    fontSize: 13,
    fontWeight: 100,
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      padding: '32px 0',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto',
      gridGap: 16,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '150%'
    }
  },
  '& .levelCompletion__item': {
    display: 'flex',
    alignItems: 'center',
    '&.complete': {
      color: theme.palette.moreColors.yellow
    }
  },
  '& .progress': {
    height: 1,
    [theme.breakpoints.down('desktopApp')]: {
      height: 2
    },
    '& .progress__line': {
      backgroundColor: theme.palette.moreColors.purpleLight_2,
      [theme.breakpoints.down('desktopApp')]: {
        backgroundColor: theme.palette.moreColors.lightPurple
      }
    }
  }
}));

function LevelCompletionContainer(props) {
  const { title, level, skills, abilities, active, setActiveLevel } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const progress = useMemo(() => {
    const arr = [...skills, ...abilities];
    const completedCount = arr.reduce((prev, current) => prev + (current.complete ? 1 : 0), 0);
    return arr.length ? Number(((completedCount * 100) / arr.length).toFixed(2)) : 0;
  }, [abilities, skills]);

  const TitleComponent = isDesktop ? 'div' : MuiButtonBase;
  const TitleComponentProps = isDesktop ? {} : { onClick: () => setActiveLevel(level) };

  return (
    <StyledRoot className="levelCompletion">
      <TitleComponent className="levelCompletion__title" {...TitleComponentProps}>
        {isDesktop ? (
          <>
            Lv: {level}: {title} - {progress}% Complete
          </>
        ) : (
          <>
            <span>
              LV {level}: {title}
            </span>
            <Box component="span" display="flex" alignItems="center">
              <span className="levelCompletion__titleProgress">{progress}%</span>
              <MdExpandMore />
            </Box>
          </>
        )}
      </TitleComponent>
      <HorizontalProgress percent={active ? progress : 0} />
      {active && (
        <div className="levelCompletion__items">
          {abilities.map(({ name, complete, abilitiesBadgeId }, i) => (
            <div
              key={`ability__${abilitiesBadgeId}`}
              className={clsx('levelCompletion__item', complete && 'complete')}
            >
              {name}
              <MdChevronRight color="inherit" sx={{ fontSize: 18 }} />
            </div>
          ))}
          {skills.map(({ name, complete, skillsBadgeId }, i) => (
            <div
              key={`skill__${skillsBadgeId}`}
              className={clsx('levelCompletion__item', complete && 'complete')}
            >
              {name}
              <MdChevronRight color="inherit" sx={{ fontSize: 18 }} />
            </div>
          ))}
        </div>
      )}
    </StyledRoot>
  );
}

LevelCompletionContainer.propTypes = {
  active: PT.bool.isRequired,
  title: PT.string.isRequired,
  level: PT.number.isRequired,
  setActiveLevel: PT.func.isRequired,
  skills: PT.arrayOf(
    PT.shape({
      abilitiesBadgeId: PT.number,
      abilitiesId: PT.number,
      badgeUrl: PT.string,
      complete: PT.bool,
      name: PT.string
    })
  ).isRequired,
  abilities: PT.arrayOf(
    PT.shape({
      skillsBadgeId: PT.number,
      skillsId: PT.number,
      badgeUrl: PT.string,
      complete: PT.bool,
      name: PT.string
    })
  ).isRequired
};

export default LevelCompletionContainer;
