import React, { useState } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { styled } from 'components';
import { Button } from 'components/shared';
import { IndustryModal } from 'components/dialogs';
import { MdChevronRight } from 'components/icons';

const PREFIX = 'jobIndustries';
const classes = {
  container: `${PREFIX}-container`,
  chips: `${PREFIX}-chips`,
  chipsItem: `${PREFIX}-chips__item`,
  updateButton: `${PREFIX}-updateButton`
};
const StyledRoot = styled('div')(({ theme }) => ({
  [`&.${classes.container}`]: {},
  [`& .${classes.chips}`]: {
    marginTop: -13,
    display: 'flex',
    flexWrap: 'wrap'
  },
  [`& .${classes.chipsItem}`]: {
    marginTop: 13,
    padding: '9px 8px',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.light,
    borderRadius: 6
  },
  [`& .${classes.updateButton}`]: {
    marginTop: 10,
    fontSize: 12
  }
}));

function EditableJobIndustries(props) {
  const { className, isEditMode, form, onFormChange } = props;
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);

  return (
    <StyledRoot className={clsx(className, classes.container)}>
      <div className={classes.chips}>
        {form.industry.map(({ name, industry_id: indId }, i) => (
          <div key={`industry__${indId}`} className={classes.chipsItem} style={{ marginRight: 13 }}>
            {name}
          </div>
        ))}
      </div>
      {isEditMode && (
        <Button
          variant="text"
          endIcon={<MdChevronRight color="primary" />}
          className={classes.updateButton}
          onClick={() => setIsIndustryModalOpen(true)}
          testID="industry-modal-trigger-button"
        >
          Update Industries
        </Button>
      )}
      <IndustryModal
        isOpen={isIndustryModalOpen}
        formAttrs={form}
        onChange={onFormChange}
        onClose={() => setIsIndustryModalOpen(false)}
      />
    </StyledRoot>
  );
}

EditableJobIndustries.propTypes = {
  className: PT.string,
  isEditMode: PT.bool.isRequired,
  form: PT.shape({
    industry: PT.arrayOf(
      PT.shape({
        name: PT.string,
        industry_id: PT.number
      })
    )
  }).isRequired,
  onFormChange: PT.func
};

EditableJobIndustries.defaultProps = {
  className: '',
  onFormChange: () => {}
};

export default EditableJobIndustries;
