import React from 'react';
import PT from 'prop-types';
import map from 'lodash/map';
import { MenuItem, styled } from 'components';
import PopupMenu from './PopupMenu';
import defaultJobImg from 'assets/img/job_default.png';

const StyledPopup = styled(PopupMenu)(({ theme }) => ({
  '& .jobsInvitePopup__menu': {
    maxHeight: 168,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  '& .jobsInvitePopup__menuItem': {
    width: '100%',
    minHeight: 55,
    padding: '9px 16px',
    justifyContent: 'flex-start',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(255,255,255,0.2)'
    }
  },
  '& .jobsInvitePopup__menuItem-img': {
    width: 38,
    minWidth: 38,
    height: 38,
    borderRadius: 3,
    backgroundColor: '#F6F5FA',
    objectFit: 'cover'
  },
  '& .jobsInvitePopup__menuItem-name': {
    textAlign: 'left',
    marginLeft: 13,
    lineHeight: '15px',
    fontSize: 14,
    fontWeight: 'bold',
    whiteSpace: 'normal'
  }
}));

function JobsInvitePopup(props) {
  const { id, jobs, onInvite, getItemKey, ...rest } = props;

  return (
    <StyledPopup
      id={id}
      triggerTitle="Invite to Apply"
      sx={{
        '& .MuiPaper-root': {
          minWidth: 250,
          maxWidth: 290
        }
      }}
      {...rest}
    >
      {({ closePopup }) => (
        <div className="jobsInvitePopup__menu">
          {map(jobs, (job) => (
            <MenuItem
              key={getItemKey(job)}
              className="jobsInvitePopup__menuItem"
              disableRipple
              onClick={() => {
                closePopup();
                onInvite(job);
              }}
            >
              <img
                src={job.imageUrl || defaultJobImg}
                alt=""
                className="jobsInvitePopup__menuItem-img"
              />
              <div className="jobsInvitePopup__menuItem-name">{job.title}</div>
            </MenuItem>
          ))}
        </div>
      )}
    </StyledPopup>
  );
}

JobsInvitePopup.propTypes = {
  jobs: PT.arrayOf(
    PT.shape({
      id: PT.number,
      imageUrl: PT.string,
      title: PT.string
    })
  ).isRequired,
  onInvite: PT.func.isRequired,
  getItemKey: PT.func.isRequired,
  id: PT.oneOfType([PT.string, PT.number]).isRequired
};

export default JobsInvitePopup;
