import React from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import { trimStr } from 'utils';
import { Box, styled } from 'components';
import { Button, Input, Spinner } from 'components/shared';
import { useForm } from 'components/form';
import { ADD_TRAININGS, POST_TRAININGS } from 'api';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.container': {
    position: 'relative',
    padding: '15px 12px',
    borderRadius: 8,
    backgroundColor: theme.palette.moreColors.grey_2
  },
  '& .label': {
    marginBottom: 4,
    color: '#A4A4A5'
  },
  '& .submit': {
    height: 30,
    padding: '6px 16px',
    borderRadius: 15,
    fontSize: 13
  }
}));

function CourseItem(props) {
  const { afterAssign, className, isEdit, initial, id, employerProfileId, employeeProfileId } =
    props;

  const [addTraining, { loading: addTrainingLoading }] = useMutation(ADD_TRAININGS);
  const [postTraining, { loading: postTrainingLoading }] = useMutation(POST_TRAININGS);

  const loading = addTrainingLoading || postTrainingLoading;

  const { set, $, withValidation } = useForm({
    initial: {
      name: '',
      link: 'https://',
      verified: true,
      complete: false,
      taken: false,
      employerProfileId,
      employeeProfileId,
      ...initial
    },
    validations: {
      name: 'presence',
      link: ['presence', 'url']
    }
  });

  const submit = withValidation(async (validAttrs) => {
    try {
      if (isEdit && id) {
        const result = await postTraining({
          variables: {
            id,
            ...validAttrs,
            name: trimStr(validAttrs.name)
          }
        });
        const { __typename, ...rest } = result?.data?.postTrainings || {};
        afterAssign(id, { ...rest }, true);
      } else if (!isEdit) {
        const result = await addTraining({
          variables: {
            ...validAttrs,
            name: trimStr(validAttrs.name)
          }
        });
        const { __typename, ...rest } = result?.data?.addTrainings || {};
        afterAssign(id, { ...rest });
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleFieldChange = (e, { name }) => {
    set(name, e.target.value);
  };

  return (
    <StyledRoot className={`${className} container`}>
      <Box mb="15px">
        <Input
          {...$('name', handleFieldChange)}
          label="Name"
          variant="textfield"
          required
          withHelperText
          labelClassName="label"
          testID="course-name-input"
        />
      </Box>
      <Box mb="20px">
        <Input
          {...$('link', handleFieldChange)}
          label="Link"
          placeholder="https://www.example.com"
          variant="textfield"
          required
          withHelperText
          labelClassName="label"
          testID="course-link-input"
        />
      </Box>
      <Button
        variant="filled-primary"
        className="submit"
        disabled={loading}
        endIcon={loading && <Spinner size={12} />}
        onClick={submit}
        testID="course-assign-button"
      >
        Assign
      </Button>
    </StyledRoot>
  );
}

CourseItem.propTypes = {
  className: PT.string,
  afterAssign: PT.func,
  employerProfileId: PT.number,
  employeeProfileId: PT.number.isRequired,
  initial: PT.objectOf(PT.any),
  id: PT.oneOfType([PT.string, PT.number]).isRequired,
  isEdit: PT.bool
};

CourseItem.defaultProps = {
  className: '',
  afterAssign: () => {},
  employerProfileId: null,
  initial: {},
  isEdit: false
};

export default CourseItem;
