import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { find } from 'lodash';
import { useJobTypesQuery } from 'hooks';
import { GET_CAREER_PATH_ITEMS_BY_JOB_TYPES } from 'api';

export default function useGrowJobTypes({ curJobTypeId, futureJobTypeId }) {
  const [isPathGroupExist, setIsPathGroupExist] = useState(true);

  const {
    getJobTypesByIndustryId,
    jobTypesByIndustry,
    jobTypesByIndustryIdLoading: typesLoading
  } = useJobTypesQuery();

  const [fetchPathGroups, { data: pathGroupsData }] = useLazyQuery(
    GET_CAREER_PATH_ITEMS_BY_JOB_TYPES
  );
  const pathGroups = pathGroupsData?.getCareerPathByJobTypeIds || [];

  const findPathGroup = useCallback(
    (startJobTypeId, endJobTypeId) =>
      find(pathGroups, {
        startJobTypeId: Number(startJobTypeId),
        endJobTypeId: Number(endJobTypeId)
      }),
    [pathGroups]
  );

  const getPathGroups = (startJobTypeId, endJobTypeId) => {
    if (startJobTypeId || endJobTypeId) {
      fetchPathGroups({
        variables: {
          startJobTypeId: startJobTypeId ? Number(startJobTypeId) : undefined,
          endJobTypeId: endJobTypeId ? Number(endJobTypeId) : undefined
        }
      });
    }
  };

  useEffect(() => {
    getPathGroups(curJobTypeId);
  }, [curJobTypeId]);

  useEffect(() => {
    getPathGroups(undefined, futureJobTypeId);
  }, [futureJobTypeId]);

  useEffect(() => {
    if (curJobTypeId && futureJobTypeId) {
      const path = findPathGroup(curJobTypeId, futureJobTypeId);
      setIsPathGroupExist(!!path);
    }
  }, [curJobTypeId, futureJobTypeId, pathGroups]);

  const formateJobTypesOptionsBySelectedJob = useCallback(
    (jobTypeId, isFutureJobOptions) => {
      if (jobTypesByIndustry.length) {
        const options = jobTypesByIndustry.map((j) => {
          const isCareerPath = isFutureJobOptions
            ? findPathGroup(jobTypeId, j.id)
            : findPathGroup(j.id, jobTypeId);
          return {
            ...j,
            isCareerPath,
            optionProps: {
              value: j.id,
              label: `${isCareerPath ? 'New! ** ' : ''}${j.name}`
            }
          };
        });
        const withPath = options.filter((o) => o.isCareerPath);
        const withoutPath = options
          .filter((o) => !o.isCareerPath)
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        return [
          ...withPath,
          ...(withPath.length
            ? [
                {
                  delimiter: true,
                  optionProps: { disabled: true, label: '------------------------', value: '' }
                }
              ]
            : []),
          ...withoutPath
        ];
      }
      return [];
    },
    [findPathGroup, jobTypesByIndustry]
  );

  const currentJobTypesOptions = useMemo(
    () => formateJobTypesOptionsBySelectedJob(futureJobTypeId, false),
    [formateJobTypesOptionsBySelectedJob, futureJobTypeId]
  );

  const futureJobTypesOptions = useMemo(
    () => formateJobTypesOptionsBySelectedJob(curJobTypeId, true),
    [formateJobTypesOptionsBySelectedJob, curJobTypeId]
  );

  return {
    getJobTypesByIndustryId,
    getPathGroups,
    jobTypesByIndustry,
    isPathGroupExist,
    currentJobTypesOptions,
    futureJobTypesOptions,
    typesLoading
  };
}
