import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import { isEmpty, head } from 'lodash';
import { Box, styled } from 'components';
import { MdExpandMore, MdExpandLess } from 'components/icons';
import { Button, Select } from 'components/shared';
import {
  BadgesPage,
  CategoriesNavigator,
  ClassesPage,
  ConnectionsPage,
  data as employeeGrowData,
  GrowInitialSetup,
  JobFitsCarousel,
  JobFitCardV2,
  PathPage,
  PositionForm,
  ProgressPage,
  QuizzesPage,
  RatingsPage,
  RecentBadgeCard,
  ResourcesPage,
  SalaryPage,
  ScorePage,
  SuggestedClasses,
  SuggestedConnections,
  SuggestedJobFits,
  SuggestedQuizzes,
  SuggestedResources,
  TotalGrowScore,
  TrendsPage
} from 'components/EmployeeGrow';
import { withEmployeeGrow, withConnections } from 'hocs';
import { useEmployeeTrainings, useMediaQueryMatches } from 'hooks';
import { getUserId, getRoutes } from 'utils';
import { GET_QUIZZES, GET_EMPLOYEE_JOBS, GET_CUSTOM_CONTENT_BY_EMPLOYER } from 'api';
import styles from 'styles/Grow/employee/rootStyles';

const StyledRoot = styled('div')(styles);

const ROUTES = getRoutes();
const { PAGES } = employeeGrowData;

const enhance = (Component) => withEmployeeGrow(withConnections(Component));

function EmployeeGrow(props) {
  const {
    addConnection,
    careerDev,
    careerDevLoading,
    careerDevSaveError,
    careerDevSaveLoading,
    careerPath,
    careerPathLoading,
    careerPathCalled,
    connectionByProfileIdLoading,
    connectionsLoading,
    futureConnections,
    getCareerDev,
    getCareerPath,
    getConnections,
    getRecentBadges,
    recentBadges,
    removeConnection,
    sameConnections,
    saveCareerDev,
    showConnectionProfile,
    starredConnections
  } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const userId = Number(getUserId());

  const {
    careerHealthScore,
    currentlySearching = false,
    curJobTypeId,
    futureJobTypeId,
    id: careerDevId,
    industryId,
    showEmployerData = false
  } = careerDev || {};
  const suggestedConnections = futureConnections.filter((o) => !o.starred);
  const { score = 0, sectionScores = [] } = careerHealthScore || {};
  const { abilitiesBadges = [], skillsBadges = [] } = careerPath || {};

  const recentBadge = useMemo(() => {
    const recentAbilityBadge = head(recentBadges?.abilitiesBadges || []);
    const recentSkillBadge = head(recentBadges?.skillsBadges || []);
    return recentAbilityBadge || recentSkillBadge;
  }, [JSON.stringify(recentBadges)]);

  const { fetchTrainings, trainings, editTraining } = useEmployeeTrainings();
  const suggestedCourses = trainings.filter((o) => !o.taken);

  const [fetchQuizzes, { data: quizzesData }] = useLazyQuery(GET_QUIZZES, {
    fetchPolicy: 'no-cache'
  });
  const quizzes = quizzesData?.getQuiz || [];

  const [fetchJobs, { data: jobsData }] = useLazyQuery(GET_EMPLOYEE_JOBS, {
    fetchPolicy: 'network-only'
  });
  const jobs = jobsData?.employeeJobs || [];
  const suggestedJobs = jobs.length > 2 ? [...jobs].splice(0, 2) : jobs;

  const [fetchCustomContent, { data: customContentData }] = useLazyQuery(
    GET_CUSTOM_CONTENT_BY_EMPLOYER,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const customContent = customContentData?.getEmployerCustomContent || {
    featured: [],
    generic: []
  };

  const [page, setPage] = useState(PAGES.path);
  const [showTotalScore, setShowTotalScore] = useState(true);
  const [showScoreDetails, setShowScoreDetails] = useState(false);
  const [showRecentBadge, setShowRecentBadge] = useState(true);

  const changePage = (pageName) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPage(pageName);
  };

  const handleCareerDevChange = useCallback(
    (data) => {
      saveCareerDev({ ...data, industryId });
    },
    [industryId, saveCareerDev]
  );

  const updateCareerDevWithPath = useCallback(
    (curJobTypeIdParam, futJobTypeIdParam) => {
      handleCareerDevChange({
        curJobTypeId: curJobTypeIdParam,
        futureJobTypeId: futJobTypeIdParam,
        isNew: true // create new career path
      });
    },
    [handleCareerDevChange]
  );

  const takeCourse = (course) => editTraining({ ...course, taken: true });

  const handleAddConnection = (connection) => {
    addConnection(connection, careerDevId);
  };

  const handleRemoveConnection = (connection) => {
    removeConnection(connection, careerDevId);
  };

  const handleShowConnection = (userIds, index) => {
    showConnectionProfile(userIds, index, { careerDevId });
  };

  useEffect(() => {
    getCareerDev();
    fetchTrainings();
    fetchQuizzes();
    fetchJobs();
    // @TODO: get custom content only if user hooked up to employer (where to get this info?)
    // fetchCustomContent({ variables: { employerProfileId: Number() } });
  }, []);

  useEffect(() => {
    if (careerDevId) {
      getCareerPath();
      getConnections({
        careerDevId,
        currentJobTypeId: curJobTypeId,
        futureJobTypeId,
        careerDevIndustryId: industryId
      });
    }
  }, [careerDevId]);

  useEffect(() => {
    setPage(PAGES.path);
  }, [isDesktop]);

  const renderPositionForm = () => (
    <PositionForm
      industryId={industryId}
      currentlySearching={currentlySearching}
      curJobTypeId={curJobTypeId}
      futureJobTypeId={futureJobTypeId}
      loading={careerDevSaveLoading}
      onChange={handleCareerDevChange}
      showEmployerData={showEmployerData}
    />
  );

  const renderPage = () => {
    if (page === PAGES.path) {
      const careerPathPage = (
        <PathPage
          careerPath={careerPath}
          careerPathLoading={careerPathLoading}
          careerPathCalled={careerPathCalled}
          futureJobTypeId={futureJobTypeId}
          industryId={industryId}
          onCreateNewCareerPath={updateCareerDevWithPath}
        />
      );
      if (!isDesktop) {
        return (
          <Box mt="30px" mb="40px" px="16px">
            <Box mb="40px">{renderPositionForm()}</Box>
            {careerPathPage}
          </Box>
        );
      }
      return careerPathPage;
    }
    if (page === PAGES.badges) {
      return (
        <BadgesPage
          abilitiesBadges={abilitiesBadges}
          skillsBadges={skillsBadges}
          recentBadges={recentBadges}
        />
      );
    }
    if (page === PAGES.trends) {
      return <TrendsPage />;
    }
    if (page === PAGES.salary) {
      return <SalaryPage />;
    }
    if (page === PAGES.metrics) {
      return <ScorePage sectionScores={sectionScores} />;
    }
    if (page === PAGES.progress) {
      return <ProgressPage careerPath={careerPath} />;
    }
    if (page === PAGES.classes) {
      return <ClassesPage courses={trainings} onTake={takeCourse} />;
    }
    if (page === PAGES.connections) {
      return (
        <ConnectionsPage
          sameConnections={sameConnections}
          starredConnections={starredConnections}
          futureConnections={futureConnections}
          onAdd={handleAddConnection}
          onRemove={handleRemoveConnection}
          onProfileShow={handleShowConnection}
        />
      );
    }
    if (page === PAGES.quizzes) {
      return <QuizzesPage quizzes={quizzes} />;
    }
    if (page === PAGES.blogs) {
      return <ResourcesPage />;
    }
    if (page === PAGES.ratings) {
      return <RatingsPage />;
    }
    return null;
  };

  const renderRecentBadge = (RecentBadgeCardProps) => (
    <RecentBadgeCard
      title={recentBadge.name}
      onClick={() => changePage(PAGES.badges)}
      {...RecentBadgeCardProps}
    />
  );

  const renderContent = () => {
    if (isDesktop) {
      return (
        <div className="desktopContentContainer">
          <div className="leftAside">
            <div className="top">
              {recentBadge && <Box mb="25px">{renderRecentBadge()}</Box>}
              <Box width="225px" mx="auto">
                <Box mb="16px" display="flex" justifyContent="center">
                  <TotalGrowScore score={score} setPage={changePage} />
                </Box>
                <Box mb="27px">{renderPositionForm()}</Box>
                <CategoriesNavigator
                  currentPage={page}
                  onSelect={(selectedPage) => changePage(selectedPage)}
                />
              </Box>
            </div>
            <div className="bottom">
              <Box mb="14px">
                <div className="sectionHeader">
                  <h3 className="sectionTitle">Suggested Connections</h3>
                  <Button className="seeAllButton" onClick={() => changePage(PAGES.connections)}>
                    See All
                  </Button>
                </div>
                <SuggestedConnections
                  connections={suggestedConnections}
                  loading={connectionsLoading}
                  connectionByProfileIdLoading={connectionByProfileIdLoading}
                  onAdd={handleAddConnection}
                />
              </Box>
              {/* <div>
                <div className="sectionHeader">
                  <h3 className="sectionTitle">Follow These Companies</h3>
                </div>
              </div> */}
            </div>
          </div>

          <div className="center">
            <div className="top">
              <div
                className={clsx('centralBlockContainer', page === PAGES.path && 'pathContainer')}
              >
                {renderPage()}
              </div>
            </div>
            <div className="bottom">
              <Box mb="25px">
                <div className="sectionHeader">
                  <h3 className="sectionTitle">Job Fits</h3>
                </div>
                <JobFitsCarousel jobs={jobs} />
              </Box>
              {!!customContent.generic.length && (
                <Box mb="25px" className="customContentContainer horizontal">
                  {customContent.generic.map((o, i) => (
                    <iframe
                      key={`generic__${i}`}
                      src={o.imageUrl}
                      frameborder="0"
                      className="customContent__item"
                    />
                  ))}
                </Box>
              )}
              <Box mb="25px">
                <div className="sectionHeader">
                  <h3 className="sectionTitle">Quizzes to Jumpstart your Career</h3>
                  <Button className="seeAllButton" onClick={() => changePage(PAGES.quizzes)}>
                    See All
                  </Button>
                </div>
                <SuggestedQuizzes quizzes={quizzes} />
              </Box>
              <Box mb="25px">
                <div className="sectionHeader">
                  <h3 className="sectionTitle">Resources to Guide your Journey</h3>
                  <Button className="seeAllButton" onClick={() => changePage(PAGES.blogs)}>
                    See All
                  </Button>
                </div>
                <SuggestedResources />
              </Box>
            </div>
          </div>

          <div className="rightAside">
            <div className="top">
              {!!customContent.featured.length ? (
                <Box mb="25px" className="customContentContainer vertical">
                  <div className="sectionHeader">
                    <h3 className="sectionTitle light">Welcome to the Team</h3>
                  </div>
                  {customContent.featured.map((o, i) => (
                    <iframe
                      key={`generic__${i}`}
                      src={o.imageUrl}
                      frameborder="0"
                      className="customContent__item"
                    />
                  ))}
                </Box>
              ) : (
                <div>
                  <Box mb="25px">
                    <div className="sectionHeader">
                      <h3 className="sectionTitle light">Job Fits</h3>
                    </div>
                    <SuggestedJobFits jobs={suggestedJobs} />
                  </Box>
                  <Box mb="25px">
                    <div className="sectionHeader">
                      <h3 className="sectionTitle light">Jumpstart your Career</h3>
                    </div>
                    <SuggestedQuizzes quizzes={quizzes} />
                  </Box>
                </div>
              )}
            </div>
            <div className="bottom">
              <Box mb="14px">
                <div className="sectionHeader">
                  <h3 className="sectionTitle">Assigned Courses</h3>
                  <Button className="seeAllButton" onClick={() => changePage(PAGES.progress)}>
                    See All
                  </Button>
                </div>
              </Box>
              <div>
                <div className="sectionHeader">
                  <h3 className="sectionTitle">Classes</h3>
                  <Button className="seeAllButton" onClick={() => changePage(PAGES.classes)}>
                    See All
                  </Button>
                </div>
                <SuggestedClasses courses={suggestedCourses} onTake={takeCourse} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="mobContentContainer">
        {showRecentBadge && recentBadge && (
          <Box mb="32px" px="20px">
            {renderRecentBadge({
              component: 'div',
              disableRipple: true,
              onClick: () => {},
              onClose: () => setShowRecentBadge(false)
            })}
          </Box>
        )}
        <h1 className="mainTitle">WT Grow Portal</h1>
        <Box textAlign="center">
          <Button
            variant="text"
            className="scoreToggleBtn"
            sx={{ mb: '28px' }}
            endIcon={showTotalScore ? <MdExpandLess /> : <MdExpandMore />}
            disableRipple
            onClick={() => setShowTotalScore((prev) => !prev)}
          >
            Your grow score
          </Button>
        </Box>
        {showTotalScore && (
          <Box mb="16px" display="flex" justifyContent="center">
            <TotalGrowScore
              score={score}
              setPage={() => setShowScoreDetails((prev) => !prev)}
              detailsButtonTitle="Show details"
            />
          </Box>
        )}
        {showScoreDetails && (
          <div>
            <ScorePage sectionScores={sectionScores} />
            <Box textAlign="center">
              <Button
                className="outlinedAction"
                endIcon={<MdExpandLess />}
                onClick={() => setShowScoreDetails(false)}
              >
                Details
              </Button>
            </Box>
          </div>
        )}
        <Box pt="30px" px="16px">
          <Select
            id="mobile-page-select"
            value={page}
            options={[
              { value: PAGES.path, label: 'Your path' },
              { value: PAGES.classes, label: 'Classes' },
              { value: PAGES.ratings, label: 'Ratings' },
              { value: PAGES.trends, label: 'Trends' },
              { value: PAGES.salary, label: 'Salary' },
              { value: PAGES.connections, label: 'Connections' }
            ]}
            onChange={(e) => setPage(e.target.value)}
            inputVariant="outlined"
            className="outlinedSelect big"
            MenuProps={{ disablePortal: true }}
            testID="grow-mobile-page-select"
          />
        </Box>

        {renderPage()}

        <div className="bottomContent">
          {/* <div className="section">
            <div className="sectionHeader">
              <h3 className="sectionTitle">Welcome to the team</h3>
              <Button className="seeAllButton">See All</Button>
            </div>
          </div> */}
          <div className="section">
            <div className="sectionHeader">
              <h3 className="sectionTitle">Job Fits</h3>
              {/* <Button className="seeAllButton">See All</Button> */}
            </div>
            {suggestedJobs.map((job) => (
              <Box key={`suggestedJob__${job.id}`} mb="12px">
                <JobFitCardV2 job={job} />
              </Box>
            ))}
          </div>
          <div className="section">
            <div className="sectionHeader">
              <h3 className="sectionTitle">Classes</h3>
              <Button className="seeAllButton" onClick={() => changePage(PAGES.classes)}>
                See All
              </Button>
            </div>
            <SuggestedClasses courses={suggestedCourses} onTake={takeCourse} />
          </div>
          <div className="section">
            <div className="sectionHeader">
              <h3 className="sectionTitle">Quizzes to jumpstart your career</h3>
              {/* <Button className="seeAllButton">See All</Button> */}
            </div>
            <SuggestedQuizzes quizzes={quizzes} />
          </div>
          {/* <div className="section">
            <div className="sectionHeader">
              <h3 className="sectionTitle">Conversation with </h3>
              <Button className="seeAllButton">See All</Button>
            </div>
          </div> */}
        </div>
      </div>
    );

    // return (
    //   <div className="notYetAvailable">
    //     <p>This is best viewed on desktop and is not yet available for mobile devices</p>
    //     <Button
    //       variant="filled-primary"
    //       isRouterLink
    //       to={ROUTES.employee.dashboard}
    //       sx={{ height: 44, width: 218, marginTop: '15px', padding: 'auto !important' }}
    //     >
    //       Back to Dashboard
    //     </Button>
    //   </div>
    // );
  };

  const growIsEmpty = isEmpty(careerDev);

  return (
    <StyledRoot>
      <div className="contentContainer">
        {(() => {
          if (growIsEmpty && careerDevLoading) return null;
          if (growIsEmpty) {
            return (
              <GrowInitialSetup
                onSave={saveCareerDev}
                loading={careerDevSaveLoading}
                isSetupFailed={!!careerDevSaveError}
              />
            );
          }
          return renderContent();
        })()}
      </div>
    </StyledRoot>
  );
}

EmployeeGrow.propTypes = {
  addConnection: PT.func.isRequired,
  careerDev: PT.shape({
    careerHealthScore: PT.shape({
      score: PT.number,
      sectionScores: PT.array
    }),
    currentlySearching: PT.bool,
    curJobTypeId: PT.number,
    futureJobTypeId: PT.number,
    id: PT.number,
    industryId: PT.number,
    showEmployerData: PT.bool
  }).isRequired,
  careerPath: PT.shape({
    careerPathGroupItems: PT.arrayOf(PT.objectOf(PT.any)),
    abilitiesBadges: PT.arrayOf(PT.objectOf(PT.any)),
    skillsBadges: PT.arrayOf(PT.objectOf(PT.any))
  }).isRequired,
  careerPathLoading: PT.bool.isRequired,
  careerPathCalled: PT.bool.isRequired,
  careerDevLoading: PT.bool.isRequired,
  careerDevSaveLoading: PT.bool.isRequired,
  careerDevSaveError: PT.any,
  connectionByProfileIdLoading: PT.number,
  connectionsLoading: PT.bool.isRequired,
  futureConnections: PT.arrayOf(PT.any).isRequired,
  getCareerDev: PT.func.isRequired,
  getCareerPath: PT.func.isRequired,
  getRecentBadges: PT.func.isRequired,
  getConnections: PT.func.isRequired,
  recentBadges: PT.shape({
    abilitiesBadges: PT.arrayOf(PT.objectOf(PT.any)),
    skillsBadges: PT.arrayOf(PT.objectOf(PT.any))
  }).isRequired,
  removeConnection: PT.func.isRequired,
  sameConnections: PT.arrayOf(PT.any).isRequired,
  saveCareerDev: PT.func.isRequired,
  showConnectionProfile: PT.func.isRequired,
  starredConnections: PT.arrayOf(PT.any).isRequired
};

EmployeeGrow.defaultProps = {
  connectionByProfileIdLoading: null,
  careerDevSaveError: undefined
};

export default enhance(EmployeeGrow);
