import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  LANDING_ORIGIN,
  qaAttr,
  getUserId,
  getAuthToken,
  getUrlParameter,
  getPlanIdParamFromSearch,
  getRoutes,
  employerStartPage,
  employeeStartPage
} from 'utils';
import { gtmTrackOnSignIn, gtmTrackOnSignUp } from 'utils/gtm';
import { track } from 'utils/segmentAnalytics';
import { Box, FormHelperText, styled } from 'components';
import { Button, IconButton, Input, Spinner } from 'components/shared';
import { MdArrowBack } from 'components/icons';
import { useForm } from 'components/form';
import { GoogleAuthButton, FacebookAuthButton } from 'components/Login';
import { useAuth, useEmployerProfileQuery, useEmployeeProfileQuery } from 'hooks';
import styles from 'styles/Login';
import { LOGIN, POST_VERIFY } from 'api';
import LoginLayout from '../Layouts/LoginLayout';

const StyledLoginLayout = styled(LoginLayout)(styles);

const ROUTES = getRoutes();

const getReferrerLocation = (location) => location?.state?.from?.location || {};

function SignIn() {
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useAuth();

  const { fetch: fetchEmployerProfile } = useEmployerProfileQuery({ autoFetch: false });
  const { fetch: fetchEmployeeProfile } = useEmployeeProfileQuery({ autoFetch: false });

  const [signIn, { error: signInError, loading: signInLoading }] = useMutation(LOGIN, {
    fetchPolicy: 'no-cache'
  });
  const [verifyToken, { error: verifyError }] = useMutation(POST_VERIFY, {
    fetchPolicy: 'no-cache'
  });
  const backendError =
    signInError?.graphQLErrors?.[0]?.message || verifyError?.graphQLErrors?.[0]?.message;

  const { $, attrs, set, validate } = useForm({
    initial: { email: '', password: '' },
    validations: {
      email: ['presence', 'email'],
      password: 'presence'
    }
  });

  const verifySignUpToken = async () => {
    const tokenParam = location.search ? getUrlParameter(location.search, 'token') : '';
    const planIdParam = location.search ? getUrlParameter(location.search, 'plan_id') : '';

    if (tokenParam) {
      try {
        const { data } = await verifyToken({ variables: { token: tokenParam } });
        const { token, userId } = data?.postEmployeeVerify || {};

        if (token && userId) {
          const postSignUpReferrerObj = localStorage.getItem('post_signup_referrer');
          let referrerPathname = JSON.parse(postSignUpReferrerObj)?.referrer;
          const referrerState = {};
          if (planIdParam) {
            // If verification link has plan_id param, then employer should be navigated
            // to profile page after onboarding and upgrade plan modal should be displayed
            referrerPathname = ROUTES.employer.profile;
            referrerState.planId = planIdParam;
          }
          gtmTrackOnSignUp();
          localStorage.setItem('token', token);
          localStorage.setItem('userId', userId);
          localStorage.removeItem('post_signup_referrer');
          login();
          navigate(ROUTES.onboarding.default, {
            replace: true,
            state: {
              from: {
                location: {
                  pathname: referrerPathname,
                  state: referrerState
                }
              }
            }
          });
        }
      } catch (error) {
        const errorMsg = error.graphQLErrors?.[0]?.message;
        console.error('Verify token error:', errorMsg);
      }
    }
  };

  const authAsEmployee = async (employeeProfile) => {
    const { profile_id, profileWizard, regStep = '' } = employeeProfile;
    const referrerLocation = getReferrerLocation(location);
    localStorage.setItem('profileId', profile_id);
    track('User Signed In');

    if (profileWizard) {
      const {
        pathname: referrer,
        search: referrerSearch = '',
        state: referrerState
      } = referrerLocation;

      login();
      navigate((referrer || employeeStartPage) + referrerSearch, {
        replace: true,
        state: referrerState
      });
    } else {
      login();
      navigate(ROUTES.onboarding.default, {
        replace: true,
        state: { from: { location: referrerLocation }, regStep: `employee_${regStep}` }
      });
    }
  };

  const authAsEmployer = async (employerProfile) => {
    const { profile_id, profileWizard, regStep = '' } = employerProfile;
    const referrerLocation = getReferrerLocation(location);
    const planIdParam = getPlanIdParamFromSearch(
      getReferrerLocation(location).search || location.search || ''
    );
    localStorage.setItem('profileId', profile_id);
    track('User Signed In');

    if (profileWizard) {
      let {
        pathname: referrer,
        // eslint-disable-next-line prefer-const
        search: referrerSearch = '',
        state: referrerState
      } = referrerLocation;

      // if planId presence - navigate to profile and show plans modal
      referrer = planIdParam ? ROUTES.employer.profile : referrer;
      referrerState = planIdParam ? { planId: planIdParam } : referrerState;

      login();
      navigate((referrer || employerStartPage) + referrerSearch, {
        replace: true,
        state: referrerState
      });
    } else {
      login();
      navigate(ROUTES.onboarding.default, {
        replace: true,
        state: { from: { location: referrerLocation }, regStep: `employer_${regStep}` }
      });
    }
  };

  const authUser = async () => {
    const token = getAuthToken();
    const userId = getUserId();

    if (token && userId) {
      const { data: employeeData } = await fetchEmployeeProfile();

      if (employeeData?.employeeProfile) {
        authAsEmployee(employeeData.employeeProfile);
        return;
      }

      const { data: employerData } = await fetchEmployerProfile();

      if (employerData?.employerProfile) {
        authAsEmployer(employerData.employerProfile);
      } else {
        track('User Signed In');
        const referrerLocation = getReferrerLocation(location);
        login();
        navigate(ROUTES.onboarding.default, {
          replace: true,
          state: { from: { location: referrerLocation } }
        });
      }
    }
  };

  useEffect(() => {
    authUser();
  }, []);

  useEffect(() => {
    if (location.pathname === '/verify') {
      verifySignUpToken();
    }
  }, [location]);

  const handleUserSignIn = async ({ email, password, googleToken, fbToken }) => {
    const { data } = await signIn({ variables: { email, password, googleToken, fbToken } });
    const { token, userId } = data?.logIn || {};

    if (token && userId) {
      gtmTrackOnSignIn();
      localStorage.setItem('token', token);
      localStorage.setItem('userId', userId);
      localStorage.removeItem('post_signup_referrer');
      authUser();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    validate().then(async ({ email, password }) => {
      handleUserSignIn({ email, password });
    });
  };

  const handleInputChange = (e, { name }) => {
    set(name, e.target.value);
  };

  return (
    <StyledLoginLayout className="container">
      <div className="content">
        <div className="contentHeader">
          <IconButton
            component="a"
            href={LANDING_ORIGIN}
            color="primary"
            aria-label="to landing home"
          >
            <MdArrowBack color="inherit" />
          </IconButton>
        </div>
        <div className="mainContent">
          <h1 className="pageTitle" {...qaAttr('sign-in-title')}>
            Sign In
          </h1>
          <div className="centeredBlock flexColumn">
            <GoogleAuthButton
              authType="signIn"
              className="mainButton"
              onSuccess={handleUserSignIn}
            />
            <FacebookAuthButton
              authType="signIn"
              className="mainButton"
              onSuccess={handleUserSignIn}
            />
          </div>
          <form className="centeredBlock" noValidate onSubmit={submitForm}>
            <Input
              id="email"
              {...$('email', handleInputChange)}
              placeholder="Email"
              type="email"
              required
              withHelperText
              highlightInputOnError={false}
              autoComplete="email"
              FormControlProps={{ sx: { mb: '16px' } }}
              analyticParams={{
                key: `Email focused (Sign In)`,
                trigger: 'focus'
              }}
              testID="sign-in-email-input"
            />
            <Box mb="16px">
              <Input
                id="password"
                {...$('password', handleInputChange)}
                placeholder="Password"
                type="password"
                withHelperText
                highlightInputOnError={false}
                autoComplete="current-password"
                analyticParams={{
                  key: `Password focused (Sign In)`,
                  trigger: 'focus'
                }}
                testID="sign-in-password-input"
              />
              {backendError && (
                <FormHelperText error className="helperText">
                  {backendError}
                </FormHelperText>
              )}
            </Box>
            <Button
              type="submit"
              variant="filled-primary"
              className="mainButton submitBtn"
              disabled={signInLoading}
              endIcon={signInLoading && <Spinner size={12} />}
              testID="sign-in-submit-button"
            >
              Sign In
            </Button>
            <Button
              isRouterLink
              to={ROUTES.login.newPwd}
              variant="text"
              width="100%"
              sx={{ mb: '16px', fontSize: 14, lineHeight: '22px' }}
              testID="go-to-forgot-password-page"
            >
              Forgot Password
            </Button>
          </form>
        </div>
        <div className="centeredBlock">
          <Box fontSize={14} textAlign="center" lineHeight="22px">
            <Box component="p" fontWeight="bold">
              Don’t Have an Account?
            </Box>
            <Button
              isRouterLink
              to={{
                pathname: ROUTES.login.signUp,
                search: location.search
              }}
              // save referrer after navigation to sign up
              state={{ ...(location?.state?.from ? { from: location.state.from } : {}) }}
              variant="text"
              width="100%"
              sx={{ fontSize: 14, lineHeight: '22px' }}
              testID="go-to-sign-up-page"
            >
              Sign Up
            </Button>
          </Box>
        </div>
      </div>
    </StyledLoginLayout>
  );
}

SignIn.propTypes = {};

export default SignIn;
