import React from 'react';
import PT from 'prop-types';
import { GoogleLogin } from '@react-oauth/google';
import { parseJwt } from "../../utils";

const { GOOGLE_CLIENT_ID } = process.env;

function GoogleAuthButton({ authType, title, onSuccess, onFailure, ...rest }) {
  const isSingIn = authType === 'signIn';
  const buttonTitle = isSingIn ? 'Sign In With Google' : 'Sign Up With Google';

  const handleSuccess = ({ credential }) => {
    const data = parseJwt(credential) || {};
    const { email } = data;

    if(!email) {
      throw new Error('User email does not exist at Google')
    }
    onSuccess({ googleToken: credential, email });
  };

  const handleFailure = () => {
    console.log('Google auth failure - no more information available');
    if (onFailure) onFailure();
  };

  return (
    <div style={{marginBottom: 18}}>
      <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleFailure}
          size={'large'}
          shape={'rectangular'}
          logo_alignment={'center'}
          width={'320'}
        />
    </div>
  )
}

GoogleAuthButton.propTypes = {
  authType: PT.oneOf(['signIn', 'signUp']).isRequired,
  title: PT.string,
  onSuccess: PT.func.isRequired,
  onFailure: PT.func
};

GoogleAuthButton.defaultProps = {
  title: '',
  onFailure: () => {}
};

export default GoogleAuthButton;
