import React, { useState, useEffect, useCallback, useRef } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import map from 'lodash/map';
import { withApplicantActions, withApplicantProfileDialog } from 'hocs';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Spinner } from 'components/shared';
import { GET_NEXT_STEPS_APPLICANTS } from 'api';
import styles from 'styles/ApplicantsDashboard';
import ApplicantsSearch from './ApplicantsSearch';
import ListRowItem from './ListRowItem';
import ListCardItem from './ListCardItem';
import { NEXT_STEPS_TAB, GRID_LAYOUT } from './data';
import { filterActiveApplicants, getApplicantUserId } from './utils';

const StyledRoot = styled('div')(styles);

const enhance = (Component) => withApplicantActions(withApplicantProfileDialog(Component));

function NextStepsApplicants(props) {
  const {
    acceptApplicant,
    closeApplicantProfileDialog,
    jobId,
    layoutType,
    openApplicantProfileDialog,
    rejectApplicant,
    saveUserToJobsNotes,
    starApplicant,
    setApplicantProfileDialogProps,
    webUrl
  } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [list, setList] = useState([]);
  const [starredApplicants, setStarredApplicants] = useState({});
  const [notesByUserIdLoading, setNotesByUserIdLoading] = useState({});

  const [getApplicants, { data, loading = true }] = useLazyQuery(GET_NEXT_STEPS_APPLICANTS, {
    variables: { jobId: parseInt(jobId) },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    getApplicants();
  }, []);

  useEffect(() => {
    if (data?.nextSteps) {
      setList(filterActiveApplicants(data.nextSteps));
    }
  }, [JSON.stringify(data)]);

  const onSuggestionSelect = (suggestion) => {
    if (suggestion) {
      const { data: applicantData, type } = suggestion;
      setList([{ ...applicantData }]);
    }
  };

  const onSearchClear = () => {
    getApplicants();
  };

  const handleAccept = useCallback(
    (applicant) => {
      closeApplicantProfileDialog();
      acceptApplicant({
        applicantData: applicant,
        jobId,
        jobWebUrl: webUrl,
        onSuccess: () => {
          getApplicants();
        }
      });
    },
    [jobId, webUrl, acceptApplicant, closeApplicantProfileDialog, getApplicants]
  );

  const handleReject = useCallback(
    (applicant) => {
      closeApplicantProfileDialog();
      rejectApplicant({
        applicantData: applicant,
        jobId,
        jobWebUrl: webUrl,
        onSuccess: () => {
          getApplicants();
        }
      });
    },
    [jobId, webUrl, rejectApplicant, closeApplicantProfileDialog, getApplicants]
  );

  const handleStar = useCallback(
    (applicant, starred = true, isProfileDialog = false) => {
      const applicantUID = getApplicantUserId(applicant);
      starApplicant({
        applicantData: applicant,
        jobId,
        starred,
        onSuccess: () => {
          if (isProfileDialog) {
            setApplicantProfileDialogProps((prev) => ({ ...prev, isStarred: starred }));
          }
          setStarredApplicants((prev) => ({ ...prev, [applicantUID]: starred }));
        }
      });
    },
    [jobId, starApplicant, setApplicantProfileDialogProps]
  );

  const onRejectFromChatSuccess = useCallback(() => {
    getApplicants();
  }, [getApplicants]);

  const openApplicantProfile = useCallback(
    (applicant, extraParams = {}) => {
      const { dialogProps = {} } = extraParams;
      const applicantUID = getApplicantUserId(applicant);
      const isStarred = starredApplicants[applicantUID] || applicant.starredJob;
      const isApproved = applicant.approvedJob;
      const isRejected = applicant.passedJob;
      let approveStatus = '';
      if (isApproved) approveStatus = 'approved';
      if (isRejected) approveStatus = 'rejected';

      openApplicantProfileDialog({
        id: applicantUID,
        jobId,
        jobLink: webUrl,
        isStarred,
        approveStatus,
        withActions: true,
        onAccept: () => handleAccept(applicant, {}),
        onReject: () => handleReject(applicant, {}),
        onStar: (starred) => handleStar(applicant, starred, true),
        ...dialogProps
      });
    },
    [
      JSON.stringify(starredApplicants),
      starredApplicants,
      jobId,
      webUrl,
      openApplicantProfileDialog,
      handleAccept,
      handleReject,
      handleStar
    ]
  );

  const handleSaveNotes = useCallback(
    async (applicant, notes) => {
      const applicantUID = getApplicantUserId(applicant);
      setNotesByUserIdLoading((prev) => ({ ...prev, [applicantUID]: true }));
      await saveUserToJobsNotes({
        applicantData: applicant,
        jobId,
        notes
      });
      setNotesByUserIdLoading((prev) => ({ ...prev, [applicantUID]: false }));
    },
    [jobId, saveUserToJobsNotes]
  );

  const applicantsProps = {
    jobId,
    webUrl,
    type: NEXT_STEPS_TAB,
    onSelect: openApplicantProfile,
    onReject: handleReject,
    onAccept: handleAccept,
    onStar: handleStar,
    onMoveToNext: onRejectFromChatSuccess,
    onNotesSave: handleSaveNotes,
    withNotes: true
  };

  return (
    <StyledRoot role="tabpanel" className="boardContainer">
      <div className="searchContainer">
        <ApplicantsSearch
          jobId={jobId}
          type={NEXT_STEPS_TAB}
          onSelect={onSuggestionSelect}
          onClear={onSearchClear}
        />
      </div>
      {/* <h2 className="boardTitle">Applicants</h2> */}
      <Box position="relative">
        {loading && (
          <div className="boardLoader">
            <Spinner />
          </div>
        )}
        {isDesktop ? (
          <div
            className={clsx(
              'boardListContainer',
              layoutType === GRID_LAYOUT && 'boardListContainer_grid'
            )}
          >
            {map(list, (applicant, i) => {
              const applicantUID = getApplicantUserId(applicant);

              return layoutType === GRID_LAYOUT ? (
                <ListCardItem
                  key={`applicant__${i}`}
                  data={applicant}
                  starred={starredApplicants[applicantUID] || false}
                  notesLoading={notesByUserIdLoading[applicantUID]}
                  {...applicantsProps}
                />
              ) : (
                <ListRowItem
                  key={`applicant__${i}`}
                  data={applicant}
                  starred={starredApplicants[applicantUID] || false}
                  notesLoading={notesByUserIdLoading[applicantUID]}
                  {...applicantsProps}
                />
              );
            })}
          </div>
        ) : (
          <div className="boardListContainer">
            {map(list, (applicant, i) => {
              const applicantUID = getApplicantUserId(applicant);

              return (
                <ListRowItem
                  key={`applicant__${i}`}
                  data={applicant}
                  starred={starredApplicants[applicantUID] || false}
                  notesLoading={notesByUserIdLoading[applicantUID]}
                  {...applicantsProps}
                />
              );
            })}
          </div>
        )}
      </Box>
    </StyledRoot>
  );
}

NextStepsApplicants.propTypes = {
  acceptApplicant: PT.func.isRequired,
  cancelApplicant: PT.func.isRequired,
  closeApplicantProfileDialog: PT.func.isRequired,
  jobId: PT.number.isRequired,
  layoutType: PT.string.isRequired,
  openApplicantProfileDialog: PT.func.isRequired,
  rejectApplicant: PT.func.isRequired,
  saveUserToJobsNotes: PT.func.isRequired,
  starApplicant: PT.func.isRequired,
  setApplicantProfileDialogProps: PT.func.isRequired,
  webUrl: PT.string.isRequired
};

export default enhance(NextStepsApplicants);
