import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useCallback,
  useImperativeHandle,
  memo
} from 'react';
import PropTypes from 'prop-types';
import { useSprings, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import { styled } from 'components';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import forEach from 'lodash/forEach';

const baseTo = (
  i,
  shiftY,
  translateZ,
  gone = new Set(),
  ungoneIdxs = [],
  currentIndex,
  maxVisibleStack
) => {
  const isGone = gone && gone.has(i);
  let index = isGone ? 0 : i;
  if (ungoneIdxs.length) index = ungoneIdxs.indexOf(i);

  return {
    x: 0,
    y: index * shiftY,
    z: index * translateZ,
    rotate: 0,
    delay: index * 100,
    visible: isGone || i > currentIndex + maxVisibleStack ? 0 : 1
  };
};
const baseFrom = (i, gone = new Set(), ungoneIdxs = []) => {
  const firstIdx = gone.length && ungoneIdxs.length ? ungoneIdxs[0] : 0;
  return { x: 0, y: 0, z: 0, rotate: 0, visible: i === firstIdx ? 1 : 0 };
};
const useGestureOpts = { filterTaps: true };
const CARD_SHIFT_Y = 10;
const TRANSLATE_Z = -30;
const TRASHHOLD = 70;

const Deck = forwardRef((props, ref) => {
  const {
    adaptiveHeight,
    classNames,
    disabled,
    initIndex,
    items,
    leftLabel,
    maxVisibleStack,
    onDeckHeightChange,
    onSwipeLeft,
    onSwipeRight,
    onSwipeStart,
    onSwipeEnd,
    renderItem,
    rightLabel,
    shiftY,
    translateZ,
    trashhold
  } = props;
  // eslint-disable-next-line no-use-before-define
  const [deck, setDeck] = useState([...items]);
  const [deckChanged, setDeckChanged] = useState(0);
  const [gone, setGone] = useState(setGoneCardsOnInit);
  const [currentIndex, setCurrentIndex] = useState(initIndex);
  const [label, setLabel] = useState('');
  const [height, setHeight] = useState(0);
  const [resize, setResize] = useState(0);
  const [isCardSwiped, setIsCardSwiped] = useState(false);
  const [debugLogs, setLogs] = useState('');

  let containerHeight = height + (deck.length === 1 ? 0 : maxVisibleStack * shiftY);
  if (deck.length <= maxVisibleStack) containerHeight = height + deck.length * shiftY;

  const [springs, springsApi] = useSprings(
    deck.length,
    (i) => ({
      ...baseTo(i, shiftY, translateZ, gone, getUngoneIndexes(), currentIndex, maxVisibleStack),
      from: baseFrom(i, gone, getUngoneIndexes())
    }),
    []
  );

  const elements = useRef([{}, false]);
  const heightRef = useRef(height);
  const prevItems = useRef(items);

  const cardChildMeasureRef = useCallback(
    (node) => {
      const [elList, isFullList] = elements.current || [{}, false];
      if (
        adaptiveHeight &&
        node !== null &&
        elements.current &&
        (!elList[node.id] || elList[node.id].offsetHeight !== node.offsetHeight)
      ) {
        elList[node.id] = node;
        elements.current[1] = Object.keys(elList).length === deck.length;
        if (elements.current[1]) setResize((n) => n + 1);
      }
    },
    [deckChanged]
  );

  function setGoneCardsOnInit() {
    const initGone = new Set();

    if (initIndex > 0 && items.length) {
      forEach(items, (item, i) => {
        if (i < initIndex) initGone.add(i);
      });
    }

    return initGone;
  }

  useEffect(() => {
    if (adaptiveHeight && elements.current[1]) {
      const calcMax = () => {
        const arr = map(elements.current[0], (el) => {
          el.style = '';
          const contentHeight = el.offsetHeight;
          el.style = 'flex: 1;';
          return contentHeight;
        });
        const max = Math.max(...arr);
        if (max !== heightRef.current) {
          heightRef.current = max;
          setHeight(max);
        }
      };
      const resizeCb = () => calcMax();
      const debouncedResCb = debounce(resizeCb, 1000);
      window.addEventListener('resize', debouncedResCb);
      calcMax();

      return () => {
        window.removeEventListener('resize', debouncedResCb);
      };
    }
  }, [adaptiveHeight, resize]);

  useEffect(() => {
    if (onDeckHeightChange) onDeckHeightChange(containerHeight);
  }, [containerHeight]);

  useEffect(() => {
    const nextGone = setGoneCardsOnInit();

    if (prevItems.current !== items) {
      gone.clear();
      // itemsHeight.clear();
      elements.current = [{}, false];
      heightRef.current = items.length ? height : 0;
      setHeight(items.length ? height : 0);
      setDeck(items);
      setCurrentIndex(initIndex);
      setDeckChanged((i) => i + 1);
    }

    setIsCardSwiped(!!nextGone.size);
    setGone(nextGone);
  }, [JSON.stringify(items)]);

  useEffect(() => {
    prevItems.current = items;
  });

  useImperativeHandle(ref, () => ({
    currentIndex,
    isCardSwiped,
    swipeBack,
    swipeLeft,
    swipeRight,
    jumpToCardIndex
  }));

  // function getNextCardsIndexes(currIndex) {
  //   return deck[currIndex] ? deck.map((o, i) => i).splice(currIndex, deck.length) : [];
  // }

  function getUngoneIndexes() {
    return deck.map((o, i) => !gone.has(i) && i).filter((i) => i !== false);
  }

  function getX(dir) {
    return (200 + window.innerWidth) * dir;
  }

  function changeLabel(mx = 0, down = false) {
    if (down && Math.abs(mx) >= trashhold) {
      setLabel(mx < 0 ? 'left' : 'right');
    } else {
      setLabel('');
    }
  }

  function jumpToCardIndex(index = 0) {
    if (disabled) return {};

    index = +index;
    if (index >= 0 && deck[index]) {
      springsApi.start((i) => {
        if (i < index) {
          if (!gone.has(i)) {
            gone.add(i);
            return { x: (200 + window.innerWidth) * -1, rot: 70, visible: 0, delay: 0 };
          }
        } else {
          if (gone.has(i)) gone.delete(i);
          const ungone = getUngoneIndexes();
          const visible = i > index + maxVisibleStack ? 0 : 1;
          return {
            x: 0,
            y: ungone.indexOf(i) * shiftY,
            z: ungone.indexOf(i) * translateZ,
            rot: 0,
            visible,
            delay: 0
          };
        }
      });
      setCurrentIndex(index);
      setIsCardSwiped(gone.size !== 0);
      setGone(gone); // gone for some reason doesn't update itself, so it's important to update it manually
    }
  }

  function swipeBack() {
    if (disabled) return {};

    const goneArr = [...gone];
    const index = goneArr[goneArr.length - 1];
    const nextCurrentIndex = currentIndex === 0 ? currentIndex : currentIndex - 1;

    if (index >= 0 && deck[index]) {
      gone.delete(index);
      const ungone = getUngoneIndexes();
      springsApi.start((i) => {
        if (!gone.has(i) && ungone.length) {
          const visible = i > nextCurrentIndex + maxVisibleStack ? 0 : 1;
          return {
            x: 0,
            y: ungone.indexOf(i) * shiftY,
            z: ungone.indexOf(i) * translateZ,
            rotate: 0,
            visible,
            delay: 0
          };
        }
        return { visible: 0 };
      });
      setCurrentIndex(nextCurrentIndex);
      setIsCardSwiped(gone.size !== 0);

      return { item: deck[index], index };
    }

    return {};
  }

  function swipeLeft(index) {
    if (disabled) return {};

    const cardIndexToSwipe = index >= 0 ? index : currentIndex;
    const dir = -1;
    const x = getX(dir);
    const nextCurrentIndex = cardIndexToSwipe === currentIndex ? currentIndex + 1 : currentIndex;

    gone.add(cardIndexToSwipe);
    const ungone = getUngoneIndexes();
    springsApi.start((i) => {
      if (i === cardIndexToSwipe) {
        return { x, rotate: 70, visible: 0, delay: 0, config: { friction: 50, tension: 500 } };
      }

      if (!gone.has(i) && ungone.length) {
        const visible = i > nextCurrentIndex + maxVisibleStack ? 0 : 1;
        return {
          x: 0,
          y: ungone.indexOf(i) * shiftY,
          z: ungone.indexOf(i) * translateZ,
          rotate: 0,
          visible,
          delay: 0
        };
      }
    });
    setCurrentIndex(nextCurrentIndex);
    setIsCardSwiped(true);

    return { item: deck[cardIndexToSwipe], index: cardIndexToSwipe };
  }

  function swipeRight(index) {
    if (disabled) return {};

    const cardIndexToSwipe = index >= 0 ? index : currentIndex;
    const dir = 1;
    const x = getX(dir);
    const nextCurrentIndex = cardIndexToSwipe === currentIndex ? currentIndex + 1 : currentIndex;

    gone.add(cardIndexToSwipe);
    const ungone = getUngoneIndexes();
    springsApi.start((i) => {
      if (i === cardIndexToSwipe) {
        return { x, rotate: -70, visible: 0, delay: 0, config: { friction: 50, tension: 500 } };
      }

      if (!gone.has(i) && ungone.length) {
        const visible = i > nextCurrentIndex + maxVisibleStack ? 0 : 1;
        return {
          x: 0,
          y: ungone.indexOf(i) * shiftY,
          z: ungone.indexOf(i) * translateZ,
          rotate: 0,
          visible,
          delay: 0
        };
      }
    });
    setCurrentIndex(nextCurrentIndex);
    setIsCardSwiped(true);

    return { item: deck[cardIndexToSwipe], index: cardIndexToSwipe };
  }

  const bind = useDrag(
    (gestureState) => {
      const {
        args: [index],
        down,
        movement: [mx],
        direction: [xDir]
      } = gestureState;
      const dir = xDir < 0 ? -1 : 1;
      const humanDir = mx < 0 ? 'left' : 'right';
      // let onRest = () => {};
      let isReadyToLeave = false;

      if (onSwipeStart) onSwipeStart(humanDir);

      springsApi.start((i) => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        let x = down ? mx : 0;
        let rotate = -1 * Math.floor(mx / 10);
        let visible = 1;
        isReadyToLeave =
          Math.abs(mx) >= trashhold &&
          ((xDir <= 0 && humanDir === 'left') || (xDir >= 0 && humanDir === 'right'));
        changeLabel(mx, down);

        if (!down && !isReadyToLeave) rotate = 0;
        if (!down && isReadyToLeave) {
          const ungone = getUngoneIndexes();
          const nextCurrentIndex = i + 1;
          gone.add(i);
          x = getX(humanDir === 'left' ? -1 : 1);
          rotate = humanDir === 'left' ? 70 : -70;
          visible = 0;
          setCurrentIndex(nextCurrentIndex);
          setIsCardSwiped(true);

          springsApi.start((i2) => {
            if (!gone.has(i2) && ungone.length) {
              const isVisible = i2 > nextCurrentIndex + maxVisibleStack ? 0 : 1;
              return {
                x: 0,
                y: ungone.indexOf(i2) * shiftY,
                z: ungone.indexOf(i2) * translateZ,
                rotate: 0,
                visible: isVisible,
                delay: 0
              };
            }
          });

          setLabel('');
          if (humanDir === 'left') onSwipeLeft(deck[i], i);
          if (humanDir === 'right') onSwipeRight(deck[i], i);
        }

        // eslint-disable-next-line consistent-return
        return {
          x,
          rotate,
          visible,
          delay: 0,
          config: { friction: 50, tension: down ? 800 : 500 }
          // onRest
        };
      });

      if (onSwipeEnd) onSwipeEnd(humanDir);
    },
    { ...useGestureOpts, enabled: !disabled }
  );

  return (
    <>
      {/*{debugLogs}*/}
      <StyledRoot
        style={{ ...(adaptiveHeight && height > 0 ? { minHeight: containerHeight } : {}) }}
        className={`deckWrapper__default ${classNames.deckWrapper || ''}`}
      >
        <div
          style={{ ...(adaptiveHeight && height > 0 ? { height } : {}) }}
          className={`deck__default ${classNames.deck || ''}`}
        >
          {map(springs, ({ x, y, z, rotate, visible }, i) => {
            let zIndex = 0;
            if (i > currentIndex) zIndex = -i;

            return (
              <animated.div
                key={`animated__${i}`}
                style={{
                  x,
                  y,
                  rotate,
                  zIndex,
                  visibility: visible.to((v) => (!v ? 'hidden' : 'visible')),
                  opacity: visible,
                  touchAction: 'pan-y' /* required on Android */
                }}
                className={`cardWrapper__default ${classNames.cardWrapper || ''}`}
              >
                <animated.div
                  {...bind(i)}
                  style={{
                    transform: z.to((v) => `perspective(1500px) translateZ(${v}px)`),
                    ...(adaptiveHeight
                      ? { boxSizing: 'border-box' }
                      : {}) /* border-box is required if adaptiveHeight enabled */
                  }}
                  className={clsx(
                    'card__default',
                    classNames.card,
                    i === currentIndex && 'firstCard__default',
                    i === currentIndex && classNames.firstCard
                  )}
                >
                  {i === currentIndex && label === 'left' && leftLabel}
                  {i === currentIndex && label === 'right' && rightLabel}
                  <div
                    id={`card_ch_${i}`}
                    ref={cardChildMeasureRef}
                    className={`cardChild__default ${classNames.cardChild || ''}`}
                  >
                    {renderItem(deck[i], { disabled, index: i })}
                  </div>
                </animated.div>
              </animated.div>
            );
          })}
        </div>
      </StyledRoot>
    </>
  );
});

Deck.displayName = 'Deck';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.deckWrapper__default': {
    // overflowX: 'hidden'
  },
  '& .deck__default': {
    position: 'relative',
    width: '100%',
    display: 'flex'
  },
  '& .cardWrapper__default': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    willChange: 'transform',
    transformOrigin: 'top center',
    display: 'flex',
    justifyContent: 'center'
  },
  '& .card__default': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  '& .cardChild__default': {
    width: '100%'
  },
  '& .firstCard__default': {}
}));

Deck.propTypes = {
  adaptiveHeight: PropTypes.bool,
  classNames: PropTypes.shape({
    cardChild: PropTypes.string,
    cardWrapper: PropTypes.string,
    card: PropTypes.string,
    deck: PropTypes.string,
    deckWrapper: PropTypes.string,
    firstCard: PropTypes.string
  }),
  disabled: PropTypes.bool,
  initIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  leftLabel: PropTypes.element,
  maxVisibleStack: PropTypes.number,
  onDeckHeightChange: PropTypes.func,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  onSwipeStart: PropTypes.func,
  onSwipeEnd: PropTypes.func,
  renderItem: PropTypes.func.isRequired,
  rightLabel: PropTypes.element,
  shiftY: PropTypes.number,
  translateZ: PropTypes.number,
  trashhold: PropTypes.number
};

Deck.defaultProps = {
  adaptiveHeight: true,
  classNames: {
    cardChild: '',
    cardWrapper: '',
    card: '',
    deck: '',
    deckWrapper: '',
    firstCard: ''
  },
  disabled: false,
  initIndex: 0,
  leftLabel: null,
  maxVisibleStack: 4,
  onDeckHeightChange: () => {},
  onSwipeLeft: () => {},
  onSwipeRight: () => {},
  onSwipeStart: () => {},
  onSwipeEnd: () => {},
  rightLabel: null,
  shiftY: CARD_SHIFT_Y,
  translateZ: TRANSLATE_Z,
  trashhold: TRASHHOLD
};

export const PureDeck = Deck;
export default memo(Deck);
