import React, { createContext, useState, useEffect, useCallback, useMemo, memo } from 'react';
import PT from 'prop-types';
import { GET_EMPLOYER_INTERVIEW_SCHEDULE, SET_EMPLOYER_INTERVIEW_SCHEDULE } from 'api';
import { useLazyQuery, useMutation } from '@apollo/client';
import { map } from 'lodash';
import { getDisplayName, convertZonedDateToUTC, getLocaleTimeZone } from 'utils';
import { formatEmployerSchedule, checkSchedulesActuality } from 'utils/schedules/schedules';
import { useEmployerProfileQuery, useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { Button, Spinner } from 'components/shared';
import { MdExpandMore, MdExpandLess, MdChevronRight } from 'components/icons';
import {
  ApplicantCriteria,
  Schedule,
  ScheduledInterviews,
  ManageEmails
} from 'components/Dashboard/employer';
import styles from 'styles/Dashboard/EmployerShedule';
import EmployerScheduleContext from './EmployerScheduleContext';
import { FormProvider, useSchedulesForm } from './EmployerScheduleFormProvider';

const StyledRoot = styled('div')(styles);

function withSchedulesForm(WrappedComponent) {
  function WithSchedulesForm(props) {
    return (
      <FormProvider>
        <WrappedComponent {...props} />
      </FormProvider>
    );
  }
  WithSchedulesForm.displayName = `WithSchedulesForm(${getDisplayName(WrappedComponent)})`;
  return WithSchedulesForm;
}

function EmployerSchedule(props) {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const { profile } = useEmployerProfileQuery();
  const { timeZone = getLocaleTimeZone() } = profile || {};

  const [tab, setTab] = useState(0);
  const [mobileTab, setMobileTab] = useState(0);
  const [employerScheduleCtx, setEmployerScheduleCtx] = useState({
    selectedScheduleIdx: 0
  });

  const { validate, attrs, set } = useSchedulesForm();

  const updateScheduleCtx = useCallback((ctxUpdate = {}) => {
    setEmployerScheduleCtx((prev) => ({
      ...prev,
      ...ctxUpdate
    }));
  }, []);

  const formatFetchedData = (data = []) =>
    map(data, (obj) => formatEmployerSchedule(obj, timeZone));

  const setInitForm = (data) => {
    const formattedData = formatFetchedData(data);
    const { experience, industry, location, careerHealthScore, skills } = data[0] || {};
    let selectedScheduleIdx = 0;

    const [schedules, currentScheduleIdx, closestNextScheduleIdx] =
      checkSchedulesActuality(formattedData);

    selectedScheduleIdx = currentScheduleIdx !== -1 ? currentScheduleIdx : closestNextScheduleIdx;
    if (selectedScheduleIdx === -1) selectedScheduleIdx = 0;

    set({ schedules, criteria: { experience, industry, location, careerHealthScore, skills } });
    updateScheduleCtx({ selectedScheduleIdx });
  };

  const [fetchSchedule, { loading: fetchScheduleLoading = false }] = useLazyQuery(
    GET_EMPLOYER_INTERVIEW_SCHEDULE,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      onCompleted: (data) => {
        const result = data?.getScheduleTimesEmployer;
        if (result && result.length) setInitForm(result);
      }
    }
  );

  const [postSchedule, { loading: postScheduleLoading = false }] = useMutation(
    SET_EMPLOYER_INTERVIEW_SCHEDULE,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );

  const ctx = useMemo(
    () => ({
      updateScheduleCtx,
      ...employerScheduleCtx
    }),
    [updateScheduleCtx, JSON.stringify(employerScheduleCtx)]
  );

  useEffect(() => {
    const employerProfileId = localStorage.getItem('profileId');

    if (employerProfileId)
      fetchSchedule({ variables: { employerProfileId: Number(employerProfileId) } });
  }, []);

  const saveSchedule = async () => {
    validate()
      .then(async (validAttrs) => {
        const { criteria, schedules } = validAttrs;
        const employerProfileId = localStorage.getItem('profileId');

        if (employerProfileId) {
          const schedulingVariable = map(schedules, ({ startDate, endDate, ...rest }) => {
            const utcStartDate = convertZonedDateToUTC(startDate, timeZone).toISOString();
            const jsonSchedule = JSON.stringify(rest.schedule);
            return {
              ...rest,
              ...criteria,
              startDate: utcStartDate,
              schedule: jsonSchedule
            };
          });
          const response = await postSchedule({
            variables: {
              employerProfileId: Number(employerProfileId),
              scheduling: schedulingVariable
            }
          });
          const savedSchedules = response?.data?.setScheduleTimesEmployer;
          if (savedSchedules) {
            const withIds = map(schedules, (obj, i) => ({
              ...obj,
              id: obj.id || savedSchedules[i].id
            }));
            set('schedules', withIds);
          }
        }
      })
      .catch((errors) => {
        console.error('schedule errors', errors);
      });
  };

  const changeTab = (t) => () => setTab(t);

  const changeMobileTab = (t) => () => setMobileTab(t === mobileTab ? null : t);

  const renderSubmitButton = () => (
    <Button
      variant="filled-primary"
      disabled={fetchScheduleLoading || postScheduleLoading}
      endIcon={fetchScheduleLoading || postScheduleLoading ? <Spinner size={24} /> : null}
      sx={{ height: 52, width: '100%', maxWidth: 240 }}
      onClick={saveSchedule}
      testID="schedule-submit-button"
    >
      Save Interview Schedule
    </Button>
  );

  const renderMobileLayout = () => (
    <StyledRoot className="container">
      <Button className="mobileTabBtn" onClick={changeMobileTab(0)}>
        <h2 className="title">Schedule</h2>
        {mobileTab === 0 ? <MdExpandMore color="primary" /> : <MdChevronRight color="primary" />}
      </Button>
      {mobileTab === 0 && (
        <div className="sectionsContainer scheduleContainer">
          <Schedule />
          <div className="submitBtnContainer">{renderSubmitButton()}</div>
        </div>
      )}
      <Button className="mobileTabBtn" onClick={changeMobileTab(1)}>
        <h2 className="title">Applicant Criteria</h2>
        {mobileTab === 1 ? <MdExpandMore color="primary" /> : <MdChevronRight color="primary" />}
      </Button>
      {mobileTab === 1 && (
        <div className="sectionsContainer criteriaContainer">
          <div className="criteriaFormContainer">
            <ApplicantCriteria />
            <div className="submitBtnContainer">{renderSubmitButton()}</div>
          </div>
        </div>
      )}
      <Button className="mobileTabBtn" onClick={changeMobileTab(2)}>
        <h2 className="title">Booked Interviews</h2>
        {mobileTab === 2 ? <MdExpandMore color="primary" /> : <MdChevronRight color="primary" />}
      </Button>
      {mobileTab === 2 && (
        <div className="sectionsContainer interviewsSectionContainer">
          <ScheduledInterviews />
        </div>
      )}
      <Button className="mobileTabBtn" onClick={changeMobileTab(3)}>
        <h2 className="title">Manage Emails</h2>
        {mobileTab === 3 ? <MdExpandMore color="primary" /> : <MdChevronRight color="primary" />}
      </Button>
      {mobileTab === 3 && (
        <div className="sectionsContainer interviewsSectionContainer">
          <ManageEmails />
        </div>
      )}
    </StyledRoot>
  );

  const renderDesktopLayout = () => (
    <StyledRoot className="container">
      <div className="sectionsContainer">
        <div className="sectionContainer scheduleContainer">
          <div className="tabs">
            <Button
              variant={tab === 0 ? 'outlined-primary' : 'filled-secondary'}
              className="tabBtn"
              width={128}
              onClick={changeTab(0)}
              testID="schedule-tab"
            >
              Schedules
            </Button>
            <Button
              variant={tab === 1 ? 'outlined-primary' : 'filled-secondary'}
              className="tabBtn"
              width={223}
              onClick={changeTab(1)}
              testID="interviews-tab"
            >
              View Booked Interviews
            </Button>
            <Button
              variant={tab === 2 ? 'outlined-primary' : 'filled-secondary'}
              className="tabBtn"
              onClick={changeTab(2)}
              testID="emails-tab"
            >
              Manage Emails
            </Button>
          </div>
          {(() => {
            switch (tab) {
              case 0:
                return <Schedule />;
              case 1:
                return <ScheduledInterviews />;
              case 2:
                return <ManageEmails />;
              default:
                return null;
            }
          })()}
        </div>
        <div className="sectionContainer criteriaContainer">
          <div className="criteriaTitleContainer">
            <h2>Set Applicant Criteria</h2>
          </div>
          <div className="criteriaFormContainer">
            <ApplicantCriteria />
            <div className="submitBtnContainer">{renderSubmitButton()}</div>
          </div>
        </div>
      </div>
    </StyledRoot>
  );

  return (
    <EmployerScheduleContext.Provider value={ctx}>
      {isDesktop ? renderDesktopLayout() : renderMobileLayout()}
    </EmployerScheduleContext.Provider>
  );
}

EmployerSchedule.propTypes = {};

export default withSchedulesForm(memo(EmployerSchedule));
