import React from 'react';
import PT from 'prop-types';
import { MuiRadioGroup, MuiRadio, FormControlLabel, styled } from 'components';
import { MdMenu, MdGridOn } from 'components/icons';
import { qaAttr } from 'utils';

export const GRID_LAYOUT = 'grid';
export const LIST_LAYOUT = 'list';

function LayoutViewController(props) {
  const { className, value, onChange } = props;
  return (
    <MuiRadioGroup
      value={value}
      aria-label="table layout"
      row
      className={`${className} layoutRadioGroup`}
      onChange={(e) => onChange(e.target.value)}
    >
      <FormControlLabel
        value={LIST_LAYOUT}
        control={
          <MuiRadio
            color="primary"
            icon={<MdMenu />}
            checkedIcon={<MdMenu />}
            inputProps={{
              'aria-label': 'list layout',
              ...qaAttr('candidates-table-list-layout-radio-input')
            }}
            {...qaAttr('candidates-table-list-layout-radio')}
            classes={{ root: 'layoutRadio' }}
          />
        }
      />
      <FormControlLabel
        value={GRID_LAYOUT}
        control={
          <MuiRadio
            color="primary"
            icon={<MdGridOn />}
            checkedIcon={<MdGridOn />}
            inputProps={{
              'aria-label': 'grid layout',
              ...qaAttr('candidates-table-grid-layout-radio-input')
            }}
            {...qaAttr('candidates-table-grid-layout-radio')}
            classes={{ root: 'layoutRadio' }}
          />
        }
      />
    </MuiRadioGroup>
  );
}

LayoutViewController.propTypes = {
  className: PT.string,
  value: PT.oneOf([GRID_LAYOUT, LIST_LAYOUT]).isRequired,
  onChange: PT.func.isRequired
};

LayoutViewController.defaultProps = {
  className: ''
};

const StyledLayoutViewController = styled(LayoutViewController)(({ theme }) => ({
  '&.layoutRadioGroup': {
    '& label': {
      margin: 0
    },
    '& label:not(:first-of-type)': {
      marginLeft: 16
    }
  },
  '& .layoutRadio': {
    padding: 0,
    color: theme.palette.moreColors.black,
    opacity: 0.4,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      opacity: 1
    }
  }
}));

export default StyledLayoutViewController;
