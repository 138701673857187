import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { emojiRates, textRates } from 'utils';
import { isEmpty, sortBy } from 'lodash';
import { Box, styled } from 'components';
import { Spinner } from 'components/shared';
import growApi from '../../api';
import EmployerGrowContext from '../../EmployerGrowContext';

const { EMPLOYEE_RATINGS_BY_EMPLOYER_ID, RATING_REASONS, RATING_REASON_BY_EMPLOYER } =
  growApi.query;

const StyledRoot = styled('div')(({ theme }) => ({
  '&.moodChart': {},
  '& .moodChart__content': {
    paddingTop: 20,
    flex: 1
  },
  '& .moodChart__reasonTitle': {
    color: theme.palette.primary.text,
    marginBottom: 10
  },
  '& .moodChart__list': {
    margin: 0,
    paddingLeft: 20,
    color: theme.palette.primary.main,
    fontSize: 24
  },
  '& .moodChart__reason': {
    fontSize: 'initial',
    color: theme.palette.primary.light
  }
}));

function EmployeeMood() {
  // const { } = useContext(EmployerGrowContext);

  const [fetchRatingsByEmployerId, { data: ratingsData, loading: ratingLoading }] = useLazyQuery(
    EMPLOYEE_RATINGS_BY_EMPLOYER_ID,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const ratings = ratingsData?.employeeRatingsByEmployerId;

  const [fetchRatingReasons, { data: ratingReasonsData, loading: reasonsLoading }] =
    useLazyQuery(RATING_REASONS);
  const ratingReasons = ratingReasonsData?.ratingReasons;

  const [fetchEmployerReasons, { data: employerReasonsData, loading: employerReasonsLoading }] =
    useLazyQuery(RATING_REASON_BY_EMPLOYER);
  const employerReasons = employerReasonsData?.ratingReasonsByEmployerProfile;

  const isDataLoading = ratingLoading || reasonsLoading || employerReasonsLoading;

  const employeeMood = useMemo(() => {
    if (ratings?.length) {
      // add up all the mood scores and return a single digit rounded number,
      // to be used as our index for emoji and text arrays
      const scoreSum = ratings.reduce((acc, cur) => acc + cur.score, 0);
      return ratings.length > 0 ? Math.round(scoreSum / ratings.length) : 'N/A';
    }
    return 'N/A';
  }, [ratings]);

  const employeeReasons = useMemo(() => {
    if (employerReasons?.length && ratingReasons?.length) {
      // group the reasons by most popular with the following structure
      // {[reasonId]: count}
      const groupedReasons = employerReasons
        ? employerReasons.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.reasonId]: acc[cur.reasonId] ? acc[cur.reasonId] + 1 : 1
            }),
            {}
          )
        : {};
      // group the reaons into a tuple of [[reasonId, count]] for sorting
      const sorted = sortBy(Object.entries(groupedReasons), ([_, count]) => count).reverse();
      return sorted
        .map(([id, count], idx) => {
          if (idx <= 2) {
            return ratingReasons.filter((reason) => reason.id === Number(id))[0]?.reason;
          }
          return null;
        })
        .filter(Boolean);
    }
    return ['No Reasons at this time'];
  }, [employerReasons, ratingReasons]);

  useEffect(() => {
    fetchRatingsByEmployerId();
    fetchRatingReasons();
    fetchEmployerReasons();
  }, []);

  const emptyContent = (
    <Box flex={1} display="flex" alignItems="center" justifyContent="center">
      <div>No ratings at this time</div>
    </Box>
  );

  const getEmojiRating = () => (
    <div className="moodChart__content">
      <Box mb="15px">
        <Box component="span" pr="15px" fontSize={20}>
          {emojiRates[employeeMood - 1 || 0]}
        </Box>
        <Box component="span" fontSize={18}>
          {textRates[employeeMood - 1 || 0]}
        </Box>
      </Box>
      <div>
        <h5 className="moodChart__reasonTitle">Top Contributors to your score:</h5>
        <ul className="moodChart__list">
          {employeeReasons.map((reason, idx) => (
            <li key={`${reason}-${idx}`}>
              <span className="moodChart__reason">{reason}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <StyledRoot className="moodChart chartCard">
      {isDataLoading && (
        <div className="chartLoaderContainer">
          <Spinner size={48} />
        </div>
      )}
      <h4 className="chartTitle">Employee Satisfaction Score</h4>
      {employeeMood === 'N/A' ? emptyContent : getEmojiRating()}
    </StyledRoot>
  );
}

export default EmployeeMood;
