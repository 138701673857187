import React, { useMemo } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { GrowRecentBadgeIcon } from 'components/icons';
import { addAlpha } from 'styles/utils';
import GrowLevel from './GrowLevel';
import LevelNavigationButton from './LevelNavigationButton';
import { LEVELS_CONTAINER_WIDTH, LEVELS_CONTAINER_MOB_WIDTH } from './utils';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.growLevels': {
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40
    }
  },
  '& .growLevels__levelsContainer': {
    width: `${LEVELS_CONTAINER_WIDTH}vw`,
    height: `${LEVELS_CONTAINER_WIDTH}vw`,
    position: 'relative',
    margin: '0 auto',
    '&::before': {
      content: '""',
      width: '45%',
      height: '100vh',
      position: 'absolute',
      top: -100,
      left: '50%',
      zIndex: 0,
      transform: 'translateX(-50%)',
      background: `linear-gradient(to bottom, ${theme.palette.common.white} 0%, ${addAlpha(
        theme.palette.common.white,
        0
      )} 100%)`,
      opacity: 0.05
    },
    [theme.breakpoints.down('desktopApp')]: {
      width: `${LEVELS_CONTAINER_MOB_WIDTH}vw`,
      height: `${LEVELS_CONTAINER_MOB_WIDTH}vw`
    }
  },
  '& .growLevels__levelContent': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  },
  '& .growLevels__levelContent-levelIcon': {
    transition: 'transform 0.65s ease-in-out',
    transform: 'rotateY(0deg)',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 125,
    color: theme.palette.common.white,
    '&.rotate': {
      transform: 'rotateY(180deg)'
    },
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 70
    }
  },
  '& .growLevels__levelContent-level': {
    lineHeight: 1,
    fontSize: 40,
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .growLevels__levelContent-steps': {
    paddingTop: 5,
    color: theme.palette.common.white,
    fontSize: 12,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .growLevels__navigationContainer': {
    width: 500,
    margin: '-30px auto 0',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
    position: 'relative',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .growLevels__curLevelDetails': {
    margin: '0 auto',
    width: '100%',
    textAlign: 'center',
    marginTop: -20,
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 0,
      marginBottom: 24
    }
  },
  '& .growLevels__curLevelDetails-title': {
    maxWidth: 310,
    margin: '0 auto',
    paddingBottom: 10,
    fontWeight: 100,
    fontSize: 30,
    color: theme.palette.common.white,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 39,
      fontWeight: 900,
      lineHeight: '130%',
      color: '#EAEAEA' // @TODO: get from theme (must be grey 2)
    }
  },
  '& .growLevels__curLevelDetails-currentlyTracking': {
    color: theme.palette.moreColors.purpleLight_2,
    fontSize: 18,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  }
}));

function GrowLevels(props) {
  const { activeLevel, setActiveLevel, levels = [] } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const totalLevels = levels.length;
  const prevLevel = levels[activeLevel - 2];
  const nextLevel = levels[activeLevel];

  const currentLevel = useMemo(() => levels[activeLevel - 1] || {}, [levels, activeLevel]);
  const { abilitiesBadges = [], skillsBadges = [] } = currentLevel;

  const badgesCount = (abilitiesBadges?.length || 0) + (skillsBadges?.length || 0);

  const completedCount = useMemo(
    () =>
      (abilitiesBadges || []).filter((o) => o.complete).length +
      (skillsBadges || []).filter((o) => o.complete).length,
    [abilitiesBadges, skillsBadges]
  );

  const renderJobTypeName = () =>
    currentLevel.jobTypeName && (
      <div className="growLevels__curLevelDetails">
        <h2 className="growLevels__curLevelDetails-title">{currentLevel.jobTypeName}</h2>
        {totalLevels > 0 && (
          <div className="growLevels__curLevelDetails-currentlyTracking">Currently Tracking</div>
        )}
      </div>
    );

  return (
    <StyledRoot className="growLevels">
      {!isDesktop && renderJobTypeName()}
      <div className="growLevels__levelsContainer">
        <div className="growLevels__levelContent">
          <div
            className={clsx(
              'growLevels__levelContent-levelIcon',
              activeLevel % 2 === 0 && 'rotate'
            )}
          >
            <GrowRecentBadgeIcon fontSize="inherit" color="inherit" />
          </div>
          {levels.length > 0 && (
            <div className="growLevels__levelContent-level">Level {activeLevel}</div>
          )}
          {badgesCount > 0 && (
            <div className="growLevels__levelContent-steps">
              <Box component="span" color="moreColors.yellow">
                {completedCount}
              </Box>{' '}
              {`of ${badgesCount} complete`}
            </div>
          )}
        </div>
        {levels.map((obj, i) => (
          <GrowLevel
            key={`level__${obj.id}`}
            level={i + 1}
            abilitiesBadges={obj.abilitiesBadges}
            skillsBadges={obj.skillsBadges}
            active={activeLevel === i + 1}
            activeLevel={activeLevel}
          />
        ))}
      </div>
      {!!levels.length && (
        <div className="growLevels__navigationContainer">
          {prevLevel ? (
            <LevelNavigationButton
              title={prevLevel.jobTypeName}
              level={activeLevel - 1}
              onClick={() => setActiveLevel(activeLevel - 1)}
            />
          ) : (
            <Box visibility="hidden" width={80} height={80} />
          )}
          {nextLevel && (
            <LevelNavigationButton
              title={nextLevel.jobTypeName}
              level={activeLevel + 1}
              onClick={() => setActiveLevel(activeLevel + 1)}
            />
          )}
        </div>
      )}
      {isDesktop && renderJobTypeName()}
    </StyledRoot>
  );
}

GrowLevels.propTypes = {
  activeLevel: PT.number.isRequired,
  setActiveLevel: PT.func.isRequired,
  levels: PT.arrayOf(
    PT.shape({
      active: PT.bool,
      careerPathGroupId: PT.number,
      completed: PT.bool,
      id: PT.number,
      index: PT.number,
      jobTypeId: PT.number,
      jobTypeName: PT.string,
      abilitiesBadges: PT.arrayOf(
        PT.shape({
          abilitiesBadgeId: PT.number,
          abilitiesId: PT.number,
          badgeUrl: PT.string,
          complete: PT.bool,
          name: PT.string
        })
      ),
      skillsBadges: PT.arrayOf(
        PT.shape({
          skillsBadgeId: PT.number,
          skillsId: PT.number,
          badgeUrl: PT.string,
          complete: PT.bool,
          name: PT.string
        })
      )
    })
  ).isRequired
};

export default GrowLevels;
