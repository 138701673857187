import create from 'zustand';
import shallow from 'zustand/shallow';
import { getAuthToken, getUserId, removeLocalStorageItems } from 'utils';
import { reset as resetSegmentAnalytics } from 'utils/segmentAnalytics';
import { client } from 'api';
import { GRID_LAYOUT } from 'components/ApplicantsDashboard/data';
import { log } from './utils';

const token = getAuthToken();
const userId = getUserId();

const initAuthState = {
  externalReferrer: ''
};
const createAuthStore = (set, get) => ({
  authed: !!(token && userId),
  ...initAuthState,
  login: () => {
    set({ authed: true });
  },
  logout: () => {
    const { resetStore, authed } = get();
    if (authed) {
      removeLocalStorageItems(['token', 'userId', 'profileId', 'post_signup_referrer', 'hasMerge']);
      resetSegmentAnalytics();
      client.clearStore();
      set({ authed: false });
      resetStore();
    }
  },
  setExternalReferrer: (link) => set({ externalReferrer: link })
});

const createColorModeStore = (set) => ({
  mode: 'dark',
  toggleColorMode: () => set((state) => ({ mode: state.mode === 'dark' ? 'light' : 'dark' }))
});

const initUIState = {
  employerJobsFilter: 0,
  employeeJobsTab: 0,
  hirePromptWasShown: false,
  newJobPromptWasShown: false,
  applicantsDashboardLayout: GRID_LAYOUT
};
const createUIStore = (set) => ({
  ...initUIState,
  setEmployerJobsFilter: (key = 0) => set({ employerJobsFilter: key }),
  setEmployeeJobsTab: (tab = 0) => set({ employeeJobsTab: tab }),
  setHirePromptWasShown: (flag = false) => set({ hirePromptWasShown: flag }),
  setNewJobPromptWasShown: (flag = false) => set({ newJobPromptWasShown: flag }),
  setApplicantsDashboardLayout: (type) => set({ applicantsDashboardLayout: type })
});

const useAppStore = create(
  log((...args) => {
    const [set] = args;
    return {
      ...createAuthStore(...args),
      ...createColorModeStore(...args),
      ...createUIStore(...args),
      resetStore: () => set({ ...initAuthState, ...initUIState })
    };
  })
);

function useAppStoreSelector(selector, options) {
  const { isShallow, equals } = options || {};
  const equalityFunc = isShallow ? shallow : equals;
  return useAppStore(selector, equalityFunc);
}

export { useAppStore, useAppStoreSelector };
