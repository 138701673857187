import { gql } from '@apollo/client';

export const GET_EMAILS_FOR_NOTIFICATIONS = gql`
  query getNotificationsType($employerUserId: Int!) {
    getNotificationsType(employerUserId: $employerUserId) {
      id
      email
      employerUserId
      notificationsType
    }
  }
`;

// get employer schedule
export const GET_EMPLOYER_INTERVIEW_SCHEDULE = gql`
  query getScheduleTimesEmployer($employerProfileId: Int!) {
    getScheduleTimesEmployer(employerProfileId: $employerProfileId) {
      careerHealthScore
      count
      day
      employerProfileId
      experience
      id
      industry
      length
      location
      schedule
      skills
      startDate
      timeZone
    }
  }
`;

// get all interviews for selected job
export const GET_INTERVIEW_SCHEDULE_FOR_JOB = gql`
  query getScheduleTimesForJob($jobId: Int!, $employeeProfileId: Int!) {
    getScheduleTimesForJob(jobId: $jobId, employeeProfileId: $employeeProfileId) {
      careerHealthScore
      day
      employerScheduleId
      employeeProfileId
      employerProfileId
      experience
      interviewDate
      id
      industry
      jobId
      location
      scheduleCount
      skills
      timeZone
    }
  }
`;

// get employer interviews
export const GET_EMPLOYER_SCHEDULED_INTERVIEWS = gql`
  query getScheduleEmployer($employerProfileId: Int) {
    getScheduleEmployer(employerProfileId: $employerProfileId) {
      day
      employerProfileId
      employeeProfileId
      employeeName
      employeeImage
      id
      interviewDate
      hiredStatus
      jobId
      jobTitle
      jobLocation
      rate
      schedule
      status
      statusEnum
      timeZone
      user_id
    }
  }
`;

// hire/deny reasons
export const GET_HIRE_REASONS = gql`
  query getReasons {
    getReasons {
      id
      reason
      type
    }
  }
`;
