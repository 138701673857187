import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon4(props) {
  return (
    <SvgIcon viewBox="0 0 29.26 33.32" {...props}>
      <path
        d="M6.8,2.6C16.6-3.06,27.67,.63,27.67,12.7v2.23l-3.96,2.28v-4.51c0-9.07-7.91-10.74-14.94-6.68L1.59,10.17v-3.32c0-.69,.56-1.58,1.25-1.98l3.96-2.29Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M22.14,12.7c0-6.92-5.66-9.31-12.58-5.32L1.25,12.19c-.69,.4-1.25,1.29-1.25,1.98v3.32l7.12-4.11v7.25c0,6.92,5.66,9.31,12.58,5.32l8.31-4.8c.69-.4,1.25-1.29,1.25-1.98v-3.32l-7.12,4.11v-7.25h0Zm-11.07-1.62c2.75-1.59,7.11-3.52,7.11,1.62v9.53c-2.75,1.58-7.11,3.52-7.11-1.62V11.09Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M22.46,30.72c-9.8,5.66-20.88,1.97-20.88-10.11v-2.23l3.96-2.28v4.51c0,9.07,7.91,10.74,14.94,6.68l7.19-4.15v3.32c0,.69-.56,1.58-1.25,1.97l-3.96,2.29Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M12.65,12.02v8.6c0,2.99,2.36,1.53,3.95,.69V12.7c0-2.99-2.36-1.53-3.95-.69Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default GrowBadgeIcon4;
