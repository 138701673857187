import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import ResourceCard from './ResourceCard';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.suggestedQuizzes': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('desktopApp')]: {
      gridTemplateColumns: '1fr',
      gridGap: 12
    },
    '& .resourceCard': {
      [theme.breakpoints.down('desktopApp')]: {
        padding: 15,
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 4px 9px rgba(0, 0, 0, 0.25)'
      }
    },
    '& .resourceCard__title': {
      color: 'initial',
      [theme.breakpoints.down('desktopApp')]: {
        fontSize: 14,
        fontWeight: 700,
        color: theme.palette.moreColors.grey_7
      }
    },
    '& .resourceCard__img': {
      backgroundColor: theme.palette.moreColors.darkPurple,
      [theme.breakpoints.down('desktopApp')]: {
        width: 48,
        height: 48,
        minWidth: 'auto',
        borderRadius: 15,
        backgroundColor: theme.palette.moreColors.grey_3
      }
    }
  }
}));

function SuggestedQuizzes({ quizzes }) {
  const arr = quizzes.slice(0, 2);
  return (
    <StyledRoot className="suggestedQuizzes">
      {arr.map(({ name, value }, i) => (
        <ResourceCard key={`sugQuiz__${i}`} title={name} link={value} />
      ))}
    </StyledRoot>
  );
}

SuggestedQuizzes.propTypes = {
  quizzes: PT.arrayOf(
    PT.shape({
      name: PT.string,
      value: PT.string
    })
  ).isRequired
};

export default SuggestedQuizzes;
