import React from 'react';
import PT from 'prop-types';
import map from 'lodash/map';
import { styled } from 'components';
import ResourceCard from './ResourceCard';
import { careerDevBlogs } from './employeeBlogsData';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.suggestedResources': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& .resourceCard__title': {
      color: 'initial'
    },
    '& .resourceCard__img': {
      backgroundColor: theme.palette.moreColors.darkPurple
    }
  }
}));

function SuggestedResources(props) {
  const arr = careerDevBlogs.slice(0, 2);
  return (
    <StyledRoot className="suggestedResources">
      {map(arr, ({ title, link }, i) => (
        <ResourceCard key={`sugRes__${i}`} title={title} link={link} />
      ))}
    </StyledRoot>
  );
}

SuggestedResources.propTypes = {};

export default SuggestedResources;
