import React, { useState, useEffect } from 'react';
import PT from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  employeeStartPage,
  employerStartPage,
  getRoutes,
  getUserId,
  getLocaleTimeZone,
  qaAttr,
  saveProfileId
} from 'utils';
import { trackPixelEvent } from 'utils/fbpixel';
import TagManager from 'react-gtm-module';
import { withEmployeeGrow } from 'hocs';
import { useAuth, useTimezoneCommands } from 'hooks';
import { Box, MuiIconButton, MobileStepper, styled } from 'components';
import { Button, RadioGroup } from 'components/shared';
import { MdArrowBack } from 'components/icons';
import {
  employer_steps,
  employer_all_steps,
  employee_steps,
  employee_all_steps
} from 'components/Onboarding/data';
import { client, POST_USER_TYPE, SET_REG_STEP } from 'api';
import styles from 'styles/Onboarding';
import { WorkTorchLogo } from 'assets/workTorchLogo';
import EmployerOnboardingSteps from './EmployerOnboardingSteps';
import EmployeeOnboardingSteps from './EmployeeOnboardingSteps';

const StyledRoot = styled('div')(styles);
// const ROUTES = getRoutes();

const EMPLOYER_FORM = {
  address: '',
  name: '',
  phone: '',
  size: '1-49',
  image: '',
  industry: [],
  preview: '',
  zip_code: ''
};

const EMPLOYEE_FORM = {
  birth_date: '',
  curJobTypeId: '',
  futureJobTypeId: '',
  file: '',
  gender: 'female',
  industryId: '',
  name: '',
  photo: '',
  phone: '',
  preview: '',
  race: 'American Indian or Alaska Na..',
  skills: {},
  video: null,
  website: '',
  zip: ''
};

const enhance = (Component) => withEmployeeGrow(Component);

function Onboarding(props) {
  const { getCareerDev } = props;
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { saveTimezone } = useTimezoneCommands();
  const { regStep = '' } = location?.state || {};

  const [onboardingType, setOnboardingType] = useState('employee');
  const [step, setStep] = useState('default');
  const [form, setForm] = useState(EMPLOYEE_FORM);

  const stepsByRole = onboardingType === 'employer' ? employer_all_steps : employee_steps;
  const totalSteps = stepsByRole.length + 1;
  const activeStepIndex = stepsByRole.indexOf(step) + 1;

  const [postRegStep] = useMutation(SET_REG_STEP);
  const [postUserType] = useMutation(POST_USER_TYPE);

  const saveUserType = async () => {
    const timeZone = getLocaleTimeZone();
    await postUserType({ variables: { profileType: onboardingType, timeZone } });
    saveTimezone(timeZone, onboardingType);
  };

  useEffect(() => {
    const [role, regStepStep] = regStep.split('_');

    if (role && regStepStep) {
      const stepIdx = parseInt(regStepStep);
      const steps = role === 'employer' ? employer_steps : employee_steps;

      // Fetch employee Grow data if it's presence. It's required for career path steps
      if (role === 'employee') {
        (async () => {
          const result = await getCareerDev();
          const careerDev = result?.data?.careerDevelopmentByUserId;
          if (careerDev) {
            const { curJobTypeId, futureJobTypeId, industryId } = careerDev;
            setForm((prev) => ({
              ...prev,
              curJobTypeId: curJobTypeId || '',
              futureJobTypeId: futureJobTypeId || '',
              industryId: industryId || ''
            }));
          }
        })();
      }

      if (typeof stepIdx === 'number') setStep(steps[stepIdx]);
      setOnboardingType(role);
    }
  }, []);

  useEffect(() => {
    setForm(onboardingType === 'employer' ? EMPLOYER_FORM : EMPLOYEE_FORM);
  }, [onboardingType]);

  const setRegistrationStep = async () => {
    const countableSteps = onboardingType === 'employer' ? employer_steps : employee_steps;
    const currCountableStepIdx = countableSteps.indexOf(step);

    if (currCountableStepIdx + 1 >= 1) {
      const userId = Number(getUserId());
      try {
        postRegStep({ variables: { userId, regStep: currCountableStepIdx + 1 } });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const goToNextStep = async (func, options) => {
    const { skipped = false } = options || {};
    const steps = onboardingType === 'employer' ? employer_all_steps : employee_all_steps;
    const currStep = steps[steps.indexOf(step)];

    if (skipped) await setRegistrationStep();

    if (func) {
      func().then((data) => {
        if (data?.data?.postEmployeeName?.profile_id) {
          saveProfileId(data.data.postEmployeeName.profile_id);
        } else if (data?.data?.setEmployerName?.profile_id) {
          saveProfileId(data.data.setEmployerName.profile_id);
        }
        setStep(steps[steps.indexOf(step) + 1]);
      });
    } else {
      setStep(steps[steps.indexOf(step) + 1]);
    }
  };

  const goToPrevStep = () => {
    const steps = onboardingType === 'employer' ? employer_all_steps : employee_all_steps;
    setStep(steps[steps.indexOf(step) - 1]);
  };

  const navigateAfterFinish = async (role) => {
    const {
      pathname: referrer,
      search: referrerSearch,
      state: referrerState
    } = location?.state?.from?.location || {};

    if (role === 'employer') {
      navigate(referrer || employerStartPage, { state: referrerState });
      trackPixelEvent('Profile Complete Employer');
      // //trigger Google analytics event
      TagManager.dataLayer({dataLayer: {event: 'Profile Complete Employer'}});
    } else if (role === 'employee') {
      navigate(referrer || employeeStartPage, { state: referrerState });
      trackPixelEvent('Profile Complete Employee');
      // //trigger Google analytics event
      TagManager.dataLayer({dataLayer: {event: 'Profile Complete Employee'}});
    }
  };

  const handleOnboardingFinish = async (selectedRole, func, options) => {
    const { skipped } = options || {};

    if (skipped) await setRegistrationStep();
    if (selectedRole === 'employee' || selectedRole === 'employer') {
      // clear cached profile so that it will be fetched with up to date data
      // at ProtectedEmployeeRoute/ProtectedEmployerRoute
      client.cache.evict({ fieldName: `${selectedRole}Profile` });
    }

    if (func) {
      func().then((d) => {
        navigateAfterFinish(selectedRole);
      });
    } else {
      navigateAfterFinish(selectedRole);
    }
  };

  const handleFormChange = (nextForm) => {
    setForm(nextForm);
  };

  const handleOnboardingTypeChange = (e) => {
    setOnboardingType(e.target.value);
  };

  const signOut = () => logout();

  const renderOnboardingSteps = (type) => {
    if (type === 'employer') {
      return (
        <EmployerOnboardingSteps
          step={step}
          form={form}
          onFinish={(func, ...rest) => handleOnboardingFinish('employer', func, ...rest)}
          onFormChange={handleFormChange}
          onGoToNextStep={goToNextStep}
          setStep={setStep}
        />
      );
    }
    if (type === 'employee') {
      return (
        <EmployeeOnboardingSteps
          step={step}
          form={form}
          onFinish={(func, ...rest) => handleOnboardingFinish('employee', func, ...rest)}
          onFormChange={handleFormChange}
          onGoToNextStep={goToNextStep}
          setStep={setStep}
        />
      );
    }
    return null;
  };

  const renderDefaultStep = () => (
    <div className="defaultStepContent">
      <Box
        component="h1"
        mb="24px"
        className="pageTitle"
        {...qaAttr('onboarding-default-step-title')}
      >
        Let’s Finish Your Profile
      </Box>
      <Box width="100%" maxWidth={320} mx="auto">
        <Box mb="16px" fontSize={14} lineHeight="22px" textAlign="center">
          I am
        </Box>
        <RadioGroup
          data={[
            { label: 'Looking for a Job', value: 'employee' },
            { label: 'Posting a Job', value: 'employer' }
          ]}
          value={onboardingType}
          defaultValue="employee"
          name="onboarding_type"
          sx={{ mb: '15px' }}
          onChange={handleOnboardingTypeChange}
        />
        <Button
          variant="filled-primary"
          width="100%"
          height={50}
          onClick={() => goToNextStep(saveUserType)}
          testID="onboarding-default-step-next-button"
        >
          Next
        </Button>
      </Box>
    </div>
  );

  return (
    <StyledRoot className="pageContainer">
      <div className="contentWrapper">
        <Box className="header">
          <div className="companyName">
            <WorkTorchLogo />
          </div>
          {step !== 'default' ? (
            <MuiIconButton
              edge="start"
              color="primary"
              aria-label="return"
              className="mobilePrevBtn"
              onClick={goToPrevStep}
              {...qaAttr('onboarding-go-to-prev-mobile-button')}
            >
              <MdArrowBack fontSize="inherit" />
            </MuiIconButton>
          ) : (
            <Box minWidth={36} height={48} />
          )}
          <Button variant="text" onClick={signOut} testID="onboarding-signout-button">
            Sign Out
          </Button>
        </Box>
        <MobileStepper
          variant="progress"
          position="static"
          steps={totalSteps}
          activeStep={activeStepIndex}
          classes={{ root: 'stepper', progress: 'progressBar' }}
        />
        {step !== 'default' && (
          <div className="desktopPrevBtn">
            <MuiIconButton
              edge="start"
              color="primary"
              aria-label="return"
              className="arrowBtn"
              onClick={goToPrevStep}
              {...qaAttr('onboarding-go-to-prev-button')}
            >
              <MdArrowBack fontSize="inherit" />
            </MuiIconButton>
          </div>
        )}
        {step === 'default' ? renderDefaultStep() : renderOnboardingSteps(onboardingType)}
      </div>
    </StyledRoot>
  );
}

Onboarding.propTypes = {
  getCareerDev: PT.func.isRequired
};

export default enhance(Onboarding);
