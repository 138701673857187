import React, { useCallback } from 'react';
import PT from 'prop-types';
import { useMutation } from '@apollo/client';
import clsx from 'clsx';
import map from 'lodash/map';
import { qaAttr } from 'utils';
import { useMediaQueryMatches } from 'hooks';
import { Box, Switch, styled } from 'components';
import { Button, Input } from 'components/shared';
import { useForm } from 'components/form';
import styles from 'styles/Profile/EmployerProfile';
import { ADD_EMPLOYER_TO_ADMIN, CHANGE_EMPLOYER_TO_ADMIN_ACTIVE } from 'api';
import SectionLayout from '../SectionLayout';

const StyledRoot = styled('div')(styles);

function ManageAccess(props) {
  const { expanded, jumpLink, onExpand, sectionName, users, usersLoading } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [postNewUser, { loading: postNewUserLoading, error }] = useMutation(ADD_EMPLOYER_TO_ADMIN, {
    update(cache, { data: { addAdminAccount } }) {
      cache.modify({
        fields: {
          getAdminAccounts(cachedRefsList, { readField }) {
            // save new list to cache
            return addAdminAccount.map((obj) => ({ __ref: cache.identify(obj) }));
          }
        }
      });
    }
  });

  const [toggleActiveStatus, { loading: toggleActiveStatusLoading }] = useMutation(
    CHANGE_EMPLOYER_TO_ADMIN_ACTIVE
  );

  const autoScrollRef = useCallback((node) => {
    if (node) node.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  const { $, attrs, set, validate } = useForm({
    initial: { users: [] },
    validations: {
      'users.*.email': ['presence', 'email']
    }
  });

  const addNewUser = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const arr = [...attrs.users, { email: '' }];
    set('users', arr);
  };

  const handleChange = (e, { name }) => {
    const { value } = e.target;
    set(name, value);
  };

  const toggleSwitch = (e, { adminProfileId, employerProfileId }) => {
    const { checked } = e.target;
    toggleActiveStatus({
      variables: {
        employerProfileId,
        adminProfileId,
        active: checked
      }
    });
  };

  const submitUser = (index) => {
    validate(`users.${index}.email`).then(async (email) => {
      await postNewUser({ variables: { email } });
      set(
        'users',
        attrs.users.filter((_, i) => i !== index)
      );
    });
  };

  return (
    <StyledRoot>
      <SectionLayout
        expanded={expanded}
        jumpLink={jumpLink}
        onExpand={onExpand}
        sectionName={sectionName}
        sectionHeaderContent={
          expanded || isDesktop ? (
            <Button
              variant="filled-primary"
              className="manageAccess__actionBtn"
              sx={{ width: 82, height: 25, marginLeft: '10px' }}
              onClick={addNewUser}
              testID="manage-access-add-user-button"
            >
              Add User
            </Button>
          ) : null
        }
        sectionHeaderProps={{ className: 'manageAccess__sectionHeader' }}
      >
        <div className="section manageAccess__container">
          <div className="manageAccess__list">
            {map(users, (user) => (
              <div
                key={`employer__${user.id}`}
                className={clsx('manageAccess__list-item', user.active && 'active')}
              >
                <Box flex={1} maxWidth={200} mr="20px">
                  {user.name || 'N/A'}
                </Box>
                <Box flex={1} mr="20px">
                  {user.email}
                </Box>
                <Box pr="10px" display="flex" alignItems="center">
                  {user.active && (
                    <Box mr="8px" color="primary.main" fontSize={12} fontWeight="bold">
                      active
                    </Box>
                  )}
                  <Switch
                    checked={user.active}
                    disabled={toggleActiveStatusLoading}
                    color="primary"
                    inputProps={{
                      'aria-label': 'toggle user',
                      ...qaAttr(`manage-access-switch-${user.email}`)
                    }}
                    classes={{ root: 'manageAccess__switch' }}
                    onChange={(e) => toggleSwitch(e, user)}
                  />
                </Box>
              </div>
            ))}
          </div>
          {map(attrs.users, (user, i) => (
            <Box key={`newUser__${i}`} ref={autoScrollRef} display="flex" alignItems="center">
              <Box maxWidth={255} mb="10px">
                <Input
                  {...$(`users.${i}.email`, handleChange)}
                  label="Email Address"
                  type="email"
                  variant="textfield"
                  labelClassName="manageAccess__input-label label"
                  withHelperText
                  testID={`manage-access-user-input-${i}`}
                />
              </Box>
              <Button
                variant="filled-primary"
                width={58}
                height={25}
                className="manageAccess__actionBtn"
                sx={{ ml: '30px' }}
                disabled={usersLoading || postNewUserLoading}
                onClick={() => submitUser(i)}
                testID={`manage-access-user-submit-button-${i}`}
              >
                Add
              </Button>
            </Box>
          ))}
          {error?.message && <Box color="error.main">{error.message}</Box>}
        </div>
      </SectionLayout>
    </StyledRoot>
  );
}

ManageAccess.propTypes = {
  expanded: PT.bool.isRequired,
  jumpLink: PT.string,
  onExpand: PT.func.isRequired,
  sectionName: PT.string.isRequired,
  users: PT.arrayOf(PT.any).isRequired,
  usersLoading: PT.bool
};

ManageAccess.defaultProps = {
  jumpLink: '',
  usersLoading: false
};

export default ManageAccess;
