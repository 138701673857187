import { addAlpha } from 'styles/utils';

export default ({ theme }) => ({
  '&.enterpriseDashboardContainer': {
    padding: '0 30px',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 0
    }
  },
  '& .container': {
    display: 'flex',
    marginLeft: -15,
    marginRight: -15,
    marginBottom: -30,
    alignItems: 'flex-start',
    [theme.breakpoints.down('desktopApp')]: {
      margin: 0
    }
  },
  '& .mainTemplateContainer': {
    flex: 2,
    display: 'flex',
    flexFlow: 'row wrap',
    [theme.breakpoints.down('desktopApp')]: {
      flex: 1
    }
  },
  '& .sideTemplateContainer': {
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .card': {
    width: '100%',
    maxWidth: '33.33%',
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 30,
    [theme.breakpoints.down('desktopApp')]: {
      paddingRight: 8,
      paddingLeft: 8,
      paddingBottom: 16
    }
  },
  '& .cardBtn': {
    height: 184,
    width: '100%',
    padding: 0,
    paddingTop: 19,
    border: '1px solid',
    borderColor: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 16,
    [theme.breakpoints.down('desktopApp')]: {
      height: 'auto',
      padding: '0 6px',
      borderRadius: 10,
      flexDirection: 'column',
      alignItems: 'center'
    },
    '&.Mui-disabled': {
      backgroundColor: 'inherit',
      color: 'inherit',
      opacity: 0.5
    },
    '& .cardBtn__icon': {
      minWidth: 90,
      height: 90,
      marginBottom: 16,
      fontSize: 38,
      [theme.breakpoints.down('desktopApp')]: {
        minWidth: 'auto',
        height: 'auto',
        padding: 30,
        marginBottom: 12,
        backgroundColor: 'transparent'
      },
      [theme.breakpoints.down(600)]: {
        padding: 15,
        fontSize: 30
      },
      [theme.breakpoints.down(425)]: {
        padding: 3,
        fontSize: 26
      }
    },
    '&.sideCard': {
      height: 122,
      marginBottom: 30,
      paddingTop: 0,
      paddingLeft: 23,
      paddingRight: 25,
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& .cardBtn__icon': {
        minWidth: 48,
        height: 48,
        marginBottom: 0,
        marginRight: 24,
        fontSize: 19
      },
      '&.tutorialCardBtn': {
        height: 200,
        padding: 12,
        [theme.breakpoints.down('desktopApp')]: {
          height: '100%',
          marginBottom: 0,
          padding: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'transparent'
        }
      }
    },
    '&.employerPlan': {
      backgroundColor: addAlpha(theme.palette.primary.light, 0.2)
    }
  },
  '& .cardBtn__icon': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.moreColors.purpleLight,
    backgroundColor: theme.palette.primary.lightTransparent
  },
  '& .cardBtn__title': {
    lineHeight: '20px',
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    [theme.breakpoints.down(768)]: {
      lineHeight: '14px',
      fontSize: 12
    },
    [theme.breakpoints.down(425)]: {
      lineHeight: '9px',
      fontSize: 8
    }
  },
  '& .contactUsCard__mobile': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block'
    }
  },
  '& .jobsCard__desktop': {
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .profileCard__desktop': {
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .profileCard__mobile': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
      marginBottom: 16,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderBottom: `1px solid ${addAlpha(theme.palette.moreColors.grey_1, 0.2)}`,
      borderTop: `1px solid ${addAlpha(theme.palette.moreColors.grey_1, 0.2)}`,
      borderRadius: '0px !important',
      textAlign: 'left'
    }
  },
  '& .profileLoaderWrapper': {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .companyLogo': {
    width: 48,
    minWidth: 48,
    height: 48,
    marginRight: 24,
    border: `1px solid ${addAlpha(theme.palette.moreColors.grey_9, 0.2)}`,
    borderRadius: '50%',
    [theme.breakpoints.down('desktopApp')]: {
      minWidth: 48,
      height: 48,
      marginRight: 16
    }
  },
  '& .companyName': {
    marginBottom: 12,
    lineHeight: '24px',
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 8,
      lineHeight: '18px',
      fontSize: 16,
      color: theme.palette.primary.text
    }
  },
  '& .plan': {
    lineHeight: '17px',
    fontSize: 14
  },
  '& .plan__trial': {
    marginLeft: 12,
    opacity: 0.5
  }
});
