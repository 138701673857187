import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { MdShare, FaFacebook } from 'components/icons';

const StyledRoot = styled('a')(({ theme }) => ({
  '&.resourceCard': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .resourceCard__img': {
    minWidth: 130,
    height: 80,
    marginRight: 15,
    objectFit: 'cover',
    borderRadius: 15,
    backgroundColor: theme.palette.common.white
  },
  '& .resourceCard__title': {
    fontSize: 18,
    color: theme.palette.common.white
  },
  '& .resourceCard__socialLink': {
    marginRight: 8,
    textDecoration: 'none',
    color: theme.palette.common.white,
    fontSize: 20
  }
}));

function ResourceCard(props) {
  const { title, imageUrl, link } = props;
  return (
    <StyledRoot href={link} className="resourceCard">
      <div className="resourceCard__img" />
      <div>
        <span className="resourceCard__title">{title}</span>
        {/* <div>
          <a
            href={`mailto:?subject=&body=Check this out, ${link}`}
            className="resourceCard__socialLink"
          >
            <MdShare fontSize="inherit" />
          </a>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
            target="_blank"
            rel="noreferrer"
            className="resourceCard__socialLink"
          >
            <FaFacebook fontSize="inherit" />
          </a>
        </div> */}
      </div>
    </StyledRoot>
  );
}

ResourceCard.propTypes = {
  title: PT.string.isRequired,
  link: PT.string,
  imageUrl: PT.string
};

ResourceCard.defaultProps = {
  link: '',
  imageUrl: ''
};

export default ResourceCard;
