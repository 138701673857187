import React, { useState, useEffect, memo } from 'react';
import PT from 'prop-types';
import { Box } from 'components';
import GrowLevels from './GrowLevels';
import LevelCompletionContainer from './LevelCompletionContainer';
import PathComingSoon from './PathComingSoon';
import AvailableTracks from './AvailableTracks';

function PathPage(props) {
  const {
    careerPath,
    careerPathLoading,
    careerPathCalled,
    futureJobTypeId,
    industryId,
    onCreateNewCareerPath
  } = props;
  const levels = careerPath?.careerPathGroupItems || [];
  const showAvailableTracks = !levels.length && !careerPathLoading && careerPathCalled;

  const [activeLevel, setActiveLevel] = useState(1);

  useEffect(() => {
    if (levels.length) {
      const current = levels.filter((o) => !o.completed)?.[0];
      setActiveLevel(current.index + 1);
    }
  }, [JSON.stringify(levels)]);

  return (
    <div>
      {!!levels.length && (
        <GrowLevels levels={levels} activeLevel={activeLevel} setActiveLevel={setActiveLevel} />
      )}
      {levels.map((level, i) => (
        <LevelCompletionContainer
          key={`level__${level.index + 1}`}
          active={i === activeLevel - 1}
          title={level.jobTypeName}
          level={level.index + 1}
          skills={level.skillsBadges}
          abilities={level.abilitiesBadges}
          setActiveLevel={setActiveLevel}
        />
      ))}
      {showAvailableTracks && (
        <>
          <Box mb="15px">
            <PathComingSoon futureJobTypeId={futureJobTypeId} />
          </Box>
          <Box maxWidth={550} mx="auto">
            <AvailableTracks industryId={industryId} onPathSelect={onCreateNewCareerPath} />
          </Box>
        </>
      )}
    </div>
  );
}

PathPage.propTypes = {
  careerPathLoading: PT.bool,
  careerPathCalled: PT.bool,
  careerPath: PT.shape({
    careerPathGroupItems: PT.arrayOf(
      PT.shape({
        active: PT.bool,
        completed: PT.bool,
        careerPathGroupId: PT.number,
        careerPathGroups: PT.shape({
          id: PT.number,
          startJobTypeId: PT.number,
          endJobTypeId: PT.number
        }),
        id: PT.number,
        index: PT.number,
        jobTypeId: PT.number,
        jobTypeName: PT.string,
        abilitiesBadges: PT.arrayOf(
          PT.shape({
            abilitiesBadgeId: PT.number,
            abilitiesId: PT.number,
            badgeUrl: PT.string,
            complete: PT.bool,
            name: PT.string
          })
        ),
        skillsBadges: PT.arrayOf(
          PT.shape({
            skillsBadgeId: PT.number,
            skillsId: PT.number,
            badgeUrl: PT.string,
            complete: PT.bool,
            name: PT.string
          })
        )
      })
    )
  }).isRequired,
  futureJobTypeId: PT.number.isRequired,
  industryId: PT.number.isRequired,
  onCreateNewCareerPath: PT.func.isRequired
};

PathPage.defaultProps = {
  careerPathLoading: false,
  careerPathCalled: false
};

export default memo(PathPage);
