import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon2(props) {
  return (
    <SvgIcon viewBox="0 0 35.98 34.61" {...props}>
      <polygon
        points="29.71 5.75 26.63 17.24 18.22 17.24 8.26 0 29.71 5.75"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="20.28 34.61 11.87 26.19 16.07 18.91 35.98 18.91 20.28 34.61"
        fill="currentColor"
        fillRule="evenodd"
      />
      <polygon
        points="0 12.01 11.49 8.93 15.7 16.22 5.75 33.46 0 12.01"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default GrowBadgeIcon2;
