import React from 'react';
import PT from 'prop-types';
import { FAQ_URL } from 'utils';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { IconButton } from 'components/shared';
import { QuestionMark } from 'components/icons';

const MobileIconButton = styled(IconButton)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 7
}));

function FaqLink(props) {
  const { userRole } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  return isDesktop ? (
    <IconButton
      isRouterLink={false}
      href={FAQ_URL}
      variant="outlined"
      color="primary"
      aria-label="faq"
      className="headerElement faqButton"
      testID={`${userRole}-faq-button`}
    >
      <QuestionMark />
    </IconButton>
  ) : (
    <MobileIconButton
      isRouterLink={false}
      href={FAQ_URL}
      edge="start"
      color="primary"
      aria-label="faq"
      className="headerElement faqButton"
      testID={`${userRole}-faq-button`}
    >
      <QuestionMark />
    </MobileIconButton>
  );
}

FaqLink.propTypes = {
  userRole: PT.string.isRequired
};

export default FaqLink;
