import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { GrowBadgeIcon2 } from 'components/icons';
import { GET_EMPLOYMENT_TRENDS } from 'api';
import PageHeader from '../PageHeader';
import TrendCard from './TrendCard';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.trendsPage': {
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: 15
    }
  },
  '& .trendsPage__content': {
    paddingTop: 15
  },
  '& .trendsPage__ratingTitle': {
    marginBottom: 8,
    padding: '0 22px',
    display: 'none',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '150%',
    color: theme.palette.moreColors.grey_3,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'inline-block'
    }
  },
  '& .trendsPage__currentCareer': {
    padding: '15px 0',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    color: theme.palette.common.white,
    '& > span': {
      marginRight: 10,
      display: 'inline-block'
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40,
      padding: '0 22px',
      flexWrap: 'wrap'
    }
  },
  '& .trendsPage__rating': {
    fontSize: 40,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 30,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .trendsPage__title': {
    fontSize: 40,
    [theme.breakpoints.down('desktopApp')]: {
      order: -1,
      width: '100%',
      display: 'block',
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .trendsPage__outlook': {
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '150%',
      color: theme.palette.moreColors.lightPurple
    }
  },
  '& .trendsPage__subtitle': {
    marginBottom: 15,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .trendsPage__growthContainer': {
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 20px'
    },
    '& .trendsPage__subtitle': {
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 20,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '130%'
      }
    }
  },
  '& .trendsPage__cards': {
    marginBottom: 30,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 16,
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40,
      gridTemplateColumns: '1fr',
      gridGap: 20
    }
  },
  '& .trendsPage__brightOutlookCareersContainer': {
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 40,
      padding: '0 20px'
    },
    '& .trendsPage__subtitle': {
      marginBottom: 7,
      fontSize: 9,
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 12,
        fontSize: 25,
        fontWeight: 700,
        lineHeight: '130%'
      }
    },
    '& .trendsPage__brightOutlookCareers': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      columnGap: 20,
      [theme.breakpoints.down('desktopApp')]: {
        gridTemplateColumns: '1fr'
      }
    },
    '& .trendsPage__outlookTitle_1, .trendsPage__outlookTitle_2': {
      [theme.breakpoints.down('desktopApp')]: {
        color: theme.palette.primary.main
      }
    },
    '& .trendsPage__outlookTitle_1': {
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 12,
        fontSize: 16,
        fontWeight: 800,
        lineHeight: '150%'
      }
    }
  },
  '& .trendsPage__outlookTitle_2': {
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 24,
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .trendsPage__position': {
    padding: '10px 0',
    fontSize: 13,
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 0 16px',
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '150%',
      color: theme.palette.moreColors.lightPurple,
      '&:last-of-type': {
        paddingBottom: 0
      }
    }
  }
}));

export default function TrendsPage() {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [fetchTrends, { data }] = useLazyQuery(GET_EMPLOYMENT_TRENDS, {
    fetchPolicy: 'cache-and-network'
  });
  const {
    brightOutlook = [],
    myCareerOutlook = {},
    similarCareerJobOutlook = []
  } = data?.employmentTrends || {};
  const { outlook, title, rating } = myCareerOutlook;

  useEffect(() => {
    fetchTrends();
  }, []);

  return (
    <StyledRoot className="trendsPage">
      {isDesktop && (
        <PageHeader
          title="NATIONAL TRENDS"
          subtitle="Current Outlook on your position"
          icon={<GrowBadgeIcon2 />}
        />
      )}
      <div className="trendsPage__content">
        <h3 className="trendsPage__ratingTitle sectionTitle light">
          Current outlook on your position
        </h3>
        <div className="trendsPage__currentCareer">
          {rating && <span className="trendsPage__rating">{rating}</span>}
          {title && <span className="trendsPage__title">{title}</span>}
          {outlook && <span className="trendsPage__outlook">{`${outlook} Outlook`}</span>}
        </div>
        {/* <div className="trendsPage__growthContainer">
          <h3 className="trendsPage__subtitle sectionTitle light">General Growth</h3>
          <div className="trendsPage__cards">
            <TrendCard
              title="Drinking Places (Alcoholic Beverages)"
              rate={38.4}
              newJobsCount={15700}
            />
            <TrendCard title="Full-Service Restaurants" rate={18.8} newJobsCount={29000} />
            <TrendCard title="Special Food Services" rate={14.4} newJobsCount={6000} />
          </div>
        </div> */}
        <div className="trendsPage__brightOutlookCareersContainer">
          <h3 className="trendsPage__subtitle trendsPage__outlookTitle_1 sectionTitle light">
            Looking for a Career Switch?
          </h3>
          <div className="trendsPage__outlookTitle_2 sectionTitle light">
            Careers with a bright outlook
          </div>
          <div className="trendsPage__brightOutlookCareers">
            {brightOutlook.map((careers, i) => (
              <div key={`career__${i}`} className="trendsPage__position">
                {careers}
              </div>
            ))}
          </div>
        </div>
      </div>
    </StyledRoot>
  );
}
