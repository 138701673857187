import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import Footer from 'components/Footer';

const StyledRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'maxWidth' && prop !== 'showFooter'
})(({ theme, fullWidth, maxWidth }) => ({
  position: 'relative',
  // height: '100vh',
  width: '100%',
  margin: '0 auto',

  '& .contentWrapper': {
    width: '100%',
    maxWidth: fullWidth ? '100%' : maxWidth,
    // height: '100%',
    minHeight: '100vh',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    // ...(showFooter && {
    //   paddingBottom: 74
    // }),
    [theme.breakpoints.down('desktopApp')]: {
      paddingBottom: 0
    }
  }
}));

function AppLayout(props) {
  const { children, className, fullWidth, headerElement, footerElement, maxWidth, showFooter } =
    props;
  return (
    <StyledRoot
      className={className}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      showFooter={showFooter}
    >
      <div className="contentWrapper">
        {headerElement}
        {children}
      </div>
      {showFooter && (footerElement || <Footer />)}
    </StyledRoot>
  );
}

AppLayout.propTypes = {
  children: PT.node,
  className: PT.string,
  fullWidth: PT.bool,
  footerElement: PT.element,
  headerElement: PT.element,
  maxWidth: PT.oneOfType([PT.string, PT.number]),
  showFooter: PT.bool
};

AppLayout.defaultProps = {
  children: null,
  className: '',
  fullWidth: false,
  footerElement: null,
  headerElement: null,
  maxWidth: 1024,
  showFooter: true
};

export default AppLayout;
