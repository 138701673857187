import React from 'react';
import PropTypes from 'prop-types';
import { format as formatDate } from 'date-fns';
import { getRoutes } from 'utils';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import styles from 'styles/Dashboard/JobCard';
import defaultImg from 'assets/img/job_default.png';

const StyledRoot = styled(Button)(styles);
const Dot = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  marginRight: 6,
  marginBottom: 2,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main
}));

function JobCard({ data, onClick, ...rest }) {
  const {
    active,
    draft,
    createdAt,
    updatedAt,
    closedDate,
    id = '',
    imageUrl = '',
    title = '',
    applicants = {}
  } = data;

  const {
    qmCount = 'N/A',
    total = 'N/A',
    rejectedCount = 'N/A',
    needsActionCount = 'N/A',
    starredCount = 'N/A'
  } = applicants;

  const isClosed = !active;

  return (
    <StyledRoot
      isRouterLink
      to={getRoutes({ id }).employer.job}
      // disabled={isClosed}
      className="card __displayFlex"
      onClick={onClick}
      testID={`job-link-${title}`}
      {...rest}
    >
      <img src={imageUrl || defaultImg} alt="" className="card__photoAreaSmall" />
      <Box p="24px 10px 31px">
        <h2 className="card__name">{title}</h2>
        <div className="card__stats">
          {isClosed ? (
            <Box width="100%">
              <Box mb="5px">Position Closed</Box>
              <Box width="100%" mb="5px" display="flex" justifyContent="space-between">
                <div>
                  <div className="card__dateTitle">Opening date:</div>
                  <div className="card__date">{formatDate(Number(createdAt), 'MM/dd/yyyy')}</div>
                </div>
                <div>
                  <div className="card__dateTitle">Closing date:</div>
                  <div className="card__date">
                    {closedDate
                      ? formatDate(Number(closedDate), 'MM/dd/yyyy')
                      : formatDate(Number(updatedAt), 'MM/dd/yyyy')}
                  </div>
                </div>
              </Box>
              <Box>{`${total || 0} applicant${total !== 1 || total !== 'N/A' ? 's' : ''}`}</Box>
            </Box>
          ) : (
            <>
              <Box className={'card__metrics'}>
                <span>{total} <span>applied</span></span>
                <span className={`anomaly ${qmCount >= 10 ? '__success' : ''}`}>
                  {qmCount} <span>matches</span>
                </span>
                <span className={`anomaly ${needsActionCount > 15 ? '__error' : ''}`}>
                  {needsActionCount} <span>todo</span>
                </span>
              </Box>
              {draft && <div className="draftLabel">Draft</div>}
            </>
          )}
        </div>
      </Box>
    </StyledRoot>
  );
}

JobCard.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired
};

export default JobCard;
