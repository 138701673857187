import React, { useEffect, useCallback, useRef, useState, useMemo, memo } from 'react';
import PT from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import AvatarEditor from 'react-avatar-editor';
import clsx from 'clsx';
import join from 'lodash/join';
import map from 'lodash/map';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import format from 'date-fns/format';
import {
  capitalizeFirstLetter,
  fileNameFromUrl,
  getProfileId,
  getComparableFields,
  getLocaleTimeZone,
  imageTypeHelperText,
  makeInitForm,
  qaAttr,
  trimStr,
  unformatPhone
} from 'utils';
import { track } from 'utils/segmentAnalytics';
import { useCountryStateQuery, useEmployeeTrainings, useMediaQueryMatches } from 'hooks';
import { Box, Dialog, FormHelperText, styled } from 'components';
import { Button, IconButton, Input, PhoneInput, Spinner, Select, Video } from 'components/shared';
import { MdArrowBack, MdChevronRight, MdCheckCircle, MdFilePresent } from 'components/icons';
import { useForm } from 'components/form';
import { IndustriesForm, SkillsForm } from 'components/dialogs/components';
import {
  ADD_CERTIFICATES,
  UPDATE_CERTIFICATES,
  UPDATE_USER_PROFILE,
  UPDATE_USER_EDUCATION,
  UPDATE_USER_EXP,
  UPDATE_USER_INDUSTRY,
  UPDATE_USER_SKILLS,
  UPDATE_EMPLOYEE_PROFILE_IMG,
  UPDATE_EMPLOYEE_PROFILE_VIDEO,
  UPDATE_EMPLOYEE_PROFILE_CV
} from 'api';
import styles from 'styles/Profile/EmployeeProfile';
import defaultEmployeeImg from 'assets/img/employee_default.png';
import MultiFormItem from './MultiFormItem';
import SectionLayout from '../SectionLayout';

const StyledRoot = styled('div')(styles);
const StyledSubDialog = styled(Dialog)(styles);

const NO_PREV_FIELDS = [
  'newImageFile',
  'newVideoFile',
  'newResumeFile',
  'imageUrl',
  'resumeUrl',
  'videoUrl'
];

const GENDER_ITEMS = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
  { label: 'Non-Binary', value: 'non-binary' },
  { label: 'I Prefer Not to Answer', value: 'no answer' }
];
const SERVER_DATE_FORMAT = 'yyyy-MM-dd';

function renderIndustriesString(industry) {
  return industry.length > 0 ? join(map(industry, 'name'), ', ') : '';
}

function generateForm(profile) {
  return makeInitForm(profile, { noPrevFields: NO_PREV_FIELDS });
}

function Profile(props) {
  const { fetchEmployeeProfile, profile, sectionLayoutProps } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const editorRef = useRef();
  const initProfile = useMemo(() => generateForm(profile), [JSON.stringify(profile)]);

  const { fetchCountryState, countries, states, getCountryById, getStateById } =
    useCountryStateQuery();

  const { fetchTrainings, editTraining, trainings } = useEmployeeTrainings();
  const inCompleteTrainings = trainings.filter((o) => !o.complete);

  const [postEmployeeUpdate, { loading: postProfileLoading = false }] =
    useMutation(UPDATE_USER_PROFILE);
  const [postEmployeeEducationUpdate, { loading: postEducationLoading = false }] =
    useMutation(UPDATE_USER_EDUCATION);
  const [postEmployeeExperienceUpdate, { loading: postExperienceLoading = false }] =
    useMutation(UPDATE_USER_EXP);
  const [postEmployeeIndustriesUpdate, { loading: postIndustriesLoading = false }] =
    useMutation(UPDATE_USER_INDUSTRY);
  const [postEmployeeSkillsUpdate, { loading: postSkillsLoading = false }] =
    useMutation(UPDATE_USER_SKILLS);
  const [addCertificates, { loading: addCertificatesLoading = false }] =
    useMutation(ADD_CERTIFICATES);
  const [updateCertificates, { loading: updateCertificatesLoading = false }] =
    useMutation(UPDATE_CERTIFICATES);
  const [postEmployeeProfileImg, { loading: postImageLoading = false }] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_IMG
  );
  const [postEmployeeProfileVideo, { loading: postVideoLoading = false }] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_VIDEO
  );
  const [postEmployeeProfileCV, { loading: postCVLoading = false }] = useMutation(
    UPDATE_EMPLOYEE_PROFILE_CV
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isProfileUpdate, setIsProfileUpdate] = useState(false);
  const [isImageLoading, setImageLoading] = useState(false);
  const [isImageChanged, setImageChanged] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [isVideoChanged, setIsVideoChanged] = useState(false);
  const [isResumeLoading, setIsResumeLoading] = useState(false);
  const [isResumeChanged, setIsResumeChanged] = useState(false);
  const [isIndustryModalOpen, setIsIndustryModalOpen] = useState(false);
  const [isSkillsModalOpen, setIsSkillsModalOpen] = useState(false);
  const [videoUploadError, setVideoUploadError] = useState('');
  const [courses, setCourses] = useState([]);

  const profileUpdateLoading =
    updateCertificatesLoading ||
    postProfileLoading ||
    postIndustriesLoading ||
    postEducationLoading ||
    postExperienceLoading ||
    postSkillsLoading ||
    isVideoLoading ||
    isResumeLoading ||
    isImageLoading;

  const { $, attrs, set, withValidation, validate, usePartial, useConfig } = useForm({
    initial: initProfile,
    validations: {
      // address: 'presence',
      phone: ['presence', 'phone'],
      name: ['presence'],
      zip: ['presence', 'zip'],
      city: ['presence'],
      countryId: ['presence', 'numericality'],
      stateId: ['presence', 'numericality']
    }
  });

  const selectedCountryOption = useMemo(
    () => getCountryById(attrs.countryId),
    [getCountryById, attrs.countryId]
  );

  const selectedStateOption = useMemo(
    () => getStateById(attrs.stateId),
    [getStateById, attrs.stateId]
  );

  const currentStates = states.filter(
    (stateObj) => stateObj?.country?.name === selectedCountryOption?.name
  );

  useConfig(() => {
    if (selectedCountryOption.code) {
      return {
        validations: {
          zip: ['presence', { zip: { countryCode: selectedCountryOption.code } }]
        }
      };
    }
    return {};
  }, [selectedCountryOption.code]);

  useEffect(() => {
    fetchTrainings();
    fetchCountryState();
  }, []);

  useEffect(() => {
    set({ ...initProfile });
  }, [JSON.stringify(initProfile)]);

  useEffect(() => {
    if (
      selectedCountryOption.name &&
      selectedStateOption.name &&
      selectedStateOption?.country?.name !== selectedCountryOption.name
    ) {
      set({ stateId: '' });
    }
  }, [selectedCountryOption.name, selectedStateOption.name]);

  const toggleEditMode = (e) => {
    e.stopPropagation(); // for mobile
    setIsEdit(!isEdit);
  };

  const handleFieldChange = useCallback((e, { name }) => {
    set(name, name === 'phone' ? unformatPhone(e.target.value) : e.target.value);
  }, []);

  const handleFormChange = useCallback((nextForm) => {
    set({ ...nextForm });
  }, []);

  const handlePhotoUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      setImageChanged(true);
      set({ imageUrl: reader.result, newImageFile: file });
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleVideoUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      setIsVideoChanged(true);
      set({ videoUrl: window.URL.createObjectURL(file), newVideoFile: file });
    };
    if (file) reader.readAsDataURL(file);
  };

  const handleResumeUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onload = async () => {
      setIsResumeChanged(true);
      set({ resumeUrl: reader.result, newResumeFile: file });
    };
    if (file) reader.readAsDataURL(file);
  };

  const postProfilePhoto = async () => {
    const file = attrs.newImageFile;

    if (editorRef.current && file) {
      setImageLoading(true);
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      const fileName = file.name;
      const ext = fileName.split('.').pop();
      canvasScaled.toBlob(async (blob) => {
        const blobToFile = new File([blob], fileName);
        await postEmployeeProfileImg({ variables: { file: blobToFile } });
        const imageUrl = window.URL.createObjectURL(blob);
        set({ imageUrl, newImageFile: null });
        setImageChanged(false);
        setImageLoading(false);
      }, `image/${ext}`);
    }
  };

  const postProfileVideo = async () => {
    const file = attrs.newVideoFile;

    if (file) {
      const fileName = file.name;
      const ext = fileName.split('.').pop();
      setIsVideoLoading(true);

      try {
        const result = await postEmployeeProfileVideo({ variables: { file } });
        if (result?.data?.errors?.[0]?.message) {
          setVideoUploadError(result.data.errors[0].message);
        }
        set({ newVideoFile: null /* , videoUrl: file */ });
      } catch (error) {
        console.error('postProfileVideo', error);
      } finally {
        setIsVideoLoading(false);
        setIsVideoChanged(false);
      }
    }
  };

  const postResume = async () => {
    const file = attrs.newResumeFile;

    if (file) {
      const fileName = file.name;
      const ext = fileName.split('.').pop();
      setIsResumeLoading(true);

      try {
        await postEmployeeProfileCV({ variables: { file } });
      } catch (error) {
        console.error('postResume', error);
      } finally {
        set({ newResumeFile: null });
        setIsResumeLoading(false);
        setIsResumeChanged(false);
      }
    }
  };

  const postCertificates = async (prevCertificates, certificates) => {
    const profileId = Number(getProfileId());
    let readyCertificates = [];
    const response = await updateCertificates({
      variables: { certificates, employeeProfileId: profileId }
    });
    readyCertificates = [
      ...response.data.postCertificates.map(({ __typename, ...rest }) => ({
        ...rest
      }))
    ];

    set({ certificates: readyCertificates, prevEducation: readyCertificates });
  };

  const handleSave = (e) => {
    e.stopPropagation(); // for mobile
    validate()
      .then(async (validatedAttrs) => {
        setIsProfileUpdate(true);
        const {
          certificates,
          education,
          experience,
          industry,
          skills,
          prevEducation,
          prevExperience,
          prevIndustry,
          prevSkills,
          prevCertificates
        } = validatedAttrs;
        const { current, prev } = getComparableFields(validatedAttrs, [
          'address',
          'name',
          'gender',
          'motto',
          'phone',
          'city',
          'countryId',
          'stateId',
          'zip'
        ]);
        let formChanged = false;

        if (isImageChanged) {
          formChanged = true;
          await postProfilePhoto();
        }
        if (isVideoChanged) {
          formChanged = true;
          await postProfileVideo();
        }
        if (isResumeChanged) {
          formChanged = true;
          await postResume();
        }
        if (!isEqual(prev, current)) {
          formChanged = true;
          const res = await postEmployeeUpdate({
            variables: {
              address: trimStr(validatedAttrs.address),
              name: trimStr(validatedAttrs.name),
              gender: validatedAttrs.gender,
              motto: trimStr(validatedAttrs.motto),
              phone: validatedAttrs.phone,
              city: trimStr(validatedAttrs.city),
              countryId: Number(validatedAttrs.countryId),
              stateId: Number(validatedAttrs.stateId),
              zip: validatedAttrs.zip
            }
          });
          if (res?.data?.postEmployeeUpdate) {
            const { address, name, gender, motto, phone, zip } = res.data.postEmployeeUpdate;
            set({ ...makeInitForm({ address, name, gender, motto, phone, zip }) });
          }
        }
        if (!isEqual(prevEducation, education)) {
          formChanged = true;
          const formatted = map(education, (o) => ({
            ...o,
            startDate: format(o.startDate, 'yyyy'),
            endDate: o.endDate ? format(o.endDate, 'yyyy') : o.endDate
          }));
          const res = await postEmployeeEducationUpdate({
            variables: { education: formatted }
          });
          if (res?.data?.postEmployeeEducation?.education) {
            // @todo: set result from BE (need fix on BE)
            // const formInd = prepareEducationForForm(res.data.postEmployeeEducationUpdate.education);
            set({ education, prevEducation: education });
          }
        }
        if (!isEqual(prevExperience, experience)) {
          formChanged = true;
          const formatted = map(experience, (o) => ({
            ...o,
            startDate: format(o.startDate, SERVER_DATE_FORMAT),
            endDate: o.endDate ? format(o.endDate, SERVER_DATE_FORMAT) : o.endDate
          }));
          const res = await postEmployeeExperienceUpdate({
            variables: { experience: formatted }
          });
          if (res?.data?.postEmployeeExperience?.experience) {
            set({ experience, prevExperience: experience });
          }
        }
        if (!isEqual(prevIndustry, industry)) {
          formChanged = true;
          const res = await postEmployeeIndustriesUpdate({
            variables: { industry }
          });
          if (res?.data?.postEmployeeIndustry?.industry) {
            // @todo: set result from BE (need fix on BE)
            // const formInd = prepareIndustryForForm(res.data.postEmployeeIndustriesUpdate.industry);
            set({ industry, prevIndustry: industry });
          }
        }
        if (!isEqual(prevSkills, skills)) {
          formChanged = true;
          const res = await postEmployeeSkillsUpdate({
            variables: { skills }
          });
          if (res?.data?.postEmployeeSkills /*?.skills*/) {
            // @todo: set result from BE (need fix on BE)
            // const formSkills = prepareSkillsForForm(res.data.postEmployeeSkillsUpdate.skills);
            set({ skills, prevSkills: skills });
          }
        }
        if (!isEqual(prevCertificates, certificates)) {
          formChanged = true;
          const formatted = map(certificates, (o) => ({
            ...o,
            completionDate: format(o.completionDate, SERVER_DATE_FORMAT)
          }));
          await postCertificates(prevCertificates, formatted);
        }

        if (formChanged) {
          track('Employee Profile Updated');
          fetchEmployeeProfile();
        }
        // setIsProfileUpdate(false);
        setIsEdit(false);
      })
      .catch((errors) => {
        const fieldsWithErr = Object.keys(errors).filter((field) => errors[field]);
        if (fieldsWithErr[0]) {
          const element = document.getElementById(fieldsWithErr[0]);
          if (element) element.scrollIntoView({ behavior: 'smooth' });
        }
      });
  };

  const addMultiFormItem = (field) => {
    if (field === 'certificates') {
      set(field, [
        ...attrs[field],
        {
          name: '',
          completionDate: null,
          complete: true,
          employeeProfileId: Number(getProfileId())
        }
      ]);
      return;
    }

    // const startDate = format(new Date(), field === 'education' ? 'yyyy' : 'yyyy-MM-dd');
    const startDate = null;
    if (isDesktop) {
      set(field, [
        ...attrs[field],
        {
          currentJob: false,
          name: '',
          pos: '',
          startDate,
          endDate: null
        }
      ]);
    } else {
      // first on mobile for more comfortable view
      set(field, [
        {
          currentJob: false,
          name: '',
          pos: '',
          startDate,
          endDate: null
        },
        ...attrs[field]
      ]);
    }
  };

  const deleteMultiFormItem = (field, index) => {
    const newVal = [...attrs[field]];
    newVal.splice(index, 1);
    set(field, newVal);
  };

  const toggleSkillsModal = () => {
    setIsSkillsModalOpen(!isSkillsModalOpen);
  };

  const toggleIndustriesModal = () => {
    setIsIndustryModalOpen(!isIndustryModalOpen);
  };

  const renderIndustryModal = () => (
    <StyledSubDialog open={isIndustryModalOpen} onClose={toggleIndustriesModal}>
      <div className="dialogHeader">
        <div className="headerAction_start">
          <IconButton
            color="primary"
            aria-label="return"
            className="headerAction_end"
            onClick={toggleIndustriesModal}
            testID="close-modal-button"
          >
            <MdArrowBack />
          </IconButton>
        </div>
        <h2 className="dialogTitle" {...qaAttr('industries-form-title')}>
          Industries
        </h2>
        <div className="headerAction_end" />
      </div>
      <div className="dialogContent" style={{ display: 'flex' }}>
        <IndustriesForm
          form={attrs}
          withSubmitBtn={false}
          withSkipBtn={false}
          onChange={handleFormChange}
        />
      </div>
    </StyledSubDialog>
  );

  const renderSkillsModal = () => (
    <StyledSubDialog open={isSkillsModalOpen} onClose={toggleSkillsModal}>
      <div className="dialogHeader">
        <div className="headerAction_start">
          <IconButton
            color="primary"
            aria-label="return"
            className="headerAction_end"
            onClick={toggleSkillsModal}
            testID="close-modal-button"
          >
            <MdArrowBack />
          </IconButton>
        </div>
        <h2 className="dialogTitle" {...qaAttr('skills-form-title')}>
          Skills
        </h2>
        <div className="headerAction_end" />
      </div>
      <div className="dialogContent" style={{ display: 'flex' }}>
        <SkillsForm
          form={attrs}
          withSubmitBtn={false}
          withSkipBtn={false}
          onChange={handleFormChange}
        />
      </div>
    </StyledSubDialog>
  );

  const {
    address,
    certificates,
    city,
    education,
    experience,
    gender,
    imageUrl,
    industry,
    motto,
    name,
    newImageFile,
    newVideoFile,
    newResumeFile,
    phone,
    resumeUrl,
    skills,
    videoUrl,
    zip
  } = attrs;

  const selectProps = {
    className: 'select',
    labelProps: { className: 'label' },
    native: false,
    required: true,
    withEmptyOption: true,
    withHelperText: true
  };

  const renderTraining = (training, i) => (
    <div key={`training__${training.id}`} className="course">
      <div className="course__assignedLabel">Assigned</div>
      <Box width="100%" mb="12px">
        <div className="label course__label">Certificate Name</div>
        <div className="course__value">{training.name}</div>
      </Box>
      {!training.taken && (
        <Button
          variant="filled-primary"
          className="course__action"
          onClick={() => editTraining({ ...training, taken: true })}
        >
          Take
        </Button>
      )}
    </div>
  );

  const renderAddressFields = () => (
    <div className="profile__addressGroup">
      {isEdit ? (
        <div className="formItem">
          <Input
            {...$('city', handleFieldChange)}
            variant="textfield"
            id="city-input"
            label="City"
            required
            labelClassName="label"
            withHelperText
            analyticParams={{
              key: 'City focused (employee profile)',
              trigger: 'focus'
            }}
            testID="city-input"
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">City</div>
          <div className="value">{city || 'N/D'}</div>
        </div>
      )}
      {isEdit ? (
        <div className="formItem">
          <Select
            {...$('stateId', handleFieldChange)}
            {...selectProps}
            inputVariant="outlined"
            label="State"
            id="stateId-select"
            analyticParams={{
              key: 'State focused (employee profile)',
              trigger: 'focus'
            }}
            testID="stateId-input"
            options={map(currentStates, (o, i) => ({
              value: o.id,
              label: o.name,
              ...qaAttr(`state-option-${i}`)
            }))}
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">State</div>
          <div className="value">{selectedStateOption.name || 'N/D'}</div>
        </div>
      )}
      {isEdit ? (
        <div className="formItem">
          <Input
            {...$('zip', handleFieldChange)}
            variant="textfield"
            id="zip-input"
            label="Zip Code"
            required
            labelClassName="label"
            withHelperText
            analyticParams={{
              key: 'Zip focused (employee profile)',
              trigger: 'focus'
            }}
            testID="zip-input"
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">Zip Code</div>
          <div className="value">{zip || 'N/D'}</div>
        </div>
      )}
      {isEdit ? (
        <div className="formItem">
          <Select
            {...$('countryId', handleFieldChange)}
            {...selectProps}
            inputVariant="outlined"
            label="Country"
            id="countryId-select"
            analyticParams={{
              key: 'Country focused (employee profile)',
              trigger: 'focus'
            }}
            testID="country-input"
            options={map(countries, (o, i) => ({
              value: o.id,
              label: o.name,
              ...qaAttr(`country-option-${i}`)
            }))}
          />
        </div>
      ) : (
        <div className="formItem">
          <div className="label">Country</div>
          <div className="value">{selectedCountryOption.name || 'N/D'}</div>
        </div>
      )}
    </div>
  );

  const sectionHeader =
    isDesktop || sectionLayoutProps.expanded ? (
      <Button
        variant={isEdit ? 'filled-primary' : 'text'}
        className={clsx('profile__headerAction', isEdit && 'profile_saveBtn')}
        disabled={profileUpdateLoading}
        endIcon={profileUpdateLoading ? <Spinner size={12} /> : null}
        onClick={isEdit ? handleSave : toggleEditMode}
        testID={`employee-profile-${isEdit ? 'save' : 'edit'}-button`}
      >
        {isEdit ? 'Save' : 'edit'}
      </Button>
    ) : null;

  return (
    <SectionLayout
      {...sectionLayoutProps}
      sectionHeaderContent={sectionHeader}
      sectionHeaderProps={{ style: isEdit ? { display: 'flex', alignItems: 'flex-start' } : {} }}
    >
      <StyledRoot className="profile__container">
        <div className="profile__formColumns">
          {isEdit ? (
            <div className="formItem">
              <Input
                {...$('name', handleFieldChange)}
                variant="textfield"
                id="name"
                label="Name"
                required
                labelClassName="label"
                withHelperText
                analyticParams={{
                  key: 'Name focused (employee profile)',
                  trigger: 'focus'
                }}
                testID="employee-profile-name-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Name</div>
              <div className="value">{name}</div>
            </div>
          )}
          {isEdit ? (
            <div className="formItem">
              <Input
                {...$('address', handleFieldChange)}
                variant="textfield"
                id="address"
                label="Location"
                labelClassName="label"
                withHelperText
                analyticParams={{
                  key: 'Address focused (employee profile)',
                  trigger: 'focus'
                }}
                testID="employee-profile-address-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Location</div>
              <div className="value">{address || 'N/D'}</div>
            </div>
          )}
        </div>

        {renderAddressFields()}

        <div className="profile__formColumns">
          {isEdit ? (
            <div className="formItem">
              <Input
                {...$('motto', handleFieldChange)}
                variant="textfield"
                id="motto"
                label="Motto"
                labelClassName="label"
                withHelperText
                analyticParams={{
                  key: 'Motto focused (employee profile)',
                  trigger: 'focus'
                }}
                testID="employee-profile-motto-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Motto</div>
              <div className="value">{motto || 'N/D'}</div>
            </div>
          )}
          {isEdit ? (
            <div className="formItem">
              <Select
                value={gender}
                inputVariant="textfield"
                id="gender"
                label="Gender"
                labelProps={{ className: 'label' }}
                analyticParams={{
                  key: 'Gender focused (employee profile)',
                  trigger: 'focus'
                }}
                options={map(GENDER_ITEMS, (o, i) => ({
                  value: o.value,
                  label: o.label,
                  ...qaAttr(`employee-profile-gender-option-${i}`)
                }))}
                onChange={(e) => handleFieldChange(e, { name: 'gender' })}
                testID="employee-profile-gender"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Gender</div>
              <div className="value">{capitalizeFirstLetter(gender)}</div>
            </div>
          )}
        </div>

        <div className="profile__formColumns">
          {isEdit ? (
            <div className="formItem">
              <PhoneInput
                {...$('phone', handleFieldChange)}
                variant="textfield"
                id="phone"
                label="Phone Number"
                labelClassName="label"
                withHelperText
                analyticParams={{
                  key: 'Phone focused (employee profile)',
                  trigger: 'focus'
                }}
                testID="employee-profile-phone-input"
              />
            </div>
          ) : (
            <div className="formItem">
              <div className="label">Phone Number</div>
              <div className="value">{phone}</div>
            </div>
          )}
        </div>

        <div className="profile__mediaGroup">
          <div className="profile__formColumnsWrapper">
            <div className="profile__formGroup">
              {isEdit ? (
                <div className="formItem">
                  <div className="label">Profile Image</div>
                  <AvatarEditor
                    ref={editorRef}
                    image={newImageFile || imageUrl}
                    width={230}
                    height={230}
                    border={0}
                    borderRadius={0}
                    color={[255, 255, 255, 0.6]}
                    scale={1.1}
                    rotate={0}
                    style={{ border: '1px solid #ECECF5', cursor: 'move' }}
                  />
                  <p className="profile__imageTypeTip">{imageTypeHelperText}</p>
                  <input
                    accept="image/*"
                    id="profile-image-input"
                    type="file"
                    onChange={handlePhotoUpload}
                    style={{ display: 'none' }}
                    {...qaAttr('employee-profile-photo-edit-input')}
                  />
                  <label htmlFor="profile-image-input">
                    <Button
                      component="span"
                      variant="filled-primary"
                      disabled={profileUpdateLoading}
                      className="mainAction"
                      testID="employee-profile-photo-edit-button"
                    >
                      Upload New
                    </Button>
                  </label>
                </div>
              ) : (
                <div className="formItem">
                  <div className="label">Profile Image</div>
                  <img src={imageUrl || defaultEmployeeImg} className="profile__imagePreview" />
                  {/* <div
                  style={{ backgroundImage: `url("${imageUrl || defaultEmployeeImg}")` }}
                  className={classes.profile__imagePreview}
                /> */}
                </div>
              )}
            </div>
            <div className="profile__formGroup">
              <div className="formItem">
                <div className="label">Intro Video</div>
                <Video
                  initUrl={videoUrl}
                  variant={isDesktop ? 'modal' : 'default'}
                  disabled={!videoUrl}
                  className="profile__videoPreview"
                />
                <input
                  accept="video/mp4,video/x-m4v,video/*"
                  id="profile-video-input"
                  type="file"
                  onChange={handleVideoUpload}
                  style={{ display: 'none' }}
                  {...qaAttr('employee-profile-video-edit-input')}
                />
                {isEdit && (
                  <label htmlFor="profile-video-input">
                    <Button
                      component="span"
                      variant="filled-primary"
                      disabled={profileUpdateLoading}
                      className="mainAction"
                      testID="employee-profile-video-edit-button"
                    >
                      Upload New
                    </Button>
                  </label>
                )}
                {videoUploadError && <FormHelperText error>{videoUploadError}</FormHelperText>}
              </div>
            </div>
          </div>
          <div className="formItem">
            <div className="label">Resume</div>
            <input
              accept=".doc,.docx,.txt,.pdf"
              id="profile-cv-input"
              type="file"
              onChange={handleResumeUpload}
              style={{ display: 'none' }}
              {...qaAttr('employee-profile-cv-edit-input')}
            />
            <Box display="flex">
              <Box
                component={resumeUrl ? 'a' : 'div'}
                {...(resumeUrl ? { href: resumeUrl, download: true } : {})}
                className="cvIconContainer"
              >
                {resumeUrl && <MdCheckCircle className="cvCheckIcon" />}
                <MdFilePresent color="primary" fontSize="inherit" />
              </Box>
              <div>
                <div className="value" style={{ marginBottom: '25px', wordBreak: 'break-all' }}>
                  {(() => {
                    if (newResumeFile?.name) return newResumeFile.name;
                    if (resumeUrl) return fileNameFromUrl(resumeUrl);
                    return '';
                  })()}
                </div>
                <div>
                  {isEdit && (
                    <label htmlFor="profile-cv-input">
                      <Button
                        component="span"
                        variant="filled-primary"
                        disabled={profileUpdateLoading}
                        className="mainAction"
                        {...qaAttr('employee-profile-cv-edit-button')}
                      >
                        Upload New
                      </Button>
                    </label>
                  )}
                </div>
              </div>
            </Box>
          </div>
        </div>

        <div className="formItem">
          <div className="label">Skills</div>
          <div className="chips">
            {map(skills, ({ name: skillName, skill_id: skillId }, i) => (
              <div key={`skill__${skillId}`} className="chips__item" style={{ marginRight: 13 }}>
                {skillName}
              </div>
            ))}
          </div>
          {isEdit && (
            <Button
              variant="text"
              endIcon={<MdChevronRight color="primary" />}
              className="chipsBtn"
              onClick={toggleSkillsModal}
              testID="employee-profile-skills-button"
            >
              Update Skills
            </Button>
          )}
          {renderSkillsModal()}
        </div>
        <div className="formItem">
          <div className="label">Industries</div>
          <div className="chips">
            {map(industry, ({ name: industryName, id }, i) => (
              <div key={`industry__${id}`} className="chips__item" style={{ marginRight: 13 }}>
                {industryName}
              </div>
            ))}
          </div>
          {isEdit && (
            <Button
              variant="text"
              endIcon={<MdChevronRight color="primary" />}
              className="chipsBtn"
              onClick={toggleIndustriesModal}
              testID="employee-profile-industries-button"
            >
              Update Industries
            </Button>
          )}
          {renderIndustryModal()}
        </div>
        <div className="formItem" style={{ marginBottom: 48 }}>
          <div className="label">Education</div>
          {education.length ? (
            <div className="multiFormContainer">
              {map(education, ({ education_id }, i) => (
                <MultiFormItem
                  type="education"
                  key={`educationForm__${i}`}
                  usePartial={usePartial}
                  index={i}
                  isEdit={isEdit}
                  onDelete={deleteMultiFormItem}
                />
              ))}
            </div>
          ) : (
            <div className="value" style={{ marginBottom: 20 }}>
              N/D
            </div>
          )}
          {isEdit && (
            <Button
              variant="filled-primary"
              className="mainAction"
              onClick={() => addMultiFormItem('education')}
              testID="employee-profile-add-education-button"
            >
              Add Education
            </Button>
          )}
        </div>
        <div className="formItem" style={{ marginBottom: 48 }}>
          <div className="label">Work History</div>
          <p className="profile__experienceDescription">
            Connect your work history to your employer to rate them and get insights to other
            similar employers. Ask your employer for their code to connect your account.
          </p>
          {experience.length ? (
            <div className="multiFormContainer">
              {map(experience, ({ experience_id }, i) => (
                <MultiFormItem
                  type="experience"
                  key={`experienceForm__${i}`}
                  usePartial={usePartial}
                  index={i}
                  bordered
                  isEdit={isEdit}
                  onDelete={deleteMultiFormItem}
                  testID={`experience-item-${i}`}
                />
              ))}
            </div>
          ) : (
            <div className="value" style={{ marginBottom: 20 }}>
              No experience
            </div>
          )}
          {isEdit && (
            <Button
              variant="filled-primary"
              className="mainAction"
              onClick={() => addMultiFormItem('experience')}
              testID="employee-profile-add-experience-button"
            >
              Add Experience
            </Button>
          )}
        </div>

        <div className="formItem" style={{ marginBottom: 0 }}>
          <div className="label">Certificates</div>
          <p className="profile__experienceDescription">
            You can add certificates you’ve trained for here. Classes to get certificates will also
            populate here to complete them. Ask your employer to verify your certificates!
          </p>
          {(!!certificates.length || !!inCompleteTrainings.length) && (
            <div className="multiFormContainer">
              {map(certificates, ({ certificate_id }, i) => (
                <MultiFormItem
                  type="certificates"
                  key={`certificateForm__${i}`}
                  usePartial={usePartial}
                  index={i}
                  bordered
                  isEdit={isEdit}
                  onDelete={deleteMultiFormItem}
                />
              ))}
              {map(inCompleteTrainings, renderTraining)}
            </div>
          )}
          {isEdit && (
            <Button
              variant="filled-primary"
              className="mainAction"
              disabled={updateCertificatesLoading}
              onClick={() => addMultiFormItem('certificates')}
              testID="employee-profile-add-certificate-button"
            >
              Add Certificate
            </Button>
          )}
        </div>
      </StyledRoot>
    </SectionLayout>
  );
}

Profile.propTypes = {
  fetchEmployeeProfile: PT.func.isRequired,
  profile: PT.object.isRequired,
  sectionLayoutProps: PT.objectOf(PT.any).isRequired
};

export default memo(Profile);
