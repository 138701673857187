import React, { useState } from 'react';
import PT from 'prop-types';
import { MenuItem, styled } from 'components';
import { MdExpandMore } from 'components/icons';
import usflag from 'assets/img/united-states.png';
import PopupMenu from './PopupMenu';
import Button from '../Button';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.container': {},
  '& .langPopup__flag': {
    marginRight: 16
  },
  '& .langPopup__menuItem': {
    color: '#000'
  }
}));

// TODO: maybe it would be better to use external packages for flags and languages
const LANGS_MAP = {
  'en-us': { label: 'English, USA', flag: usflag }
};
const LANGS = Object.keys(LANGS_MAP);

function LangSwitchPopup(props) {
  const [currentLang, setCurrentLang] = useState('en-us');

  const select = (val) => setCurrentLang(val);

  return (
    <StyledRoot className="container">
      <PopupMenu disablePortal getTrigger={triggerButton} triggerProps={{ currentLang }}>
        {({ closePopup }) =>
          LANGS.map((lang) => (
            <MenuItem
              key={lang}
              selected={lang === currentLang}
              className="langPopup__menuItem"
              onClick={() => {
                select(lang);
                closePopup();
              }}
            >
              <img src={LANGS_MAP[lang].flag} alt="" className="langPopup__flag" />
              {LANGS_MAP[lang].label}
            </MenuItem>
          ))
        }
      </PopupMenu>
    </StyledRoot>
  );
}

function triggerButton({ triggerProps, openPopup }) {
  const { currentLang } = triggerProps;
  return (
    <Button onClick={openPopup} sx={{ padding: 0 }}>
      <img src={LANGS_MAP[currentLang].flag} alt="" className="langPopup__flag" />
      <span className="langPopup__buttonTitle">{LANGS_MAP[currentLang].label}</span>
      <MdExpandMore color="inherit" />
    </Button>
  );
}

LangSwitchPopup.propTypes = {};

export default LangSwitchPopup;
