export default ({ theme }) => ({
  '& .paper': {
    maxWidth: '436px !important',
    '&.checkout': {
      '&.withCards': {
        maxWidth: '760px !important',
        [theme.breakpoints.down('desktopApp')]: {
          maxWidth: '436px !important'
        }
      }
    },
    overflow: 'hidden',
    [theme.breakpoints.down('desktopApp')]: {
      width: '100%',
      margin: '20px 15px !important'
    }
  },
  '& .header': {
    flex: 0,
    padding: '12px 27px 10px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '5px 17px'
    }
  },
  '& .actionsContainer': {
    padding: '0 27px 17px',
    textAlign: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '10px 17px 17px'
    }
  },
  '& .content': {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('desktopApp')]: {}
  },
  '& .content__inner': {
    width: '100%',
    padding: '10px 27px 0',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '5px 17px 0'
    },
    '&.checkout': {
      '&.withCards': {
        overflow: 'hidden',
        [theme.breakpoints.down('desktopApp')]: {
          flexDirection: 'column',
          overflow: 'auto'
        },
        '& .paymentDetailsContainer': {
          width: '60%',
          overflow: 'auto',
          [theme.breakpoints.down('desktopApp')]: {
            width: '100%',
            overflow: 'initial'
          }
        },
        '& .savedCardsContainer': {
          width: '40%',
          [theme.breakpoints.down('desktopApp')]: {
            width: '100%'
          }
        }
      }
    }
  },
  '& .paymentDetailsContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  '& .savedCardsList': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    [theme.breakpoints.down('desktopApp')]: {
      flexDirection: 'row'
    }
  },
  '& .formContainer': {
    maxWidth: 382,
    margin: '0 auto',
    flex: 1,
    overflow: 'auto'
  },
  '& .title': {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '18px',
    textAlign: 'center',
    color: theme.palette.primary.text
  },
  '& .subTitle': {
    marginBottom: 20,
    fontSize: 14,
    lineHeight: '16px',
    textAlign: 'center',
    color: theme.palette.primary.text
  },
  '& .closeBtn': {
    padding: 0,
    fontSize: 24
  },
  '& .plasticContainer': {
    marginBottom: 32,
    '& .rccs': {
      width: '100%',
      maxWidth: 382
    },
    '& .rccs__card': {
      width: '100%',
      height: 239
    },
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 24
    }
  },
  '& .formItem': {
    marginBottom: 16,
    '&:last-of-type': {
      marginBottom: 24
    }
  },
  '& .doubleInputRow': {
    display: 'flex',
    '& .formItem': {
      width: '50%',
      '&:not(:first-of-type)': {
        marginLeft: 16
      }
    }
  },
  '& .htmlInput': {
    padding: '6px 0 16px',
    fontSize: 16,
    lineHeight: '18px'
  },
  '& .savedCardsContainer': {
    display: 'flex',
    flexDirection: 'column'
  },
  '& .savedCard': {
    padding: '10px 20px',
    borderRadius: 15,
    cursor: 'pointer',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '10px 8px'
    },
    '&.card_selected': {
      backgroundColor: theme.palette.primary.main
    },
    '&:not(:last-of-type)': {
      marginBottom: 25,
      [theme.breakpoints.down('desktopApp')]: {
        marginBottom: 0
      }
    }
  },
  '& .savedCard__card': {
    cursor: 'pointer',
    '& .rccs, .rccs__card': {
      width: '100%',
      maxWidth: 220,
      minWidth: 220
    },
    '& .rccs__card': {
      height: 150
    },
    '& .rccs__number': {
      fontSize: 13
    },
    '& .rccs__name, .rccs__expiry__value': {
      fontSize: 12
    },
    '& .rccs__expiry__valid': {
      fontSize: 8
    },
    '& .rccs__card--front, .rccs__card--back': {
      boxShadow: 'none'
    }
  },
  '& .savedCard__number': {
    marginTop: 12,
    fontSize: 16,
    textAlign: 'center',
    '&.card_selected': {
      color: theme.palette.primary.white
    }
  },
  '& .autorenewCheckbox': {
    marginLeft: 17,
    marginBottom: 15,
    '& .StyledCheckbox-label': {
      marginLeft: 20,
      fontSize: 12
    },
    '& .StyledCheckbox-checkbox': {
      width: 24,
      height: 24
    }
  }
});
