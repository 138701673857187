import React, { useEffect, useCallback, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_QUESTIONS } from 'api';

const FIELD_NAME = 'questions';

export default function useJobQuestionsQuery(props) {
  const { autoFetch = true, jobId, ...queryOptions } = props || {};

  const [fetch, result] = useLazyQuery(GET_QUESTIONS, {
    variables: { jobsJobsId: Number(jobId) },
    ...queryOptions
  });
  const getResultFields = useCallback((res) => res?.data?.[FIELD_NAME]?.[0]?.questions, []);
  const questions = getResultFields(result);

  useEffect(() => {
    if (autoFetch) fetch({ ...queryOptions });
  }, [autoFetch]);

  return useMemo(
    () => ({
      fetch,
      questions,
      fieldName: FIELD_NAME,
      getResultFields,
      ...result
    }),
    [fetch, getResultFields, questions, result]
  );
}
