import React from 'react';
import PT from 'prop-types';
import { useMediaQueryMatches } from 'hooks';
import { styled } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdArrowBack } from 'components/icons';

const DesktopIconButton = styled(Button)(({ theme }) => ({
  height: 44,
  padding: '0 20px',
  borderRadius: 22,
  fontSize: 14
}));
const MobileIconButton = styled(IconButton)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 7
}));

function ReturnLink(props) {
  const { title, ...rest } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  return isDesktop ? (
    <DesktopIconButton
      variant="outlined-secondary"
      startIcon={<MdArrowBack />}
      className="headerElement returnLink"
      {...rest}
    >
      {title}
    </DesktopIconButton>
  ) : (
    <MobileIconButton
      edge="start"
      aria-label="return"
      color="primary"
      className="headerElement returnLink"
      {...rest}
    >
      <MdArrowBack />
    </MobileIconButton>
  );
}

ReturnLink.propTypes = {
  title: PT.string.isRequired
};

export default ReturnLink;
