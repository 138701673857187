export default ({ theme }) => ({
  '&.careerDevModal': {
    '& .careerDevModal__paper': {
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%',
      maxWidth: 278,
      minHeight: '100vh',
      margin: 0,
      borderRadius: 0,
      overflow: 'initial',
      [theme.breakpoints.down('desktopApp')]: {
        position: 'relative',
        top: 'initial',
        right: 'initial',
        minHeight: 'calc(100% - 50px)',
        maxHeight: 'calc(100% - 50px)',
        width: 'calc(100% - 32px)',
        margin: '20px 16px 15px'
      }
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
    }
  },
  '& .profile': {
    position: 'relative',
    flex: 1,
    overflow: 'auto'
  },
  '& .profile__header': { position: 'relative' },
  '& .profile__imageContainer': {
    width: '100%',
    aspectRatio: '16 / 9',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .profile__image': {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  '& .profile__closeBtn': {
    position: 'absolute',
    right: '-7px',
    top: '-7px',
    zIndex: 1,
    transform: 'scale(.8)'
  },
  '& .profile__starredBtn': {
    width: 35,
    height: 35,
    borderRadius: '50%',
    border: `1px solid ${theme.palette.moreColors.grey_1}`,
    backgroundColor: theme.palette.moreColors.grey_1,
    '&.profile__starredBtn_active': {
      border: `1px solid ${theme.palette.moreColors.yellow}`,
      backgroundColor: '#FFF'
    }
  },
  '& .profile__starIcon': {
    fontSize: 20,
    color: '#FFF',
    '&.profile__starIcon_active': {
      color: theme.palette.moreColors.yellow
    }
  },
  '& .profile__mailBtn': {
    width: 35,
    height: 35,
    borderRadius: '50%'
  },
  '& .sentMessage': {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 11,
    lineHeight: '12px',
    fontWeight: 'bold'
  },
  '& .viewMessagesBtn': {
    fontSize: 11,
    lineHeight: '12px'
  },
  '& .profile__nameContainer': {
    padding: '16px 0',
    borderBottom: '1px solid #e4e4e4'
  },
  '& .profile__name': {
    fontSize: 16,
    paddingBottom: '.5rem'
  },
  '& .profile__motto': {
    // color: theme.palette.moreColors.grey_1,
    fontSize: 12
  },
  '& .profile__expContainer': {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    position: 'relative'
  },
  '& .profile__expCircle': {
    height: 3,
    width: 3,
    borderRadius: '50%',
    position: 'absolute',
    left: -2,
    top: '50%'
  },
  '& .profile__careerPathContainer': {
    backgroundColor: '#F4F4F4',
    padding: 12,
    borderRadius: 10,
    margin: '1rem 0'
  },
  '& .profile__sectionContainer': {
    padding: '8px 0'
  },
  '& .profile__sectionTitle': {
    textTransform: 'uppercase',
    fontSize: 11,
    color: '#999',
    paddingBottom: 2,
    borderBottom: '1px solid #ECECF5',
    marginBottom: '.5rem'
  },
  '& .profile__navContainer': {
    position: 'sticky',
    bottom: 0,
    padding: '.75rem 1rem .5rem',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 -3px 4px rgb(0 0 0 / 3%)',
    backgroundColor: '#FFF',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'block'
    }
  },
  '& .profile__navBtn': {
    fontSize: 13,
    padding: '5px 10px',
    [theme.breakpoints.down('desktopApp')]: {
      marginRight: 10
    }
  },
  '& .profile__jobsContainer': {
    marginBottom: 10,
    padding: '.5rem',
    display: 'block',
    textAlign: 'left',
    [theme.breakpoints.down('desktopApp')]: {
      borderLeftWidth: 0,
      padding: 0
    }
  },
  '& .profile__jobsContainer_active': {
    borderLeftColor: theme.palette.primary.main
  },
  '& .jobCardContainer': {
    background: '#FFF',
    position: 'absolute',
    right: 'calc(1024px / 3 * 2 / 3 + 60px)',
    zIndex: 5,
    // maxWidth: 250,
    borderRadius: 18,
    boxShadow: '0 0 07 rgba(0, 0, 0, .25)'
  },
  '& .profileCloseButton': {
    position: 'absolute',
    left: '-7px',
    top: '-7px',
    zIndex: 1,
    transform: 'scale(.8)'
  },
  '& .profile__suggestedJobsTitle': {
    fontSize: 14,
    color: theme.palette.primary.main
  },
  '& .profile__suggestedJobsCompany': {
    fontSize: 11
    // color: theme.palette.moreColors.grey_1
  },
  '& .scrollToTop': {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 1,
    width: 1
  }
});
