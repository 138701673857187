import React from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { Button } from 'components/shared';

const StyledRoot = styled(Button)(({ theme }) => ({
  '&.levelBtn': {
    height: 80,
    width: 80,
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: '1px solid #FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .levelBtn__level': {
    paddingBottom: 10,
    lineHeight: 1,
    fontSize: 10,
    color: theme.palette.moreColors.purpleLight_2
  },
  '& .levelBtn__title': {
    lineHeight: 1,
    fontSize: 10,
    letterSpacing: -1,
    color: '#FFF'
  }
}));

function LevelNavigationButton(props) {
  const { level, title, onClick } = props;
  return (
    <StyledRoot className="levelBtn" onClick={onClick}>
      <Box fontSize={10} color="moreColors.yellow">
        View
      </Box>
      <span className="levelBtn__level">{`Level ${level}`}</span>
      {title && <span className="levelBtn__title">{title}</span>}
    </StyledRoot>
  );
}

LevelNavigationButton.propTypes = {
  level: PT.number.isRequired,
  onClick: PT.func.isRequired,
  title: PT.string
};

LevelNavigationButton.defaultProps = {
  title: ''
};

export default LevelNavigationButton;
