import React from 'react';
import PT from 'prop-types';
import { styled } from 'components';
import { Button, IconButton } from 'components/shared';
import { MdLogout } from 'components/icons';
import { useAuth, useMediaQueryMatches } from 'hooks';

const StyledButton = styled(Button)(({ theme }) => ({
  width: 126,
  height: 45,
  fontSize: 14,
  lineHeight: '16px',
  borderRadius: 3
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  padding: 7
}));

function LogoutButton(props) {
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { logout } = useAuth();

  const logOut = () => {
    logout();
  };

  return isDesktop ? (
    <StyledButton
      variant="filled-secondary"
      className="headerElement logoutButton"
      onClick={logOut}
      testID="logout-button"
    >
      Logout
    </StyledButton>
  ) : (
    <StyledIconButton
      color="primary"
      aria-label="log out"
      className="headerElement logoutButton"
      onClick={logOut}
      testID="logout-button"
    >
      <MdLogout />
    </StyledIconButton>
  );
}

export default LogoutButton;
