import React from 'react';
import { SvgIcon } from 'components';

function DollarIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 4.8 8.1"
        enableBackground="new 0 0 4.8 8.1"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill="#2AB673"
            d="M4.3,6.5C3.9,6.8,3.4,7,2.8,7.1v1H2.1v-1C1.7,7.1,1.3,7,0.9,6.9C0.5,6.7,0.2,6.6,0,6.4l0.4-0.9
		C0.6,5.7,0.9,5.8,1.2,6c0.3,0.1,0.6,0.2,0.9,0.2V4.5c-0.4-0.1-0.7-0.2-1-0.3S0.6,3.9,0.4,3.6C0.2,3.4,0.1,3.1,0.1,2.7
		c0-0.5,0.2-0.9,0.5-1.2C1,1.2,1.5,1,2.1,1V0h0.7v1C3.1,1,3.4,1,3.7,1.1s0.6,0.2,0.8,0.4L4.2,2.3C3.8,2.1,3.3,1.9,2.8,1.9v1.7
		c0.4,0.1,0.8,0.2,1,0.3C4.1,4,4.3,4.2,4.5,4.4C4.7,4.7,4.8,5,4.8,5.3C4.8,5.8,4.6,6.2,4.3,6.5z M1.5,3.1c0.1,0.1,0.4,0.2,0.6,0.3
		V1.9C1.8,1.9,1.6,2,1.4,2.2S1.2,2.5,1.2,2.7C1.2,2.9,1.3,3,1.5,3.1z M3.5,5.9c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.3-0.2-0.5
		S3.1,4.7,2.8,4.6v1.5C3.1,6.1,3.3,6,3.5,5.9z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default DollarIcon;
