import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PT from 'prop-types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { GET_JOB, GET_PUBLIC_JOB, GET_QUESTIONS } from 'api';
import { parse as parseDate, format as formatDate } from 'date-fns';
import { INTERVIEW_TYPES, getRoutes, formatJobAddress } from 'utils';
import { useWindowSize, useCountryStateQuery, useAuth, useMediaQueryMatches } from 'hooks';
import { withEmployeeJobActions } from 'hocs';
import { Box, styled, useTheme } from 'components';
import { Button, IconButton, Spinner } from 'components/shared';
import { MdArrowBack, MdIosShare, MdStarBorder, MdPlace } from 'components/icons';
import { ShareJob, InterviewTypeIcon } from 'components/Job';
import { EmployeeInterviewScheduleModal } from 'components/Interview';
import styles from 'styles/Dashboard/EmployeeJob';
import jobDefaultImg from 'assets/img/job_default.png';

const StyledRoot = styled('div')(styles);

function calcImageSize(screenWidth = 0) {
  const w = screenWidth <= 600 ? screenWidth - 32 : 600 - 32;
  return {
    width: w,
    height: (w / 4) * 3
  };
}

const DEFAULT_MODALS_CONF = {};
const REFERRER = {
  allJobs: 'allJobs'
};
const ROUTES = getRoutes();

const enhance = (WrappedComponent) => withEmployeeJobActions(WrappedComponent);

function EmployeeJob(props) {
  const {
    applyLoading,
    interviewSaveLoading,
    onApply,
    onReject,
    onRetract,
    onSaveInterview,
    onStar,
    prepareInterviewReschedule,
    rejectLoading,
    retractLoading,
    starLoading
  } = props;

  const client = useApolloClient();
  const { id: jobId } = useParams();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const { authed: isAuthenticated } = useAuth();
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { width, height } = useWindowSize();
  const theme = useTheme();

  const [modals, setModals] = useState(DEFAULT_MODALS_CONF);
  const [isRejected, setIsRejected] = useState(false);
  const [scheduleModalConfig, setScheduleModalConfig] = useState({ isOpen: false });
  const [referrerPage, setReferrerPage] = useState(null);

  const { fetchCountryState, getCountryById, getStateById } = useCountryStateQuery();

  const manageModals = useCallback((newConfig = {}) => {
    setModals((prev) => ({ ...prev, ...newConfig }));
  }, []);

  const [fetchJob, { data: jobData, loading: jobLoading = false }] = useLazyQuery(GET_JOB, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  });
  const fetchedJob = jobData?.job;

  const [fetchPublicJob, { data: publicJobData, loading: publicJobLoading = false }] = useLazyQuery(
    GET_PUBLIC_JOB,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );
  const fetchedPublicJob = publicJobData?.publicJob;

  const [fetchQuestions, { data: questionData, loading: questionsLoading }] = useLazyQuery(
    GET_QUESTIONS,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );
  const fetchedQuestions = questionData?.questions?.[0]?.questions || [];

  const job = useMemo(() => {
    let jobToReturn = fetchedPublicJob || fetchedJob || {};
    if (fetchedQuestions.length) {
      const filledQuestions = (fetchedQuestions || []).map(
        (q) => q.name && q.name.trim().length > 0
      );
      jobToReturn = { ...jobToReturn, questions: fetchedQuestions }; // filledQuestions };
    }
    return jobToReturn;
  }, [
    JSON.stringify(fetchedJob),
    JSON.stringify(fetchedPublicJob),
    JSON.stringify(fetchedQuestions)
  ]);

  const fetchJobById = useCallback(async () => {
    if (jobId) {
      const id = Number(jobId);
      if (isAuthenticated) {
        const data = await fetchJob({ variables: { jobId: id } });
        // job query doesn't return questions, so separate request is used
        fetchQuestions({ variables: { jobsJobsId: id } });
        return data?.data?.job;
      }
      const data = await fetchPublicJob({ variables: { jobId: id } });
      return data?.data?.publicJob;
    }
    return null;
  }, [fetchJob, fetchPublicJob, fetchQuestions, isAuthenticated, jobId]);

  useEffect(() => {
    const { isAllJobsOpener = false } = routerLocation?.state || {};

    if (isAllJobsOpener) {
      // clear history state
      navigate(routerLocation.pathname, { replace: true, state: {} });
      setReferrerPage(REFERRER.allJobs);
    }

    fetchJobById();
    fetchCountryState();
  }, [jobId]);

  const {
    active = false,
    applicantAmount,
    approved = false,
    city,
    countryId,
    description = 'N/D',
    employerProfile = {},
    employerProfileProfileId, // ! not available on public
    endDate,
    hireDate,
    imageUrl,
    interviewType,
    isSchedule = false,
    location = 'N/D',
    passed = false,
    payRange = 'N/D',
    requirements = 'N/D',
    scheduleStatusCount = 0,
    starred = false,
    stateId,
    title: jobTitle = 'N/D',
    userInterviewDate: interviewDate = null,
    webUrl,
    zip
  } = job;
  const image = imageUrl || jobDefaultImg;
  const {
    imageUrl: companyLogo,
    name: companyName,
    profile_id: employerProfileId
  } = employerProfile;
  const interviewAppointed = !!interviewDate;
  const canShowInterviewLocation = interviewAppointed;
  const canShowInterviewMapLink = interviewAppointed && interviewType === INTERVIEW_TYPES.inperson;

  const formattedHireDate = useMemo(
    () =>
      hireDate ? formatDate(parseDate(hireDate, 'yyyy-MM-dd', new Date()), 'MMMM dd, yyyy') : 'N/D',
    [hireDate]
  );

  const selectedState = useMemo(() => getStateById(stateId), [getStateById, stateId]);
  const selectedCountry = useMemo(() => getCountryById(countryId), [getCountryById, countryId]);
  const formattedInterviewDate = useMemo(
    () => formatDate(new Date(interviewDate), "'Scheduled:' MM-dd-yyyy '@' hh:mm aaa"),
    [interviewDate]
  );

  const jobAddress =
    formatJobAddress({
      zip,
      city,
      country: selectedCountry.code,
      state: selectedState.code
    }) || 'N/D';

  const isApproved = approved;
  // const isPassed = passed;
  const isStarred = starred;
  const isJobApproving =
    interviewSaveLoading || applyLoading || rejectLoading || starLoading || retractLoading;
  const isApplyDisabled = isApproved || isRejected || isJobApproving;
  const isRejectDisabled = isRejected || isJobApproving;

  const openScheduleModal = useCallback(() => {
    setScheduleModalConfig({
      isOpen: true,
      jobId: Number(jobId),
      jobTitle,
      employerProfileName: companyName,
      employerProfileId
    });
  }, [companyName, employerProfileId, jobId, jobTitle]);

  const closeScheduleModal = useCallback(() => {
    setScheduleModalConfig({ isOpen: false });
  }, []);

  const callAuthAction = (callback) => {
    if (isAuthenticated) callback();
    else
      navigate(ROUTES.login.default, {
        state: { from: { location: routerLocation } }
      });
  };

  const handleApply = () => {
    callAuthAction(() => {
      onApply(job, {
        showSchedule: () => openScheduleModal(),
        onSuccess: () => setIsRejected(false)
      });
    });
  };

  const handleInterviewConfirm = useCallback(
    ({ employeeProfileId, date, employerScheduleId }, options) => {
      const { onSuccess } = options || {};

      onSaveInterview(
        {
          date,
          employeeProfileId,
          employerScheduleId,
          jobId
        },
        {
          onSuccess: (result) => {
            const { bookedDate, countLimit } = result;
            onSuccess(result);

            // bookedDate or countLimit will be true only if date has been already taken
            if (!bookedDate && !countLimit) {
              // refetch job to be sure that scheduleStatusCount and isSchedule were updated
              fetchJobById();
              closeScheduleModal();
            }
          }
        }
      );
    },
    [closeScheduleModal, fetchJobById, onSaveInterview, jobId]
  );

  const handleReSchedule = () => {
    prepareInterviewReschedule(job, { onReschedule: () => openScheduleModal() });
  };

  const handleReject = () => {
    callAuthAction(() => {
      onReject(job, {
        onSuccess: () => {
          setIsRejected(true);

          if (isDesktop) {
            client.cache.modify({
              fields: {
                employeeJobs(refs, { readField }) {
                  return refs.filter((ref) => job.id !== readField('id', ref));
                }
              }
            });
          }
        }
      });
    });
  };

  const handleStar = () => {
    callAuthAction(() => {
      onStar(job, !isStarred);
    });
  };

  const handleRetract = () => {
    callAuthAction(() => {
      onRetract(job, { onSuccess: () => setIsRejected(false) });
    });
  };

  const renderReturnBtn = () => {
    const historyState =
      referrerPage === REFERRER.allJobs
        ? {
            jobId,
            refetchJobs: false,
            jobActions: {
              approved: isApproved || interviewAppointed,
              rejected: isRejected
            }
          }
        : {};
    return (
      <IconButton
        isRouterLink
        to={ROUTES.employee.dashboard}
        state={historyState}
        variant="outlined"
        color="primary"
        className="goBackBtn"
      >
        <MdArrowBack />
      </IconButton>
    );
  };

  let applyButtonTitle = isSchedule ? 'Schedule Interview' : 'Apply Now!';
  applyButtonTitle = isApproved ? 'Applied' : applyButtonTitle;

  const renderInterviewType = () => {
    if (!interviewType || interviewType === INTERVIEW_TYPES.inperson) return null;

    const label =
      interviewType === INTERVIEW_TYPES.phone
        ? 'Interview held over phone'
        : 'Interview held virtually';

    return (
      <Box mb="24px">
        <InterviewTypeIcon
          interviewType={interviewType}
          iconProps={{
            sx: {
              color: theme.palette.moreColors.purpleLight_1,
              marginRight: '17px',
              verticalAlign: 'middle'
            }
          }}
        />
        <Box component="span" color="#6A6A6A">
          {label}
        </Box>
      </Box>
    );
  };

  const renderInterviewLocation = () => {
    switch (interviewType) {
      case INTERVIEW_TYPES.inperson:
        return (
          <>
            <div className="label">Interview Street Address</div>
            <div className="text">{location}</div>
          </>
        );
      case INTERVIEW_TYPES.web:
        return (
          <>
            <div className="label">Interview Link</div>
            <a
              className="text inlineBlock"
              href={location}
              target="_blank"
              rel="noopener noreferrer"
            >
              {location}
            </a>
          </>
        );
      case INTERVIEW_TYPES.phone:
        return (
          <>
            <div className="label">Interview Phone</div>
            <a className="text inlineBlock" href={`tel:${location}`}>
              {location}
            </a>
          </>
        );
      default:
        return null;
    }
  };

  const renderActions = () => (
    <div className="actionsContainer">
      {interviewAppointed && interviewDate && (
        <div className="interviewDate">{formattedInterviewDate}</div>
      )}
      {canShowInterviewLocation && isDesktop && renderInterviewType()}
      <div className="actionsBtnsWrapper">
        {interviewAppointed ? (
          <>
            <Button
              variant="filled-primary"
              disabled={isJobApproving}
              endIcon={retractLoading ? <Spinner size={24} /> : null}
              className="applyBtn"
              onClick={handleRetract}
            >
              Cancel
            </Button>
            <Button
              variant="filled-primary"
              disabled={!isSchedule || isJobApproving}
              endIcon={applyLoading || interviewSaveLoading ? <Spinner size={24} /> : null}
              className="applyBtn secondaryActionWrapper"
              onClick={handleReSchedule}
            >
              Re-Schedule
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="filled-primary"
              disabled={isApplyDisabled}
              endIcon={applyLoading ? <Spinner size={24} /> : null}
              className={`applyBtn ${isApproved ? 'applied' : ''}`}
              onClick={handleApply}
            >
              {applyButtonTitle}
            </Button>
            <Button
              variant="outlined-secondary"
              disabled={isRejectDisabled}
              endIcon={rejectLoading || retractLoading ? <Spinner size={24} /> : null}
              className="cancelBtn"
              onClick={isApproved || interviewAppointed ? handleRetract : handleReject}
            >
              {isApproved ? 'Cancel' : 'Skip Job'}
            </Button>
            <div className="secondaryActionWrapper secondaryActionWrapper_labeled">
              <IconButton
                variant={isStarred ? 'filled-primary' : 'outlined'}
                color="primary"
                aria-label={isStarred ? 'Unstar' : 'Save for Later'}
                withTooltip
                toolTipProps={{ title: isStarred ? 'Unstar' : 'Save for Later' }}
                disabled={isJobApproving}
                className="secondaryAction"
                onClick={handleStar}
              >
                {starLoading ? <Spinner size={24} /> : <MdStarBorder fontSize="inherit" />}
              </IconButton>
              <div className="secondaryAction__label">
                {(() => {
                  if (isStarred) return 'Unstar';
                  if (isDesktop) return 'Save for Later';
                  return 'Save';
                })()}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const renderShareButton = () => (
    <ShareJob jobId={jobId} jobTitle={jobTitle}>
      {(share) => (
        <Button
          variant="filled-primary"
          endIcon={<MdIosShare />}
          className="shareButton"
          onClick={share}
        >
          Show a Friend
        </Button>
      )}
    </ShareJob>
  );

  const renderMapLink = () => (
    <a
      href={`https://maps.google.com/maps?q=${encodeURIComponent(
        `${companyName} ${location} ${zip}`
      )}`}
      target="_blank"
      rel="noopener noreferrer"
      className="mapLink"
    >
      <MdPlace fontSize="inherit" color="inherit" sx={{ mr: '9px' }} />
      Directions
    </a>
  );

  const renderDesktopLayout = () => (
    <>
      {isAuthenticated && <Box mb="20px">{renderReturnBtn()}</Box>}
      <div className="job__employer">{companyName}</div>
      <Box display="flex">
        <div>
          <img src={image} alt="" width={242} height={213} className="job__photo" />
          <div className="label">Pay Range</div>
          <div className="text">{payRange && payRange !== 'N/D' ? payRange : 'N/D'}</div>
          {canShowInterviewLocation && renderInterviewLocation()}
          <div className="label">Location</div>
          <div className="text">{jobAddress}</div>
          <div className="label">Hire Date</div>
          <div className="text">{formattedHireDate}</div>
        </div>
        <Box ml="24px">
          <h1 className="job__title">{jobTitle}</h1>
          <h2 className="label">Job Description</h2>
          <p className="text">{description}</p>
          <h3 className="label">Requirements</h3>
          <p className="text">{requirements}</p>
          {canShowInterviewMapLink && <Box mb="18px">{renderMapLink()}</Box>}
          {active && renderActions()}
          <div>{renderShareButton()}</div>
        </Box>
      </Box>
    </>
  );

  const renderMobileLayout = () => (
    <>
      <Box position="relative">
        {isAuthenticated && renderReturnBtn()}
        <img
          src={image}
          alt=""
          // width={260}
          // height={260}
          className="job__photo"
          style={calcImageSize(width)}
        />
      </Box>
      {canShowInterviewLocation && renderInterviewType()}
      {canShowInterviewMapLink && <Box mb="18px">{renderMapLink()}</Box>}
      <div className="job__employer">{companyName}</div>
      <div className="job__title">{jobTitle}</div>
      <div className="row">
        <div className="label">Pay Range</div>
        <div className={`$"text" $"rowColumnRight"`}>
          {payRange && payRange !== 'N/D' ? payRange : 'N/D'}
        </div>
      </div>
      {canShowInterviewLocation && <div className="row">{renderInterviewLocation()}</div>}
      <div className="row">
        <div className="label">Location</div>
        <div className="text">{jobAddress}</div>
      </div>
      <div className="row">
        <div className="label">Hire Date</div>
        <div className="text">{formattedHireDate}</div>
      </div>
      <div className="label">Job Description</div>
      <div className="text">{description}</div>
      <div className="label">Requirements</div>
      <div className="text">{requirements}</div>
      <div>{renderShareButton()}</div>
      {active && renderActions()}
    </>
  );

  return (
    <StyledRoot>
      {(jobLoading || publicJobLoading) && (
        <div className="loaderOverlay">
          <Spinner size={48} />
        </div>
      )}
      <div className="content">{isDesktop ? renderDesktopLayout() : renderMobileLayout()}</div>

      {scheduleModalConfig.isOpen && (
        <EmployeeInterviewScheduleModal
          {...scheduleModalConfig}
          interviewSaveLoading={interviewSaveLoading}
          onClose={closeScheduleModal}
          onConfirm={handleInterviewConfirm}
        />
      )}
    </StyledRoot>
  );
}

EmployeeJob.propTypes = {
  applyLoading: PT.bool,
  onApply: PT.func.isRequired,
  onReject: PT.func.isRequired,
  onRetract: PT.func.isRequired,
  onSaveInterview: PT.func.isRequired,
  onStar: PT.func.isRequired,
  interviewSaveLoading: PT.bool,
  prepareInterviewReschedule: PT.func.isRequired,
  rejectLoading: PT.bool,
  retractLoading: PT.bool,
  starLoading: PT.bool
};

EmployeeJob.defaultProps = {
  applyLoading: false,
  interviewSaveLoading: false,
  rejectLoading: false,
  retractLoading: false,
  starLoading: false
};

export default enhance(EmployeeJob);
