import React, { useState, useEffect, useCallback } from 'react';
import PT from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import map from 'lodash/map';
import { withApplicantProfileDialog, withApplicantActions } from 'hocs';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Spinner } from 'components/shared';
import { GET_STARRED } from 'api';
import styles from 'styles/ApplicantsDashboard';
import ApplicantsSearch from './ApplicantsSearch';
import ListRowItem from './ListRowItem';
import ListCardItem from './ListCardItem';
import { STARRED_TAB, GRID_LAYOUT } from './data';
import { filterActiveApplicants, getApplicantUserId } from './utils';

const StyledRoot = styled('div')(styles);

const enhance = (Component) => withApplicantActions(withApplicantProfileDialog(Component));

function StarredApplicants(props) {
  const {
    acceptApplicant,
    closeApplicantProfileDialog,
    jobId,
    layoutType,
    moveToNextSteps,
    openApplicantProfileDialog,
    rejectApplicant,
    starApplicant,
    webUrl
  } = props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();

  const [list, setList] = useState([]);

  const [getStarred, { data, loading = true }] = useLazyQuery(GET_STARRED, {
    variables: { jobId: parseInt(jobId) },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    getStarred();
  }, []);

  useEffect(() => {
    if (data?.starred) {
      setList(filterActiveApplicants(data.starred));
    }
  }, [JSON.stringify(data)]);

  const onSuggestionSelect = (suggestion) => {
    if (suggestion) {
      const { data: applicantData, type } = suggestion;
      setList([{ ...applicantData }]);
    }
  };

  const onSearchClear = () => {
    getStarred();
  };

  const handleMoveToNextSteps = useCallback(
    (applicant) => {
      closeApplicantProfileDialog();
      moveToNextSteps({
        applicantData: applicant,
        jobId,
        onSuccess: () => {
          getStarred();
        }
      });
    },
    [jobId, moveToNextSteps, closeApplicantProfileDialog, getStarred]
  );

  const handleAccept = useCallback(
    (applicant) => {
      closeApplicantProfileDialog();
      acceptApplicant({
        applicantData: applicant,
        jobId,
        jobWebUrl: webUrl,
        onSuccess: () => {
          getStarred();
        }
      });
    },
    [jobId, webUrl, acceptApplicant, closeApplicantProfileDialog, getStarred]
  );

  const handleReject = useCallback(
    (applicant) => {
      closeApplicantProfileDialog();
      rejectApplicant({
        applicantData: applicant,
        jobId,
        jobWebUrl: webUrl,
        onSuccess: () => {
          getStarred();
        }
      });
    },
    [jobId, webUrl, rejectApplicant, closeApplicantProfileDialog, getStarred]
  );

  const onRejectFromChatSuccess = useCallback(() => {
    getStarred();
  }, []);

  const handleStar = useCallback(
    (applicant, starred = true) => {
      starApplicant({
        applicantData: applicant,
        jobId,
        starred,
        onSuccess: () => {
          closeApplicantProfileDialog();
          getStarred();
        }
      });
    },
    [jobId, starApplicant, closeApplicantProfileDialog, getStarred]
  );

  const openApplicantProfile = useCallback(
    (applicant, extraParams = {}) => {
      const { dialogProps } = extraParams;
      const applicantUID = getApplicantUserId(applicant);
      const isApproved = applicant.approvedJob;
      const isRejected = applicant.passedJob;
      let approveStatus = '';
      if (isApproved) approveStatus = 'approved';
      if (isRejected) approveStatus = 'rejected';

      openApplicantProfileDialog({
        id: applicantUID,
        jobId,
        jobLink: webUrl,
        withActions: true,
        isStarred: true,
        approveStatus,
        onAccept: () => handleAccept(applicant),
        onReject: () => handleReject(applicant),
        onStar: () => handleStar(applicant, false),
        ...dialogProps
      });
    },
    [jobId, webUrl, openApplicantProfileDialog, handleAccept, handleReject, handleStar]
  );

  const applicantsProps = {
    jobId,
    webUrl,
    type: STARRED_TAB,
    onSelect: openApplicantProfile,
    onReject: handleReject,
    onAccept: handleAccept,
    onStar: handleStar,
    onMoveToNext: handleMoveToNextSteps,
    onRejectFromChatSuccess
  };

  return (
    <StyledRoot role="tabpanel" className="boardContainer">
      <div className="searchContainer">
        <ApplicantsSearch
          jobId={jobId}
          type={STARRED_TAB}
          onSelect={onSuggestionSelect}
          onClear={onSearchClear}
        />
      </div>
      <h2 className="boardTitle">Starred Applicants</h2>
      <Box position="relative">
        {loading && (
          <div className="boardLoader">
            <Spinner />
          </div>
        )}
        {isDesktop ? (
          <div
            className={clsx(
              'boardListContainer',
              layoutType === GRID_LAYOUT && 'boardListContainer_grid'
            )}
          >
            {map(list, (applicant, i) =>
              layoutType === GRID_LAYOUT ? (
                <ListCardItem key={`applicant__${i}`} data={applicant} {...applicantsProps} />
              ) : (
                <ListRowItem key={`applicant__${i}`} data={applicant} {...applicantsProps} />
              )
            )}
          </div>
        ) : (
          <div className="boardListContainer">
            {map(list, (applicant, i) => (
              <ListRowItem key={`applicant__${i}`} data={applicant} {...applicantsProps} />
            ))}
          </div>
        )}
      </Box>
    </StyledRoot>
  );
}

StarredApplicants.propTypes = {
  acceptApplicant: PT.func.isRequired,
  closeApplicantProfileDialog: PT.func.isRequired,
  jobId: PT.number.isRequired,
  layoutType: PT.string.isRequired,
  moveToNextSteps: PT.func.isRequired,
  openApplicantProfileDialog: PT.func.isRequired,
  rejectApplicant: PT.func.isRequired,
  starApplicant: PT.func.isRequired,
  webUrl: PT.string.isRequired
};

export default enhance(StarredApplicants);
