import React from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { styled } from 'components';
import { useMediaQueryMatches } from 'hooks';
import RootSelect from '../RootSelect/RootSelect';
import SelectModal from './SelectModal';

function Select({ withMobileModal, mobileModalProps, getKey, classes, ...props }) {
  const { isDesktopApp, isMobile } = useMediaQueryMatches();
  const canShowSelectModal = withMobileModal && isMobile;
  return (
    <RootSelect
      classes={{ button: clsx(classes?.button, canShowSelectModal && 'isModalView'), ...classes }}
      renderList={
        canShowSelectModal
          ? (params) => (
              <SelectModal
                isOpen={params.listboxVisible}
                options={props.options}
                onClose={() => params.setListboxVisible(false)}
                onConfirm={(...confirmParams) => {
                  props.onChange(...confirmParams);
                  params.setListboxVisible(false);
                }}
                getKey={getKey}
                {...mobileModalProps}
                {...params}
              />
            )
          : undefined
      }
      {...props}
    />
  );
}

Select.propTypes = {
  ...RootSelect.propTypes,
  variant: PT.oneOf(['default', 'outlined']),
  withMobileModal: PT.bool,
  mobileModalProps: PT.shape({
    title: PT.string
  })
};
Select.defaultProps = {
  variant: 'default',
  withMobileModal: true,
  mobileModalProps: {
    title: 'Select option'
  }
};

const StyledSelect = styled(Select)(({ theme, variant, withMobileModal }) => ({
  '& .RootSelect-root': {
    position: 'relative',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: '17px',
    textAlign: 'left',
    color: theme.palette.moreColors.black,
    borderBottom: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    ...(variant === 'outlined' && {
      border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
      borderRadius: 8,
      '&.expanded': {
        borderRadius: '8px 8px 0 0',
        '&.isModalView': {
          borderRadius: 8
        }
      }
    }),
    '&.error': {
      borderColor: theme.palette.error.main
    },
    '&.disabled': {
      color: theme.palette.text.disabled,
      border: '1px solid rgba(255,255,255,0.2)'
    }
  },
  '& .RootSelect-button': {
    width: '100%',
    minHeight: 47,
    padding: '12px 24px 12px 0',
    border: 'none',
    textAlign: 'inherit',
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    ...(variant === 'outlined' && {
      padding: '12px 24px 12px 12px'
    })
  },
  '& .RootSelect-label': {
    marginBottom: 4,
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '17px',
    color: theme.palette.primary.text,
    ...(variant === 'outlined' && {
      marginBottom: 12
    }),
    '&.error': {
      color: theme.palette.error.main
    }
  },
  '& .placeholder': {
    color: theme.palette.moreColors.grey_4
  },
  '& .RootSelect-list': {
    maxHeight: 4 * 47 + 29,
    position: 'absolute',
    top: '100%',
    left: -1,
    right: -1,
    zIndex: 1,
    overflow: 'auto',
    border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    borderRadius: '0 0 8px 8px',
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '150%',
    backgroundColor: '#13122D', // @TODO: get from theme
    '&.hidden': {
      maxHeight: 1,
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 0.2s, visibility 0.2s'
    }
  },
  '& .RootSelect-option': {
    padding: '10px 12px',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.moreColors.purpleLight_2}`
    },
    '&.selected, &.highlighted, &:hover, &:focus': {
      '&:not(.disabled)': {
        backgroundColor: theme.palette.moreColors.lightPurple
      }
    },
    '&.disabled': {
      cursor: 'initial'
    }
  },
  '& .RootSelect-helperText': {
    marginTop: 6,
    display: 'inline-block',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '17px',
    '&.error': {
      color: theme.palette.error.main
    }
  },
  '& .RootSelect-icon': {
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 0.5em)',
    userSelect: 'none',
    pointerEvents: 'none',
    color: theme.palette.primary.main,
    '&.disabled': {
      color: theme.palette.text.disabled
    }
  }
}));

export default StyledSelect;
