import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import PT from 'prop-types';
import { truncate, map } from 'lodash';
import clsx from 'clsx';
import { qaAttr, formatJobAddress, getProfileId, getRoutes, getLocaleTimeZone } from 'utils';
import {
  useCountryStateQuery,
  useTrackJobView,
  useEmployeeProfileQuery,
  useMediaQueryMatches
} from 'hooks';
import { Box, styled } from 'components';
import { JobInterviewSchedule, useJobSchedule } from 'components/Interview';
import { Button, IconButton, SimpleRating } from 'components/shared';
import {
  CalendarIcon,
  MdArrowBack,
  MdClose,
  MdCheck,
  MdIosShare,
  MdStar,
  MdStarBorder
} from 'components/icons';
import { jobDefaultImg as defaultImg } from 'assets';
import styles from 'styles/Dashboard/EmployeeJobCard';
import ShareJob from '../ShareJob';
import InterviewTypeIcon from '../InterviewTypeIcon';

const StyledRoot = styled('div')(styles);

function EmployeeJobCard(props) {
  const {
    interviewSaveLoading,
    isLink,
    job,
    onApply,
    onReject,
    onRetract,
    onSaveInterview,
    onScheduleCall,
    onSelect,
    onStar,
    qhAttrs: qaAttrs,
    routerLinkProps,
    withSchedule
  } = props;
  // const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const { trackJobView } = useTrackJobView();
  const { profile } = useEmployeeProfileQuery();
  const { timeZone = getLocaleTimeZone() } = profile || {};

  const linkProps = isLink
    ? {
        isRouterLink: true,
        to: getRoutes({ id: job.id }).employee.job,
        ...routerLinkProps
      }
    : {};

  const {
    approved,
    bestFit,
    city,
    countryId,
    description = '',
    distance = 0,
    employerProfile,
    employerProfileProfileId = null,
    id = '',
    imageUrl,
    interviewType,
    isSchedule,
    location = '',
    passed,
    payRange = '',
    rate = 0,
    starred,
    stateId,
    title = '',
    webUrl = '',
    zip
  } = job;
  const { name: employerName, imageUrl: employerImageUrl } = employerProfile || {};
  const isApplied = approved;
  const isRejected = passed;
  const isStarred = starred;
  const canSchedule = withSchedule && isSchedule && !isApplied;

  const { fetchCountryState, getCountryById, getStateById } = useCountryStateQuery();
  const selectedState = useMemo(() => getStateById(stateId), [getStateById, stateId]);
  const selectedCountry = useMemo(() => getCountryById(countryId), [getCountryById, countryId]);

  const [isScheduleOpen, setIsScheduleOpen] = useState(false);

  const {
    employerTimeZone,
    getJobSchedule,
    getJobInterviews,
    inactiveDates,
    interviewDate,
    schedules,
    setInterviewDate,
    setInactiveDates
  } = useJobSchedule();

  useEffect(() => {
    fetchCountryState();
  }, []);

  useEffect(() => {
    if (isScheduleOpen) {
      const profileId = getProfileId();
      getJobSchedule(employerProfileProfileId);
      getJobInterviews(id, profileId, timeZone);
    }
  }, [employerProfileProfileId, id, isScheduleOpen, timeZone]);

  const jobAddress = formatJobAddress({
    city,
    zip,
    country: selectedCountry.code,
    state: selectedState.code
  });

  const handleJobSelect = () => {
    // check local storage for infos
    if (localStorage.getItem('profileId')) {
      trackJobView(job.id);
    }
    onSelect(job);
  };
  const handleJobApply = () => onApply(job);
  const handleJobReject = () => onReject(job);
  const handleStar = () => onStar(job, !isStarred);
  const handleRetract = () => onRetract(job);
  const handleOpenSchedule = () => {
    onScheduleCall(job, { showSchedule: () => setIsScheduleOpen(true) });
  };

  const handleInterviewDateSelect = useCallback(
    (date, scheduleId) => {
      setInterviewDate(date);
    },
    [setInterviewDate]
  );

  const confirmNewInterviewDate = useCallback(
    async (date, employerScheduleId, callbacks) => {
      const profileId = Number(getProfileId());
      const data = {
        employeeProfileId: profileId,
        jobId: id,
        date,
        employerScheduleId
      };

      const result = await onSaveInterview(data, {
        onSuccess: ({ bookedDate = false, countLimit = false }) => {
          setInterviewDate(null);

          if (bookedDate || countLimit) {
            // in that case date has been already taken
            // make that date disabled
            setInactiveDates((prev) => [...prev, date]);
            callbacks?.onInterviewDeny(); // show "Choose another date"
          } else {
            setIsScheduleOpen(false);
            setInterviewDate(date);
          }
        },
        onError: () => {}
      });
    },
    [onSaveInterview, id, setInactiveDates, setInterviewDate]
  );

  const closeSchedule = useCallback(() => {
    setIsScheduleOpen(false);
  }, []);

  const renderStatusLabel = () => {
    if (isApplied) {
      return (
        <div className="status applied">
          <MdCheck color="inherit" fontSize="inherit" className="status__icon" />
          Applied
        </div>
      );
    }
    // if (isRejected) {
    //   return (
    //     <div className="status rejected">
    //       <MdClose color="inherit" fontSize="inherit" className="status__icon" />
    //       Rejected
    //     </div>
    //   );
    // }
    return null;
  };

  const renderJobInfo = () => (
    <>
      <Button
        className="card__btn"
        onClick={handleJobSelect}
        {...qaAttr(`job-button-${title}`)}
        {...qaAttrs}
        {...linkProps}
      >
        <div
          className="card__photoArea"
          style={{ backgroundImage: `url("${imageUrl || defaultImg}")` }}
        >
          {renderStatusLabel()}
          {!!rate && (
            <div className="card__ratingContainer">
              <span>{`${rate} with tips`}</span>
              <span className="card__ratingDelimiter">&nbsp;|&nbsp;</span>
              <SimpleRating rating={rate} />
            </div>
          )}
        </div>
        <Box className="card__mainContent">
          <Box width="100%" flex={1}>
            <h2 className="card__name">
              {title.length > 50 ? truncate(title, { length: 45 }) : title}
            </h2>
            <div className="card__employerContacts">
              {isSchedule && (
                <InterviewTypeIcon
                  interviewType={interviewType}
                  iconProps={{
                    color: 'primary',
                    sx: { marginRight: '3px', fontSize: 14, verticalAlign: 'text-bottom' }
                  }}
                />
              )}
              {`@${employerProfile?.name}${jobAddress ? ' | ' : ''}`}
              <span className="card__employerAddress">{jobAddress}</span>
            </div>
            <Box mb="18px" className="card__jobDescription">
              {truncate(description, { length: 125, separator: /,? +/ })}
            </Box>
          </Box>
          {!canSchedule ? (
            <div className="card__company">
              <div
                className="card__companyLogo"
                style={{ backgroundImage: `url("${employerImageUrl}")` }}
              />
              <Box ml="16px" className="card__companyName">
                {employerName}
              </Box>
            </div>
          ) : (
            <div className="footer__placeholder" />
          )}
        </Box>
      </Button>
      <div
        className={`card__actions ${
          canSchedule ? 'card__actions_withSchedule' : 'card__actions_default'
        }`}
      >
        {canSchedule && <div className="schedule__title">Employer has open interview spots!</div>}
        <div className="actions__container">
          <div className="actions__innerContainer">
            {isApplied ? (
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="Cancel"
                withTooltip
                toolTipProps={{ title: 'Cancel', placement: 'bottom' }}
                className="card__action"
                sx={{ padding: '7px' }}
                onClick={handleRetract}
                testID="job-retract-button"
              >
                <MdArrowBack />
              </IconButton>
            ) : (
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="Skip Job"
                withTooltip
                toolTipProps={{ title: 'Skip Job', placement: 'bottom' }}
                disabled={isRejected}
                className="card__action"
                sx={{ padding: '7px' }}
                onClick={handleJobReject}
                testID="job-reject-button"
              >
                <MdClose />
              </IconButton>
            )}
            {!canSchedule && (
              <IconButton
                variant="outlined"
                color="primary"
                aria-label="Apply Now"
                withTooltip
                toolTipProps={{ title: 'Apply Now!', placement: 'bottom' }}
                disabled={isApplied}
                className="card__action"
                sx={{ padding: '7px', marginLeft: '16px' }}
                onClick={handleJobApply}
                testID="job-accept-button"
              >
                <MdCheck />
              </IconButton>
            )}
            <IconButton
              variant="outlined"
              color="primary"
              aria-label="Save for Later"
              withTooltip
              toolTipProps={{ title: 'Save for Later!', placement: 'bottom' }}
              className="card__action"
              sx={{ padding: '7px', marginLeft: canSchedule ? 0 : '16px' }}
              onClick={handleStar}
              testID="job-star-button"
            >
              <MdStarBorder />
            </IconButton>
            {canSchedule && (
              <div className="card__scheduleBtnContainer">
                <IconButton
                  variant="outlined"
                  color="primary"
                  aria-label="interview"
                  className="card__scheduleBtn"
                  onClick={handleOpenSchedule}
                  testID="job-interview-button"
                >
                  <span className="scheduleBtnTitle">Schedule your interview</span>
                  <span className="scheduleBtnIconWrapper">
                    <CalendarIcon color="inherit" style={{ fontSize: 20 }} />
                  </span>
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const renderSchedule = () => (
    <JobInterviewSchedule
      confirmLoading={interviewSaveLoading}
      employerProfileId={employerProfileProfileId}
      employerTimeZone={employerTimeZone}
      inactiveDates={inactiveDates}
      interviewDate={interviewDate}
      onClose={closeSchedule}
      onConfirm={confirmNewInterviewDate}
      onDateSelect={handleInterviewDateSelect}
      schedules={schedules}
      testID={title}
      userTimeZone={timeZone}
      withCancel
      withConfirmation
    />
  );

  return (
    <StyledRoot
      className={clsx('cardRoot', isApplied && 'card__applied')}
      data-card={id}
      {...qaAttr(`job-card-${title}`)}
    >
      <div className="card__content">
        {bestFit && (
          <div className="bestFitLabel">
            <span>MATCH</span>
          </div>
        )}
        {!isScheduleOpen && (
          <ShareJob jobId={id} jobTitle={title}>
            {(share) => (
              <IconButton color="primary" className="shareButton" onClick={share}>
                <MdIosShare fontSize="inherit" />
              </IconButton>
            )}
          </ShareJob>
        )}
        {isScheduleOpen ? renderSchedule() : renderJobInfo()}
      </div>
    </StyledRoot>
  );
}

EmployeeJobCard.propTypes = {
  isLink: PT.bool,
  interviewSaveLoading: PT.bool,
  job: PT.objectOf(PT.any).isRequired,
  onApply: PT.func,
  onInterviewConfirm: PT.func,
  onReject: PT.func,
  onRetract: PT.func,
  onSaveInterview: PT.func,
  onScheduleCall: PT.func,
  onSelect: PT.func,
  onStar: PT.func,
  qhAttrs: PT.objectOf(PT.any),
  routerLinkProps: PT.objectOf(PT.any),
  withSchedule: PT.bool
};

EmployeeJobCard.defaultProps = {
  isLink: true,
  interviewSaveLoading: false,
  onApply: () => {},
  onInterviewConfirm: () => {},
  onReject: () => {},
  onRetract: () => {},
  onSaveInterview: () => {},
  onScheduleCall: (job, { showSchedule }) => {},
  onSelect: () => {},
  onStar: () => {},
  qhAttrs: {},
  routerLinkProps: {},
  withSchedule: false
};

export const PureJobCard = EmployeeJobCard;
export default memo(EmployeeJobCard);
