import React from 'react';
import PropTypes from 'prop-types';
import { useScrollToTop } from 'hooks';

function ScrollToTop(props) {
  useScrollToTop();
  return null;
}

ScrollToTop.propTypes = {};

export default ScrollToTop;
