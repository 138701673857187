import React from 'react';
import { SvgIcon } from 'components';

function GrowRatingsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 25 27.5" {...props}>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#EFB954"
          d="M22.9,6.7c0,5.3-2.7,10.4-7.6,13.2c-4.9,2.8-10.7,2.6-15.3,0
		c4.2,7.3,13.5,9.8,20.8,5.6c1.1-0.6,2-1.3,2.9-2.2C24.6,21,25,18.5,25,16C25,12.6,24.2,9.5,22.9,6.7z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#2D2B6F"
          d="M12.5,1.1c0.5,2.5,0.4,5.2-0.5,7.9C10.3,14.3,5.8,18,0.7,19.1
		c5,1.6,10.7,0.7,14.9-3.1c1.7-1.5,3-3.4,3.9-5.4C18.1,6.8,15.7,3.5,12.5,1.1z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#F7EEEF"
          d="M5.4,0c0.5,1.2,0.9,2.5,1.1,3.8C7.3,9.4,4.9,14.7,0.8,18
		c4.8-2.1,8.5-6.6,9.2-12.2C10,5.4,10,5,10.1,4.6C8.8,2.9,7.2,1.3,5.4,0z"
        />
      </g>
    </SvgIcon>
  );
}

export default GrowRatingsIcon;
