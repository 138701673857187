import React from 'react';
import PT from 'prop-types';
import { Box, styled } from 'components';
import { MdTimeline } from 'components/icons';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.trendCard': {
    width: '100%',
    padding: 15,
    borderRadius: 5,
    color: theme.palette.common.white,
    backgroundColor: '#332B3F',
    [theme.breakpoints.down('desktopApp')]: {
      padding: 12,
      borderRadius: 8,
      backgroundColor: theme.palette.moreColors.brand
    }
  },
  '& .trendCard__title': {
    marginBottom: 15,
    fontSize: 16,
    color: 'inherit',
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .trendCard__rate': {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 24,
    color: 'inherit',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 16,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '130%'
    }
  },
  '& .trendCard__icon': {
    marginRight: 6,
    color: '#2CA66A' // @TODO: get from theme
  },
  '& .trendCard__jobsCount': {
    // fontSize: 10,
    fontWeight: 800,
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 16,
      fontWeight: 800,
      lineHeight: '150%',
      color: theme.palette.moreColors.lightPurple
    }
  }
}));

export default function TrendCard(props) {
  const { newJobsCount, rate, title } = props;
  return (
    <StyledRoot className="trendCard">
      <div className="trendCard__title">{title}</div>
      <div className="trendCard__rate">
        <MdTimeline className="trendCard__icon" />
        {`+${rate}%`}
      </div>
      <div className="trendCard__jobsCount">{`${newJobsCount} new Jobs`}</div>
    </StyledRoot>
  );
}

TrendCard.propTypes = {
  newJobsCount: PT.number,
  rate: PT.number,
  title: PT.string.isRequired
};

TrendCard.defaultProps = {
  newJobsCount: 0,
  rate: 0
};
