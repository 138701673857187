import React from 'react';
import { styled } from 'components';
import { qaAttr } from 'utils';
import { BrandLogo } from 'assets';

const { LANDING_ORIGIN } = process.env;

const StyledLink = styled('a')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: -0.08,
  lineHeight: '20px',
  textAlign: 'center',
  color: theme.palette.primary.main
}));

export default function LogoLink(props) {
  return (
    <StyledLink
      href={LANDING_ORIGIN}
      className="logoLink"
      {...qaAttr('landing-home-link')}
      {...props}
    >
      <BrandLogo />
    </StyledLink>
  );
}
