import React, { useState } from 'react';
import PT from 'prop-types';
import clsx from 'clsx';
import { useMediaQueryMatches } from 'hooks';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import { GrowConnectionsIcon } from 'components/icons';
import ConnectionCard from './ConnectionCard';
import PageHeader from '../PageHeader';

const StyledRoot = styled('div')(({ theme }) => ({
  '&.connectionsPage': {
    [theme.breakpoints.down('desktopApp')]: { marginTop: 30 }
  },
  '& .connectionsPage__connections': {
    marginBottom: 15,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 18,
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 16px',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: 8
    }
  },
  '& .connectionsPage__header': {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 16px',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  '& .connectionsPage__title': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 16,
      fontSize: 25,
      fontWeight: 700,
      lineHeight: '150%'
    }
  },
  '& .connectionsPage__subTitle': {
    fontSize: 9,
    color: theme.palette.moreColors.purpleLight_2,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 18,
      fontWeight: 800,
      lineHeight: '150%',
      color: theme.palette.moreColors.lightPurple
    }
  },
  '& .connectionsPage__seeAll': {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '150%',
    color: theme.palette.moreColors.lightPurple
  },
  '& .connectionsPage__tabs': {
    [theme.breakpoints.down('desktopApp')]: {
      marginBottom: 32
    }
  },
  '& .connectionsPage__action': {
    padding: '10px 25px',
    border: `1px solid ${theme.palette.moreColors.purpleLight_2}`,
    borderRadius: 50,
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    '&.connectionsPage__tab': {
      [theme.breakpoints.down('desktopApp')]: {
        padding: 0,
        border: 'none',
        borderRadius: 0,
        fontSize: 16,
        fontWeight: 800,
        lineHeight: '150%',
        color: theme.palette.common.white
      },
      '&:not(:first-of-type)': {
        marginLeft: 10,
        [theme.breakpoints.down('desktopApp')]: {
          marginLeft: 16
        }
      },
      '&.connectionsPage__tab_active': {
        [theme.breakpoints.down('desktopApp')]: {
          borderBottom: `2px solid ${theme.palette.moreColors.gold}`,
          color: theme.palette.moreColors.gold
        }
      }
    }
  }
}));

function ConnectionsPage(props) {
  const { onAdd, onRemove, onProfileShow, sameConnections, futureConnections, starredConnections } =
    props;
  const { isDesktopApp: isDesktop } = useMediaQueryMatches();
  const starredUserIds = starredConnections.map((o) => o.user_id).filter(Boolean);

  const [tab, setTab] = useState(0);

  return (
    <StyledRoot className="connectionsPage">
      {isDesktop && (
        <PageHeader
          title="CONNECTIONS"
          subtitle="Search for People and Companies"
          icon={<GrowConnectionsIcon fill="#EFB954" />}
        />
      )}
      {/* <div className="connectionsPage__header">
        <div className="connectionsPage__tabs">
          <Button
            disableRipple
            className={clsx(
              'connectionsPage__action connectionsPage__tab',
              tab === 0 && 'connectionsPage__tab_active'
            )}
            onClick={() => setTab(0)}
          >
            People
          </Button>
          <Button
            disableRipple
            className={clsx(
              'connectionsPage__action connectionsPage__tab',
              tab === 1 && 'connectionsPage__tab_active'
            )}
            onClick={() => setTab(1)}
          >
            Companies
          </Button>
        </div>
        <div className="connectionsPage__title">
          <h3>Your connections</h3>
          <Button variant="text" className="connectionsPage__seeAll">
            See all
          </Button>
        </div>
        <div className="connectionsPage__subTitle">Manage Connections</div>
      </div> */}
      <div className="connectionsPage__connections">
        {starredConnections.map((obj, i) => {
          const { profile_id, companyName, companyPosition, name, imageUrl, starred } = obj;
          return (
            <ConnectionCard
              key={`connection__${profile_id}`}
              companyName={companyName}
              companyPosition={companyPosition}
              name={name}
              imageUrl={imageUrl}
              level={0}
              levelProgress={0}
              onProfileShow={() => onProfileShow(starredUserIds, i)}
              onAdd={() => onAdd(obj)}
              onRemove={() => onRemove(obj)}
              starred={starred}
              type="person"
            />
          );
        })}
      </div>
      {/* <Box textAlign="center">
          <Button className="connectionsPage__action">Load More</Button>
        </Box> */}
    </StyledRoot>
  );
}

const ConnectionType = PT.shape({
  user_id: PT.number,
  profile_id: PT.number,
  name: PT.string,
  imageUrl: PT.string,
  experience: PT.arrayOf(PT.any),
  companyName: PT.string,
  companyPosition: PT.string,
  starred: PT.bool
});

ConnectionsPage.propTypes = {
  sameConnections: PT.arrayOf(ConnectionType).isRequired,
  futureConnections: PT.arrayOf(ConnectionType).isRequired,
  starredConnections: PT.arrayOf(ConnectionType).isRequired,
  onAdd: PT.func.isRequired,
  onRemove: PT.func.isRequired,
  onProfileShow: PT.func.isRequired
};

export default ConnectionsPage;
