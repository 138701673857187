import React from 'react';
import { SvgIcon } from 'components';

function GrowBadgeIcon13(props) {
  return (
    <SvgIcon viewBox="0 0 29.87 32.97" {...props}>
      <path
        d="M12.57,17.06c-.31-.46-.58-.94-.82-1.43-.04-.09-.09-.19-.13-.28-.22-.5-.41-1-.55-1.52-.02-.08-.04-.17-.06-.26-.14-.54-.24-1.09-.29-1.64,0-.02,0-.04,0-.07-.05-.55-.05-1.1-.01-1.66,0-.07,0-.14,0-.2,.04-.54,.14-1.08,.26-1.61,.02-.09,.04-.18,.07-.27,.14-.52,.31-1.04,.53-1.54,.03-.08,.07-.15,.11-.23,.24-.52,.51-1.02,.83-1.5,0,0,0-.01,.01-.02C5.51,5.51,0,11.45,0,18.63c0,2.76,.82,5.33,2.22,7.49l10.45-8.92s-.07-.1-.1-.15Z"
        fill="currentColor"
      />
      <path
        d="M26.16,20.82c-.08,.04-.16,.08-.24,.11-.5,.22-1.01,.39-1.53,.53-.09,.02-.19,.05-.28,.07-.53,.12-1.06,.22-1.6,.26-.07,0-.14,0-.21,0-.55,.04-1.1,.04-1.65-.01-.02,0-.04,0-.07,0-.55-.05-1.1-.16-1.64-.29-.08-.02-.17-.04-.25-.06-.52-.14-1.02-.33-1.52-.55-.09-.04-.19-.08-.28-.13-.22-.11-.44-.22-.65-.34-2.25,3.65-3.75,7.6-3.87,12,.5,.05,1,.09,1.51,.09,7.18,0,13.12-5.51,13.8-12.52,0,0-.02,0-.02,.01-.48,.32-.98,.59-1.49,.82Z"
        fill="currentColor"
      />
      <path
        d="M13.87,4.76c.61,0,1.21,.05,1.81,.13,2.84,.37,5.4,1.6,7.43,3.41l6.1-5.21c-4.27-4.16-11.11-4.14-15.34,.09-.51,.51-.95,1.06-1.34,1.63,.32-.03,.64-.04,.97-.05,.13,0,.25-.02,.37-.02Z"
        fill="currentColor"
      />
      <path
        d="M24.72,10.01c1.58,1.98,2.62,4.4,2.92,7.05,.06,.52,.1,1.04,.1,1.58,0,.13-.02,.25-.02,.38,0,.32-.02,.65-.05,.97,.58-.39,1.13-.83,1.63-1.34,3.91-3.92,4.22-10.06,.95-14.34-1.78,1.88-3.67,3.77-5.54,5.71Z"
        fill="currentColor"
      />
      <path
        d="M11.04,8.13c.14-.52,.31-1.04,.53-1.54-.22,.5-.39,1.02-.53,1.54Z"
        fill="currentColor"
      />
      <path d="M11.68,6.35c.24-.52,.51-1.02,.83-1.5-.32,.48-.59,.99-.83,1.5Z" fill="currentColor" />
      <path
        d="M16.22,20.41c.21,.12,.43,.23,.65,.34-.22-.1-.42-.24-.64-.37l-.02,.03Z"
        fill="currentColor"
      />
      <path
        d="M27.72,19.01c0-.13,.02-.25,.02-.38,0-.53-.04-1.06-.1-1.58,.06,.52,.1,1.04,.1,1.58,0,.13-.01,.25-.02,.38Z"
        fill="currentColor"
      />
      <path
        d="M15.67,4.89c-.59-.08-1.19-.13-1.81-.13-.13,0-.25,.02-.37,.02,.13,0,.25-.02,.37-.02,.61,0,1.21,.05,1.81,.13Z"
        fill="currentColor"
      />
      <path
        d="M10.71,10.01c.04-.54,.14-1.08,.26-1.61-.13,.53-.22,1.07-.26,1.61Z"
        fill="currentColor"
      />
      <path
        d="M20.57,21.78c-.55-.05-1.1-.16-1.64-.29,.54,.14,1.09,.24,1.64,.29Z"
        fill="currentColor"
      />
      <path
        d="M25.91,20.93c-.5,.22-1.01,.39-1.53,.53,.52-.14,1.03-.31,1.53-.53Z"
        fill="currentColor"
      />
      <path
        d="M10.72,11.86c-.05-.55-.05-1.1-.01-1.66-.04,.55-.04,1.1,.01,1.66Z"
        fill="currentColor"
      />
      <path
        d="M27.65,19.99c-.48,.32-.98,.59-1.49,.82,.51-.24,1.01-.5,1.49-.82Z"
        fill="currentColor"
      />
      <path
        d="M22.29,21.8c-.55,.04-1.1,.04-1.65-.01,.55,.05,1.1,.05,1.65,.01Z"
        fill="currentColor"
      />
      <path
        d="M24.1,21.53c-.53,.12-1.06,.22-1.6,.26,.54-.04,1.07-.14,1.6-.26Z"
        fill="currentColor"
      />
      <path
        d="M11.01,13.57c-.14-.54-.24-1.09-.29-1.64,.05,.55,.16,1.1,.29,1.64Z"
        fill="currentColor"
      />
      <path
        d="M11.62,15.35c-.22-.5-.41-1-.55-1.52,.14,.52,.33,1.02,.55,1.52Z"
        fill="currentColor"
      />
      <path
        d="M12.57,17.06c-.31-.46-.58-.94-.82-1.43,.24,.49,.51,.97,.82,1.43Z"
        fill="currentColor"
      />
      <path
        d="M18.67,21.43c-.52-.14-1.02-.33-1.52-.55,.5,.22,1,.41,1.52,.55Z"
        fill="currentColor"
      />
      <polygon
        points="12.53 4.83 12.52 4.83 12.52 4.83 12.53 4.83 12.53 4.83"
        fill="currentColor"
      />
      <polygon
        points="27.67 19.98 27.67 19.98 27.67 19.97 27.67 19.98 27.67 19.98"
        fill="currentColor"
      />
      <path d="M10.98,8.4c.02-.09,.04-.18,.07-.27-.02,.09-.04,.18-.07,.27Z" fill="currentColor" />
      <path
        d="M11.75,15.63c-.04-.09-.09-.19-.13-.28,.04,.09,.09,.19,.13,.28Z"
        fill="currentColor"
      />
      <path
        d="M18.93,21.49c-.08-.02-.17-.04-.25-.06,.08,.02,.17,.04,.25,.06Z"
        fill="currentColor"
      />
      <path d="M11.57,6.58c.03-.08,.07-.15,.11-.23-.04,.08-.07,.15-.11,.23Z" fill="currentColor" />
      <path d="M12.57,17.06s.07,.1,.1,.15h0c-.03-.05-.07-.1-.11-.15Z" fill="currentColor" />
      <path d="M10.7,10.21c0-.07,0-.14,0-.2,0,.07,0,.14,0,.2Z" fill="currentColor" />
      <path
        d="M11.07,13.83c-.02-.08-.04-.17-.06-.26,.02,.09,.04,.17,.06,.26Z"
        fill="currentColor"
      />
      <path
        d="M17.15,20.88c-.09-.04-.19-.08-.28-.13,.09,.04,.18,.09,.28,.13Z"
        fill="currentColor"
      />
      <path d="M10.72,11.93s0-.04,0-.07c0,.02,0,.04,0,.07Z" fill="currentColor" />
      <path d="M27.65,19.99s.02,0,.02-.01h-.02Z" fill="currentColor" />
      <path d="M22.5,21.79c-.07,0-.14,0-.21,0,.07,0,.14,0,.21,0Z" fill="currentColor" />
      <path
        d="M24.38,21.46c-.09,.02-.19,.05-.28,.07,.09-.02,.19-.04,.28-.07Z"
        fill="currentColor"
      />
      <path d="M12.53,4.83h0s0,.01-.01,.02l.02-.02Z" fill="currentColor" />
      <path
        d="M26.16,20.82c-.08,.04-.16,.08-.24,.11,.08-.04,.16-.08,.24-.11Z"
        fill="currentColor"
      />
      <path d="M20.64,21.78s-.04,0-.07,0c.02,0,.04,0,.07,0Z" fill="currentColor" />
      <path d="M13.49,4.78c-.32,0-.65,.02-.97,.05h0c.32-.03,.64-.04,.97-.05Z" fill="currentColor" />
      <path d="M27.67,19.98h0c.03-.32,.04-.64,.05-.97,0,.32-.02,.65-.05,.97Z" fill="currentColor" />
      <path
        d="M27.64,17.06c-.3-2.65-1.35-5.07-2.92-7.05h0c1.58,1.99,2.63,4.41,2.93,7.05Z"
        fill="currentColor"
      />
      <path
        d="M23.1,8.31c-2.03-1.82-4.59-3.04-7.43-3.41,2.83,.37,5.39,1.6,7.42,3.42h0Z"
        fill="currentColor"
      />
      <path d="M12.67,17.21s.03,.04,.05,.05c-.01-.02-.03-.04-.04-.06h0Z" fill="currentColor" />
    </SvgIcon>
  );
}

export default GrowBadgeIcon13;
