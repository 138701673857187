export default ({ theme }) => ({
  '&.pageContainer': {
    position: 'relative',
    maxWidth: 1024,
    width: '100%',
    margin: '0 auto',
    display: 'flex'
  },
  '& .commonStepper': {
    marginBottom: 13,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    color: theme.palette.primary.textLight
    // [theme.breakpoints.down('desktopApp')]: {
    //   lineHeight: '10px',
    //   fontSize: 9
    // }
  },
  '& .commonStepper__title': {
    marginBottom: 13,
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('desktopApp')]: {
      lineHeight: '12px',
      fontSize: 13
    }
  },
  '& .commonStepper__subTitle': {
    maxWidth: 360,
    margin: '0 auto 30px',
    textAlign: 'center',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 290,
      marginBottom: 52
    }
  },
  '& .header': {
    padding: '20px 30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('desktopApp')]: {
      padding: '4px 16px'
    }
  },
  '& .contentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  '& .defaultStepContent': {
    paddingTop: 75,
    [theme.breakpoints.down('desktopApp')]: {
      padding: '0 16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: 1
    }
  },
  '& .stepContent': {
    paddingTop: 14,
    padding: '14px 0 25px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.down('desktopApp')]: {
      padding: '40px 23px 16px',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }
  },
  '& .footerContainer': {
    position: 'absolute',
    bottom: 0,
    height: 74,
    width: '100%',
    [theme.breakpoints.down('desktopApp')]: {
      height: 56,
      display: 'none'
    }
  },
  '& .companyName': {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: -0.08,
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    display: 'block',
    height: 40,
    width: 140,
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .stepper': {
    padding: 0,
    display: 'flex',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .progressBar': {
    width: '100%',
    height: 3,
    borderRadius: '0 100px 100px 0',
    backgroundColor: 'transparent'
  },
  '& .desktopPrevBtn': {
    padding: '20px 30px 0',
    display: 'block',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'none'
    }
  },
  '& .mobilePrevBtn': {
    display: 'none',
    [theme.breakpoints.down('desktopApp')]: {
      display: 'flex'
    }
  },
  '& .arrowBtn': {
    fontSize: 26
  },
  '& .pageTitle': {
    fontSize: 26,
    fontWeight: 'bold',
    lineHeight: 1,
    letterSpacing: 0.96,
    textAlign: 'center',
    color: theme.palette.primary.text,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 31,
      lineHeight: '130%'
    }
  },
  '& .pageSubTitle': {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '26px',
    letterSpacing: 0.5,
    textAlign: 'center',
    color: theme.palette.primary.text,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 14,
      lineHeight: '16px'
    }
  },
  '& .surveyTitle': {
    marginBottom: 40,
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: 1,
    letterSpacing: 0.96,
    textAlign: 'center',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 300,
      fontSize: 19,
      lineHeight: '21px'
    }
  },
  '& .descTextBlock' : {
    width: '100%',
    [theme.breakpoints.down('desktopApp')]: {
      maxWidth: 284
    }
  },
  '& .pageContent': {
    width: '100%',
    maxWidth: 320,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  '& .primaryText': {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('desktopApp')]: {
      lineHeight: '18px',
      fontSize: 14
    }
  },
  '& .secondaryText': {
    fontWeight: 'bold',
    color: theme.palette.primary.textLight,
    [theme.breakpoints.down('desktopApp')]: {
      lineHeight: '18px',
      fontSize: 14
    }
  },
  '& .text': {
    fontWeight: 'bold',
    color: theme.palette.primary.text,
    [theme.breakpoints.down('desktopApp')]: {
      lineHeight: '12px',
      fontSize: 11
    }
  },
  '& .text1': {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: '150%',
    color: theme.palette.moreColors.grey_3
  },
  '& .text2': {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '150%',
    color: theme.palette.moreColors.grey_3
  },
  '& .title': {
    marginBottom: 12,
    fontWeight: 700,
    fontSize: 25,
    lineHeight: '130%',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 20
    }
  },
  '& .title1': {
    marginBottom: 16,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '130%',
    color: theme.palette.moreColors.grey_3
  },
  '& .nextBtn': {
    width: '100%',
    height: 50,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '25px',
    [theme.breakpoints.down('desktopApp')]: {
      height: 45,
      maxWidth: 245
    }
  },
  '& .input': {
    [theme.breakpoints.down('desktopApp')]: {
      fontSize: 12,
      '& input, & select': {
        padding: 11,
        '&::placeholder': {
          fontSize: 12,
          color: theme.palette.primary.textLight
        }
      }
    }
  },
  '& .onboardingSelect': {
    width: '100%'
  },
  '& .label': {
    marginBottom: 12,
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.common.wight
  },
  '& .tooltipIcon': {
    fontSize: 15,
    verticalAlign: 'sub',
    color: theme.palette.moreColors.lightPurple
  },
  '& .tooltip': {
    position: 'relative',
    top: 2,
    display: 'inline-flex',
    '& .tooltipText': {
      minWidth: 204,
      position: 'absolute',
      top: 'calc(100% + 6px)',
      left: -34,
      zIndex: 100,
      visibility: 'hidden',
      opacity: 0,
      transition: 'opacity 0.25s',
      marginTop: 25,
      padding: 10,
      borderRadius: 12,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '150%',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.moreColors.grey_7,
      [theme.breakpoints.down('tablet')]: {
        left: 'initial',
        right: -116
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '100%',
        left: 17,
        borderWidth: 25,
        borderStyle: 'solid',
        borderColor: `transparent transparent ${theme.palette.moreColors.grey_7} transparent`,
        [theme.breakpoints.down('tablet')]: {
          // left: -126
        }
      },
      '& a': {
        color: theme.palette.moreColors.lightPurple
      }
    },
    '&:hover > .tooltipText': {
      visibility: 'visible',
      opacity: 1
    }
  }
});
